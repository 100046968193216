import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Checkbox extends Component {
  render() {
    const { customClasses, name, value, label, onChange, isChecked } =
      this.props;

    return (
      <div className={classNames('form__checkbox-field', customClasses)}>
        <label className="form__checkbox-field-label">
          <input
            className="form__checkbox-field-input"
            type="checkbox"
            value={value}
            name={name}
            onChange={onChange}
            checked={isChecked}
          />
          <span class="custom-checkbox"></span>
          {label}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  customClasses: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

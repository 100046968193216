// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import PropTypes from 'prop-types';
import { Component } from 'react';

export default class PlaceholderCard extends Component {
  render() {
    const { customClasses, showBody, long } = this.props;

    return (
      <div className={`ph-item ${customClasses}`} style={{ width: '100%' }}>
        <div className="ph-col-12">
          <div className="ph-picture" />
          <div className="ph-row">
            <div className="ph-col-2 empty" />
            <div className="ph-col-8 big" />
            <div className="ph-col-2 empty" />
          </div>
          {showBody && (
            <div className="ph-row">
              <div className="ph-col-8" />
              <div className="ph-col-8" />
              <div className="ph-col-8" />
            </div>
          )}

          <div className="ph-row">
            <div className="ph-col-2 empty" />
            <div className="ph-col-8 big" />
            <div className="ph-col-2 empty" />
          </div>

          {long && (
            <div className="ph-row">
              <div className="ph-col-2 empty" />
              <div className="ph-col-8 big" />
              <div className="ph-col-2 empty" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

PlaceholderCard.defaultProps = {
  customClasses: '',
  showBody: true,
  long: false,
};

PlaceholderCard.propTypes = {
  customClasses: PropTypes.string,
  showBody: PropTypes.bool,
  long: PropTypes.bool,
};

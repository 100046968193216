import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import Item from './Item'
import ItemPlaceholder from './ItemPlaceholder'
import ButtonLoadMore from './ButtonLoadMore'

class List extends Component {

  componentDidUpdate() {
    window.matchHeight.match('.list-consortia__list')
  }

  componentDidMount() {
    window.matchHeight.match('.list-consortia__list')
  }
  
  render() {
    const { items, part_link, loading, loadingMore, showLoadMore, onClickButtonLoadMore } = this.props

    return (
      <div class="list-consortia__list">
        <div class="column">
          { items.map(item => (
            <div class="list-consortia__item-wrap">
              { (loading && !loadingMore) ? <ItemPlaceholder /> :
                <Item
                  slug={item.slug}
                  image={item && item.item_image && item.item_image.middle_image}
                  title={item.title}
                  subtitle={item.subtitle}
                  item_offer={item.item_offer}
                  part_link={part_link}
                />
              }
            </div>
          ))}
        </div>
        { showLoadMore && 
          <div>
            <hr />
            <ButtonLoadMore onClick={onClickButtonLoadMore} loading={loadingMore} /> 
          </div>
        }
      </div>
    )
  }
}

List.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false,
  loadingMore: false,
  onClickButtonLoadMore() {}
}

List.propTypes = {
  items: PropTypes.array,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  part_link: PropTypes.string,
  onClickButtonLoadMore: PropTypes.func
}

export default List
import fetchival from 'fetchival';
import queryParams from '../../../utils/queryParams';

export class Client {
  constructor(baseUrl = null, authToken = null) {
    this.baseUrl = baseUrl;
    this.authToken = authToken;
  }

  fetch(endpoint, paramsRequest = false, expirationMin = 30) {
    const response = this._fetchCached(endpoint, paramsRequest, expirationMin);

    return response;
  }

  _fetchCached(endpoint, paramsRequest = false, expirationMin = 30) {
    const KEY_EXPIRATION_PREFIX = 'expiration-';

    const baseUrl = this._getBaseUrl();
    const autoApiToken = this._getAuthToken();

    let fullUrl = baseUrl + endpoint;

    if (paramsRequest) {
      fullUrl += queryParams(paramsRequest);
    }

    const storageData = JSON.parse(sessionStorage.getItem(fullUrl));
    const keyExpiration = KEY_EXPIRATION_PREFIX + fullUrl;
    const storageDataExpiration = sessionStorage.getItem(keyExpiration);
    const currentTime = new Date().getTime();

    if (storageData === null || storageDataExpiration <= currentTime) {
      return this._fetchWrapper(fullUrl, autoApiToken).then(data => {
        if (data.entries && data.entries.length <= 0) {
          return data;
        }

        const timeExpiration = currentTime + expirationMin * 60 * 1000;

        try {
          sessionStorage.setItem(fullUrl, JSON.stringify(data));
          sessionStorage.setItem(keyExpiration, timeExpiration);
        } catch (e) {
          console.warn(
            `Requisição não armazenada em cache sessionStorage excedido. ${fullUrl} executado sem cache.`,
          );
        }

        return data;
      });
    }

    return Promise.resolve(storageData);
  }

  _fetchWrapper(fullUrl, token) {
    return fetchival
      .fetch(fullUrl, {
        method: 'get',
        headers: {
          Authorization: `Token token=${token}`,
        },
      })
      .then(res => res.json());
  }

  _getBaseUrl() {
    if (this.baseUrl) return this.baseUrl;

    const autoApiUrl = document.querySelector(
      "meta[name='auto_api_url']",
    ).content;

    this.baseUrl = autoApiUrl;

    return autoApiUrl;
  }

  _getAuthToken() {
    if (this.authToken) return this.authToken;

    const autoApiToken = document.querySelector(
      `meta[name='auto_api_token']`,
    ).content;

    this.authToken = autoApiToken;

    return autoApiToken;
  }
}

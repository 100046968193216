export default function(e) {
  let target = e.target;

  if (
    !target.hasAttribute('data-open-modal') ||
    !target.getAttribute('data-target-id') ||
    target.getAttribute('data-target-id') == ''
  ) {
    return;
  }
  e.preventDefault();

  Micromodal.show(target.getAttribute('data-target-id'));
}

import { h } from 'preact'

export default function (props) {
  return (
    <button
      type="button"
      class="btn button button--block button--line conversion-step__button-prev"
      onClick={props.onClick}
    >
      Voltar
    </button>
  )
}

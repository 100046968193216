import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @typedef RemainingTimeData
 * @property {number} days
 * @property {number} hours
 * @property {number} minutes
 * @property {number} seconds
 */

/**
 * @typedef {('days'|'hours'|'minutes'|'seconds')} TimeUnit
 */

/**
 * @readonly
 * @enum {TimeUnit}
 */
export const UNIT_TYPES = {
  DAY: 'days',
  HOUR: 'hours',
  MINUTE: 'minutes',
  SECOND: 'seconds'
};

/**
 * @property {Date} endDate A data final
 * @property {number} initialDeltaTime A diferença inicial da data final e da data atual, em milisegundos.
 * @property {number} remainingTime O tempo restante para o contador.
 * @property {number} intervalId O ID do intervalo executado para atualizar o contador.
 */
export default class ChronometerBox extends Component {
  constructor(props) {
    super(props);
    this.setEndDate(this.props.endDate);
    this.initialDeltaTime = this.remainingTime =
      this.endDate.getTime() - Date.now();

    this.state = {
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  }

  componentDidMount() {
    this.startCounting();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  /**
   * Efetua operações lógicas para flexibilizar a criação da data final que
   * pode ser criada se `value` for:
   * - uma string no formato `YYYY-MM-DD`;
   * - for um `number` em Unix Time Stamp;
   * - for uma instância de `Date`
   * @param {Date|string|number} value O valor para criar a data a partir dele
   */
  setEndDate(value) {
    this.endDate = value instanceof Date ? value : new Date(value);
    this.endDate.setTime(this.endDate.getTime() + (3*60*60*1000) + (1000 * 60 * 60 * 24));
  }

  /**
   * Inicia o contador regressivo e continua executando até que o tempo restante
   * seja zerado.
   */
  startCounting() {
    this.intervalId = setInterval(this.decrementRemainingTime.bind(this), 1000);
  }

  /**
   * Decrementa o tempo restante até zerá-lo.
   */
  decrementRemainingTime() {
    this.remainingTime -= 1000;

    this.setState({ remainingTime: { ...this.getRemainingTimeData() } });

    if (this.remainingTime <= 0) {
      clearInterval(this.intervalId);
      return;
    }
  }

  /**
   * Obtém os dados de tempo restante em forma de objeto.
   * @returns {RemainingTimeData}
   */
  getRemainingTimeData() {
    return {
      days: this.getDeltaInUnits(UNIT_TYPES.DAY),
      hours: this.getDeltaInUnits(UNIT_TYPES.HOUR),
      minutes: this.getDeltaInUnits(UNIT_TYPES.MINUTE),
      seconds: this.getDeltaInUnits(UNIT_TYPES.SECOND)
    };
  }

  /**
   * Obtém o Δ do tempo em unidades como dias, horas, minutos e segundos.
   * @param {TimeUnit} unit
   * @returns {number} O valor inteiro da diferença da unidade de tempo
   */
  getDeltaInUnits(unit) {
    let result;
    switch (unit) {
      case UNIT_TYPES.DAY:
        result = Math.floor(this.remainingTime / (1000 * 60 * 60 * 24));
        break;
      case UNIT_TYPES.HOUR:
        result = Math.floor(
          (this.remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        break;
      case UNIT_TYPES.MINUTE:
        result = Math.floor(
          (this.remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        break;
      case UNIT_TYPES.SECOND:
        result = Math.floor((this.remainingTime % (1000 * 60)) / 1000);
        break;
    }

    // Aqui evitamos que retornem valores negativos.
    if (result < 0) {
      return '00';
    }

    // Se o resultado tiver apenas um dígito, por exemplo "1", fazemos um
    // prepend no valor para uniformizar a saída, resultando em "01".
    let strResult = String(result);
    if (strResult.length === 1) {
      strResult = `0${strResult}`;
    }

    return strResult;
  }

  /**
   * Imprime o markup com os dígitos de uma dada unidade de tempo.
   * @param {TimeUnit} unit
   */
  printDigits(unit) {
    let arrayValue = (String(this.state.remainingTime[unit]) || 0).split(''),
      numbersMarkup = arrayValue.map(n => (
        <div className="chronometer-box__number">{n}</div>
      ));

    return <div className="chronometer-box__digits">{numbersMarkup}</div>;
  }

  /**
   * Renderiza todo o bloco de informações relacionadas a uma unidade de tempo.
   * @param {TimeUnit} unit
   */
  renderUnitInfo(unit) {
    const { unitTypes } = this.props
    
    let label;
    switch (unit) {
      case UNIT_TYPES.DAY:
        label = unitTypes.days;
        break;
      case UNIT_TYPES.HOUR:
        label = unitTypes.hours;
        break;
      case UNIT_TYPES.MINUTE:
        label = unitTypes.minutes;
        break;
      case UNIT_TYPES.SECOND:
        label = unitTypes.seconds;
        break;
    }

    return (
      <div className="chronometer-box__unit-info">
        {this.printDigits(unit)}
        <div className="chronometer-box__unit">{label}</div>
      </div>
    );
  }

  render() {
    return (
      <div className={classNames('chronometer-box', {
        'panel': this.props.addClassPanel,
        'chronometer-box--vertical': this.props.direction === 'vertical'
      })}>
        <div className="chronometer-box__icon-wrapper">
          <i className="icon icon-clock" />{' '}
          <span className="chronometer-box__main-text">
            Essa oferta acaba em
          </span>
        </div>
        <div className="chronometer-box__info">
          {this.renderUnitInfo(UNIT_TYPES.DAY)}
          {this.renderUnitInfo(UNIT_TYPES.HOUR)}
          {this.renderUnitInfo(UNIT_TYPES.MINUTE)}
          {this.renderUnitInfo(UNIT_TYPES.SECOND)}
        </div>
      </div>
    );
  }
}

ChronometerBox.defaultProps = {
  addClassPanel: true,
  unitTypes: {
    days: 'Dias',
    hours: 'Horas',
    minutes: 'Min.',
    seconds: 'Seg.',
  },
};

ChronometerBox.propTypes = {
  addClassPanel: PropTypes.bool,
  direction: PropTypes.oneOf(['vertical']),
  unitTypes: PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
  }),
};

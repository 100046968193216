import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { setList } from '../../stores/SearchFilterFca';
import ListOffer from './List';
import ItemPlaceholder from './ItemPlaceholder';

class ListOfferApp extends Component {
  constructor(props) {
    super(props)

    const { items, total_pages } = this.props

    this.state = {
      items: [],
      loading: false
    }

    setList()

    window.store.SearchFilterFca.watch(state => {
      this.setState({
        items: state.items || [],
        loading: state.isFetching
      })
    })
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-offers')
  }

  componentDidMount() {
    window.matchHeight.match('.list-offers')
  }

  render() {
    const { items, loading } = this.state
    const { part_link } = this.props

    if (loading) {
      return (
        <div class="list-offers__list">
          <div class="row">
            <div class="col-md-6 col-lg-4 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div class="col-md-6 col-lg-4 list-offers__col-card">
              <ItemPlaceholder />
            </div>
            <div class="col-md-6 col-lg-4 list-offers__col-card">
              <ItemPlaceholder />
            </div>
          </div>
        </div>
      )
    } else {
      if (items.length > 0) {
        return (
          <ListOffer
            items={items}
            loading={loading}
            part_link={part_link}
          />
        )
      }
    }
  }
}

ListOfferApp.defaultProps = {
  loadInitial: true,
  items: [],
}

ListOfferApp.propTypes = {
  loadInitial: PropTypes.bool,
  items: PropTypes.array,
  total_pages: PropTypes.number,
  per_page: PropTypes.number,
  part_link: PropTypes.string
}

export default ListOfferApp

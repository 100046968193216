import { Component } from 'preact';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import ListPagination, { configureList } from '../ListPagination';
import store, {
  setMethod,
  setList,
  setPage,
  setFilters,
  setSort,
  setApiVersion,
} from '../../stores/ListPagination';
import ConversionFormApp from './ConversionFormApp';

export default class ListPaginationApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      page: 1,
      total: 0,
      perPage: 0,
    };

    setSort(this.props.defaultOrder || '-featured,ordination,-created_at');

    setApiVersion('v2');
    setMethod(this.props.methodAPI);

    this.applyFilters();

    setList().then(() => {
      configureList();
    });

    this.storeWatch();

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handlePaginationChange(page) {
    setPage(page);
    setList().then(() => {
      configureList();
    });
  }

  getSearchParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }

  /**
   * Aplica filtro com os valores passados na url
   */
  applyFilters() {
    const { mapFields } = this.props;
    const params = this.getSearchParams();

    const filters = [];

    Object.entries(params).forEach(filter => {
      const [key, value] = filter;
      if (
        mapFields[key] &&
        value !== '' &&
        (!isNil(value) || mapFields[key].toLowerCase().includes('km'))
      ) {
        // poderia ser usado o método includes do ES6,
        // mas ainda sem suporte para o IE
        if (mapFields[key].indexOf('in') !== -1 && value.indexOf(',') !== -1) {
          const values = value.split(',');
          values.forEach(inValue => {
            filters.push({ [`${mapFields[key]}[]`]: inValue });
          });
        } else {
          filters.push({ [mapFields[key]]: value });
        }
      }
    });
    setFilters(filters);
  }

  storeWatch() {
    store().watch(currentState => {
      this.setState({
        items: currentState.items,
        isFetching: currentState.isFetching,
        page: currentState.page,
        total: currentState.total,
        perPage: currentState.per_page,
      });
    });
  }

  render() {
    const { items, isFetching, page, total, perPage } = this.state;

    return (
      <ListPagination
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        items={items}
        isFetching={isFetching}
        page={page}
        total={total}
        per_page={perPage}
        formNoResult={
          <ConversionFormApp
            formId="list-used-models-groups__form"
            titleForm="Ops! No momento não encontramos nenhuma oferta com esses parâmetros."
            mainPhrase="Preencha o formulário abaixo que os nossos consultores entrarão em contato para te ajudar."
            linkPrivacyPolicy={this.props.linkPrivacyPolicy}
            className="form-conversion--light"
            units={this.props.units}
            showUnits={this.props.showUnits}
            showCpf={this.props.showCpf}
            category={this.props.category}
            shouldShowDataPermissions={this.props.shouldShowDataPermissions}
            dataPermissionsCustomText={this.props.dataPermissionsCustomText}
          />
        }
        onPaginationChange={this.handlePaginationChange}
      />
    );
  }
}

ListPaginationApp.defaultProps = {
  showUnits: true,
  showCpf: false,
  shouldShowDataPermissions: false,
  linkPrivacyPolicy: '',
  defaultOrder: '',
  units: [],
  dataPermissionsCustomText: '',
};

ListPaginationApp.propTypes = {
  linkPrivacyPolicy: PropTypes.string,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  /**
   * chave para filtro passadas pela quey string
   */
  mapFields: PropTypes.objectOf.isRequired,
  /**
   * Nome do método devido no service API
   */
  methodAPI: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  defaultOrder: PropTypes.string,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

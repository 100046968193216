/**
 * Implementação simples para lidar com change de input.
 * Ao utilizar esse método, o target precisa de um `name`, pois ele será
 * utilizado por `setState` para atribuir o valor do input à chave do `state`
 * do componente.
 *
 * Esse método precisa ser vinculado utilizando `bind`, por exemplo:
 * @example
 * // No construtor do seu componente:
 * this.handleInputChange = handleInputChange.bind(this);
 * @param {Event} e O evento do input
 */
export default function handleInputChange(e) {
  const target = e.target,
    value = target.type === 'checkbox' ? target.checked : target.value;
  this.setState({ [target.name]: value });
}

import { fieldError } from '../fieldError';

export function checkPattern(pattern, message = 'Formato incorreto') {
  const patternRegex = new RegExp(pattern);

  return value => {
    const isValid = patternRegex.test(value);

    return isValid ? null : fieldError(message);
  };
}

import { Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import ItemPlaceholder from './ItemPlaceholder';
import ButtonLoadMore from './ButtonLoadMore';

class List extends Component {
  componentDidMount() {
    window.matchHeight.match('.list-vehicles-new__list');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-vehicles-new__list');
  }

  render() {
    const {
      items,
      part_link,
      loading,
      loadingMore,
      showLoadMore,
      onClickButtonLoadMore,
    } = this.props;

    return (
      <div className="list-vehicles-new__list">
        <div className="row">
          {items.map(item => (
            <div className="col-6 col-md-4 col-lg-3 mb-4">
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  model={item.model}
                  brand={item.brand}
                  year={item.year}
                  release={item.release}
                  slug={item.slug}
                  image={item.item_image && item.item_image.middle_image}
                  title={item.title}
                  subtitle={item.subtitle}
                  show_price={item['show_price?']}
                  price={item.price}
                  part_link={part_link}
                />
              )}
            </div>
          ))}
        </div>
        {showLoadMore && (
          <div>
            <hr />
            <ButtonLoadMore
              onClick={onClickButtonLoadMore}
              loading={loadingMore}
            />
          </div>
        )}
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false,
  loadingMore: false,
  onClickButtonLoadMore() {},
};

List.propTypes = {
  items: PropTypes.arrayOf,
  loadingMore: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  part_link: PropTypes.string,
  onClickButtonLoadMore: PropTypes.func,
};

export default List;

/**
 * Faz um proxy de eventos entre dois objetos, executando o evento configurado
 * de um elemento no outro.
 *
 * ```html
 * <!-- HTML -->
 * <button id="first" data-proxy-event="click@#second">First</button>
 * <button id="second" onclick="alert('proxied click!')">Second</button>
 * <!--
 * No exemplo acima, ao clicar em `#first` ele vai disparar o evento `click` do
 * elemento `#second`, conforme indicado no atributo `data-proxy-event` do
 * elemento `#first`.
 * -->
 * <script>
 *   // Isso é o suficiente para habilitar o proxy de eventos para todos os
 *   // elementos que contém um atributo `data-proxy-event` no formato
 *   // `<eventType>@<targetSelector>`
 *  document.addEventListener('click', proxyEvent);
 * </script>
 * ```
 * @param {Event} e
 * @throws
 */
export default function proxyEvent({ target }) {
  if (!target.dataset.proxyEvent) {
    return;
  }

  const proxyEventData = target.dataset.proxyEvent,
    proxyEventArray = proxyEventData.split('@');

  // O array precisa ter length === a 2 porque o formato mínimo é
  // `<eventType>@<targetSelector>`
  if (!proxyEventData && proxyEventArray.length < 2) {
    return;
  }

  const eventTarget = document.querySelector(proxyEventArray[1]);

  if (!eventTarget || eventTarget.length === 0) {
    return;
  }

  const eventType = proxyEventArray[0];
  eventTarget.dispatchEvent(new Event(eventType));
}

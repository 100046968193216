import fetchival from 'fetchival';

const VEHICLE_TYPE = {
  CAR: 1
}

/**
 * @class fipeServiceApi
 */
class fipeServiceApi {
  constructor(options) {
    this.options = {
      getReferenceTableCodeUrl: 'https://veiculos.fipe.org.br/api/veiculos/ConsultarTabelaDeReferencia',
      getBrandsUrl: 'https://veiculos.fipe.org.br/api/veiculos//ConsultarMarcas',
      getModelsUrl: 'https://veiculos.fipe.org.br/api/veiculos//ConsultarModelos',
      getModelYearsUrl: 'https://veiculos.fipe.org.br/api/veiculos//ConsultarAnoModelo',
      getPriceUrl: 'https://veiculos.fipe.org.br/api/veiculos/ConsultarValorComTodosParametros',
      referenceTableCode: null,
      ...options
    }
  }

  async getBrands() {
    const params = new URLSearchParams();
    params.append('codigoTabelaReferencia', await this._getReferenceTableCode());
    params.append('codigoTipoVeiculo', VEHICLE_TYPE.CAR);

    const brands = await this._fetchWrapper(this.options.getBrandsUrl, params);

    return brands.map((brand) => ({
      value: brand.Value,
      label: brand.Label
    }));
  }

  async getModels(brandId) {
    const params = new URLSearchParams();
    params.append('codigoTabelaReferencia', await this._getReferenceTableCode());
    params.append('codigoTipoVeiculo', VEHICLE_TYPE.CAR);
    params.append('codigoMarca', brandId);

    const models = await this._fetchWrapper(this.options.getModelsUrl, params);

    return models.Modelos.map((model) => ({
      value: model.Value,
      label: model.Label
    }));
  }

  async getModelYears(brandId, modelId) {
    const params = new URLSearchParams();
    params.append('codigoTabelaReferencia', await this._getReferenceTableCode());
    params.append('codigoTipoVeiculo', VEHICLE_TYPE.CAR);
    params.append('codigoMarca', brandId);
    params.append('codigoModelo', modelId);

    const modelYears = await this._fetchWrapper(this.options.getModelYearsUrl, params);

    return modelYears.map((modelYear) => ({
      value: modelYear.Value,
      label: modelYear.Label
    }));
  }

  async getPrice(brandId, modelId, modelYearSlug) {
    const [modelYear, fuelTypeId] = modelYearSlug.split('-');

    const params = new URLSearchParams();
    params.append('codigoTabelaReferencia', await this._getReferenceTableCode());
    params.append('codigoTipoVeiculo', VEHICLE_TYPE.CAR);
    params.append('codigoMarca', brandId);
    params.append('codigoModelo', modelId);
    params.append('anoModelo', modelYear);
    params.append('codigoTipoCombustivel', fuelTypeId);
    params.append('tipoVeiculo', 'carro');
    params.append('codigoTipoCombustivel', fuelTypeId);
    params.append('tipoConsulta', 'tradicional');

    const data = await this._fetchWrapper(this.options.getPriceUrl, params);

    return {
      price: data.Valor,
      brand: data.Marca,
      model: data.Modelo,
      modelYear: data.AnoModelo,
      fuel: data.Combustivel,
      fipeCode: data.CodigoFipe,
      referenceMonth: data.MesReferencia,
      vehicleType: data.TipoVeiculo,
      fuelAbbr: data.SiglaCombustivel,
      queryDate: data.DataConsulta
    };
  }

  async _getReferenceTableCode() {
    if (!this.options.referenceTableCode) {
      let codes = await this._fetchWrapper(this.options.getReferenceTableCodeUrl);

      if (!codes || codes.length === 0) {
        throw new Error('Ocorreu um erro ao tentar buscar a tabela de referência.');
      }

      this.options.referenceTableCode = codes[0].Codigo;
    }

    return this.options.referenceTableCode;
  }

  _fetchWrapper(fullUrl, params) {
    return fetchival.fetch(fullUrl, {
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: params
    })
    .then(res => res.json());
  }
}

export default new fipeServiceApi();

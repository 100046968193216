import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';

import ScheduleServiceConversionForm from './ScheduleServiceConversionForm';

export default class ScheduleServiceConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });

    const { link } = this.props;
    const { errorSendingForm } = this.state;

    if (link && !errorSendingForm) {
      window.open(link, '_self');
    }
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });

    return serviceConversion
      .convert(params)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false
        });
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: true
        });
      });
  }

  render() {
    const { formId, product, channel, category, titleForm } = this.props;

    return (
      <ScheduleServiceConversionForm
        formId={formId}
        titleForm={titleForm}
        handleSubmit={this.handleSubmit}
        isSubmittingForm={this.state.isSubmittingForm}
        errorSendingForm={this.state.errorSendingForm}
        handleCloseOverlay={this.handleCloseOverlay}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        showOverlay={this.state.showOverlay}
        services={this.props.services}
        product={product}
        channel={channel}
        category={category}
        brand={this.props.brand}
        units={this.props.units}
        filterUnitsByLocation={this.props.filterUnitsByLocation}
        showUnits={this.props.showUnits}
        showVehicleDetails={this.props.showVehicleDetails}
        showCpf={this.props.showCpf}
        versions={this.props.versions}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        showLocationFields={this.props.showLocationFields}
      />
    );
  }
}

ScheduleServiceConversionFormApp.defaultProps = {
  brand: '-',
  showUnits: true,
  showCpf: false,
  showVehicleDetails: true,
  units: [],
  filterUnitsByLocation: false,
  versions: [],
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false,
};

ScheduleServiceConversionFormApp.propTypes = {
  formId: PropTypes.string,
  unit: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  filterUnitsByLocation: PropTypes.bool,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  services: PropTypes.array,
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  showVehicleDetails: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  showLocationFields: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

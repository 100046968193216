import { Component } from 'preact';
import PropTypes from 'prop-types';
import { sortItems } from '%/utils/sort';
import SimpleFilterButtonsApp from '../SearchFilter/SimpleFilterButtonsApp';

class SimpleFilterButtons extends Component {
  render() {
    const {
      filterCategories,
      filterModels,
      sort,
      showDropdownIcon,
      showActiveStatus,
      showButtonFilter,
    } = this.props;
    return (
      <SimpleFilterButtonsApp
        filterCategories={filterCategories}
        filterModels={filterModels}
        sort={sort}
        showButtonFilter={showButtonFilter}
        fieldCategoryFilter="q[categories_name_in][]"
        fieldModelFilter="q[models_name_in][]"
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
      />
    );
  }
}

SimpleFilterButtons.defaultProps = {
  sort: sortItems,
  showButtonFilter: false,
  showDropdownIcon: false,
  showActiveStatus: false,
  filterCategories: [],
  filterModels: [],
};

SimpleFilterButtons.propTypes = {
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  showButtonFilter: PropTypes.bool,
  filterModels: PropTypes.arrayOf(),
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
};

export default SimpleFilterButtons;

import { h } from 'preact';
import { Component } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';

class Item extends Component {
  render() {
    const { code, seal_list, badge, item_image, title, subtitle, item_offer, class_modifier, cta_item_text } = this.props;

    return (
      <div className={classNames('card', class_modifier)}>
        <div class="card__header">
          {badge && (
            <span class="card__badge badge badge-primary badge--pill">
              {badge}
            </span>
          )}

          <a href={`${this.props.part_link}/${this.props.slug}`}>
            <div class="card__image">
              {(item_image && item_image.middle_image && (
                <img class="card__image-value card__image-value--fit" src={item_image.middle_image} alt={title} />
              )) || <div class="card__image-value" />}
            </div>
          </a>
        </div>

        <div class="card__content">
          <div data-match-height="list-parts-card-content">
            <div class="card__title">{title}</div>
            <div class="card__subtitle">{subtitle}</div>
          </div>
          <hr class="card__separator" />
          <div class="card__content-list" data-match-height="list-parts-card-list">
            <ul class="list-parts__card-list card__list list">
              <li>Código: { code }</li>
              { seal_list.slice(0, 2).map(text => <li>{ text }</li>) }
            </ul>
            <hr class="card__separator" />
          </div>
        </div>

        <div class="card__footer list-parts__card-footer">
          <div data-match-height="list-parts-footer" className="card__triggers">
            {item_offer && item_offer.secondary_call && (
              <div class="card__trigger">{item_offer.secondary_call}</div>
            )}
            {item_offer && item_offer.main_call && (
              <div class="card__title">{item_offer.main_call}</div>
            )}{' '}
            {item_offer && item_offer.featured && (
              <div class="card__trigger-value">{item_offer.featured}</div>
            )}
            {item_offer && item_offer.description && (
              <div class="card__trigger-description">
                {item_offer.description}
              </div>
            )}
            {item_offer && item_offer.extra && (
              <div class="card__trigger-description">{item_offer.extra}</div>
            )}
          </div>
          <a
            href={`${this.props.part_link}/${this.props.slug}`}
            class="btn button button--primary button--block button--large card__cta"
          >
            {cta_item_text}
          </a>
        </div>
      </div>
    );
  }
}

Item.defaultProps = {
  item_image: {},
  seal_list: [],
  class_modifier: '',
  cta_item_text: 'ESTOU INTERESSADO',
};

Item.propTypes = {
  part_link: PropTypes.string,
  item_image: PropTypes.object,
  seal_list: PropTypes.array,
  class_modifier: PropTypes.string,
  cta_item_text: PropTypes.string,
};

export default Item;

import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import IMask from 'imask'

import { zFlow } from '%/zflow'
import { priceMask } from '%/utils/masks'
import handleInputChange from '%/utils/handleInputChange';
import CustomCheck from '../CustomCheck'

export default class ZFlow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      monthOptions: [12, 24, 36, 48],
      upfront: null,
      months: 12,
    };

    this.handleInputChange = handleInputChange.bind(this);
  }

  componentDidMount() {
    const priceFieldMask = IMask(this.input, priceMask);
    priceFieldMask.on("accept", () => this.setState({ upfront: priceFieldMask.value }));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSubmitting && this.props.isSubmitting) {
      const {
        upfront,
        months
      } = this.state;

      let version,
        brand;
      const formData = this.props.getFormData();

      if (typeof this.props.getCurrentVariantItem !== 'undefined') {
        const currentVariant = this.props.getCurrentVariantItem() || this.props.defaultVariant;
        version = currentVariant ? currentVariant.getVariantProperty('model', '') : '';
        brand = currentVariant ? currentVariant.getVariantProperty('brand', '') : '';
      }

      zFlow({
        ...formData,
        upfront,
        months,
        version,
        brand: brand && brand.name
      });
    }
  }

  render() {
    const {
      isZFlowEnabled,
      checkStyle,
    } = this.props;

    const {
      monthOptions,
      months,
      upfront
    } = this.state;

    return (
      <fieldset>
        <div className="form-group">
          <label className="conversion-form__control-label">Número de parcelas</label>
          <div className="form-check form-check-inline">
            {monthOptions.map(monthOption => (
              <CustomCheck
                name="months"
                value={monthOption}
                isChecked={months == monthOption}
                onInput={this.handleInputChange}
                type="radio"
                checkStyle={checkStyle}
              >
                {monthOption}
              </CustomCheck>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label className="conversion-form__control-label">Valor de entrada</label>
          <input
            ref={node => this.input = node}
            onInput={this.handleInputChange}
            value={upfront}
            type="text"
            className="form-control"
            name="upfront"
            required
            placeholder="Valor de entrada"
            data-bouncer-target="#invalid-price"
          />
          <div id="invalid-price" className="invalid-feedback" />
        </div>
      </ fieldset>
    )
  }
}

ZFlow.defaultProps = {}

ZFlow.propTypes = {
  isSubmitting: PropTypes.bool,
  getFormData: PropTypes.func,
  getCurrentVariantItem: PropTypes.func,
  defaultVariant: PropTypes.string,
  checkStyle: PropTypes.oneOf(['dark', 'light']),
}

const toBool = value => /^(true|1)$/i.test(value);

export const cardDDMOffersLink = (baseType, slug, baseUrl) => {
  const offersType = {
    Autodromo: {
      link: `/ofertas/${slug}`,
    },
    Hub: {
      link: `/ofertas-nacionais/${slug}`,
    },
    Marketplace: {
      link: `/ofertas-exclusivas/${slug}`,
    },
  };

  const { link } = offersType[baseType];
  const fullUrl = `${baseUrl}${link}`;

  return fullUrl;
};

const helpers = {
  toBool,
  cardDDMOffersLink,
};

export default helpers;

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { setMethodApi, resetList, setTotalPages, addFilter, setSort } from '../../stores/SearchFilter'
import List from './List'

class ListApp extends Component {
  constructor(props) {
    super(props)

    const { list, total_pages } = this.props

    this.state = {
      list: [],
      loading: false,
      loadingMore: false
    }

    setMethodApi('getAccessories')
    setSort('-featured,ordination')
    addFilter({ 'q[stock_eq]': true })
    setTotalPages(total_pages)
    resetList(list)

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
      })
    })
  }

  showLoadMore() {
    const { total_pages, page, loadingMore } = this.state
    return (total_pages > page) || loadingMore
  }

  render() {
    const { list, loading, loadingMore } = this.state
    const { resource_link, card_class_modifier, cta_item_text } = this.props

    if (list.length > 0) {
      return (
        <List
          items={list}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={this.showLoadMore()}
          resource_link={resource_link}
          card_class_modifier={card_class_modifier}
          cta_item_text={cta_item_text}
        />
      )
    }
  }
}

ListApp.defaultProps = {
  list: [],
  card_class_modifier: '',
}

ListApp.propTypes = {
  list: PropTypes.array,
  total_pages: PropTypes.number,
  per_page: PropTypes.number,
  resource_link: PropTypes.string,
  card_class_modifier: PropTypes.string,
}

export default ListApp

// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setURLSearchParams,
  mapFilters,
  findFilterArray,
} from '%/utils/filters';
import SimpleFilterButtons from './SimpleFilterButtons';
import {
  setSort,
  setList,
  incrementFilter,
  removeFilter,
} from '../../stores/SearchFilter';

class SimpleFilterButtonsApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      showOrder: false,
    };

    this.watchStore();

    this.handleClickFilter = this.handleClickFilter.bind(this);
    this.handleSetSortParams = this.handleSetSortParams.bind(this);
    this.handleChangeCategories = this.handleChangeCategories.bind(this);
    this.handleChangeModels = this.handleChangeModels.bind(this);
    this.handleFilterDropClick = this.handleFilterDropClick.bind(this);
    this.handleClickOrder = this.handleClickOrder.bind(this);
  }

  handleClickFilter() {
    this.props.onClickFilter();
  }

  handleSetSortParams(paramValue) {
    setSort(paramValue);
    setList().then(() => {
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams();
      }
    });
    this.setState({ showOrder: false });
  }

  handleChangeCategories(categories) {
    const { fieldCategoryFilter } = this.props;
    if (fieldCategoryFilter) {
      removeFilter(fieldCategoryFilter);
      categories.forEach(category => {
        incrementFilter({ [fieldCategoryFilter]: category });
      });
      setList().then(() => {
        if (this.props.shouldSetURLSearchParams) {
          this.applyURLSearchParams();
        }
      });
    } else {
      console.warn('Defina a prop fieldCategoryFilter');
    }
  }

  handleChangeModels(models) {
    const { fieldModelFilter } = this.props;
    if (fieldModelFilter) {
      removeFilter(fieldModelFilter);
      models.forEach(model => {
        incrementFilter({ [fieldModelFilter]: model });
      });
      setList().then(() => {
        if (this.props.shouldSetURLSearchParams) {
          this.applyURLSearchParams();
        }
      });
    } else {
      console.warn('Defina a prop fieldModelFilter');
    }
  }

  handleFilterDropClick() {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  handleClickOrder() {
    const { showOrder } = this.state;
    this.setState({ showOrder: !showOrder });
  }

  watchStore() {
    window.store.SearchFilter.watch(state => {
      this.setState({
        categoriesSelected: findFilterArray(
          state.filterArray,
          this.props.fieldCategoryFilter,
        ),
        modelsSelected: findFilterArray(
          state.filterArray,
          this.props.fieldModelFilter,
        ),
        valueSort: state.sort,
      });
    });
  }

  /**
   * Faz o mapeamento da chaves utilizadas nas buscas para o que será exibido na url
   */
  mapFilters() {
    return {
      ...mapFilters,
      [this.props.fieldKeywordFilter]: 'q',
      [this.props.fieldCategoryFilter]: 'category[]',
      [this.props.fieldModelFilter]: 'model[]',
      ...this.props.mapFilters,
    };
  }

  applyURLSearchParams() {
    const { filter, filterArray, sort } = window.store.SearchFilter.getState();

    const filterSimple = {
      ...filter,
      sort,
    };

    setURLSearchParams(filterSimple, filterArray, this.mapFilters());
  }

  render() {
    const { showOrder, showFilter } = this.state;
    const {
      filterCategories,
      filterModels,
      sort,
      showButtonFilter,
      orderDropRight,
      showDropdownIcon,
      showActiveStatus,
      showFiltersCleaner,
    } = this.props;

    return (
      <SimpleFilterButtons
        onFilterClick={this.handleClickFilter}
        onFilterDropClick={this.handleFilterDropClick}
        onClickOrder={this.handleClickOrder}
        onSetSortParams={this.handleSetSortParams}
        categoriesSelected={this.state.categoriesSelected}
        modelsSelected={this.state.modelsSelected}
        filterCategories={filterCategories}
        filterModels={filterModels}
        valueSort={this.state.valueSort}
        sort={sort}
        showFilter={showFilter}
        showButtonFilter={showButtonFilter}
        onChangeCategories={this.handleChangeCategories}
        onChangeModels={this.handleChangeModels}
        orderDropRight={orderDropRight}
        showOrder={showOrder}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
        showFiltersCleaner={showFiltersCleaner}
      />
    );
  }
}

SimpleFilterButtonsApp.defaultProps = {
  onClickFilter() {},
  showButtonFilter: true,
  orderDropRight: true,
  shouldSetURLSearchParams: false,
  fieldCategoryFilter: 'q[name_in][]',
  fieldModelFilter: 'q[models_name_in][]',
  showDropdownIcon: false,
  showActiveStatus: false,
  showFiltersCleaner: false,
  mapFilters: {},
};

SimpleFilterButtonsApp.propTypes = {
  onClickFilter: PropTypes.func,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterModels: PropTypes.arrayOf,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
  fieldKeywordFilter: PropTypes.string,
  showButtonFilter: PropTypes.bool,
  shouldSetURLSearchParams: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  showFiltersCleaner: PropTypes.bool,
  fieldCategoryFilter: PropTypes.string, // representa a chave que será usada para filtrar as categorias,
  fieldModelFilter: PropTypes.string, // representa a chave que será usada para filtrar por modelos
  orderDropRight: PropTypes.bool,
  mapFilters: PropTypes.objectOf,
};

export default SimpleFilterButtonsApp;

export const statesIdsList = [
  { id: 12, sigla: 'AC' },
  { id: 27, sigla: 'AL' },
  { id: 16, sigla: 'AP' },
  { id: 13, sigla: 'AM' },
  { id: 29, sigla: 'BA' },
  { id: 23, sigla: 'CE' },
  { id: 53, sigla: 'DF' },
  { id: 32, sigla: 'ES' },
  { id: 52, sigla: 'GO' },
  { id: 21, sigla: 'MA' },
  { id: 51, sigla: 'MT' },
  { id: 50, sigla: 'MS' },
  { id: 31, sigla: 'MG' },
  { id: 15, sigla: 'PA' },
  { id: 25, sigla: 'PB' },
  { id: 41, sigla: 'PR' },
  { id: 26, sigla: 'PE' },
  { id: 22, sigla: 'PI' },
  { id: 33, sigla: 'RJ' },
  { id: 24, sigla: 'RN' },
  { id: 43, sigla: 'RS' },
  { id: 11, sigla: 'RO' },
  { id: 14, sigla: 'RR' },
  { id: 42, sigla: 'SC' },
  { id: 35, sigla: 'SP' },
  { id: 28, sigla: 'SE' },
  { id: 17, sigla: 'TO' },
];

export const statesList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react';
import DetailTabs from './DetailTabs';
import DetailTabsConsortium from './Consortium/DetailTabs';
import ConsortiumConversionFormApp from './ConsortiumConversionFormApp';
import ConsortiumSearchFormApp from './ConsortiumSearchFormApp';
import VideoGallery from './Video/Gallery';
import NewVehicleConversionFormApp from './NewVehicleConversionFormApp';
import UsedVehicleConversionFormApp from './UsedVehicleConversionFormApp';
import PartConversionFormApp from './PartConversionFormApp';
import StaticConversionFormApp from './StaticConversionFormApp';
import WhatsAppBoxWithModal from './WhatsAppBoxWithModal';
import ChronometerBox from './ChronometerBox';
import WhatsAppFormApp from './WhatsAppFormApp';
import DirectSalesFormApp from './DirectSalesFormApp';
import ScheduleServiceConversionFormApp from './ScheduleServiceConversionFormApp';
import PillsFilter from './PillsFilter';
import SearchFilterUsedmodel from './SearchFilterUsedmodel';
import SimpleFilterButtonsUsedModel from './SearchFilterUsedmodel/SimpleFilterButtons';
import ResultSearchSimpleApp from './SearchFilter/ResultSearchSimpleApp';
import ListUsedModelApp from './ListUsedModels/ListApp';
import ConversionFormNoResultApp from './ConversionFormNoResult/ConversionFormApp';
import WhatsAppFormModal from './WhatsAppFormModal';
import DirectSalesFormModal from './DirectSalesFormModal';
import ScheduleServiceFormModal from './ScheduleServiceFormModal';
import ScheduleServiceFixedTopFormModal from './ScheduleServiceFixedTopFormModal';
import TimeMissingCard from './TimeMissingCard';
import AccordionVehicle from './AccordionVehicle';
import ListNewVehicle from './NewVehicle/ListApp';
import ListNewVehicleV2 from './NewVehicleV2/ListAppV2';
import SimpleFilterNewVehicle from './NewVehicle/SimpleFilter';
import SimpleFilterButtonsApp from './SearchFilter/SimpleFilterButtonsApp';
import SearchFilterOffer from './Offers/SimpleFilter';
import SimpleFilterButtonsOffer from './Offers/SimpleFilterButtons';
import SimpleFilterOffers from './Offers/SimpleFilter';
import ListAppOffers from './Offers/ListApp';
import SearchFcaOffer from './FcaOffers/SimpleFilter';
import SeeConditions from './SeeConditions';
import ListAppFcaOffers from './FcaOffers/ListApp';

import SearchFilterPart from './Parts/SimpleFilter';
import SimpleFilterButtonsPart from './Parts/SimpleFilterButtons';
import ListAppParts from './Parts/ListApp';

import SearchFilterAccessory from './Accessories/SimpleFilter';
import SimpleFilterButtonsAccessory from './Accessories/SimpleFilterButtons';
import ListAppAccessories from './Accessories/ListApp';
import SimpleFilterDirectSalesCategories from './DirectSalesCategories/SimpleFilter';
import ListDirectSalesCategories from './DirectSalesCategories/ListApp';
import SimpleFilterDirectSales from './DirectSales/SimpleFilter';
import ListAppDirectSales from './DirectSales/ListApp';
import SimpleFilterConsortia from './Consortia/SimpleFilter';
import ListAppConsortia from './Consortia/ListApp';
import ConsortiumListApp from './Consortium/ListApp';
import PlanMonthsChooser from './Consortium/PlanMonthsChooser';
import PlanMonthsRadiobox from './Consortium/PlanMonthsRadiobox';
import ConversionStep from './ConversionStep';
import SearchFilterMainApp from './SearchFilterMainApp';
import SearchFilterSidebarUsedModelsApp from './SearchFilterSidebarApp/UsedModels';
import SearchFilterSidebarUsedModelsSpecialApp from './SearchFilterSidebarApp/UsedModelsSpecial';
import SearchFilterSidebarUsedModelsFullStockApp from './SearchFilterSidebarApp/UsedModelsFullStock';
import ListPaginationUsedModelsApp from './ListPaginationApp/UsedModels';
import ListPaginationUsedModelsSpecialApp from './ListPaginationApp/UsedModelsSpecial';
import ListPaginationUsedModelsFullStockApp from './ListPaginationApp/UsedModelsFullStock';
import MapUnitsApp from './MapUnitsApp';
import NewModelDetails from './NewVehicle/NewModelDetails';
import NewModelDetailsLand from './NewVehicle/NewModelDetailsLand';
import CustomFormApp from './Forms/CustomForm/CustomFormApp';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

(() => {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    ChronometerBox,
    DetailTabs,
    DetailTabsConsortium,
    VideoGallery,
    NewVehicleConversionFormApp,
    PillsFilter,
    DirectSalesFormApp,
    ScheduleServiceConversionFormApp,
    SearchFilterUsedmodel,
    SearchFilterOffer,
    SimpleFilterOffers,
    SearchFcaOffer,
    SimpleFilterButtonsUsedModel,
    SimpleFilterButtonsOffer,
    ResultSearchSimpleApp,
    ListUsedModelApp,
    ConversionFormNoResultApp,
    ListAppOffers,
    ListAppFcaOffers,
    TimeMissingCard,
    AccordionVehicle,
    UsedVehicleConversionFormApp,
    WhatsAppBoxWithModal,
    WhatsAppFormApp,
    WhatsAppFormModal,
    DirectSalesFormModal,
    ScheduleServiceFormModal,
    ScheduleServiceFixedTopFormModal,
    PartConversionFormApp,
    StaticConversionFormApp,
    SearchFilterPart,
    SimpleFilterButtonsPart,
    ListAppParts,
    SearchFilterAccessory,
    SimpleFilterButtonsAccessory,
    ListAppAccessories,
    ListNewVehicle,
    ListNewVehicleV2,
    SimpleFilterNewVehicle,
    SimpleFilterButtonsApp,
    SimpleFilterDirectSalesCategories,
    ListDirectSalesCategories,
    ListAppDirectSales,
    SimpleFilterDirectSales,
    SimpleFilterConsortia,
    ListAppConsortia,
    PlanMonthsChooser,
    PlanMonthsRadiobox,
    ConsortiumConversionFormApp,
    ConsortiumSearchFormApp,
    ConversionStep,
    SearchFilterMainApp,
    SearchFilterSidebarUsedModelsApp,
    SearchFilterSidebarUsedModelsSpecialApp,
    SearchFilterSidebarUsedModelsFullStockApp,
    ListPaginationUsedModelsApp,
    ListPaginationUsedModelsSpecialApp,
    ListPaginationUsedModelsFullStockApp,
    MapUnitsApp,
    NewModelDetails,
    NewModelDetailsLand,
    ConsortiumListApp,
    SeeConditions,
    CustomFormApp,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();

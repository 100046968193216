import { h, Component } from 'preact'
import autoServiceGroupApi from '../../utils/autoServiceGroupApi'
import MapUnits from '../MapUnits'

export default class MapUnitsApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      brands: [],
      states: [],
      cities: [],
      units: [],
    }

    this.setData()
  }

  /**
   * Retorna as unidades ordenadas
   */
  get units() {
    return [...this.state.units].sort((a, b) => {
      if (a.priority > b.priority)
        return 1
      if (a.priority < b.priority)
        return -1

      return 0
    })
  }

  getData() {
    return autoServiceGroupApi
      .getChannels()
      .then(res => res)
  }

  async setData() {
    const { entries } = await this.getData()
    
    entries.forEach(channel => {
      const { brand, units } = channel

      this.addBrand(units, brand)
      this.addState(units, brand.id)
      this.addCity(units, brand.id)
      this.addUnit(units, brand.id)
    })
  }

  addBrand(units, brand = {}) {
    units.forEach(unit => {
      const hasBrand = this.state.brands.find(({ id }) => id === brand.id)
      if (hasBrand || !this.hasMap(unit)) return

      this.setState((state) => ({
        brands: [
          ...state.brands,
          {
            id: brand.id,
            title: brand.name,
          }
        ]
      }))
    })
  }

  addState(units, brandId) {
    units.forEach(unit => {
      const hasState = this.state.states.find(
        (state) => state.title === unit.state && state.brandId === brandId
      )

      if (hasState || !this.hasMap(unit)) return

      this.setState((state) => ({
        states: [
          ...state.states,
          {
            title: unit.state.toUpperCase(),
            brandId,
          }
        ]
      }))
    })
  }

  addCity(units, brandId) {
    units.forEach(unit => {
      const hasCity = this.state.cities.find(city => 
        city.title === unit.city &&
        city.state === unit.state.toUpperCase() && 
        city.brandId === brandId
      )

      if (hasCity || !this.hasMap(unit)) return

      this.setState((state) => ({
        cities: [
          ...state.cities,
          {
            title: unit.city,
            state: unit.state.toUpperCase(),
            brandId,
          }
        ]
      }))
    })
  }

  addUnit(units, brandId) {
    units.forEach(unit => {
      const hasUnit = this.state.units.find(unitState =>
        unitState.title === (unit.display_name || unit.name) &&
        unitState.state === unit.state.toUpperCase() &&
        unitState.city === unit.city &&
        unitState.brandId === brandId
      )

      if (hasUnit || !this.hasMap(unit)) return

      this.setState((state) => ({
        units: [
          ...state.units,
          {
            id: unit.id,
            title: unit.display_name || unit.name,
            state: unit.state.toUpperCase(),
            city: unit.city,
            map: unit.map,
            brandId,
            priority: unit.priority,
          }
        ]
      }))
    })
  }

  /**
   * Verifica se a unidade tem mapa
   * @param {Object} unit
   */
  hasMap(unit) {
    return unit.map
  }

  render() {
    return (
      <MapUnits
        brands={this.state.brands}
        states={this.state.states}
        cities={this.state.cities}
        units={this.units}
      />
    )
  }
}

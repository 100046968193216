/**
 * Transforma um array para o formato de query string
 *
 * @param {array} queryParams Array de objetos com os parametros da request
 */
export default function(queryParams) {
  let arr = queryParams.map(function(currentParam){
    let arrItem;

    Object.keys(currentParam).map(function(k){
      arrItem = k + '=' + encodeURIComponent(currentParam[k]);
    });

    return arrItem;
  });

  return '?' + arr.join('&');
}
import { h } from 'preact';
import { Component } from 'react';
import classNames from 'classnames';
import {
  setPlans,
  setAvailableMonths,
  setSelectedMonths
} from '../../stores/consortium';

export default class PlanMonthsChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // selectedMonths representa a quantidade de meses que o usuário escolheu,
      // que varia de acordo com o plano.
      selectedMonths: null,
      availableMonths: []
    };

    window.store.Consortium.watch(setAvailableMonths, ({ availableMonths }) => {
      this.setState({
        availableMonths
      });
    });

    window.store.Consortium.watch(setSelectedMonths, ({ selectedMonths }) => {
      this.setState({
        selectedMonths
      });
    });

    window.store.Consortium.watch(setPlans, ({ plans }) => {
      this.setState({
        availableMonths: plans.plans
      });
    });
  }

  componentDidMount() {
    if (this.state.selectedMonths == null) {
      setSelectedMonths({
        selectedMonths: this.getFirstAvailableMonths()
      });
    }
  }

  getFirstAvailableMonths() {
    return (
      this.state.availableMonths.length > 0 &&
      this.state.availableMonths[0].months
    );
  }

  renderPills() {
    return (
      this.state.availableMonths.length > 0 &&
      this.state.availableMonths.map(({ months }) => {
        return (
          <a
            href="javascript:;"
            class={classNames('showcase__plan-chooser-month', {
              'showcase__plan-chooser-month--active':
                this.state.selectedMonths === months
            })}
            key={months}
            onClick={() => setSelectedMonths({ selectedMonths: months })}
          >
            {months}&times;
          </a>
        );
      })
    );
  }

  render() {
    return (
      <div class="showcase__plan-chooser-months">{this.renderPills()}</div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/UsedVehicles/MainUsedVehiclesCard';
import store, {
  setApiVersion,
  setList,
  setMethod,
  setPerPage,
  setSort,
} from '../../../stores/ListPagination';
import { configureList } from '../../ListPagination';

export default class MainUsedVehiclesCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedModels: [],
      isFetching: true,
    };

    store();

    setSort(this.props.sortParams);
    setPerPage(this.props.maxItems);

    setApiVersion('v2');
    setMethod('getUsedModels');

    setList().then(() => {
      configureList();
    });

    this.storeWatch();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  storeWatch() {
    store().watch(currentState => {
      this.setState({
        usedModels: currentState.items,
        isFetching: currentState.isFetching,
      });
    });
  }

  initCarousel() {
    const carousel = new window.Carousel(this.elCarousel, {
      slidesPerView: 1.1,
      centerInsufficientSlides: true,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      navigation: {
        nextEl: '.vehicles-new__carousel-next',
        prevEl: '.vehicles-new__carousel-prev',
      },
      scrollbar: {
        el: '.used-vehicle__carousel-scrollbar',
        draggable: true,
      },
      observer: true,
      observeParents: true,
      breakpoints: {
        576: {
          slidesPerView: 2.1,
        },
        768: {
          slidesPerView: 3.1,
        },
        992: {
          slidesPerView: 4.1,
        },
        1200: {
          slidesPerView: 4.7,
        },
      },
      on: {
        observerUpdate: () => {
          window.matchHeight.match('.used-vehicles-carousel');
        },
      },
    });

    return carousel;
  }

  render() {
    const { usedModels, isFetching } = this.state;
    const { showUnitTag, cardLink } = this.props;

    return (
      <div>
        <div
          ref={node => {
            this.elCarousel = node;
          }}
          className="carousel used-vehicles-carousel swiper-container"
        >
          <ul className="swiper-wrapper used-models__carousel-items-wrapper">
            {(isFetching || usedModels.length <= 0) &&
              [1, 2, 3, 4, 5].map(item => (
                <li
                  className="used-models__carousel-placeholder-item carousel__item swiper-slide"
                  key={item}
                >
                  <div className="ph-item mr-3" style={{ width: '100%' }}>
                    <div className="ph-col-12">
                      <div className="ph-picture" />
                      <div className="ph-row">
                        <div className="ph-col-2 empty" />
                        <div className="ph-col-8 big" />
                        <div className="ph-col-2 empty" />
                      </div>
                      <div className="ph-row">
                        <div className="ph-col-8" />
                        <div className="ph-col-8" />
                        <div className="ph-col-8" />
                      </div>
                      <div className="ph-row">
                        <div className="ph-col-2 empty" />
                        <div className="ph-col-8 big" />
                        <div className="ph-col-2 empty" />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            {!isFetching &&
              usedModels.length > 0 &&
              usedModels.map(usedModel => (
                <li
                  className="carousel__item used-models__carousel-item swiper-slide"
                  key={usedModel.slug}
                >
                  <Card
                    brand={usedModel.brand}
                    badge={usedModel.badge}
                    exchange={usedModel.exchange}
                    fabricationYear={usedModel.fabrication_year}
                    fuelText={usedModel.fuel_text}
                    model={usedModel.model}
                    modelYear={usedModel.model_year}
                    moduleLink={cardLink}
                    id={usedModel.id}
                    itemImage={usedModel.item_image}
                    itemUnit={usedModel.item_unit}
                    kind={usedModel.kind}
                    km={usedModel.km}
                    name={usedModel.name}
                    oldPrice={usedModel.old_price}
                    price={usedModel.price_value || usedModel.price}
                    seals={usedModel.seals}
                    shielded={usedModel.shielded}
                    showUnitTag={showUnitTag}
                    slug={usedModel.slug}
                    subscriptionPrice={usedModel.subscription_price}
                    subtitle={usedModel.subtitle}
                  />
                </li>
              ))}
          </ul>
          <div className="carousel__prev vehicles-new__carousel-prev swiper-button-prev">
            <i className="icon icon-arrow-r" />
          </div>
          <div className="carousel__next vehicles-new__carousel-next swiper-button-next">
            <i className="icon icon-arrow-r" />
          </div>
        </div>
        <div className="container">
          <div className="used-vehicle__carousel-scrollbar carousel__scrollbar" />
        </div>
      </div>
    );
  }
}

MainUsedVehiclesCarousel.defaultProps = {
  sortParams: '-featured,-price,-ordination',
  maxItems: 16,
  showUnitTag: false,
  cardLink: '/seminovos',
};

MainUsedVehiclesCarousel.propTypes = {
  sortParams: PropTypes.string,
  maxItems: PropTypes.number,
  showUnitTag: PropTypes.bool,
  cardLink: PropTypes.string,
};

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FormHeader extends Component {
  render() {
    const { customClasses, title, subtitle } = this.props;

    return (
      <header className={classNames('form__header', customClasses)}>
        {title && <h2 className="form__header-title">{title}</h2>}
        {subtitle && <p className="form__header-subtitle">{subtitle}</p>}
      </header>
    );
  }
}

FormHeader.propTypes = {
  customClasses: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

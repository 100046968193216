import { createStore, createEffect, createEvent } from 'effector'
import fcaServiceApi from '../utils/fcaServiceApi'

const fetchList = () => {
  const { keyword, offersCategories } = window.store.SearchFilterFca.getState()

  let customParams = {}

  if(keyword) {
    customParams = {
      ...customParams,
      'q': `*${keyword}*`
    }
  }

  return fcaServiceApi.getOffers(customParams, offersCategories)
}

// effects
export const setList = createEffect('set list').use(fetchList)
export const setKeyword = createEvent('set keyword')
export const changeCategoryFilter = createEvent('change category filter')

let SearchFilterFca = (function(){
  let instance;

  return function(defaultValue = {
    keyword: '',
    offersCategories: [],
  }){
    if(!instance){
      instance = createStore(defaultValue)

      instance.on(setKeyword, (state, keyword) => {
        return {
          ...state,
          keyword
        }
      })

      instance.on(setList.pending, (state, isFetching) => {
        return {
          ...state,
          isFetching,
        }
      })

      instance.on(setList.fail, (state) => {
        return {
          ...state,
          isFetching: false,
          didInvalidate: true,
        }
      })

      instance.on(changeCategoryFilter, (state, categories) => ({
        ...state,
        offersCategories: categories
      }))

      instance.on(setList.done, (state, { result }) => {

        const hits = result.hits.hit.filter(item => new Date(item.fields.end_date) >= new Date());

        return {
          ...state,
          items: hits,
          total: hits.length,
          categories: (result.facets && result.facets.category.buckets) || [],
          isFetching: false,
          didInvalidate: false,
        }
      })
    }

    return instance
  }
})()

export default SearchFilterFca

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import handleInputChange from '../../utils/handleInputChange'
import Form from './Form'
import MapIframe from './MapIframe'

export default class MapUnts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      brand: '',
      state: '',
      city: '',
      unit: '',
      map: '',
    }

    this.handleInputChange = handleInputChange.bind(this)
    this.handleBrandChange = this.handleBrandChange.bind(this)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleCityChange = this.handleCityChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /**
   * Dados para select de marcas
   */
  get brands() {
    return this.props.brands.map(brand => ({
      label: brand.title,
      value: brand.id,
    }))
  }

  /**
   * Dados para select de estados
   */
  get states() {
    return this.props.states
      .filter(state => state.brandId == this.state.brand)
      .map(state => ({
        label: state.title,
        value: state.title,
      }))
  }

  /**
   * Dados para select de cidades
   */
  get cities() {
    return this.props.cities
      .filter(city => 
        city.brandId == this.state.brand &&
        city.state === this.state.state
      )
      .map(city => ({
        label: city.title,
        value: city.title,
      }))
  }

  /**
   * Dados para select de unidades
   */
  get units() {
    return this.props.units
      .filter(unit =>
        unit.brandId == this.state.brand &&
        unit.state === this.state.state &&
        unit.city === this.state.city  
      )
      .map(unit => ({
        label: unit.title,
        value: unit.id,
      }))
  }

  get disableSubmit() {
    return !this.state.unit
  }

  get mapSrc() {
    if (this.state.map) {
      return this.state.map
    }

    if (this.props.units.length > 0) {
      return this.props.units[0].map
    }
  }

  handleBrandChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      state: '',
      city: '',
      unit: ''
    })
  }

  handleStateChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      city: '',
      unit: ''
    })
  }

  handleCityChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      unit: ''
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const unit = this.props.units.find(unit => unit.id == this.state.unit)
    if (unit) {
      this.setState({ map: unit.map })
    }
  }

  render() {
    return (
      <div className="map-units">
        <Form
          disableSubmit={this.disableSubmit}
          
          brands={this.brands}
          states={this.states}
          cities={this.cities}
          units={this.units}

          brand={this.state.brand}
          state={this.state.state}
          city={this.state.city}
          unit={this.state.unit}

          onBrandChange={this.handleBrandChange}
          onStateChange={this.handleStateChange}
          onCityChange={this.handleCityChange}
          onUnitChange={this.handleInputChange}
          onSubmit={this.handleSubmit}
        />
        <MapIframe src={this.mapSrc} />
      </div>
    )
  }
}

MapUnts.defaultProps = {
  brands: [],
  states: [],
  cities: [],
  units: [],
}

MapUnts.propTypes = {
  brands: PropTypes.array,
  states: PropTypes.array,
  cities: PropTypes.array,
  units: PropTypes.array,
}

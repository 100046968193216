import queryParams from './queryParams';
import { FilterDataCacheHandler } from './FilterDataCacheHandler';
import { fetchWrapper } from './fetchWrapper';

/**
 * Salva as request get na API em um cache no sessionStorage
 * além disso adiciona o cabeçalho de Authorization padrão para a API
 * e define um tempo de expiração padrão de 30 min.
 *
 * @param {string} endpoint
 * @param {object} paramsRequest Os parâmetros da request GET na API
 * @param {number} expirationMin Tempo máximo em minutos em que o cache deve se manter salvo
 * @return {promise}
 */

function fetchCached(endpoint, paramsRequest = false, expirationMin = 30, skipCache = false) {
  const filterDataCacheHandler = window.filterDataCacheHandler
    ? window.filterDataCacheHandler
    : new FilterDataCacheHandler('requestResult');

  const autoApiUrl = document.querySelector(
    "meta[name='auto_api_url']",
  ).content;
  const autoApiToken = document.querySelector(
    "meta[name='auto_api_token']",
  ).content;
  const currentTime = new Date().getTime();
  let fullUrl = autoApiUrl + endpoint;

  if (paramsRequest) {
    fullUrl += queryParams(paramsRequest);
  }

  return filterDataCacheHandler
    .getCachedAPIData(fullUrl, skipCache)
    .then(data => {
      const dataExistsInCache = data !== null;

      if (dataExistsInCache) {
        return JSON.parse(data);
      }

      return fetchWrapper(fullUrl, autoApiToken);
    })
    .then(data => {
      const dataIsEmpty = data.entries && data.entries.length <= 0;
      if (dataIsEmpty) {
        return data;
      }

      const timeExpirationInMilliseconds =
        currentTime + expirationMin * 60 * 1000;

      filterDataCacheHandler
        .saveDataFromAPIToCache(
          fullUrl,
          JSON.stringify(data),
          timeExpirationInMilliseconds,
        )
        .then(isSaved => {
          if (!isSaved)
            console.warn('Não foi possível salvar os dados no cache.');
        });

      return data;
    });
}

export default fetchCached;

import { createStore, createEffect, createEvent } from 'effector'
import autoServiceGroupApi from '../utils/autoServiceGroupApi'
import { autoServiceApiV2 } from '../services/autoServiceApiV2/index';

const fetchList = () => {
  const {
    page,
    per_page,
    sort,
    filters,
    method,
    apiVersion,
  } = getStore().getState()
  
  let customParams = [
    { page },
    { per_page },
    { sort },
    ...filters,
  ]
  
  const serviceApi =
    apiVersion === 'v2' ? autoServiceApiV2 : autoServiceGroupApi;
  
  return serviceApi[method](customParams);
}

// effects
export const setList = createEffect('set list').use(fetchList)

// events
export const setMethod = createEvent('set method')
export const setApiVersion = createEvent('set api version')
export const setPage = createEvent('set page')
export const setPerPage = createEvent('set per_page')
export const resetPerPage = createEvent('reset per_page')
export const setSort = createEvent('set sort')
export const resetSort = createEvent('reset sort')
export const setFilters = createEvent('set filters')

let instance

const defaultValues = {
  /**
   * Nome do método definido em '../utils/autoServiceGroupApi'
   */
  method: 'getUsedModels',
  items: [],
  page: 1,
  per_page: 9,
  sort: '-featured,-ordination,-created_at',
  total: 0,
  total_pages: 0,
  isFetching: false,
  didInvalidate: false,
  filters: [],
  apiVersion: 'v1',
}

export default function getStore (values = defaultValues) {
  if (instance) return instance

  instance = createStore(values)

  instance.on(setMethod, (state, method) => {
    return {
      ...state,
      method,
    }
  })

  instance.on(setApiVersion, (state, apiVersion) => {
    return {
      ...state,
      apiVersion,
    }
  })

  instance.on(setList.pending, (state, isFetching) => {
    return {
      ...state,
      isFetching,
    }
  })

  instance.on(setList.fail, (state) => {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
    }
  })

  instance.on(setList.done, (state, { result }) => {
    return {
      ...state,
      items: result.entries,
      total: result.total,
      total_pages: result.total_pages,
      isFetching: false,
      didInvalidate: false,
    }
  })

  instance.on(setPage, (state, page) => {
    return {
      ...state,
      page,
    }
  })

  instance.on(setPerPage, (state, per_page) => {
    return {
      ...state,
      per_page,
    }
  })

  instance.on(resetPerPage, (state) => {
    return {
      ...state,
      per_page: defaultValues.per_page,
    }
  })

  instance.on(setSort, (state, sort) => {
    return {
      ...state,
      sort,
    }
  })

  instance.on(resetSort, (state) => {
    return {
      ...state,
      sort: defaultValues.sort,
    }
  })

  instance.on(setFilters, (state, filters) => {
    return {
      ...state,
      filters,
    }
  })

  return instance
}

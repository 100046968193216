import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setList,
  resetList,
  setTotalPages,
  setMethodApi
} from '../../stores/SearchFilter';
import ListOffer from './List';

class ListOfferApp extends Component {
  constructor(props) {
    super(props)

    const { list, total_pages } = this.props

    this.state = {
      list: []
    }

    setMethodApi('getOffers')
    setTotalPages(total_pages)
    resetList(list) 

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
      })
    })
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-offers')
  }

  componentDidMount() {
    window.matchHeight.match('.list-offers')

    const { loadInitial } = this.props
    if (loadInitial) {
      setList()
    }
  }

  showLoadMore() {
    const { total_pages, page, loadingMore } = this.state
    return (total_pages > page) || loadingMore
  }

  render() {
    const { list, loading, loadingMore } = this.state
    const { part_link } = this.props

    if (list.length > 0) {
      return (
        <ListOffer
          items={list}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={this.showLoadMore()}
          part_link={part_link}
        />
      )
    }
  }
}

ListOfferApp.defaultProps = {
  loadInitial: true,
  list: [],
}

ListOfferApp.propTypes = {
  loadInitial: PropTypes.bool,
  list: PropTypes.array,
  total_pages: PropTypes.number,
  per_page: PropTypes.number,
  part_link: PropTypes.string
}

export default ListOfferApp

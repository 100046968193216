import { Component } from 'preact';
import PropTypes from 'prop-types';
import { usedModelSortItems } from '%/utils/sort';
import { setShowFilterAdvanceMobile } from '../../stores/ListUsedModels';
import SimpleFilterButtonsApp from '../SearchFilter/SimpleFilterButtonsApp';

class SimpleFilterButtons extends Component {
  constructor(props) {
    super(props);

    window.store.ListUsedModels.watch(state => {
      this.setState({
        showComponent: state.showComponent,
      });
    });

    this.handleClickFilter = this.handleClickFilter.bind(this);
  }

  handleClickFilter() {
    setShowFilterAdvanceMobile(true);
  }

  render() {
    const { showDropdownIcon, showActiveStatus } = this.props;

    if (
      this.state.showComponent === 'SimpleFilter' ||
      this.state.showComponent == null
    ) {
      return (
        <SimpleFilterButtonsApp
          onClickFilter={this.handleClickFilter}
          showDropdownIcon={showDropdownIcon}
          showActiveStatus={showActiveStatus}
          shouldSetURLSearchParams
          sort={usedModelSortItems}
        />
      );
    }

    return null;
  }
}

SimpleFilterButtons.defaultProps = {
  showDropdownIcon: false,
  showActiveStatus: false,
};

SimpleFilterButtons.propTypes = {
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
};

export default SimpleFilterButtons;

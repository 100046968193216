import { h, Component } from 'preact'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class ButtonFilter extends Component {
  renderContent() {
    const { children, isOpen } = this.props

    return (
      <span>
        <span
          className={classNames({
            'd-none': !isOpen
          })}
          data-testid="close"
        >
          Fechar
        </span>

        <span
          className={classNames({
            'd-none': isOpen
          })}
          data-testid="open"
        >
          <i class="icon icon-filter"></i>&nbsp;&nbsp;
          { children.length > 0 ? children : 'Filtrar' }
        </span>
      </span>
    )
  }

  render () {
    const { className, onClick } = this.props
    return (
      <a
        class={classNames('btn button button--large button--no-upper', className)}
        href="#"
        onClick={(e) => onClick(e)}
      >
        { this.renderContent() }
      </a>
    )
  }
}

ButtonFilter.prpoType = {
  onClick() {},
  isOpen: PropTypes.bool
}

export default ButtonFilter

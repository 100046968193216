import { getMask } from './mask/getMask';
import { validateFields } from './validation';
import { makeFieldErrorHandler } from './validation/makeFieldErrorHandler';

export const makeHandleFieldInput = (validators, maskConfig) => {
  const store = window.store.CustomFormFields.getState();
  const updateFieldValue = store.events.updateFieldValue;
  const setFieldError = store.events.setFieldError;

  const handleFieldError = makeFieldErrorHandler(setFieldError);
  const mask = maskConfig ? getMask(maskConfig) : null;

  return e => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const originalValue = value;

    if (mask) {
      value = mask(value);
      target.value = value;
    }

    if (!target.dataset.initialized) {
      return;
    }

    const validationResult = validateFields(validators, originalValue);
    handleFieldError(target, validationResult);

    updateFieldValue({
      key: target.name,
      value: validationResult.hasError ? null : value,
    });
  };
};

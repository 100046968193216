// Injeta pôneis saltitantes na página.
export default (function() {
  let alreadyInjectedShareThis = false;

  return function() {
    if (alreadyInjectedShareThis) {
      return;
    }
    var script = document.createElement('script');
    script.async = 'async';
    script.defer = 'defer';
    script.src =
      '//platform-api.sharethis.com/js/sharethis.js#property=597f8dc55eb3920011c7cde6&product=inline-share-buttons';
    document.getElementsByTagName('head')[0].appendChild(script);
    alreadyInjectedShareThis = true;
  };
})();

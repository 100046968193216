import { conversionEventWithUserData } from './conversionEventWithUserData';
import { isDDMShowroom } from './isDDMShowroom';

/**
 * Essa função roda sempre que uma conversão for enviada com sucesso,
 *
 * @param {object} Dados da conversão enviados para a API
 */
export default function autoHookConversion(conversion) {
  // Envia um evento de conversão para o Google Analytics
  // eslint-disable-next-line no-undef
  ga('send', 'event', 'Conversões');
  const eventName = 'conversion';

  const gtmEvent = isDDMShowroom()
    ? conversionEventWithUserData(eventName, conversion)
    : { event: eventName };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(gtmEvent);
}

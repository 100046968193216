import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import getUTM from '%/utils/utm'
import StepWizard from 'react-step-wizard'
import Step from './Step'

export default class ConversionStep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {},
      isSubmittingForm: false,
      showOverlay: false,
      errorSendingForm: false,
      fields: [],
      totalFieldsShownByStep: [],
      fieldsShownByStep: [],
    }

    this.setFields()
    this.setFieldsShownByStep()

    this.handleChangeForm = this.handleChangeForm.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this)
  }

  /**
   * Set os campos que serão renderizados.
   * Alterar a ordem de chamada do método push também altera a ordem de prioridade
   * dos inputs nas etapas. Os primeiro adicionados serão os exibidos na primeira etapa.
   */
  setFields() {
    const { showLastName, showCpf, showModel, showInteraction } = this.props
    let fields = []

    fields.push('showName')

    if (showLastName)
      fields.push('showLastName')

    if (showCpf)
      fields.push('showCpf')

    fields.push('showEmail')
    fields.push('showPhone')

    if (this.showUnit())
      fields.push('showUnit')

    if (showModel)
      fields.push('showModel')

    if (showInteraction)
      fields.push('showInteraction')

    fields.push('showVersions')
    fields.push('contactBy')

    this.setState({ fields })
  }

  /**
   * Controla a exibição do select de unidades
   */
  showUnit() {
    const { showUnit, units } = this.props
    return showUnit && units.length > 1
  }

  /**
   * Seta na state um array de objetos.
   * Cada elemento desse array representa os campos a serem renderizados
   * @param {String} field - chave que será usada para exibir ou não um campo do form
   * @param {*} step - etapa em que o campo será renderizado
   */
  addFieldsShownByStep(field, step) {
    let fieldsShownByStep = this.state.fieldsShownByStep

    fieldsShownByStep[step] = fieldsShownByStep[step] || {}
    fieldsShownByStep[step][field] = true

    this.setState({ fieldsShownByStep })
  }

  /**
   * Cria o array de ojetos com os campos que serão renderizados em cada etapa
   * com base na prop maxFieldsByStep
   */
  setFieldsShownByStep() {
    const { fields, totalFieldsShownByStep } = this.state
    const { maxFieldsByStep } = this.props
    let step = 0

    totalFieldsShownByStep[step] = 0

    fields.forEach((field) => {
      this.addFieldsShownByStep(field, step)
      totalFieldsShownByStep[step] += 1

      if (totalFieldsShownByStep[step] >= maxFieldsByStep) {
        step += 1
        totalFieldsShownByStep[step] = 0
      }
    })

    this.setState({ totalFieldsShownByStep: totalFieldsShownByStep.filter(total => total > 0) })
  }

  handleCloseOverlay(e) {
    this.setState({ showOverlay: false });
  }

  handleChangeForm(params = {}) {
    this.setState(state => ({
      params: {
        ...state.params,
        ...params
      }
    }))
  }

  handleFinish() {
    this.setState({ isSubmittingForm: true })

    const { params } = this.state
    const { title, channel, category, brand, bait } = this.props
    const scriptedData =  window.scheduleServiceBannerByScriptData && window.scheduleServiceBannerByScriptData() || {}

    const dataToConversion = {
      ...params,
      ...scriptedData,
      utmz: getUTM(),
      product: params.product || title,
      channel,
      category,
      brand,
      bait
    }

    return serviceConversion
      .convert(dataToConversion)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false
        })
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true
        })
      })
      .then(() => {
        this.setState({
          showOverlay: true
        })
      })
  }

  render() {
    const {
      id,
      title,
      linkPrivacyPolicy,
      models,
      units,
      unit,
      expiredAt,
      showForm,
      showChronometer
    } = this.props

    const { fieldsShownByStep } = this.state

    return (
      <div className="conversion-step">
        <div class="conversion-step__main">
          <StepWizard>
            {fieldsShownByStep.map((fieldsStep, index) =>
              <Step
                title={title}
                formId={`${id}-step-${index}`}
                linkPrivacyPolicy={linkPrivacyPolicy}
                units={units}
                unit={unit}
                expiredAt={expiredAt}
                showChronometer={showChronometer}
                showForm={showForm}
                showUnit={!!fieldsStep.showUnit}
                showName={!!fieldsStep.showName}
                showLastName={!!fieldsStep.showLastName}
                showEmail={!!fieldsStep.showEmail}
                showPhone={!!fieldsStep.showPhone}
                showCpf={!!fieldsStep.showCpf}
                showModel={!!fieldsStep.showModel}
                showInteraction={!!fieldsStep.showInteraction}
                showMailing={!!fieldsStep.contactBy}
                showWhatsapping={!!fieldsStep.contactBy}
                showPhoning={!!fieldsStep.contactBy}
                showVersions={!!fieldsStep.showVersions}
                onChangeForm={this.handleChangeForm}
                onFinish={this.handleFinish}
                handleCloseOverlay={this.handleCloseOverlay}
                isSubmittingForm={this.state.isSubmittingForm}
                showOverlay={this.state.showOverlay}
                errorSendingForm={this.state.errorSendingForm}
                optionModel={models}
                versions={this.props.versions}
                shouldShowDataPermissions={this.props.shouldShowDataPermissions}
                showDataPermissions={!!fieldsStep.contactBy}
                dataPermissionsCustomText={this.props.dataPermissionsCustomText}
              />
            )}
          </StepWizard>
        </div>
      </div>
    )
  }
}

ConversionStep.defaultProps = {
  maxFieldsByStep: 4,
  units: [],
  models: [],
  brand: '-',
  bait: '-3',
  showLastName: false,
  showCpf: false,
  showInteraction: false,
  showModel: false,
  showForm: false,
  showChronometer: true,
  shouldShowDataPermissions: true
}

ConversionStep.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  maxFieldsByStep: PropTypes.number,
  linkPrivacyPolicy: PropTypes.string,
  showUnit: PropTypes.bool,
  showLastName: PropTypes.bool,
  showCpf: PropTypes.bool,
  showInteraction: PropTypes.bool,
  showModel: PropTypes.bool,
  units: PropTypes.array,
  unit: PropTypes.string,
  models: PropTypes.array,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  category: PropTypes.string,
  bait: PropTypes.string,
  expiredAt: PropTypes.string,
  showForm: PropTypes.bool,
  showChronometer: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
}

const commonSortItems = [
  { title: 'Mais Recente', paramValue: '-created_at' },
  { title: 'Mais Antigo', paramValue: 'created_at' },
  { title: 'Mais Relevante', paramValue: 'ordination' },
];

const defaultPriceSortItems = [
  { title: 'Por Preço Maior/Menor', paramValue: '-price' },
  { title: 'Por Preço Menor/Maior', paramValue: 'price' },
];

const usedModelPriceSortItems = [
  { title: 'Por Preço Maior/Menor', paramValue: '-price_value' },
  { title: 'Por Preço Menor/Maior', paramValue: 'price_value' },
];

const nameSortItems = [
  { title: 'Por Nome A/Z', paramValue: 'name' },
  { title: 'Por Nome Z/A', paramValue: '-name' },
];

export const sortItems = [...commonSortItems, ...nameSortItems];
export const usedModelSortItems = [
  ...commonSortItems,
  ...usedModelPriceSortItems,
  ...nameSortItems,
];
export default [...commonSortItems, ...defaultPriceSortItems, ...nameSortItems];

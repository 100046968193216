import fetchival from 'fetchival';

/**
 * @class autoServiceApi
 */
class fcaServiceApi {
  constructor(options) {
    this.options = {
      fcaApiUrl: document.querySelector("meta[name='fca_api_url']").content,
      fcaApiDealer: document.querySelector("meta[name='fca_api_dealer']").content,
      fcaApiBrand: document.querySelector("meta[name='fca_api_brand']").content,
      ...options
    }
  }

  // adapta o valor recebido para 'jeep' ou 'fiat'
  _getFcaApiBrand() {
    if (this.options.fcaApiBrand.includes('jeep')) {
      return 'jeep'
    } else {
      return this.options.fcaApiBrand
    }
  }
  /**
   * Lista as offers com base nos parâmetros
   *
   * @param {object[]} customParams Parâmetros adicionais para a request
   * @returns {Promisse}
   */
  getOffers(customParams, offersCategories = []) {

    const stringDealerIds = this.options.fcaApiDealer.split(',')
    const numberDealerIds = stringDealerIds.map((code_string) => Number(code_string) )

    if (numberDealerIds.length < 1) {
      return;
    }

    /**
     * @var {object[]} defaultParams Os parametros default em todas as requests de clones
     */
    let defaultParams = {
      "brand": `${this._getFcaApiBrand()}`,
      "market": "pt-BR",
      "offerType": "dealer",
      "stats": [
          "price_full"
      ],
      "facets": [
          "category",
          "engine",
          "gear",
          "highlight",
          "dealer_group",
          "regional_list",
          "state_list",
          "city_list"
      ],
      "allowQuery": {
          "advancedQuery": {
              "model_code": [],
              "category": [],
              "campaign": [],
              "mvs": [],
              "model_year": [],
              "highlight": [],
              "regional": [],
              "zone": [],
              "dealer_group": [],
              "dealer_list": numberDealerIds
          }
      },
      "denyQuery": {
          "advancedQuery": {
              "campaign": [],
              "category": [],
              "mvs": [],
              "model_code": [],
              "model_year": [],
              "dealer_group": []
          }
      },
      "order": "REGIONAL_INDIVIDUAL,NATIONAL_INDIVIDUAL,REGIONAL_DIRECT_SALES,NATIONAL_DIRECT_SALES"
  }

    return this.fetchWrapper(
      `${this.options.fcaApiUrl}/offers/search`,
      {...defaultParams, ...customParams}
    )
  }

  getOffer(id) {
    /**
     * @var {object[]} defaultParams Os parametros default em todas as requests de clones
     */
    const defaultParams = {
      "brand": this._getFcaApiBrand(),
      "market": "pt-BR",
      "offerType": "dealer",
      "offerId": id
    }

    return this.fetchWrapper(
      `${this.options.fcaApiUrl}/offers/search`,
      defaultParams
    )
  }

  fetchWrapper(fullUrl, body) {
    // parametros exatos conforme nos enviaram
    return fetchival.fetch(fullUrl, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
  }
}

export default new fcaServiceApi();

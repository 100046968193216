import { h, Component, render } from 'preact'
import PropTypes from 'prop-types'

import TimeMissing from './TimeMissing'

class TimeMissingCard extends Component {
  shouldRender() {
    const { date } = this.props
    return date && !isNaN(date)
  }

  render() {
    const { date } = this.props

    if (this.shouldRender()) {
      return (
        <div className="time-missing-card">
          <i className="icon icon-clock"></i>
          <span className="time-missing-card__text">TEMPO RESTANTE:</span>
          <TimeMissing date={date} />
        </div>
      )
    }
  }
}

TimeMissingCard.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
}

export default TimeMissingCard

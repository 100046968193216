import { h } from 'preact'
import ChronometerBox from '../ChronometerBox'

export default function({ endDate }) {
  return (
    <div>
      <ChronometerBox
        endDate={endDate} addClassPanel={false}
        direction="vertical"
      />
    </div>
  )
}

import { Component } from 'preact';
import { findFilterArray } from '%/utils/filters';
import PropTypes from 'prop-types';
import ResultSearchSimple from './ResultSearchSimple';

class ResultSearchSimpleApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConversionForm: false,
      showFilters: false,
    };

    if (props.showFilters) {
      this.setState({
        showFilters: props.showFilters,
      });
    }

    this.watch();
  }

  watch() {
    window.store.SearchFilter.watch(state => {
      this.setState({
        showResults: state.showResults,
        keyword: state.keyword,
        total: state.total,
        showConversionForm: state.showConversionForm,
        selectedCategories: findFilterArray(
          state.filterArray,
          'q[model_category_in][]',
        ),
      });
    });
  }

  render() {
    const {
      showResults,
      showConversionForm,
      keyword,
      total,
      selectedCategories,
      showFilters,
    } = this.state;

    if (showResults && !showConversionForm) {
      return (
        <ResultSearchSimple
          keyword={keyword}
          total={total}
          selectedCategories={selectedCategories}
          showFilters={showFilters}
        />
      );
    }

    return null;
  }
}

ResultSearchSimpleApp.propTypes = {
  showFilters: PropTypes.bool,
};

export default ResultSearchSimpleApp;

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { resetList, setTotalPages, setPerPage } from '../../stores/SearchFilter'
import List from './List'

class ListApp extends Component {
  constructor(props) {
    super(props)

    const { list, total_pages, per_page } = this.props

    this.state = {
      list: []
    }

    per_page && setPerPage(per_page)
    setTotalPages(total_pages)
    resetList(list)

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
      })
    })
  }

  showLoadMore() {
    const { total_pages, page, loadingMore } = this.state
    return (total_pages > page) || loadingMore
  }

  render() {
    const { list, loading, loadingMore } = this.state
    const { part_link } = this.props

    if (list.length > 0) {
      return (
        <List
          items={list}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={this.showLoadMore()}
          part_link={part_link}
        />
      )
    }
  }
}

ListApp.defaultProps = {
  list: [],
}

ListApp.propTypes = {
  list: PropTypes.array,
  total_pages: PropTypes.number,
  per_page: PropTypes.number,
  part_link: PropTypes.string
}

export default ListApp

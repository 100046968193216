import { h, Component } from 'preact'
import PropTypes from 'prop-types'

const DAY_TIME = 1000*60*60*24
const HOUR_TIME = 1000*60*60
const MINUTES_TIME = 1000*60
const SECONDS_TIME = 1000

class TimeMissing extends Component {
  constructor(props) {
    super(props)

    let { date } = this.props

    this.nowTime = Date.now()
    this.dateTime = date.getTime()
  }

  componentDidMount() {
    window.setInterval(() => {
      this.nowTime += 1000
      this.forceUpdate()
      
      // se zerar o contador
      if (this.nowTime >= this.dateTime) {
        window.clearInterval(this)
      }
    }, 1000)
  }

  getDays() {
    let days = (this.dateTime - this.nowTime) / DAY_TIME
    days = Math.floor(days)

    if (days < 0) {
      days = 0
    }
    
    return days
  }

  getHours() {
    let hours = (this.dateTime - this.nowTime) / HOUR_TIME
    hours = Math.floor(hours)

    if (hours < 0) {
      hours = 0
    }

    return hours
  }

  getMinutes() {
    let diff = this.dateTime - this.nowTime
    let minutes = diff / MINUTES_TIME
    minutes -= (this.getHours() * 60)
    minutes = Math.floor(minutes)

    if (minutes < 0) {
      minutes = 0
    }

    return minutes
  }

  getSeconds() {
    let diff = this.dateTime - this.nowTime
    let seconds = diff / SECONDS_TIME
    seconds -= (this.getHours() * 60 * 60)
    seconds -= (this.getMinutes() * 60)
    seconds = Math.floor(seconds)

    if (seconds < 0) {
      seconds = 0
    }

    return seconds
  }

  // retornar os dias no formato 10 DIAS
  getDaysFormat() {
    let textDays = 'DIAS'
    let days = this.getDays()

    if (days <= 1) {
      textDays = 'DIA' 
    }

    return `${days} ${textDays}`
  }

  // retorna as horas e minutos no formato 12h 25m
  getHorsMinutesFormat() {
    let hours = this.getHours().toString().padStart(2, '0')
    let minutes = this.getMinutes().toString().padStart(2, '0')

    return `${hours}h ${minutes}m`
  }

  // retorna os minutos e segundos no formato 12m 22s
  getMinutesSecondsFormat() {
    let minutes = this.getMinutes().toString().padStart(2, '0')
    let seconds = this.getSeconds().toString().padStart(2, '0')

    return `${minutes}m ${seconds}s`
  }

  renderTime() {
    if (this.getDays() >= 1) {
      return this.getDaysFormat()
    } else if (this.getHours() > 0) {
      return this.getHorsMinutesFormat()
    } else {
      return this.getMinutesSecondsFormat()
    }
  }

  render() {
    return (
      <div className="time-missing">
        <div className="time-missing__time">{ this.renderTime() }</div>
      </div>
    )
  }
}

TimeMissing.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
}

export default TimeMissing

import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class PillsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: null
    };

    this.handleSelectFilter = this.handleSelectFilter.bind(this);
  }

  getLabelFilter(filter) {
    if (!filter) {
      return null
    }

    if (typeof filter === 'string') {
      return filter
    }

    return filter.label
  }

  renderItems() {
    return this.props.filters.map(filter => {
      return (
        <li key={ this.getLabelFilter(filter) } className="filter-pills__options-item">
          <span
              className={classNames('filter-pills__options-text', {
                active: this.state.selectedFilter === filter
              })}
              onClick={e => this.handleSelectFilter(e, filter)}
            >
            { this.getLabelFilter(filter) }
          </span>
        </li>
      );
    });
  }

  handleSelectFilter(e, filter) {
    e.preventDefault();
    this.setSelectedFilter(filter);
    this.props.onSelectFilter(e, filter);
  }

  setSelectedFilter(filter) {
    this.setState({
      selectedFilter: filter
    });
  }

  render() {
    return (
      <div className="filter-pills">
        <span className="filter-pills__title">{this.props.supportText}</span>
        <ul className="filter-pills__options">
          <li key={this.getLabelFilter()} className="filter-pills__options-item">
            <span
              className={classNames('filter-pills__options-text', {
                active: !this.state.selectedFilter
              })}
              onClick={e => this.handleSelectFilter(e, null)}
            >
              Todos
            </span>
          </li>
          {this.renderItems()}
        </ul>
      </div>
    );
  }
}

PillsFilter.defaultProps = {
  supportText: '',
  filters: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      slug: PropTypes.string,
    })
  ]),
  onSelectFilter: function() {}
};

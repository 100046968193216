import { createStore, createEvent } from 'effector';

export const setAvailableMonths = createEvent('set available months');
export const setPlans = createEvent('set plans');
export const setSelectedMonths = createEvent('set selected months');
export const setMonthlyValue = createEvent('set monthly value');
export const setTotalValue = createEvent('set total value');
export const setPeriod = createEvent('set period');

const Consortium = (function () {
  let instance;

  return function (
    defaultValue = {
      availableMonths: null,
      selectedMonths: null,
      monthlyValue: null,
      totalValue: null,
      plans: [],
      period: null,
    },
  ) {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setSelectedMonths, (state, selectedMonths) => ({
        ...state,
        ...selectedMonths,
      }));

      instance.on(setAvailableMonths, (state, availableMonths) => ({
        ...state,
        availableMonths,
      }));

      instance.on(setMonthlyValue, (state, monthlyValue) => ({
        ...state,
        monthlyValue,
      }));

      instance.on(setPlans, (state, plans) => ({
        ...state,
        plans,
      }));

      instance.on(setTotalValue, (state, totalValue) => ({
        ...state,
        totalValue,
      }));

      instance.on(setPeriod, (state, period) => ({
        ...state,
        period,
      }));
    }

    return instance;
  };
})();

export default Consortium;

// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp';
import {
  setPage,
  setPerPage,
  setApiVersion,
  setMethodApi,
} from '../../stores/SearchFilter';

class SimpleFilterV2 extends Component {
  constructor(props) {
    super(props);

    setPage(1);
    setPerPage(24);
    setApiVersion('v2');
    setMethodApi('getClones');
  }

  render() {
    const {
      filterCategories,
      fieldCategoryFilter,
      classNameButtonFilter,
      classNameButtonOrder,
      showDropdownIcon,
      showActiveStatus,
      sort,
    } = this.props;

    return (
      <SimpleFilterApp
        filterCategories={filterCategories}
        fieldCategoryFilter={fieldCategoryFilter}
        classNameButtonFilter={classNameButtonFilter}
        classNameButtonOrder={classNameButtonOrder}
        showButtonFilter={!!filterCategories}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
        mapFilters={{
          'category[]': 'category[]',
        }}
        sort={sort}
        fieldKeywordFilter="search"
        shouldSetURLSearchParams
        shouldSetListOnInit
      />
    );
  }
}

SimpleFilterV2.defaultProps = {
  fieldCategoryFilter: 'category[]',
  classNameButtonFilter: 'button--line',
  classNameButtonOrder: 'button--line',
  showDropdownIcon: false,
  showActiveStatus: false,
  sort: [
    { title: 'Mais Recente', paramValue: '-created_at' },
    { title: 'Mais Antigo', paramValue: 'created_at' },
    { title: 'Mais Relevante', paramValue: 'ordination' },
    { title: 'Por Preço Maior/Menor', paramValue: '-price' },
    { title: 'Por Preço Menor/Maior', paramValue: 'price' },
    { title: 'Por Nome A/Z', paramValue: 'name.keyword' },
    { title: 'Por Nome Z/A', paramValue: '-name.keyword' },
  ],
};

SimpleFilterV2.propTypes = {
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
  fieldCategoryFilter: PropTypes.string,
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
};

export default SimpleFilterV2;

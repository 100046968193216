import { h } from 'preact';
import { Component } from 'react';

/**
 * Representa um box com CTA que abre um form ao clicar nese CTA
 */
export default class CTABox extends Component {
  /**
   * Obtém o conteúdo do box baseado no seu tipo
   */
  get content() {
    return this.props.content[this.props.type];
  }

  render() {
    return (
      <div
        className={`cta-box cta-box--${this.props.direction} cta-box--${this.content.boxTypeModifier
          }`}
      >
        <div className="cta-box__phrase">
          <span>{this.props.mainPhrase}</span>
        </div>
        <div className="cta-box__button-container">
          <button
            id={this.props.buttonId}
            href={this.props.link}
            target="_blank"
            className={'btn button button--large ' + this.content.buttonClasses}
            onClick={this.props.handleClick}
          >
            {this.content.shouldIncludeIcon && (
              <i className={this.content.iconClasses} />
            )}
            {this.props.buttonText}
          </button>
        </div>
      </div>
    );
  }
}

CTABox.defaultProps = {
  type: 'whatsapp',
  mainPhrase:
    'Fale agora mesmo com um especialista e tire todas as suas dúvidas',
  buttonText: 'Negocie pelo WhatsApp',
  // Conteúdo que varia com o tipo do box
  content: {
    whatsapp: {
      boxTypeModifier: 'whatsapp',
      buttonClasses: 'button--whatsapp',
      shouldIncludeIcon: true,
      iconClasses: 'icon icon-whatsapp'
    }
  },
  // A direção em que as partes do box são dispostas
  direction: 'vertical',
  handleClick: function () { },
  link: '#',
};
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import ItemPlaceholder from './ItemPlaceholder';

class ListOffers extends Component {
  mainCallByFields(fields) {
    if (fields.price_type == 'Payment5') {
      return `De R$ ${fields.price_from.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
      })}`;
    }

    if (fields.price_type == 'Payment4') {
      return 'À vista a partir de';
    }

    if (fields.price_type == 'Payment2') {
      return '';
    }

    if (fields.price_type == 'Payment17') {
      return '';
    }

    if (fields.price_type == 'Payment18') {
      return '';
    }

    if (fields.price_type == 'Payment19') {
      return '';
    }
  }

  featuredByFields(fields) {
    if (fields.price_type == 'Payment5') {
      return `Por R$ ${fields.price_to.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
      })}`;
    }

    if (fields.price_type == 'Payment4') {
      return `R$ ${fields.price_full.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
      })}`;
    }

    if (fields.price_type == 'Payment2') {
      return `Entrada de R$ ${this.formattedPrice(fields.price_entrance)} + ${
        fields.number_installments
      }X de R$ ${this.formattedPrice(fields.price_installments)}`;
    }

    if (fields.price_type == 'Payment17') {
      return `Entrada + ${
        fields.number_installments
      }X parcelas de R$ ${this.formattedPrice(fields.price_installments)}`;
    }

    if (fields.price_type == 'Payment18') {
      return `Entrada + ${
        fields.number_installments
      }X parcelas de R$ ${this.formattedPrice(fields.price_installments)}`;
    }

    if (fields.price_type == 'Payment19') {
      return `Entrada de R$ ${this.formattedPrice(
        fields.price_entrance,
      )} + Saldo em ${fields.number_installments}X`;
    }
  }

  extraByField(fields) {
    if (fields.price_type == 'Payment5') {
      return '';
    }

    if (fields.price_type == 'Payment4') {
      return '';
    }

    if (fields.price_type == 'Payment2') {
      return `Parcela final R$ ${this.formattedPrice(fields.price_final)}`;
    }

    if (fields.price_type == 'Payment17') {
      return '+ Parcela final';
    }

    if (fields.price_type == 'Payment18') {
      return `+ Parcela final de R$ ${this.formattedPrice(fields.price_final)}`;
    }

    if (fields.price_type == 'Payment19') {
      return `+ Parcela final de R$ ${this.formattedPrice(fields.price_final)}`;
    }
  }

  formattedPrice(stringPrice) {
    return stringPrice.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  render() {
    const { items, loading, part_link } = this.props;

    return (
      <div className="list-offers__list">
        <div className="row">
          {items.map(({ fields }) => (
            <div className="col-md-6 col-lg-4 list-offers__col-card">
              {loading ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  title={fields.model_name}
                  subtitle={fields.version_name}
                  seal_list={fields.highlight}
                  part_link={part_link}
                  slug={fields.offer_id}
                  secondary_call=""
                  main_call={this.mainCallByFields(fields)}
                  featured={this.featuredByFields(fields)}
                  description=""
                  extra={this.extraByField(fields)}
                  badge=""
                  model_code={fields.model_code}
                  version_code={fields.version_code.toLowerCase()}
                  series_code={fields.series_code}
                  model_year={fields.model_year}
                  category={fields.category}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ListOffers.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false,
};

ListOffers.propTypes = {
  items: PropTypes.array,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  part_link: PropTypes.string,
};

export default ListOffers;

/**
 * Adiciona uma classe no primario e ultimo elemento
 */
export function addClassFirstLastElement(selector, classFirst, classLast) {
  const elements = document.querySelectorAll(selector)
  if (elements.length > 0) {
    elements[0].classList.add(classFirst)
    elements[elements.length - 1].classList.add(classLast)
  }
}

export function plateMask(value) {
  let formattedValue = value.toUpperCase();

  formattedValue = formattedValue.replace(/[^A-Z0-9]/g, '');
  formattedValue = formattedValue.slice(0, 7);

  const firstPart = formattedValue.slice(0, 3);
  const secondPart = formattedValue.slice(3);

  let formattedPlate = firstPart;

  if (!/^[A-Z]{3}$/.test(firstPart)) {
    formattedPlate = firstPart.replace(/\d/g, '');
  } else {
    if (
      secondPart.length > 2 &&
      (/^\d$/.test(secondPart[2]) || /^\d{2}$/.test(secondPart.slice(2, 4)))
    ) {
      formattedPlate += '-' + secondPart;
    } else if (secondPart.length > 1 && /^[A-Z0-9]$/.test(secondPart[1])) {
      formattedPlate += '-' + secondPart.slice(0, 2);
    } else if (secondPart.length > 0 && /^\d$/.test(secondPart[0])) {
      formattedPlate += '-' + secondPart[0];
    }
  }

  return formattedPlate;
}

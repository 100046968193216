import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Custom check representa "checkable" como radios e checkboxes.
 */
export default class CustomCheck extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChangeCheckable && this.props.onChangeCheckable(e);
  }

  render() {
    return (
      <div
        className={classNames(
          'custom-check',
          `custom-check--${this.props.checkStyle}`,
          `custom-check--${this.props.type}`,
          this.props.className,
        )}
      >
        <input
          type={this.props.type}
          className="checkable"
          value={this.props.value}
          name={this.props.name}
          checked={this.props.isChecked}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        />
        <div className="custom-check__check" />
        {this.props.children.toString() && <label>{this.props.children}</label>}
      </div>
    );
  }
}

CustomCheck.defaultProps = {
  checkStyle: 'light',
  disabled: false,
};

CustomCheck.propTpes = {
  checkStyle: PropTypes.string,
  disabled: PropTypes.bool,
};

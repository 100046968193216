import { h, Component } from 'preact'
import classNames from 'classnames'
import Accordion from './index'

class Collapse extends Component {
  render() {
    const { eventKey, children } = this.props

    return (
      <Accordion.Consumer>
        {({ defaultActiveKey }) => (
          <div
            className={classNames('collapse accordion__collapse', {
              'accordion__collapse--active': defaultActiveKey === eventKey,
              'show': defaultActiveKey === eventKey
            })}
          >
            { children }
          </div>
        )}
      </Accordion.Consumer>
    )
  }
}

export default Collapse

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ProfileImageTypes from '%/utils/variantProfileImageTypes';
import VariantCollection, {
  variantObjectsToCustomSelectOptions,
} from '%/utils/variantCollection';
import { registerField } from '%/utils/customForm';
import { Select, FieldGroup } from '../../../FormComponents';

export default class VersionElements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVariant: this.props.defaultVariant || '',
    };

    this.getCurrentVariantItem = this.getCurrentVariantItem.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this);
  }

  handleVariantChange(e) {
    const slugVersion = e.target.value;
    this.setSelectedVariant(slugVersion);

    const currentVariantItem = this.getCurrentVariantItem();

    if (currentVariantItem) {
      if (this.props.showVariantSelect) {
        this.historyPushState();
      }
    }
  }

  historyPushState() {
    if (window.history.pushState)
      window.history.pushState({}, null, this.getUrlVersion());
  }

  getSlugVersion() {
    const matches = window.location.pathname.match(/(novo|ofertas).+$/);
    if (matches && matches.length > 0) {
      const pathItems = matches[0].split('/');
      if (pathItems.length > 2) {
        return pathItems[2].replace(/\//g, '');
      } else if (pathItems.length === 2 && pathItems[0] === 'ofertas') {
        return pathItems[1].replace(/\//g, '');
      }
    }
    return '';
  }

  getIndexBySlugVersion() {
    const slugVersion = this.getSlugVersion();
    const index = this.props.variants.reduce((acc, variant, index) => {
      if (
        variant.slug === slugVersion ||
        (typeof offer_slug !== 'undefined' && offer_slug == variant.slug)
      ) {
        acc = index;
      }
      return acc;
    }, 0);

    return index;
  }

  variantOptionsToCustomSelect() {
    const options = variantObjectsToCustomSelectOptions(
      this.props.variants,
      'slug',
      'name',
    );

    const slugVersion = this.getSlugVersion();

    if (slugVersion) {
      options.forEach(option => {
        if (option.value === slugVersion) {
          option.selected = true;
        }
      });
    }

    return options;
  }

  getCurrentVariantPhotoUrl() {
    return (
      this.getCurrentVariantItem() &&
      this.getCurrentVariantItem().getProfileImageOfType(
        ProfileImageTypes.MODEL_MIDDLE,
      )
    );
  }

  getCurrentVariantItem() {
    const selectedVariant =
      this.props.defaultVariant || this.state.selectedVariant;
    return (
      selectedVariant &&
      this.variantsCollection &&
      this.variantsCollection.findVariantBy('slug', selectedVariant)
    );
  }

  getCurrentVariantItemPrice() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem.price;
  }

  getCurrentVariantItemName() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem._variant.name;
  }

  showVariantSelect() {
    return this.props.showVariantSelect && this.props.variants.length > 1;
  }

  showVariantPrice() {
    return this.props.showVariantPrice && !!this.getCurrentVariantItemPrice();
  }

  showVariantImage() {
    return this.props.showVariantImage && !!this.getCurrentVariantPhotoUrl();
  }

  setSelectedVariant(slugVersion) {
    const versions = this.variantOptionsToCustomSelect();

    if (versions.length === 0) return;

    let index;
    if (slugVersion) {
      index = versions.findIndex(option => option.value === slugVersion);
    } else if (this.getSlugVersion() === '') {
      index = 0;
    } else {
      index = this.getIndexBySlugVersion();
    }

    const selectedVariant = versions[index].value;
    const activeVersion = this.props.variants[index];

    this.setState({ selectedVariant });
    window.store.vehicleVersionDetails.setState({ activeVersion });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.variants !== this.props.variants) {
      const variantsCollectionPromise = new Promise((resolve, reject) => {
        this.variantsCollection = new VariantCollection(this.props.variants);
        resolve();
      });

      variantsCollectionPromise.then(() => {
        this.setSelectedVariant();
      });
    }
  }

  getUrlVersion() {
    const href = window.location.pathname,
      slugVersion = this.getSlugVersion(),
      currentVersion = this.getCurrentVariantItem(),
      newSlugVersion = currentVersion.getVariantProperty('slug');

    const newPathname = href
      .replace(/\/$/, '')
      .replace(slugVersion, '')
      .replace(/\/$/, '');

    return `${window.location.origin}${newPathname}/${newSlugVersion}${window.location.search}`;
  }

  render() {
    return (
      <FieldGroup>
        <div className="conversion-form__version-info">
          {this.showVariantImage() && (
            <div className="conversion-form__photo">
              <LazyLoad>
                <img
                  src={this.getCurrentVariantPhotoUrl()}
                  alt={
                    this.getCurrentVariantItem() &&
                    this.getCurrentVariantItem().getVariantProperty('name', '')
                  }
                />
              </LazyLoad>
            </div>
          )}
          {this.showVariantPrice() && (
            <p className="conversion-form__price">
              A partir de <span>R$ {this.getCurrentVariantItemPrice()}</span>
            </p>
          )}
          {this.showVariantSelect() && (
            <div>
              <Select
                customClasses="conversion-form__select"
                options={this.variantOptionsToCustomSelect()}
                label="Versão"
                {...registerField('version', {
                  callback: this.handleVariantChange,
                })}
              />
            </div>
          )}
        </div>
      </FieldGroup>
    );
  }
}

VersionElements.defaultProps = {
  handleVariantChange: () => {},
  showVariantSelect: false,
  showVariantImage: false,
  showVariantPrice: false,
  defaultVariant: '',
  variants: [],
  version: null,
};

VersionElements.propTypes = {
  handleVariantChange: PropTypes.func,
  showVariantSelect: PropTypes.bool,
  showVariantImage: PropTypes.bool,
  showVariantPrice: PropTypes.bool,
  variants: PropTypes.array.isRequired,
  defaultVariant: PropTypes.string,
  version: PropTypes.string,
};

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FormWrapper extends Component {
  render() {
    const { customClasses, formId, setRef, handleSubmit, children } =
      this.props;

    return (
      <form
        className={classNames('form form-wrapper', customClasses)}
        id={formId}
        ref={setRef}
        onSubmit={handleSubmit}
      >
        {children}
      </form>
    );
  }
}

FormWrapper.propTypes = {
  customClasses: PropTypes.string,
  formId: PropTypes.string,
  handleSubmit: PropTypes.func,
  setRef: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
};

import VariantItem from './variantItem';

/**
 * Representa uma coleção de variantes de veículos ou afins. Não controla ou
 * altera as variantes. Serve apenas como base de variantes e contém métodos
 * para auxiliar na filtragem das mesmas.
 */
export default class VariantCollection {
  /**
   *
   * @param {object[]} variants
   */
  constructor(variants) {
    if (!variants instanceof Array) {
      throw '`variants` não é do tipo array.';
    }

    variants.length === 0 &&
      console.warn('Variantes de inicialização estão vazias');

    this._variants = variants.reduce(
      (acc, cur) => acc.push(new VariantItem(cur)) && acc,
      [],
    );

    // Memoization para algumas operações, como obter o valor mínimo das
    // variantes.
    this.memo = {};
  }

  /**
   * @return {number}
   */
  get length() {
    return this._variants.length;
  }

  /**
   * Obtém o valor mínimo dentre todas as variantes.
   * @return {number}
   */
  get minValueFromVariants() {
    this.memo.minValueFromVariants =
      this.memo.minValueFromVariants ||
      Math.min.apply(
        null,
        this._variants.reduce(
          (acc, curr) => acc.push(curr.priceAsNumber) && acc,
          [],
        ),
      );

    return this.memo.minValueFromVariants;
  }

  /**
   * Obtém variantes baseado em um campo e seu valor. Essa função *pode*
   * retornar mais de um objeto se os critérios de buscas derem match em mais
   * de um deles.
   *
   * @param {string} field O campo a ser buscado
   * @param {string|number} value O valor do campo a ser buscado
   * @return {Array<VariantItem>}
   */
  findVariantsBy(field, value) {
    return this._variants.filter(
      variant => variant.getVariantProperty(field) == value,
    );
  }

  /**
   * Obtém no máximo uma variante baseado em uma chave e seu valor.
   * Essa função retorna *no máximo* um item de variante.
   *
   * @param {string} field O campo a ser buscado
   * @param {string|number} value O valor do campo a ser buscado
   * @return {VariantItem|undefined}
   */
  findVariantBy(field, value) {
    return (
      // this._variants.length > 0 &&
      this._variants.find(variant => variant.getVariantProperty(field) == value)
    );
  }
}

/**
 * Transforma uma VariantCollection em um objeto compatível de opções do
 * CustomSelect.
 * @param {VariantCollection} collection
 * @param {string} valueField O campo que será o valor
 * @param {string} labelField O campo que será o label da opção
 * @return {Object[]}
 */
export function variantCollectionToCustomSelectOptions(
  collection,
  valueField = 'id',
  labelField = 'name',
) {
  if (!collection || collection.length === 0) {
    return [];
  }

  return collection._variants.reduce((accumulator, variant) => {
    const value = variant.getVariantProperty(valueField),
      label = variant.getVariantProperty(labelField);

    // Adicionamos apenas variantes que contenham `value` e `label`.
    if (value && label) {
      accumulator.push({ value, label });
    }

    return accumulator;
  }, []);
}

/**
 * Converte um array de objetos planos representando variantes em opções de
 * CustomSelect.
 * @param {Object[]} variantObjects
 * @param {string} valueField O campo que será o valor
 * @param {string} labelField O campo que será o label da opção
 * @return {Object[]|[]}
 */
export function variantObjectsToCustomSelectOptions(
  variantObjects,
  valueField = 'id',
  labelField = 'name',
) {
  if (!(variantObjects instanceof Array)) {
    console.warn('`variantObjects` precisa ser um array de objetos');
    return [];
  }

  return variantCollectionToCustomSelectOptions(
    new VariantCollection(variantObjects),
    valueField,
    labelField,
  );
}

import { checkPattern } from './checkPattern';

export function checkCnpjPattern() {
  const cnpjPattern = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/;
  const cnpjValidator = checkPattern(cnpjPattern, 'Digite um CNPJ válido.');

  return value => {
    const validationResult = cnpjValidator(value);
    return validationResult;
  };
}

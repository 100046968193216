/*
  Player para vídeo em html5, youtube e vimeo
*/
import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import Plyr from 'plyr'

export default class VideoPlayer extends Component {
  componentDidMount() {
    const { source } = this.props.options

    this.player = new Plyr(this.ref, this.props.options)
    this.player.source = source

    this.props.onInstance(this.player)
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.destroy()
    }
  }

  renderHTML5() {
    return <video ref={node => this.ref = node} />
  }

  /**
   * Retorna um componente com base no provider
   * esse componente vai renderizar com base nas opções
   * de vídeo disponível na plataforma (Youtube ou Vimeo)
   *
   * @param {string} provider Identifica qual o tipo de video deve ser carregado (Youtube|Vimeo)
   */
  renderYoutubeOrVimeo(provider) {
    const { embedId } = this.props
    return <div ref={node => this.ref = node} data-plyr-provider={provider} data-plyr-embed-id={embedId}></div>
  }

  renderVideo() {
    const { type } = this.props

    switch (type) {
      case 'html5':
        return this.renderHTML5()

      case 'youtube':
        return this.renderYoutubeOrVimeo('youtube')

      case 'vimeo':
        return this.renderYoutubeOrVimeo('vimeo')
    }
  }

  render() {
    return(
      <div className="video-player">
        { this.renderVideo() }
      </div>
    )
  }
}

VideoPlayer.defaultProps = {
  type: 'html5',
  options: {},
  onInstance() {}
}

VideoPlayer.propTypes = {
  type: PropTypes.oneOf(['html5', 'youtube', 'vimeo']),
  embedId: PropTypes.string, // url do vídeo do youtube ou vimeo
  options: PropTypes.object, // https://github.com/sampotts/plyr#options
  onInstance: PropTypes.func // retorna a instância do player no argumento
}

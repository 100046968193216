function makePriceDisplay(price) {
  const parsedPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price);

  return parsedPrice;
}

export function makeItemOfferDisplay(oldPrice, price) {
  const oldPriceDisplay = oldPrice ? makePriceDisplay(oldPrice) : null;
  const priceDisplay = price ? makePriceDisplay(price) : null;

  const itemOfferDisplay = {
    secondary_call: oldPrice > price ? `De ${oldPriceDisplay}` : '',
    main_call: price ? 'Por apenas' : '',
    original_price: oldPriceDisplay || '',
    featured: priceDisplay || '',
  };

  return itemOfferDisplay;
}

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import validationFactory from '%/utils/validation'
import handleInputChange from '%/utils/handleInputChange'
import FormMessageOverlay from '../FormMessageOverlay'
import IMask from 'imask'
import { cpfMasks, phoneMasks } from '%/utils/masks'
import Header from './Header'
import Chronometer from './Chronometer'
import Footer from './Footer'
import ButtonPrev from './ButtonPrev'
import SubmitButton from './../SubmitButton'
import ButtonNext from './ButtonNext'
import CustomSelect from '../CustomSelect'
import CustomCheck from '../CustomCheck'

export default class Step extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mailing: false,
      phoning: false,
      whatsapping: false,
      versionValidStatus: true,
      contactOptions: '',
    }

    this.handleInputChange = handleInputChange.bind(this)
    this.handlePrevStep = this.handlePrevStep.bind(this)
    this.handleNextStep = this.handleNextStep.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
    this.handleContactOptionsChange = this.handleContactOptionsChange.bind(this)
  }

  /**
   * Retorna o valor da unidade que será utilizada no form de conversão
   */
  getUnitConversion() {
    const { units, unit, showUnit } = this.props

    let unitConversion

    if (showUnit) {
      if (units.length == 1) {
        unitConversion = units[0].value
      } else {
        unitConversion = this.state.unit
      }
    } else {
      unitConversion = unit
    }

    return unitConversion
  }

  handlePrevStep() {
    this.props.previousStep()
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e)
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : ''
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(this.state.phoning || this.state.mailing || this.state.whatsapping)
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }

  versionValid() {
    return this.state.model_car;
  }

  handleFinish() {
    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;
    this.setState({ versionValidStatus: true });

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return
      }
    }

    if (!formIsValid) {
      return
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }

    this.props.onChangeForm(this.state)
    this.props.onFinish()
  }

  handleNextStep() {
    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (!formIsValid) {
      return
    }

    let unit = this.getUnitConversion(),
      params = {
        ...this.state
      }

    if (unit) {
      params.unit = unit
    }

    this.props.onChangeForm(params)
    this.props.nextStep()
  }

  showButtonPrevStep() {
    return this.props.currentStep > 1
  }

  showButtonNextStep() {
    const { currentStep, totalSteps } = this.props
    return !(currentStep === totalSteps)
  }

  showButtonFinish() {
    const { currentStep, totalSteps } = this.props
    return (currentStep === totalSteps)
  }

  // formulários dos banners
  componentDidUpdate() {
    if (typeof window.scheduleServiceBannerFormUpdate !== "undefined")
      window.scheduleServiceBannerFormUpdate();
  }

  componentDidMount() {
    if (this.inputCpf) {
      IMask(this.inputCpf, {
        mask: cpfMasks
      })
    }

    if (this.phoneInput) {
      const phoneMask = IMask(this.phoneInput, {
        mask: [...phoneMasks]
      })
      phoneMask.on("accept", () => this.setState({ phone: phoneMask.value }));
    }

    this.validator = validationFactory(`#${this.props.formId}`)
  }

  render() {
    const {
      title,
      linkPrivacyPolicy,
      units,
      expiredAt,
      showForm,
      showChronometer,
      showUnit,
      showName,
      showLastName,
      showEmail,
      showPhone,
      showVersions,
      showCpf,
      showModel,
      showInteraction,
      showMailing,
      showWhatsapping,
      showPhoning,
      showDataPermissions
    } = this.props

    return (
      <form
        className="conversion-step__form"
        ref={form => (this.form = form)}
        id={this.props.formId}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />

        {showForm && <Header title={title} />}

        {expiredAt && showChronometer &&
          <Chronometer endDate={expiredAt} />
        }

        {showForm &&
          <div className="conversion-step__body">

            {showName &&
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  type="text"
                  className="form-control"
                  name="name"
                  required
                  placeholder="Nome"
                  maxLength={80}
                  data-bouncer-target="#invalid-name"
                />
                <div id="invalid-name" className="invalid-feedback" />
              </div>
            }

            {showLastName &&
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={this.state.last_name}
                  type="text"
                  className="form-control"
                  name="last_name"
                  required
                  placeholder="Sobrenome"
                  data-bouncer-target="#invalid-last-name"
                />
                <div id="invalid-last-name" className="invalid-feedback" />
              </div>
            }

            {showCpf &&
              <div className="form-group">
                <input
                  ref={node => this.inputCpf = node}
                  onChange={this.handleInputChange}
                  value={this.state.cpf}
                  type="text"
                  className="form-control"
                  name="cpf"
                  required
                  placeholder="CPF"
                  minLength={14}
                  data-bouncer-target="#invalid-cpf"
                />
                <div id="invalid-cpf" className="invalid-feedback" />
              </div>
            }

            {showEmail &&
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  type="email"
                  required
                  className="form-control"
                  name="email"
                  placeholder="E-mail"
                  maxLength={80}
                  data-bouncer-target="#invalid-email"
                />
                <div id="invalid-email" className="invalid-feedback" />
              </div>
            }

            {showPhone &&
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={this.state.phone}
                  ref={phoneInput => (this.phoneInput = phoneInput)}
                  className="form-control"
                  name="phone"
                  required
                  type="phone"
                  data-bouncer-target="#invalid-phone"
                  placeholder="Telefone/Whatsapp"
                />
                <div id="invalid-phone" className="invalid-feedback" />
              </div>
            }

            {showUnit &&
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="unit"
                  options={units}
                  value={this.state.unit}
                  shouldSort={false}
                  placeholderValue="Escolha a unidade"
                  placeholder={true}
                  searchEnabled={false}
                />
              </div>
            }

            {showModel &&
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="product"
                  options={this.props.optionModel}
                  value={this.state.product}
                  shouldSort={false}
                  placeholderValue="Selecione o modelo"
                  searchEnabled={false}
                />
              </div>
            }

            {showInteraction &&
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="interaction"
                  options={this.props.optionInteration}
                  value={this.state.interaction}
                  shouldSort={false}
                  placeholderValue="Qual seu interesse?"
                  searchEnabled={false}
                />
              </div>
            }

            {showVersions && this.props.versions && this.props.versions.length > 0 && (
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="model_car"
                  options={this.props.versions}
                  value={this.state.model_car}
                  shouldSort={false}
                  placeholderValue="Escolha um modelo"
                  searchEnabled={true}
                />

                {!this.state.versionValidStatus && (
                  <div id="invalid-version" class="invalid-feedback is-invalid-version">
                    <div class="error-message" id="bouncer-error_version">Por favor, selecione esse campo</div>
                  </div>
                )}
              </div>
            )}

            {(showMailing || showWhatsapping || showPhoning) &&
              <fieldset class="conversion-step__contact-by">
                <legend className="conversion-step__legend">Quero receber contato por:</legend>
                {showMailing &&
                  <div className="form-check form-check-inline">
                    <CustomCheck
                      name="mailing"
                      value="true"
                      onChangeCheckable={this.handleContactOptionsChange}
                      isChecked={this.state.mailing}
                      type="checkbox"
                      checkStyle="dark"
                    >
                      E-mail
                    </CustomCheck>
                  </div>
                }

                {showWhatsapping &&
                  <div className="form-check form-check-inline">
                    <CustomCheck
                      name="whatsapping"
                      value="true"
                      isChecked={this.state.whatsapping}
                      onChangeCheckable={this.handleContactOptionsChange}
                      type="checkbox"
                      checkStyle="dark"
                    >
                      Whatsapp
                    </CustomCheck>
                  </div>
                }

                {showPhoning &&
                  <div className="form-check form-check-inline">
                    <CustomCheck
                      name="phoning"
                      value="true"
                      isChecked={this.state.phoning}
                      onChangeCheckable={this.handleContactOptionsChange}
                      type="checkbox"
                      checkStyle="dark"
                    >
                      Telefone
                    </CustomCheck>
                  </div>
                }
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control d-none"
                    name="contact-options"
                    required
                    placeholder="Opções de contato"
                    data-bouncer-target="#invalid-contact-options"
                    value={this.state.contactOptions}
                    checked={this.state.contactOptions}
                  />
                  <div id="invalid-contact-options" className="invalid-feedback" />
                </div>
              </fieldset>
            }

            {this.props.shouldShowDataPermissions && showDataPermissions && (
              <fieldset className="mt-0 mb-2">
                <div className="form-check form-check-inline conversion-form__data-permissions-field dark">
                  <CustomCheck
                    name="dataPermissions"
                    value="true"
                    isChecked={this.state.dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                    checkStyle="dark"
                  >
                    { this.props.dataPermissionsCustomText }
                  </CustomCheck>
                </div>
              </fieldset>
            )}

            { (showMailing || showWhatsapping || showPhoning) &&
                <footer className="form-conversion__footer">
                Ao informar meus dados, eu concordo com a{' '}
                <Footer link={linkPrivacyPolicy} />
                <hr className="my-3"></hr>
              </footer>
            }

            <div className="conversion-step__actions">
              {this.showButtonPrevStep() &&
                <ButtonPrev onClick={this.handlePrevStep} />
              }
              {this.showButtonNextStep() &&
                <ButtonNext onClick={this.handleNextStep} />
              }
              {this.showButtonFinish() &&
                <SubmitButton
                  handleClick={this.handleFinish}
                  isSubmitting={this.props.isSubmittingForm}
                  classes="btn button button--block button--primary"
                  label="Agendar agora"
                />
              }
            </div>
          </div>
        }
      </form>
    )
  }
}

Step.defaultProps = {
  units: [],
  unit: '',
  showForm: true,
  showChronometer: true,
  showOverlay: false,
  errorSendingForm: false,
  onChangeForm() { },
  handleCloseOverlay() { },
  optionModel: [],
  shouldShowDataPermissions: false,
  showDataPermissions: false,
  optionInteration: [
    { label: 'Agendar test-drive', value: 'Agendar test-drive' },
    { label: 'Receber contato', value: 'Receber contato' },
    { label: 'Preços e condições', value: 'Preços e condições' },
    { label: 'Acessórios', value: 'Acessórios' },
    { label: 'Seminovos', value: 'Seminovos' },
    { label: 'Agendamento de serviços', value: 'Agendamento de serviços' },
    { label: 'Peças', value: 'Peças' },
    { label: 'Manutenção periódica', value: 'Manutenção periódica' },
    { label: 'Inspeção gratuita', value: 'Inspeção gratuita' },
    { label: 'Consórcio', value: 'Consórcio' },
    { label: 'Financiamento', value: 'Financiamento' },
    { label: 'Seguro', value: 'Seguro' }
  ]
}

Step.propTypes = {
  formId: PropTypes.string.isRequired,
  title: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  units: PropTypes.array,
  expiredAt: PropTypes.string,
  showForm: PropTypes.bool,
  showChronometer: PropTypes.bool,
  showUnit: PropTypes.bool,
  showName: PropTypes.bool,
  showLastName: PropTypes.bool,
  showEmail: PropTypes.bool,
  showPhone: PropTypes.bool,
  showCpf: PropTypes.bool,
  showModel: PropTypes.bool,
  showInteraction: PropTypes.bool,
  showMailing: PropTypes.bool,
  showWhatsapping: PropTypes.bool,
  showPhoning: PropTypes.bool,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  onChangeForm: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  optionInteration: PropTypes.array,
  optionModel: PropTypes.array,
  shouldShowDataPermissions: PropTypes.bool,
  showDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
}

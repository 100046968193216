import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import MicroModal from 'micromodal';

export default class SeeConditions extends Component {

  constructor(props) {
    super(props);
    this.handleOpenModalClick = this.handleOpenModalClick.bind(this);
  }

  handleOpenModalClick(e) {
    e.preventDefault();

    this.modal = MicroModal.show(this.props.modalId);
  }

  render() {
    return (
      <div className="see-conditions">
        <a href="#" className={"see-conditions__link"} onClick={this.handleOpenModalClick}>{this.props.buttonText}</a>
        <div
          class="modal micromodal-slide"
          id={this.props.modalId}
          aria-hidden="true"
        >
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div className="position-relative">
              <i
                className="modal__close modal__overlay-close--form icon icon-close"
                data-micromodal-close
              />
              <div className="see-conditions__conditions-box" dangerouslySetInnerHTML={{ __html: `
                <h1>Condições da Oferta</h1>${this.props.conditions}` }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SeeConditions.defaultProps = {
  modalId: 'modal-whatsapp',
  conditions: 'Preencha o formulário para ter mais informações sobre as condições da oferta.',
  buttonText: 'Ver condições',
};

SeeConditions.propTypes = {
  modalId: PropTypes.string,
  conditions: PropTypes.string,
  buttonText: PropTypes.string,
};

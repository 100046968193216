/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unknown-property */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { registerField } from '%/utils/customForm';
import { handleInputFileChange } from '%/utils/uploadFile';

export default class InputFile extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      files: [],
      sendingFile: false,
      uploadError: false,
    };

    this.handleInputFileChange = handleInputFileChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload({ file }) {
    return window.serviceConversion.uploadFileS3(file);
  }

  onChange(e) {
    this.props.onChange(e);
  }

  get label() {
    if (this.state.sendingFile) {
      return 'Enviando...';
    }
    return this.hasFiles() ? this.state.files[0].name : this.props.label;
  }

  hasFiles() {
    return this.state.files && this.state.files.length > 0;
  }

  render() {
    const { id, fileExtension, multipart, maxSizeFiles, textHelper, multiple } =
      this.props;

    return (
      <div className="input-file">
        <div className="input-group">
          <div className="custom-file">
            <input
              ref={node => {
                this.inputFile = node;
              }}
              type="file"
              className="custom-file-input"
              id={id}
              accept={fileExtension}
              multipart={multipart}
              multiple={multiple}
              {...registerField('files', {
                callback: this.handleInputFileChange,
              })}
            />
            <label
              className="input-file__label custom-file-label"
              htmlFor={id}
              aria-describedby={id}
            >
              {this.label}
            </label>
          </div>
        </div>

        {this.state.uploadError && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            <div className="error-message">
              houve um erro ao enviar o arquivo
            </div>
          </div>
        )}

        {textHelper ? (
          <small className="input-file__helper">{textHelper}</small>
        ) : (
          <small className="input-file__helper">
            No formato {fileExtension} com o tamanho de até {maxSizeFiles}.
          </small>
        )}
      </div>
    );
  }
}

InputFile.defaultProps = {
  onChange() {},
  label: 'Escolher arquivo',
  multipart: true,
  fileExtension: '.pdf, .doc, .docx',
  maxSizeFiles: '10mb',
  multiple: true,
};

InputFile.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  fileExtension: PropTypes.string,
  multipart: PropTypes.string,
  maxSizeFiles: PropTypes.string,
  textHelper: PropTypes.string,
  multiple: PropTypes.bool,
};

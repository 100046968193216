// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchTerm from './SearchTerm';
import ButtonFilter from './ButtonFilter';
import DropdownFilter from './DropdownFilter';
import ButtonOrder from './ButtonOrder';
import DropdownOrder from './DropdownOrder';

/**
 * Componente da primeira área do filtro de seminovos
 */
class SimpleFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      showSuggestions: false,
    };

    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleFilterDropClick = this.handleFilterDropClick.bind(this);
    this.handleClickOrder = this.handleClickOrder.bind(this);
    this.handleSetSortParams = this.handleSetSortParams.bind(this);
    this.handleClickSuggestion = this.handleClickSuggestion.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleChangeKeywordImediate =
      this.handleChangeKeywordImediate.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleChangeCategories = this.handleChangeCategories.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleChangeModels = this.handleChangeModels.bind(this);
  }

  handleFilterClick(e) {
    e.preventDefault();
    this.props.onFilterClick(e);
  }

  handleFilterDropClick(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.props.onFilterDropClick(e);
  }

  handleChangeCategories(categories) {
    this.props.onChangeCategories(categories);
  }

  handleChangeModels(models) {
    this.props.onChangeModels(models);
  }

  handleClickOrder(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.props.onClickOrder(e);
  }

  handleSetSortParams(params) {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      sortTitle: params.title,
    });
    this.props.onSetSortParams(params.paramValue);
  }

  handleClickSuggestion(suggestion) {
    this.props.onClickSuggestion(suggestion).then(() => {
      this.setState({
        showSuggestions: false,
      });
    });
  }

  handleChangeKeywordImediate(e) {
    const { value } = e.target;
    this.props.onChangeKeywordImediate(value);
  }

  handleChangeKeyword(e) {
    const { fieldSuggestion } = this.props;
    const keyword = e.target.value;

    this.props.onChangeKeyword(keyword).then(res => {
      const suggestions = res.entries.map(
        suggestion => suggestion[fieldSuggestion],
      );

      this.setState({
        suggestions,
        showSuggestions: !(keyword === '') && e.key !== 'Enter',
      });
    });
  }

  handleClickSearch(keyword) {
    this.setState({
      showSuggestions: false,
    });
    this.props.onClickSearch(keyword).then(() => {
      this.setState({
        showSuggestions: false,
      });
    });
  }

  handleSubmitSearch(e) {
    e.preventDefault();
    this.props.onSubmitSearch();
  }

  showDropdownFilter() {
    const { filterCategories, filterModels } = this.props;

    if (typeof filterCategories === 'string' && filterCategories) return true;

    if (Array.isArray(filterCategories) && filterCategories.length > 0)
      return true;

    if (Array.isArray(filterModels) && filterModels.length > 0) return true;

    return false;
  }

  render() {
    const {
      showFilter,
      showOrder,
      showButtonFilter,
      showButtonOrder,
      showDropdownIcon,
      showActiveStatus,
      filterCategories,
      fieldKeywordFilter,
      classNameButtonFilter,
      classNameButtonOrder,
      sort,
      orderDropRight,
      filterModels,
      categoriesSelected,
      modelsSelected,
    } = this.props;

    return (
      <form className="simple-filter" onSubmit={this.handleSubmitSearch}>
        <div className="row">
          {showButtonFilter && (
            <div className="col-md-3 col-lg-2 simple-filter__label-filter filter-button">
              {!this.showDropdownFilter() && (
                <ButtonFilter
                  className={classNames(
                    'd-none d-md-block simple-filter-btn-filter simple-filter__item',
                    classNameButtonFilter,
                  )}
                  onClick={this.handleFilterClick}
                  showDropdownIcon={showDropdownIcon}
                />
              )}

              {this.showDropdownFilter() && (
                <DropdownFilter
                  className="d-none d-md-block"
                  showFilter={showFilter}
                  categoriesSelected={categoriesSelected}
                  modelsSelected={modelsSelected}
                  filterCategories={filterCategories}
                  filterModels={filterModels}
                  onChangeCategories={this.handleChangeCategories}
                  onChangeModels={this.handleChangeModels}
                  onClose={this.handleFilterDropClick}
                >
                  <ButtonFilter
                    className={classNames(
                      'simple-filter-btn-filter simple-filter__item',
                      classNameButtonFilter,
                    )}
                    onClick={this.handleFilterDropClick}
                    isOpen={showFilter}
                    showDropdownIcon={showDropdownIcon}
                    showActiveStatus={showActiveStatus}
                  />
                </DropdownFilter>
              )}
            </div>
          )}

          {showButtonOrder && (
            <div className="col-md-3 col-lg-2 simple-filter__label-order order-button">
              <DropdownOrder
                className="d-none d-md-block"
                showOrder={showOrder}
                onSetSortParams={this.handleSetSortParams}
                value={this.props.valueSort}
                sort={sort}
                dropRight={orderDropRight}
                onClose={this.handleClickOrder}
              >
                <ButtonOrder
                  className={classNames(classNameButtonOrder)}
                  onClick={this.handleClickOrder}
                  isOpen={showOrder}
                  showDropdownIcon={showDropdownIcon}
                  showActiveStatus={showActiveStatus}
                  sort={sort}
                />
              </DropdownOrder>
            </div>
          )}
          <div
            className={classNames('search-term', {
              'col-md-6': showButtonFilter && showButtonOrder,
              'col-lg-8': showButtonFilter && showButtonOrder,
              'col-md-9':
                (!showButtonFilter && showButtonOrder) ||
                (showButtonFilter && !showButtonOrder),
              'col-lg-10':
                (!showButtonFilter && showButtonOrder) ||
                (showButtonFilter && !showButtonOrder),
              'col-lg-12': !showButtonFilter && !showButtonOrder,
              'simple-filter__label-text': true,
            })}
          >
            <SearchTerm
              className="simple-filter__item"
              value={this.props.keyword}
              clickSuggestion={this.handleClickSuggestion}
              changeKeyword={this.handleChangeKeyword}
              changeKeywordImediate={this.handleChangeKeywordImediate}
              suggestions={this.state.suggestions}
              showSuggestions={this.state.showSuggestions}
              fieldKeywordFilter={fieldKeywordFilter}
              onClickSearch={this.handleClickSearch}
            />
          </div>
        </div>
      </form>
    );
  }
}

SimpleFilter.defaultProps = {
  onFilterClick() {},
  fieldSuggestion: 'name',
  onClickSuggestion() {},
  onSetSortParams() {},
  onChangeKeywordImediate() {},
  onChangeKeyword() {},
  onClickSearch() {},
  onChangeCategories() {},
  onChangeModels() {},
  onSubmitSearch() {},
  onFilterDropClick() {},
  onClickOrder() {},
  showButtonFilter: true,
  showButtonOrder: true,
  orderDropRight: false,
  showFilter: false,
  showOrder: false,
  showDropdownIcon: false,
  showActiveStatus: false,
  filterCategories: [],
  filterModels: [],
};

SimpleFilter.propTypes = {
  onFilterClick: PropTypes.func,
  fieldSuggestion: PropTypes.string,
  onClickSuggestion: PropTypes.func,
  onSetSortParams: PropTypes.func,
  onChangeKeywordImediate: PropTypes.func,
  onChangeKeyword: PropTypes.func,
  onClickSearch: PropTypes.func,
  onChangeCategories: PropTypes.func,
  onChangeModels: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  onFilterDropClick: PropTypes.func,
  onClickOrder: PropTypes.func,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterModels: PropTypes.arrayOf(PropTypes.string),
  fieldKeywordFilter: PropTypes.string,
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  valueSort: PropTypes.string,
  keyword: PropTypes.string,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
  showButtonFilter: PropTypes.bool,
  showButtonOrder: PropTypes.bool,
  orderDropRight: PropTypes.bool,
  showFilter: PropTypes.bool,
  showOrder: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  categoriesSelected: PropTypes.arrayOf(PropTypes.string),
  modelsSelected: PropTypes.arrayOf(PropTypes.string),
};

export default SimpleFilter;

import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from '../../CustomSelect';

export default class InputLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      states: [],
      statesIds: [],
      cities: [],
      city: '',
    };

    this.setState({ city: this.props.city })

    this.getUFID = this.getUFID.bind(this);
    this.handleStateFieldChange = this.handleStateFieldChange.bind(this);
    this.clearCityStateField = this.clearCityStateField.bind(this);

    this.populateStateSelect();
  }

  populateStateSelect() {
    fetch(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
    )
      .then((res) => res.json())
      .then((states) => {
        const formattedStates = states.map((state) => {
          return {
            value: state.sigla,
            label: state.sigla,
          };
        });
        const statesIds = states.map((state) => {
          return {
            sigla: state.sigla,
            id: state.id,
          };
        });
        this.setState({ statesIds: statesIds });
        this.setState({ states: formattedStates });
      });
  }

  populateCitySelect(uf) {
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`
    )
      .then((res) => res.json())
      .then((cities) => {
        const formattedCities = cities.map((city) => {
          return {
            value: city.nome,
            label: city.nome,
          };
        });

        this.setState({ cities: formattedCities });

        if(this.props.showExternalCity){
          this.setState({ 
            city: this.props.externalCity
          });
        }
      });
  }

  getUFID(uf) {
    if (!uf) return;
    const state = this.state.statesIds.find((state) => state.sigla === uf);
    return state && state.id;
  }

  handleStateFieldChange(e) {
    const uf = e.target.value;
    const ufID = this.getUFID(uf);

    this.clearCityStateField();

    this.props.handleStateChange(e);

    if(!ufID) return;
    this.populateCitySelect(ufID);
  }

  clearCityStateField() {
    this.setState({ city: '' });
  };

  render() {
    const { handleCityChange, uf } = this.props;

    return (
      <div className="form-group">
        <div class='location-fields'>
          <CustomSelect
            handleSelectChange={this.handleStateFieldChange}
            name="uf"
            options={this.state.states}
            value={uf}
            shouldSort={false}
            placeholderValue="UF"
            searchEnabled={true}
          />

          <CustomSelect
            handleSelectChange={handleCityChange}
            name="city"
            options={this.state.cities}
            value={this.state.city}
            shouldSort={false}
            placeholderValue="Cidade"
            searchEnabled={true}
            required={true}
            errorMessageDisplay="#error-city"
          />
        </div>

        <div id="error-city" class="invalid-feedback is-error-city">
        </div>
      </div>
    );
  }
}

InputLocation.defaultProps = {};

InputLocation.propTypes = {};

import fetchival from 'fetchival';
import autoHookConversion from './utils/autoHookConversion';
import reCAPTCHA, { setRecaptchaLoadOnFormFocus } from './utils/reCAPTCHA';

document.addEventListener('readystatechange', event => {
  setRecaptchaLoadOnFormFocus();
});

/**
 * @typedef ServiceOptions
 * @property {string} baseUrl URL base das requisições
 * @property {string} token Token de requisição
 * @property {string} [account] A conta a ser utilizada
 * @property {string} [channel] O canal da conta
 * @property {string} [channelToken] O token de identificação do canal
 */

/**
 * Valida se o objeto de opções do fetcher é válido e se as chaves requeridas
 * possuem valores não nulos.
 * @param {ServiceOptions} options Opções para o fetcher
 */
export function optionsAreValid(options) {
  const mandatoryOptions = ['baseUrl'];
  return mandatoryOptions.reduce(
    (acc, cur) => acc && options[cur] != null,
    true,
  );
}

/**
 * Service de requisições para a API AutoBoxOffice.
 * @property {ServiceOptions} options
 * @property {*} _serviceFetcher Objeto que faz requisições
 */
export default class AutoForceService {
  /**
   * @param {ServiceOptions} options
   */
  constructor(options = {}) {
    if (!optionsAreValid(options)) {
      throw 'URL base da API não informada.';
    }

    this.options = options;

    // Configuramos o fetcher com uma aplicação parcial da função `fetch`.
    this._serviceFetcher = (url = '', fetcherOptions = {}) => {
      return fetchival(`${options.baseUrl}/${url}`, {
        ...fetcherOptions,
        headers: {
          Authorization: `Token token=${options.token}`,
          ...fetcherOptions.headers,
        },
      });
    };
  }

  /**
   * @return {fetchival}
   */
  get serviceFetcher() {
    return this._serviceFetcher;
  }

  getMetaContent(name, fallback = '') {
    return document.querySelector(`meta[name="${name}"]`).content || fallback;
  }

  /**
   * Efetua uma conversão no AutoRacing.
   * @param {*} data
   * @returns {Promise}
   */
  async convert(data) {
    let token = null;
    try {
      token = await reCAPTCHA();
    } catch (e) {
      console.log('erro ao pegar token do recaptcha');
    }

    let conversion = {
      'g-recaptcha-response': token,
      origin: this.options.channelToken,
      ...data,
    };

    conversion.channel = data.channel ? data.channel : this.options.channel;
    conversion.origin_url = window.location.href;
    conversion.user_agent = window.navigator.userAgent;

    return this.serviceFetcher()
      .post(conversion)
      .then(res => {
        /**
         * função padrao que deve ser chamado sempre que uma
         * conversão for enviada
         */
        autoHookConversion(conversion);

        /**
         * sempre que uma conversão for enviada com sucesso,
         * esse método vai ser chamado enviando os dados por parametro
         */
        window.hookConversion(conversion);

        return res;
      });
  }

  /**
   * Pega o request para enviar os arquivos para o S3
   * @param {Object} file arquivo de algum input type file
   * @returns {Object} Retorna um objecto com as seguintes propiedades
   * signedReques = url para enviar o arquivo para o S3
   * url = url do arquivo no S3
   */
  getSignedRequestS3(file = {}) {
    const directUploadUrl = this.getMetaContent('direct_upload_url');

    return fetchival(directUploadUrl).get({
      filename: encodeURIComponent(file.name),
      filetype: file.type,
    });
  }

  /**
   * Envia o arquivo para o AWS S3
   * @param {Object} signature valor retornado pelo método getSignedRequestS3
   * @param {File} file arquivo de algum input type file
   * @returns Retorna a url do arquivo
   */
  uploadFileDirectS3(signature, file = {}) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    };
    return fetch(signature.signedRequest, options).then(res => {
      if (res.ok) return encodeURI(signature.url);
      console.error(res);
      throw new Error('Error ao enviar arquivo para o AWS S3');
    });
  }

  /**
   * Faz o envio de um arquivo para o S3
   * @param {File} file arquivo de algum input type file
   */
  uploadFileS3(file = {}) {
    return this.getSignedRequestS3(file)
      .catch(res => {
        console.error(res);
        throw new Error('Erro ao pegar dados para o upload do método sign-s3');
      })
      .then(signature => this.uploadFileDirectS3(signature, file));
  }

  /**
   * Para multiplo uploads
   * @param {Array<File>} files
   */
  uploadMultipleFilesS3(files = []) {
    return new Promise((resolve, reject) => {
      for (let file of files) {
        this.uploadFileS3(file).catch(res => {
          console.error(res);
          reject('Erro ao enviar arquivo para o S3', res);
        });
      }

      files.length > 0
        ? resolve('Arquivos enviados com sucesso')
        : resolve('Sem arquivos para enviar');
    });
  }
}

import { h } from 'preact';
import { Component } from 'react';

/**
 * Representa um box com CTA que abre um form ao clicar nese CTA
 */
export default class BoxWithModel extends Component {
  /**
   * Obtém o conteúdo do box baseado no seu tipo
   */
  get content() {
    return this.props.content[this.props.type];
  }

  render() {
    return (
      <div
        className={`cta-box-with-model cta-box-with-model--${this.content.boxTypeModifier}`}
      >
        <div className="cta-box-with-model__model-image">
          <img
            src={this.props.modelImage}
            alt={this.props.buttonText}
            height="200"
            width="290"
          />
        </div>
        <div className="cta-box-with-model__content">
          <h2>{this.props.mainPhrase}</h2>
          <p>{this.props.subtitle}</p>
          <div className="cta-box-with-model__button-container">
            <a
              href={this.props.link}
              target="_blank"
              id={this.props.buttonId}
              onClick={this.props.handleClick}
              className={
                'btn button button--large ' + this.content.buttonClasses
              }
            >
              {this.content.shouldIncludeIcon && (
                <i className={this.content.iconClasses} />
              )}
              {this.props.buttonText}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

BoxWithModel.defaultProps = {
  type: 'whatsapp',
  mainPhrase: 'Quer um atendimento personalizado?',
  subtitle: 'Negocie diretamente com um de nossos consultores via WhatsApp.',
  buttonText: 'Negocie pelo WhatsApp',
  // Conteúdo que varia com o tipo do box
  content: {
    whatsapp: {
      boxTypeModifier: 'whatsapp',
      buttonClasses: 'button--whatsapp',
      shouldIncludeIcon: true,
      iconClasses: 'icon icon-whatsapp',
    },
  },
  handleClick: function () {},
  modelImage: '',
  link: '#',
};

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import sortItemsDefault from '%/utils/sort'

class ButtonOrder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultLabel: this.props.children.length > 0 ? this.props.children : 'Ordenar'
    }

    window.store.SearchFilter.watch(state => {
      this.setState({
        activeStatus: this.setActiveOrder(state.sort)
      })
    })
  }

  labelForDefaultValue(value) {
    const defaultValue = 'ordination,-created_at'
    if (value === defaultValue) return 'Ordenar'
  }

  getParamTitle(value) {
    const isDefault = this.labelForDefaultValue(value)
    if (!!isDefault) return isDefault

    const labels = this.props.sort;
    const param = labels.find(label => label.paramValue === value)
    return param && param.title
  }

  setActiveOrder(sortLabel) {
    return sortLabel && this.getParamTitle(sortLabel)
  }

  setLabel() {
    const { activeStatus, defaultLabel } = this.state
    if (!this.props.showActiveStatus || !activeStatus) return defaultLabel

    return activeStatus
  }

  renderContent() {
    const { isOpen, showDropdownIcon } = this.props

    return (
      <span>
        <span
          className={classNames({
            'd-none': !isOpen
          })}
          data-testid="close"
        >
          Fechar
          { showDropdownIcon && <i className="icon icon-chevron"></i> }
        </span>

        <span
          className={classNames({
            'd-none': isOpen
          })}
          data-testid="open"
        >
          <i className="icon icon-order"></i>
          { this.setLabel() }
          { showDropdownIcon && <i className="icon icon-chevron"></i> }
        </span>
      </span>
    )
  }

  render() {
    const { className, onClick } = this.props

    return (
      <a
        className={classNames('button-order btn button button--large button--no-upper', className)}
        href="#"
        onClick={(e) => onClick(e)}
      >
        { this.renderContent() }
      </a>
    )
  }
}

ButtonOrder.defaultProps = {
  onClick: () => {},
  isOpen: false,
  showDropdownIcon: false,
  showActiveStatus: false,
  sort: sortItemsDefault,
};

ButtonOrder.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
};

export default ButtonOrder;

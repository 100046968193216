import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { createContext } from 'react'

import Toggle from './Toggle'
import Collapse from './Collapse'

const { Consumer, Provider } = createContext()

class Accordion extends Component {
  render() {
    const { id, children, defaultActiveKey } = this.props

    return (
      <div id={id} className="accordion">
        <Provider value={{ id, defaultActiveKey }}>
          { children }
        </Provider>
      </div>
    )
  }
}

Accordion.Consumer = Consumer
Accordion.Toggle = Toggle
Accordion.Collapse = Collapse

Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  defaultActiveKey: PropTypes.string,
}

export default Accordion

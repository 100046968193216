import { h } from 'preact';
import { render } from 'react-dom';
import { Component } from 'react';
import maskField, { MASK_TYPES } from '%/utils/maskField';
import PropTypes from 'prop-types';

export default class StaticForm extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.renderStaticFields();
  }

  renderStaticFields() {
    let node = this.getDOMNode();
    render(
      <div
        class="form-conversion__custom-fields form-group"
        dangerouslySetInnerHTML={{ __html: this.props.staticFormMarkup }}
      ></div>,
      node
    );
  }

  render() {
    return <div />;
  }
}

StaticForm.propTypes = {
  staticFormMarkup: PropTypes.string
};

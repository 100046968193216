import { h, Component } from 'preact';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import sortItemsDefault from '%/utils/sort';

class DropdownOrder extends Component {
  addEventListeners() {
    window.addEventListener('click', event => {
      if (
        this.ref &&
        event.target !== this.ref &&
        !this.ref.contains(event.target) &&
        this.props.showOrder
      ) {
        this.props.onClose();
      }
    });
  }

  componentDidMount() {
    this.addEventListeners();
  }

  render() {
    const { children, className, showOrder, sort, dropRight } = this.props;

    return (
      <div>
        <div
          className={classNames('backdrop', {
            'backdrop--active': showOrder,
          })}
          onClick={this.props.onClose}
        />
        <div
          ref={node => (this.ref = node)}
          className={classNames('dropdown-order', className, {
            'search-filter-simple__drop': true,
            'search-filter-simple__item': true,
            'search-filter-simple__drop--active': showOrder,
          })}
        >
          {children}

          <div
            className={classNames(
              'card-collapse-deep search-filter-simple__drop-item',
              {
                'search-filter-simple__drop-item--right': dropRight,
              },
            )}
          >
            <div class="card-collapse-deep__header">Ordenar por:</div>
            <div class="card-collapse-deep__close" style={{ display: 'none' }}>
              <button
                class="btn btn--icon btn--icon--small"
                onClick={this.props.onClose}
              >
                <i class="icon icon-close"></i>
              </button>
            </div>

            <ul class="list list--border-bottom">
              {sort.map(sortItem => (
                <li
                  className={classNames({
                    'card-collapse-deep__title': true,
                    'card-collapse-deep__title--link': true,
                    'card-collapse-deep__title--active':
                      sortItem.paramValue === this.props.value,
                  })}
                  onClick={() => {
                    this.props.onSetSortParams(sortItem);
                  }}
                >
                  <strong>{sortItem.title}</strong>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

DropdownOrder.defaultProps = {
  showOrder: false,
  dropRight: false,
  sort: sortItemsDefault,
  onSetSortParams() {},
  onClose() {},
};

DropdownOrder.propTypes = {
  showOrder: PropTypes.bool,
  dropRight: PropTypes.bool,
  value: PropTypes.string.isRequired,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),

  onSetSortParams: PropTypes.func,
  onClose: PropTypes.func,
};

export default DropdownOrder;

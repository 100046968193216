import { Component } from 'preact';
import ListPaginationApp from './index';
import { mapFieldsV2 } from '../SearchFilterSidebarApp/UsedModelsSpecial';

export default class ListPaginationUsedModelsSpecialApp extends Component {
  render() {
    return (
      <ListPaginationApp
        mapFields={mapFieldsV2}
        methodAPI="getUsedModelsSpecialOffers"
        category="Clone"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
      />
    );
  }
}

import { h } from 'preact'

export default function (props) {
  return (
    <button
      type="button"
      class="btn button button--block button--primary"
      onClick={props.onClick}
    >
      Próximo
    </button>
  )
}

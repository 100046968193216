// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import ListPaginationApp from './index';
import { mapFieldsV2 } from '../SearchFilterSidebarApp/UsedModels';

export default class ListPaginationUsedModelsApp extends Component {
  render() {
    return (
      <ListPaginationApp
        mapFields={mapFieldsV2}
        methodAPI="getUsedModels"
        category="UsedModel"
        {...this.props}
      />
    );
  }
}

import { h } from 'preact';
import { Component } from 'react';
import ConsortiumSearchForm from '#/ConsortiumSearchForm';
import PropTypes from 'prop-types';
import { setMethodApi } from '../stores/SearchFilter'

export default class ConsortiumSearchFormApp extends Component {
  constructor(props) {
    super(props);

    setMethodApi('getConsortia')
  }

  render() {
    return (
      <ConsortiumSearchForm
        formId="search-form-consortium"
        maxTotalValue={this.props.maxTotalValue}
        maxValuePerMonth={this.props.maxValuePerMonth}
        minTotalValue={this.props.minTotalValue}
        minValuePerMonth={this.props.minValuePerMonth}
        formAction={this.props.formAction}
      />
    );
  }
}

ConsortiumSearchFormApp.defaultProps = {
  maxTotalValue: "0",
  maxValuePerMonth: "0",
  minTotalValue: "0",
  minValuePerMonth: "0",
};

ConsortiumSearchFormApp.propTypes = {
  maxTotalValue: PropTypes.string,
  maxValuePerMonth: PropTypes.string,
  minTotalValue: PropTypes.string,
  minValuePerMonth: PropTypes.string,
  formAction: PropTypes.string,
};

import { addErrorStatusToField } from './addErrorStatusToField';
import { removeErrorStatusFromField } from './removeErrorStatusFromField';

export function makeFieldErrorHandler(setError) {
  return (target, validationResult) => {
    if (validationResult.hasError) {
      addErrorStatusToField(target, validationResult.error);
      setError({
        key: target.name,
        error: validationResult.error,
      });
    } else {
      setError({
        key: target.name,
        error: null,
      });
      removeErrorStatusFromField(target);
    }
  };
}

import { createStore, createEvent } from 'effector';

export const setVehicleColor = createEvent('set vehicle color');

let GWMProject = (function() {
  let instance;

  return function(
    defaultValue = {
      vehicleColor: ''
    }
  ) {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setVehicleColor, (state, vehicleColor) => ({
        ...state,
        vehicleColor: vehicleColor
      }));
    }

    return instance;
  };
})();

export default GWMProject;

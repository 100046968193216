import { createStore, createEvent } from 'effector'

export const setShowFilterAdvanceMobile = createEvent('set show filter advance mobile')
export const setShowComponent = createEvent('set show component')
export const setShowNewVehicle = createEvent('set new vehicle')
export const setShowOnlyNewVehicle = createEvent('set only new vehicle')

let ListUsedModels = (function () {
  let instance

  return function (defaultValue = {
    showComponent: 'SimpleFilter',
    showFilterAdvanceMobile: false,
    showNewVehicle: null, // se deve mostrar seminovos 0km na listagem
    showOnlyNewVehicle: false, // se deve mostrar apenas seminovos 0km na listagem
  }) {
    if (!instance) {
      instance = createStore(defaultValue)

      instance.on(setShowFilterAdvanceMobile, (state, show) => ({
        ...state,
        showFilterAdvanceMobile: show
      }))

      instance.on(setShowComponent, (state, showComponent) => ({
        ...state,
        showComponent
      }))

      instance.on(setShowNewVehicle, (state, showNewVehicle) => ({
        ...state,
        showNewVehicle
      }))

      instance.on(setShowOnlyNewVehicle, (state, showOnlyNewVehicle) => ({
        ...state,
        showOnlyNewVehicle
      }))
    }

    return instance
  }
})()

export default ListUsedModels

import { h, Component } from 'preact'

class Item extends Component {
  getLink() {
    const { part_link, slug } = this.props
    return `${part_link}/${slug}`
  }

  render() {
    const { name, image, direct_sales_count, summary } = this.props

    return (
      <div class="card">
        <div class="card__header">
          <div class="card__image card__image--large card__image--background-dark">
            <a href={this.getLink()}>
              { image ?
                <div class="card__image-value" style={`background-image: url('${image}');`}></div>
              :
                <div class="card__image-value default-bg__model-middle-default"></div>
              }
            </a>
          </div>
          <div data-match-height="direct-sales-category-title" class="list-direct-sales-categories__card-title card__title card__title--fixed-bottom">{ name }</div>
        </div>
        <div class="card__content card__content--service">
          <div class="list-direct-sales-categories__card-subtitle card__title">
            <span class="list-direct-sales-categories__card-total">{ direct_sales_count }</span> ofertas encontradas
          </div>
          <div data-match-height="list-direct-sales-categories__card-summary" class="list-direct-sales-categories__card-summary card__subtitle">{ summary }</div>
        </div>

        <div class="card__footer">
          <a href={this.getLink()} class="btn button button--primary button--block button--large card__cta">Conferir ofertas</a>
        </div>
      </div>
    )
  }
}

export default Item

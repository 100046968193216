import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import Item from './Item'
import ItemPlaceholder from './ItemPlaceholder'
import ButtonLoadMore from './ButtonLoadMore'

class ListOffers extends Component {
  render() {
    const { items, onClickButtonLoadMore, loading, loadingMore, part_link } = this.props

    return (
      <div class="list-offers__list">
        <div class="row">
          { items.map(item => (
            <div class="col-md-6 col-lg-4 list-offers__col-card">
              { (loading && !loadingMore) ? <ItemPlaceholder /> :
                <Item
                  image={item.item_image && item.item_image.middle_image}
                  title={item.title}
                  subtitle={item.subtitle}
                  seal_list={item.seal_list}
                  expired_at={item.expired_at}
                  part_link={part_link}
                  slug={item.slug}
                  secondary_call={item.item_offer && item.item_offer.secondary_call}
                  main_call={item.item_offer && item.item_offer.main_call}
                  featured={item.item_offer && item.item_offer.featured}
                  description={item.item_offer && item.item_offer.description}
                  extra={item.item_offer && item.item_offer.extra}
                  badge={item.item_offer && item.item_offer.badge}
                />
              }
            </div>
          )) }
        </div>
        { this.props.showLoadMore && 
          <div>
            <hr className="list-offers__hr-button" />
            <ButtonLoadMore onClick={onClickButtonLoadMore} loading={loading} /> 
          </div>
        }
      </div>
    )
  }
}

ListOffers.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false
}

ListOffers.propTypes = {
  items: PropTypes.array,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  part_link: PropTypes.string
}

export default ListOffers

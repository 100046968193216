// import Swiper and modules
//import Swiper from 'swiper';
import {
  Swiper,
  Navigation,
  Pagination,
  Autoplay,
  Lazy,
  Thumbs,
  Scrollbar,
} from 'swiper/js/swiper.esm';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, Lazy, Thumbs, Scrollbar]);

import 'swiper/src/swiper.scss';

export default Swiper;

import { h, Component } from 'preact'
import classNames from 'classnames'
import Accordion from './index'

class Toggle extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(eventKey) {
    return (e) => {
      e.preventDefault()
      this.props.onClick(eventKey)
    }
  }

  render() {
    const { eventKey, children } = this.props

    return (
      <Accordion.Consumer>
        {({ defaultActiveKey }) => (
          <div className="accordion__card">
            <a
              href="#"
              className={classNames('accordion__toggle', {
                'accordion__toggle--active': defaultActiveKey === eventKey
              })}
              onClick={this.handleClick(eventKey === defaultActiveKey ? null : eventKey)}
            >
              { children } <i class="icon icon-arrow-d"></i>
            </a>
          </div>
        )}
      </Accordion.Consumer>
    )
  }
}

export default Toggle

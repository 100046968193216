import { Component } from 'preact';
import PropTypes from 'prop-types';
import SearchFilterSidebarApp from './index';

export const mapFields = {
  q: 'q[brand_or_name_or_version_or_model_or_plate_cont]',
  kind: 'q[kind_in]',
  category: 'q[category_in]',
  brand: 'q[brand_eq]',
  model: 'q[model_in]',
  exchange: 'q[exchange_in]',
  color: 'q[color_in]',
  minPrice: 'q[price_value_gteq_all]',
  maxPrice: 'q[price_value_lteq_all]',
  minKm: 'q[km_value_gteq_all]',
  maxKm: 'q[km_value_lteq_all]',
  minYear: 'q[model_year_gteq_all]',
  maxYear: 'q[model_year_lteq_all]',
  unit: 'q[unit_id_in]',
  state: 'q[unit_state_in]',
  city: 'q[unit_city_in]',
};

export const mapFieldsV2 = {
  q: 'search',
  kind: 'kind',
  category: 'category[]',
  brand: 'brand[]',
  model: 'model',
  exchange: 'exchange[]',
  color: 'color[]',
  minPrice: 'price[]',
  maxPrice: 'price[]',
  minKm: 'km[]',
  maxKm: 'km[]',
  minYear: 'model_year[]',
  maxYear: 'model_year[]',
  unit: 'unit_id[]',
  state: 'unit_state',
  city: 'unit_city',
};

export default class SearchFilterSidebarUsedModelsApp extends Component {
  render() {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SearchFilterSidebarApp mapFields={mapFieldsV2} {...this.props} />;
  }
}

SearchFilterSidebarUsedModelsApp.defaultProps = {
  units: [],
};

SearchFilterSidebarUsedModelsApp.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

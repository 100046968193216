import SearchFilter from './SearchFilter';
import SearchFilterFca from './SearchFilterFca';
import vehicleVersionDetails from './vehicleVersionDetails';
import vehicleSearchResults from './vehicleSearchResults';
import ListUsedModels from './ListUsedModels';
import CustomFormFields from './CustomFormFields';
import Consortium, {
  setPlans as setConsortiumPlan,
  setSelectedMonths as setConsortiumSelectedMonths,
  setAvailableMonths as setConsortiumAvailableMonths,
  setMonthlyValue as setConsortiumMonthlyValue,
  setTotalValue as setConsortiumTotalValue,
} from './consortium';
import GWMProject from './GWMProject';

(function () {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  let store = {
    vehicleVersionDetails,
    vehicleSearchResults,
    ListUsedModels,
    CustomFormFields,
    SearchFilter,
    SearchFilterFca,
    Consortium,
    GWMProject,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));

  let storeEvents = {
    setConsortiumSelectedMonths,
    setConsortiumPlan,
    setConsortiumAvailableMonths,
    setConsortiumMonthlyValue,
    setConsortiumTotalValue,
  };

  window.storeEvents = {};
  Object.keys(storeEvents).forEach(
    c => (window.storeEvents[c] = storeEvents[c]),
  );
})();

import { h } from 'preact'
import PropTypes from 'prop-types'

export default function Detash({ brand, title, image }) {
  return (
    <div className="detail-tabs__detash">
      <h3 className="detail-tabs__detash-brand">{ brand }</h3>
      <h2 className="detail-tabs__detash-title">{ title }</h2>
      <img className="detail-tabs__detash-image img-fluid" src={image} alt={title} />
    </div>
  )
}

Detash.propTypes = {
  brand: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
}

import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class Item extends Component {
  render() {
    const {
      slug,
      image,
      title,
      subtitle,
      seal_list,
      secondary_call,
      main_call,
      featured,
      description,
      extra,
      badge,
      part_link,
      brand
    } = this.props;

    const channel_pack_element = document.querySelector("meta[name='channel_pack']")
    const channel_pack = channel_pack_element && channel_pack_element.content;

    return (
      <div className="card">
        <div className="card__header">
          <a href={`${part_link}/${slug}`}>
            <div className="card__image">
              <div
                className="card__image-value"
                style={`background-image: url(${image})`}
              />
            </div>
          </a>
        </div>

        <div className="card__content">
          <div data-match-height="vehicle-card-content">
            <div className="card__title">{title}</div>
            <div className="card__subtitle">{channel_pack == 'showroom_fiat' ? brand : subtitle}</div>
          </div>
          <hr className="card__separator" />
          <div data-match-height="vehicle-card-list">
            {seal_list && seal_list.length > 0 && (
              <div>
                <ul
                  data-match-height="card-list"
                  className="card__list list list--seconday"
                >
                  {seal_list.map(text => (
                    <li>{text}</li>
                  ))}
                </ul>
                <hr className="card__separator" />
              </div>
            )}
          </div>
        </div>

        <div data-match-height="card-footer" className="card__footer">
          <div data-match-height="vehicle-footer" className="card__box-offer">
            {secondary_call && (
              <div className="card__trigger">{secondary_call}</div>
            )}
            {main_call && <div className="card__title">{main_call}</div>}
            {featured && <div className="card__trigger-value">{featured}</div>}
            {description && (
              <div className="card__trigger-description">{description}</div>
            )}
            {extra && <div className="card__trigger-description">{extra}</div>}
            {badge && (
              <div className="badge badge-primary badge--pill">{badge}</div>
            )}
          </div>
          <a
            href={`${part_link}/${slug}`}
            className="btn button button--primary button--block button--large card__cta"
          >
            { this.props.ctaButtonText }
          </a>
        </div>
      </div>
    );
  }
}

Item.defaultProps = {
  ctaButtonText: "Estou Interessado",
};

Item.propTypes = {
  ctaButtonText: PropTypes.string,
};

export default Item;

import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp';
import { sortItems } from '%/utils/sort';

class SimpleFilter extends Component {
  render() {
    const { filterCategories, filterModels, sort, showActiveStatus, showDropdownIcon } = this.props;
    return (
      <SimpleFilterApp
        fieldKeywordFilter="q[name_cont]"
        fieldCategoryFilter="q[categories_name_in][]"
        fieldModelFilter='q[models_name_in][]'
        fieldSuggestion="name"
        filterCategories={filterCategories}
        filterModels={filterModels}
        classNameButtonFilter="button--line"
        classNameButtonOrder="button--line"
        sort={sort}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
      />
    );
  }
}

SimpleFilter.defaultProps = {
  sort: sortItems,
  showDropdownIcon: false,
  showActiveStatus: false,
};

SimpleFilter.propTypes = {
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterModels: PropTypes.array,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string
    })
  )
};

export default SimpleFilter;

import { h, Component } from 'preact';
import StaticConversionForm from './StaticConversionForm';
import PropTypes from 'prop-types';

class StaticConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleStartSubmit = this.handleStartSubmit.bind(this);
    this.handleStopSubmit = this.handleStopSubmit.bind(this);
    this.handleErrorSubmit = this.handleErrorSubmit.bind(this);
    this.handleShowOverlay = this.handleShowOverlay.bind(this);
  }

  handleUpload({ file }) {
    return serviceConversion.uploadFileS3(file)
  }

  handleStartSubmit() {
    this.startSubmit()
  }

  handleStopSubmit() {
    this.stopSubmit()
  }

  handleErrorSubmit(error) {
    this.errorSubmit(error)
  }

  handleShowOverlay(show) {
    this.showOverlay(show)
  }

  startSubmit() {
    this.setState({ isSubmittingForm: true });
  }

  stopSubmit() {
    this.setState({ isSubmittingForm: false })
  }

  errorSubmit(error) {
    this.setState({ errorSendingForm: error })
  }

  showOverlay(show) {
    this.setState({
      showOverlay: show
    });
  }

  handleSubmit(e, params) {
    this.startSubmit()

    return serviceConversion
      .convert(params)
      .then(() => {
        this.errorSubmit(false)
      })
      .catch(error => {
        this.errorSubmit(true)
        console.error(error)
      })
      .finally(() => {
        this.stopSubmit()
        this.showOverlay(true)
      });
  }

  render() {
    const { isSubmittingForm, errorSendingForm, showOverlay } = this.state;
    const { formId, mainPhrase, shouldValidateCpf } = this.props;

    return (
      <StaticConversionForm
        onUpload={this.handleUpload}
        onStartSubmit={this.handleStartSubmit}
        onStopSubmit={this.handleStopSubmit}
        onErrorSubmit={this.handleErrorSubmit}
        onShowOverlay={this.handleShowOverlay}
        handleSubmit={this.handleSubmit}
        formId={formId}
        isSubmittingForm={isSubmittingForm}
        errorSendingForm={errorSendingForm}
        showOverlay={showOverlay}
        mainPhrase={mainPhrase}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        units={this.props.units}
        unit={this.props.unit}
        showUnits={this.props.showUnits}
        showUpload={this.props.showUpload}
        showCpf={this.props.showCpf}
        fileExtension={this.props.fileExtension}
        staticFormMarkup={this.props.staticFormMarkup}
        titleForm={this.props.titleForm}
        product={this.props.product}
        channel={this.props.channel}
        category={this.props.category}
        categories={this.props.categories}
        brand={this.props.brand}
        bait={this.props.bait}
        chooseArea={this.props.chooseArea}
        versions={this.props.versions}
        contactsWithDynamicRequired={this.props.contactsWithDynamicRequired}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        showLabels={this.props.showLabels}
        showLocationFields={this.props.showLocationFields}
        buttonText={this.props.buttonText}
        successMessage={this.props.successMessage}
        shouldValidateCpf={shouldValidateCpf}
      />
    );
  }
}

StaticConversionFormApp.defaultProps = {
  mainPhrase:
    'Preencha o formulário abaixo para receber o contato de um de nossos especialistas:',
  units: [],
  showUnits: true,
  showUpload: false,
  showCpf: false,
  shouldValidateCpf: false,
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false,
  brand: '-',
  buttonText: 'ESTOU INTERESSADO',
  successMessage: 'Solicitação realizada com sucesso!'
};

StaticConversionFormApp.propTypes = {
  formId: PropTypes.string.isRequired,
  mainPhrase: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  unit: PropTypes.string,
  showUnits: PropTypes.bool,
  showUpload: PropTypes.bool,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  showCpf: PropTypes.bool,
  shouldValidateCpf: PropTypes.bool,
  fileExtension: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  categories: PropTypes.array,
  brand: PropTypes.string,
  bait: PropTypes.string,
  chooseArea: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  showLabels: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  textButton: PropTypes.string,
  successMessage: PropTypes.string
};

export default StaticConversionFormApp;

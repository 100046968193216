// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FormBody extends Component {
  render() {
    const { customClasses, children } = this.props;

    return (
      <div className={classNames('form__body', customClasses)}>{children}</div>
    );
  }
}

FormBody.propTypes = {
  customClasses: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};

import { h, Component } from 'preact';
import PropTypes from 'prop-types';

import IMask from 'imask';
import { cpfMasks } from '%/utils/masks';

export default class InputCpf extends Component {
  componentDidMount() {
    const { updateState } = this.props;

    const cpfMask = IMask(this.input, {
      mask: [...cpfMasks],
    });

    cpfMask.on('accept', () => {
      if (updateState) {
        updateState(cpfMask.value);
      }
    });

    if (updateState) {
      updateState(cpfMask.value);
    }
  }

  render() {
    const { handleChange, value, name, placeholder, label, shouldValidate } = this.props;

    return (
      <div className="form-group">
        {label && <label className="control-label">{label}</label>}
        <input
          ref={(node) => (this.input = node)}
          onChange={handleChange}
          value={value}
          type="text"
          className="form-control"
          name={name}
          required
          placeholder={placeholder}
          minlength={14}
          data-bouncer-target="#invalid-cpf"
          data-should-validate={shouldValidate}
        />
        <div id="invalid-cpf" className="invalid-feedback" />
      </div>
    );
  }
}

InputCpf.defaultProps = {
  handleChange() {},
  name: 'cpf',
  placeholder: 'CPF',
  shouldValidate: false,
  updateState: () => {},
};

InputCpf.propTypes = {
  handleChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  shouldValidate: PropTypes.bool,
  updateState: PropTypes.func,
};

import { Component } from 'preact';
import PropTypes from 'prop-types';
import ConversionForm from '../ConversionFormNoResult/ConversionForm';

export default class ConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
    };

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });
  }

  handleSubmit(e, params) {
    this.setState({ isSubmittingForm: true });

    return window.serviceConversion
      .convert(params)
      .then(() => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: false,
        });
      })
      .catch(error => {
        this.setState({
          isSubmittingForm: false,
          errorSendingForm: true,
        });
        console.error(error);
      })
      .then(() => {
        this.setState({
          showOverlay: true,
        });
      });
  }

  render() {
    const { isSubmittingForm, errorSendingForm, showOverlay } = this.state;

    return (
      <div className="conversion-form-no-result">
        <ConversionForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
          checkStyle="shadow"
          showIconExclamation
          showLabels
          showPlaceholder={false}
          isSubmittingForm={isSubmittingForm}
          errorSendingForm={errorSendingForm}
          showOverlay={showOverlay}
          units={this.props.units}
          handleSubmit={this.handleSubmit}
          handleCloseOverlay={this.handleCloseOverlay}
          shouldShowDataPermissions={this.props.shouldShowDataPermissions}
          dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        />
      </div>
    );
  }
}

// default props
ConversionFormApp.defaultProps = {
  shouldShowDataPermissions: false,
  units: [],
  dataPermissionsCustomText: '',
};

ConversionFormApp.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
};

import { checkPattern } from './checkPattern';

export function checkCpfPattern() {
  const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  const cpfValidator = checkPattern(cpfPattern, 'Digite um CPF válido.');

  return value => {
    const validationResult = cpfValidator(value);
    return validationResult;
  };
}

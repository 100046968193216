import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import ButtonLoadMore from './ButtonLoadMore';
import ItemPlaceholder from './ItemPlaceholder';

class List extends Component {
  componentDidMount() {
    window.matchHeight.match('.list-parts__list');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-parts__list');
  }

  render() {
    const {
      items,
      onClickButtonLoadMore,
      loading,
      loadingMore,
      part_link,
      card_class_modifier,
      cta_item_text
    } = this.props;

    return (
      <div class="list-parts__list w-100">
        <div class="row">
          {items.map(item => (
            <div class="col-sm-6 col-md-6 col-lg-4 list-parts__col-card">
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  code={item.code}
                  seal_list={item.seal_list}
                  badge={item.badge}
                  item_image={item.item_image}
                  title={item.title}
                  subtitle={item.subtitle}
                  item_offer={item.item_offer}
                  part_link={part_link}
                  slug={item.slug}
                  class_modifier={card_class_modifier}
                  cta_item_text={cta_item_text}
                />
              )}
            </div>
          ))}
        </div>
        <hr class="my-0 mb-4" />
        {this.props.showLoadMore && (
          <ButtonLoadMore
            onClick={onClickButtonLoadMore}
            loading={loadingMore}
          />
        )}
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  loadingMore: false,
  card_class_modifier: '',
};

List.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  part_link: PropTypes.string,
  card_class_modifier: PropTypes.string,
};

export default List;

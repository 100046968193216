import { h, Component } from 'preact'
import PropTypes from 'prop-types'

export default class MapIframe extends Component {
  render() {
    return (
      <div className="map-units__map">
        <iframe className="map-units__map-iframe" src={this.props.src} />
      </div>
    )
  }
}

MapIframe.propTypes = {
  src: PropTypes.src
}

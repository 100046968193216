import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import SimpleFilterButtonsApp from '../SearchFilter/SimpleFilterButtonsApp'
import { sortItems } from '%/utils/sort'

class SimpleFilterButtons extends Component {
  render() {
    const { filterCategories, sort } = this.props
    return (
      <SimpleFilterButtonsApp
        filterCategories={filterCategories}
        sort={sort}
      />
    )
  }
}

SimpleFilterButtons.defaultProps = {
  sort: sortItems,
}

SimpleFilterButtons.propTypes = {
  filterCategories: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    })
  ),
}

export default SimpleFilterButtons

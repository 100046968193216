/* eslint-disable camelcase */
import { Component } from 'preact';
import PropTypes from 'prop-types';
import {
  resetList,
  setTotalPages,
  setPerPage,
} from '../../stores/SearchFilter';
import List from './List';

class ListApp extends Component {
  constructor(props) {
    super(props);

    const { list, total_pages, per_page } = this.props;

    this.state = {
      list: [],
    };

    if (per_page) {
      setPerPage(per_page);
    }
    setTotalPages(total_pages);
    resetList(list);

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
      });
    });
  }

  showLoadMore() {
    const { total_pages, page, loadingMore } = this.state;
    return total_pages > page || loadingMore;
  }

  // eslint-disable-next-line consistent-return
  render() {
    const { list, loading, loadingMore } = this.state;
    const { part_link, showBrand, cardsPerLine, cardTitleOnHeader } =
      this.props;

    if (list.length > 0) {
      return (
        <List
          items={list}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={this.showLoadMore()}
          part_link={part_link}
          showBrand={showBrand}
          cardsPerLine={cardsPerLine}
          ctaInterestButtonText={this.props.ctaInterestButtonText}
          cardTitleOnHeader={cardTitleOnHeader}
        />
      );
    }
  }
}

ListApp.defaultProps = {
  list: [],
  cardTitleOnHeader: false,
  cardsPerLine: 3,
  ctaInterestButtonText: 'Estou Interessado',
};

ListApp.propTypes = {
  list: PropTypes.arrayOf,
  total_pages: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  part_link: PropTypes.string.isRequired,
  showBrand: PropTypes.number.isRequired,
  ctaInterestButtonText: PropTypes.string,
  cardTitleOnHeader: PropTypes.bool,
  cardsPerLine: PropTypes.number,
};

export default ListApp;

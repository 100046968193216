// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FormFooter extends Component {
  render() {
    const { linkPrivacyPolicy, customClasses } = this.props;

    return (
      <footer className={classNames('form__footer', customClasses)}>
        <p className="form__footer-text">
          Ao informar meus dados, eu concordo com a{' '}
          <a className="form__footer-link" href={linkPrivacyPolicy}>
            Política de privacidade
          </a>
          .
        </p>
      </footer>
    );
  }
}

FormFooter.propTypes = {
  linkPrivacyPolicy: PropTypes.string,
  customClasses: PropTypes.string,
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import GWMConversionForm from '#/GWM/GWMConversionForm';

class GWMConversionFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
    };

    this.sendFirstConversion = this.sendFirstConversion.bind(this);
    this.sendSecondConversion = this.sendSecondConversion.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
  }

  handleCloseOverlay() {
    this.setState({ showOverlay: false });
  }
  
  generateConversionID(params) {
    if (!params) throw new Error('Params cannot be null or undefined');

    const str = JSON.stringify(params);
    let hash = 0;
  
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
  
    const idByParams = hash >>> 0;
    const time = Date.now();

    const conversionID = `${idByParams}-${time}`;

    return conversionID;
  }
  

  sendFirstConversion(params) {
    const conversionID = this.generateConversionID(params)

    const payload = {
      ...params,
      id_datalayer: conversionID
    }
    
    return serviceConversion
      .convert(payload)
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  sendSecondConversion(params) {
    const conversionID = this.generateConversionID(params)

    const payload = {
      ...params,
      id_datalayer: conversionID
    }

    this.setState({ isSubmittingForm: true }, () => {
      let status = 'error',
        data = null;

      return serviceConversion
        .convert(payload)
        .then(() => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: false,
          });
          status = 'success';
          data = params;
        })
        .catch(error => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: true,
          });
          data = error;
          console.error(error);
        })
        .then(() => {
          document.dispatchEvent(
            new CustomEvent('new_conversion', {
              detail: {
                status: status,
                data: data,
              },
            }),
          );
          this.setState({
            showOverlay: true,
          });
        });
    });
  }

  render() {
    const {
      titleForm,
      mainPhrase,
      linkPrivacyPolicy,
      bait,
      category,
      product,
      formType,
      avaliableModels,
    } = this.props;

    const { isSubmittingForm, showOverlay, errorSendingForm } = this.state;

    return (
      <GWMConversionForm
        titleForm={titleForm}
        mainPhrase={mainPhrase}
        bait={bait}
        category={category}
        product={product}
        formType={formType}
        linkPrivacyPolicy={linkPrivacyPolicy}
        isSubmittingForm={isSubmittingForm}
        sendFirstConversion={this.sendFirstConversion}
        sendSecondConversion={this.sendSecondConversion}
        handleCloseOverlay={this.handleCloseOverlay}
        showOverlay={showOverlay}
        errorSendingForm={errorSendingForm}
        avaliableModels={avaliableModels}
      />
    );
  }
}

// default props
GWMConversionFormApp.defaultProps = {
  titleForm: '',
  mainPhrase: 'Preencha o formulário e acompanhe os lançamentos da GWM',
  bait: 'INTERNA - TENHO INTERESSE',
  category: 'Clone',
  product: 'HAVAL H6',
  linkPrivacyPolicy: '',
  formType: 'internal',
  avaliableModels: [],
};

GWMConversionFormApp.propTypes = {
  titleForm: PropTypes.string,
  mainPhrase: PropTypes.string,
  bait: PropTypes.string,
  category: PropTypes.string,
  product: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  formType: PropTypes.string,
  avaliableModels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default GWMConversionFormApp;

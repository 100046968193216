import { h } from 'preact';
import { Component } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import CustomSelect from '#/CustomSelect';
import CustomCheck from '#/CustomCheck';
import SubmitButton from '#/SubmitButton';
import FormMessageOverlay from './FormMessageOverlay';
import handleInputChange from '%/utils/handleInputChange';
import handleUpdateState from '%/utils/handleUpdateState';
import validationFactory from '%/utils/validation';
import maskField, { MASK_TYPES } from '%/utils/maskField';
import getUTM from '%/utils/utm';
import InputCpf from './InputCpf';

class ScheduleServiceFixedTopForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.clearForm = this.clearForm.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleUpdateState = handleUpdateState.bind(this);
    this.handleTypeServiceChange = this.handleTypeServiceChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.handleContactOptionsChange = this.handleContactOptionsChange.bind(this);
  }

  resetState() {
    this.setState({
      data: '',
      email: '',
      km: '',
      model_car: '',
      name: '',
      phone: '',
      plate: '',
      type_service: '',
      mailing: false,
      phoning: false,
      whatsapping: false,
      contactOptions: '',
      year: '',
      cpf: '',
      versionValidStatus: true
    });
  }

  handleTypeServiceChange(e) {
    const { name, value } = e.target

    const validate = this.state.type_service_is_valid !== undefined

    this.setState({
      [name]: value
    }, () => {
      if (validate) {
        this.validateTypeService()
      }
    })
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }

  /**
   * Validação para select de serviço
   */
  validateTypeService() {
    const inputTypeService = this.form.querySelector('input[type="hidden"][name="type_service"]')

    this.setState({
      type_service_is_valid: this.validator.validate(inputTypeService) ? this.validator.validate(inputTypeService).valid : true
    })
  }

  versionValid() {
    return this.state.model_car;
  }

  handleSubmit(e) {
    e.preventDefault();
    const validationResult = this.validator.validateAll(this.form),
      formIsValid = validationResult.length === 0;

    this.setState({ versionValidStatus: true });

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return
      }
    }

    this.validateTypeService()

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState();
    });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e)
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : ''
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(this.state.phoning || this.state.mailing || this.state.whatsapping)
  }


  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      data: this.state.data,
      email: this.state.email,
      km: this.state.km,
      mailing: this.state.mailing,
      model_car: this.state.model_car,
      name: this.state.name,
      phone: this.state.phone,
      phoning: this.state.phoning,
      plate: this.state.plate,
      type_service: this.state.type_service,
      whatsapping: this.state.whatsapping,
      year: this.state.year
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf
    }

    let props = {
      bait: this.props.bait,
      channel: this.props.channel,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM()
    };

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    if(state['type_service']) {
      props['product'] = state['type_service']
    }

    return { ...state, ...props };
  }

  componentDidMount() {
    maskField(MASK_TYPES.PHONE, this.phoneInput, {}, maskValue =>
      this.handleUpdateState('phone', maskValue),
    );

    maskField(MASK_TYPES.DATE, this.dateInput, {}, maskValue =>
      this.handleUpdateState('data', maskValue),
    );

    maskField(MASK_TYPES.KM, this.kmInput, {}, maskValue =>
      this.handleUpdateState('km', maskValue),
    );

    maskField(
      MASK_TYPES.PLATE,
      this.plateInput,
      {
        prepare(str) {
          return str.toUpperCase();
        },
      },
      maskValue => this.handleUpdateState('plate', maskValue),
    );

    this.validator = validationFactory(`#${this.props.formId}`);
  }

  clearForm(e) {
    e.preventDefault();
    this.resetState();
  }

  render() {
    return (
      <div>
        <form
          id={this.props.formId}
          className="form-modal-fixed-top"
          onSubmit={this.handleSubmit}
          ref={form => (this.form = form)}
        >
          <div class="container">
            <FormMessageOverlay
              handleClose={this.props.handleCloseOverlay}
              isVisible={this.props.showOverlay}
              type={this.props.errorSendingForm ? 'error' : 'success'}
            />
            <div class="row">
              <div class="col-8 col-md-9">
                <header class="form-modal-fixed-top__header ">{this.props.titleForm}</header>
              </div>
              <div class="col-4 col-md-3">
                <button data-micromodal-close class="btn button modal-fixed-top__close d-none d-sm-block">Fechar</button>
                <i
                  className="modal__close modal__overlay-close--form icon icon-close d-block d-sm-none modal-fixed-top__close-mobile"
                  data-micromodal-close
                />
              </div>
            </div>


            <div className="form-modal-fixed-top__body">
              <header>{this.props.subtitleForm}</header>
              <div class="row">
                {/* INICIO: Coluna esquerda contendo os dados do contato */}
                <div class="col-md-4">
                  <fieldset>
                    <label class="form-modal-fixed-top__fieldset-title">Informações de contato:</label>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Nome</label>
                          <input
                            type="text"
                            maxLength={80}
                            placeholder="Ex.: Ayrton Senna"
                            name="name"
                            className="form-control"
                            onChange={this.handleInputChange}
                            value={this.state.name}
                            required
                            data-bouncer-target="#invalid-name"/>
                          <div id="invalid-name" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>E-mail</label>
                          <input
                            type="email"
                            maxLength={80}
                            placeholder="Ex.: ayrtonsenna@email.com"
                            name="email"
                            className="form-control"
                            onChange={this.handleInputChange}
                            value={this.state.email}
                            required
                            data-bouncer-target="#invalid-email"
                          />
                          <div id="invalid-email" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div className="form-group">
                          <label>Telefone</label>
                          <input
                            type="phone"
                            placeholder="Ex.: (99)99999-9999"
                            name="phone"
                            className="form-control"
                            ref={phoneInput => (this.phoneInput = phoneInput)}
                            value={this.state.phone}
                            required
                            onChange={this.handleInputChange}
                            data-bouncer-target="#invalid-phone"
                          />
                          <div id="invalid-phone" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        {this.props.showCpf && (
                          <InputCpf
                              label="CPF"
                              handleChange={this.handleInputChange}
                              value={this.state.cpf}
                              placeholder="000.000.000-00"
                              updateState={value => this.handleUpdateState('cpf', value)}
                            />
                        )}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <fieldset>
                          <legend>Quero receber contato por:</legend>
                          <div className="form-check form-check-inline">
                            <CustomCheck
                              checkStyle="dark"
                              name="mailing"
                              value="true"
                              type="checkbox"
                              isChecked={this.state.mailing}
                              onChangeCheckable={this.handleContactOptionsChange}
                            >
                              E-mail
                            </CustomCheck>
                          </div>
                          <div className="form-check form-check-inline">
                            <CustomCheck
                              checkStyle="dark"
                              name="whatsapping"
                              value="true"
                              type="checkbox"
                              isChecked={this.state.whatsapping}
                              onChangeCheckable={this.handleContactOptionsChange}
                            >
                              Whatsapp
                            </CustomCheck>
                          </div>
                          <div className="form-check form-check-inline">
                            <CustomCheck
                              checkStyle="dark"
                              name="phoning"
                              value="true"
                              type="checkbox"
                              isChecked={this.state.phoning}
                              onChangeCheckable={this.handleContactOptionsChange}
                            >
                              Telefone
                            </CustomCheck>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control d-none"
                              name="contact-options"
                              required
                              placeholder="Opções de contato"
                              data-bouncer-target="#invalid-contact-options"
                              value={this.state.contactOptions}
                              checked={this.state.contactOptions}
                            />
                            <div id="invalid-contact-options" className="invalid-feedback" />
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </fieldset>
                </div>
                {/* FIM: Coluna esquerda contendo os dados do contato */}

                {/* INICIO: Coluna direita contendo os dados gerais */}
                <div class="col-md-8">
                  <fieldset>
                    <label class="form-modal-fixed-top__fieldset-title">Informações gerais:</label>
                    <div class="form-modal-fixed-top__link-clear-fields d-none d-sm-block">
                      <a className="clear-fields" href="#" onClick={this.clearForm}>
                        Limpar campos
                      </a>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        {!this.props.unit && this.props.units.length > 1 && this.props.showUnits && (
                          <div class="row">
                            <div class="col-md-12">
                              <div className="form-group">
                                <div className="form-group">
                                  <label className="conversion-form__control-label">Escolha a unidade:</label>
                                  <CustomSelect
                                    handleSelectChange={this.handleInputChange}
                                    name="unit"
                                    options={this.props.units}
                                    value={this.state.unit}
                                    shouldSort={false}
                                    placeholderValue="Escolha a unidade"
                                    searchEnabled={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="col-md-6">
                        <div className="form-group">
                            <label>Quilometragem</label>
                            <input
                              placeholder="Ex.: 70.000"
                              name="km"
                              className="form-control"
                              ref={kmInput => (this.kmInput = kmInput)}
                              value={this.state.km}
                              required
                              onChange={this.handleInputChange}
                              data-bouncer-target="#invalid-km"
                            />
                            <div id="invalid-km" className="invalid-feedback" />
                          </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        {this.props.services && this.props.services.length > 0 && (
                          <div class="row">
                            <div class="col-md-12">
                              <div className={classNames('form-group', {
                                'form-group--error': this.state.type_service_is_valid === false
                              })}>
                                <label class="conversion-form__control-label">Escolha o serviço:</label>
                                <CustomSelect
                                  handleSelectChange={this.handleTypeServiceChange}
                                  name="type_service"
                                  options={this.props.services}
                                  value={this.state.type_service}
                                  placeholderValue="Escolha o serviço"
                                />
                                <input
                                  type="hidden"
                                  value={this.state.type_service}
                                  name="type_service"
                                  className="form-control"
                                  required
                                  data-bouncer-target="#invalid-type_service"
                                />
                                <div id="invalid-type_service" className="invalid-feedback" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Modelo do veículo</label>
                          {this.props.versions && this.props.versions.length <= 0 && (
                            <input
                              placeholder="Ex.: Ka"
                              name="model_car"
                              className="form-control"
                              value={this.state.model_car}
                              required
                              onChange={this.handleInputChange}
                              data-bouncer-target="#invalid-model_car"
                            />
                          )}
                          {this.props.versions && this.props.versions.length > 0 && (
                            <CustomSelect
                              handleSelectChange={this.handleInputChange}
                              name="model_car"
                              options={this.props.versions}
                              value={this.state.model_car}
                              shouldSort={false}
                              placeholderValue="Escolha um modelo"
                              searchEnabled={true}
                            />
                          )}

                          {!this.state.versionValidStatus && (
                            <div id="invalid-version" class="invalid-feedback is-invalid-version">
                              <div class="error-message" id="bouncer-error_version">Por favor, selecione esse campo</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Ano do veículo</label>
                          <input
                            placeholder="Ex.: 2011"
                            name="year"
                            className="form-control"
                            value={this.state.year}
                            required
                            onChange={this.handleInputChange}
                            data-bouncer-target="#invalid-year"
                          />
                          <div id="invalid-year" className="invalid-feedback" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div className="form-group">
                          <label class="conversion-form__control-label" htmlFor="data">Data desejada</label>
                          <input
                            id="data"
                            placeholder="Ex.: 20/11/2020"
                            name="data"
                            ref={dateInput => (this.dateInput = dateInput)}
                            className="form-control"
                            value={this.state.data}
                            required
                            type="text"
                            onChange={this.handleInputChange}
                            data-bouncer-target="#invalid-data"
                          />
                          <div id="invalid-data" className="invalid-feedback" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Placa do veículo</label>
                          <input
                            placeholder="Ex.: XXX0000"
                            name="plate"
                            className="form-control"
                            ref={plateInput => (this.plateInput = plateInput)}
                            value={this.state.plate}
                            type="plate"
                            required
                            onChange={this.handleInputChange}
                            data-bouncer-target="#invalid-plate"
                          />
                          <div id="invalid-plate" className="invalid-feedback" />
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <SubmitButton
                          classes="btn form-modal-fixed-top__submit submit-button button button--primary mb-0"
                          handleClick={this.handleSubmit}
                          label="Agendar serviço"
                          isSubmitting={this.props.isSubmitting}
                        />
                      </div>
                      <div class="col-md-12">
                        <p class="form-modal-fiexd-top__mensagem-legal">
                          * A data efetiva do agendamento será confirmada em contato por telefone pós verificação de disponibilidade
                        </p>
                      </div>
                    </div>
                  </fieldset>
                </div>
                {/* FIM: Coluna direita contendo os dados gerais */}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ScheduleServiceFixedTopForm.defaultProps = {
  titleForm: 'Agendar serviço',
  subtitleForm: 'Preencha o formulário abaixo e converse agora com um consultor:',
  handleSubmit() {},
  handleCloseOverlay() {},
  isSubmitting: false,
  services: [],
  product: '',
  channel: '',
  category: 'service',
  bait: '-2',
  units: [],
  showUnits: true,
  showCpf: false,
  versions: [],
  searchVersion: true, // alterar para false
};

ScheduleServiceFixedTopForm.propTypes = {
  titleForm: PropTypes.string,
  subtitleForm: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  services: PropTypes.array,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  bait: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  searchVersion: PropTypes.bool,
};

export default ScheduleServiceFixedTopForm;

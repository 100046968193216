import { validateFields } from './validation';
import { makeFieldErrorHandler } from './validation/makeFieldErrorHandler';

export const makeHandleFieldChange = (validators, callback) => {
  const store = window.store.CustomFormFields.getState();
  const updateFieldValue = store.events.updateFieldValue;
  const setFieldError = store.events.setFieldError;
  const handleFieldError = makeFieldErrorHandler(setFieldError);

  return e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const validationResult = validateFields(validators, value);
    handleFieldError(target, validationResult);

    updateFieldValue({
      key: target.name,
      value: validationResult.hasError ? null : value,
    });

    if (callback) callback(e);
  };
};

import AutoForceService from "./autoForceService";
/**
 * Cria e/ou retorna uma instância do service.
 * @param {string} baseUrl
 * @param {string} token
 * @param {string} account
 * @param {string} channel
 * @param {string} channelToken O token de identificação do canal
 */
const serviceFactory = (function() {
  let instance;

  function getMetaContent(name, fallback = "") {
    return document.querySelector(`meta[name="${name}"]`).content || fallback;
  }

  return function(
    baseUrl = getMetaContent("auto_api_url"),
    token = getMetaContent("auto_api_token"),
    account = getMetaContent("auto_api_account"),
    channel = getMetaContent("auto_api_channel"),
    channelToken = getMetaContent("auto_api_origin")
  ) {
    if (!instance) {
      instance = new AutoForceService({
        baseUrl,
        token,
        account,
        channel,
        channelToken
      });
    }

    return instance;
  };
})();

export default serviceFactory;

export const validateFields = (validators, value) => {
  const fieldErrors = validators.reduce(
    (accumulatedErrors, currentValidator) => {
      const fieldError = currentValidator(value);
      if (!fieldError) return accumulatedErrors;

      return [...accumulatedErrors, fieldError];
    },
    [],
  );

  const validationResult = {
    hasError: fieldErrors.length >= 1,
    error: fieldErrors[0],
    allErrors: fieldErrors,
  };

  return validationResult;
};

const URL_API_RECAPTCHA = 'https://www.google.com/recaptcha/api.js'
const SITE_KEY = '6LcmJcUUAAAAAIEJlvQOALmm6X8FS37MLx3bY75B'

let scriptLoaded = false

/**
 * Essa função depende que a função loadScriptReCAPTCHA tenha sido chamada
 */
export default function reCAPTCHA() {
  return new Promise((resolve, reject) => {
    if (scriptLoaded) {
      execute()
        .then(token => {
          const grecaptchaBadge = document.querySelector('.grecaptcha-badge')
          if (grecaptchaBadge) grecaptchaBadge.style.opacity = 1

          resolve(token)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    } else {
      reject('Token não retornado porque o script do recaptcha não foi carregado')
    }
  })
}

export function loadScriptReCAPTCHA() {
  if (!scriptLoaded) {
    let script = document.createElement('script')
    script.src = `${URL_API_RECAPTCHA}?render=${SITE_KEY}`
    script.defer = true
    script.onload = () => {
      window.grecaptcha.ready(() => {
        scriptLoaded = true
      })
    }
    document.head.appendChild(script)
  }
}

export function setRecaptchaLoadOnFormFocus() {
  if (window.grecaptcha !== undefined) return;

  const pageForms = Array.from(document.querySelectorAll('form'));
  const handleFocus = () => {
    try {
      loadScriptReCAPTCHA();
    } catch {
      console.log('Erro ao carregar script de recaptcha');
    }
  }

  pageForms.forEach(form => form.addEventListener('focusin', handleFocus, { once: true }));
}

function execute() {
  return new Promise((resolve, reject) => {
    window.grecaptcha
      .execute(SITE_KEY, { action: 'homepage' })
      .then(token =>
        token ? resolve(token) : reject('Não foi possível pegar o token da API google reCAPTCHA v3')
      )
  })
}

export default class normalizeDataTawkTo {
  constructor(data) {
    this.questions = data.questions || data;

    return this.normalizedData;
  }

  /**
   * Organiza os dados do tawk.to e retorna um objeto.
   * Ex.:
   * { name: '', email: '', phone: '', departament: '' }
   * 
   * @return {object}
   */
  get normalizedData() {
    let attrs = [
      { input: 'name', label: /[ao]me/ }, 
      { input: 'email', label: /mail/ }, 
      { input: 'phone', label: /one/ }, 
      { input: 'department', label: /nidade/ } 
    ],
    normalizedData = {};

    attrs.forEach((attr) => {
      normalizedData[attr['input']] = this.getAnswerByLabel(attr['label'])
    });

    return normalizedData;
  }

  /*
   * esse metodo deve ser usado para obter
   * o valor digitado pelo usuário com base no label
   * 
   * @return {string}
   */
  getAnswerByLabel(label) {
    let findValue = this.questions.find(value => label.test(value['label']))
    if(findValue)
      return findValue.answer;

    return '';
  }
}

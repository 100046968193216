import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Item extends Component {
  render() {
    const {
      badge,
      item_image,
      title,
      subtitle,
      model,
      year,
      slug,
      brand,
      item_offer,
      seal_list,
      kind,
      km,
      fuel_text,
      exchange,
      isOnFirstPage,
    } = this.props;
    const channel_pack_element = document.querySelector(
      "meta[name='channel_pack']",
    );
    const channel_pack = channel_pack_element && channel_pack_element.content;

    return (
      <div class="card card--used-model">
        <div class="card__header">
          <div class="card__badges">
            <div class="card__badge-item badge badge--pill badge--card-used-model">
              {badge}
            </div>
            {seal_list.slice(0, 3).map(seal => (
              <div class="card__badge-item badge badge--used-model-seal-list badge--pill">
                {seal}
              </div>
            ))}
          </div>

          <div
            class="card__image card__image--extra-large"
            data-model={model}
            data-brand={brand}
            data-year={year}
            data-slug={slug}
          >
            <a href={`${this.props.part_link}/${this.props.slug}`} alt={title}>
              {item_image.middle_image && (
                <img
                  class="card__image-value"
                  src={item_image.middle_image}
                  alt={title}
                  width="232"
                  height="175"
                  // eslint-disable-next-line react/no-unknown-property
                  fetchpriority={isOnFirstPage ? 'high' : 'low'}
                  loading={isOnFirstPage ? 'auto' : 'lazy'}
                />
              )}
              {!item_image.middle_image && (
                <div
                  className={classNames({
                    'card__image-value': true,
                    'default-bg__motorcycle_middle-default': kind === 'Moto',
                    'default-bg__model-middle-default': kind !== 'Moto',
                  })}
                ></div>
              )}
            </a>
          </div>
        </div>

        <div class="card__content">
          <div data-match-height="list-used-models-card-content">
            <div class="card__title text-left text-ellipsis">{title}</div>
            <div class="card__subtitle text-left">
              {channel_pack == 'showroom_fiat' ? brand : subtitle}
            </div>
          </div>
          <hr class="card__separator" />
          <ul class="card__list" data-match-height="list-used-models-card-list">
            {exchange && <li class="card__list-item">Câmbio: {exchange}</li>}
            {km && <li class="card__list-item">Quilometragem: {km} KM</li>}
            {fuel_text && (
              <li class="card__list-item">Combustível: {fuel_text}</li>
            )}
          </ul>
          <hr class="card__separator" />
        </div>

        <div class="card__footer">
          <div
            data-match-height="list-used-models-card-triggers"
            class="card__triggers"
          >
            {item_offer.secondary_call && (
              <div class="card__trigger">{item_offer.secondary_call}</div>
            )}

            {item_offer.main_call && (
              <div class="card__title">{item_offer.main_call}</div>
            )}

            {item_offer.featured && (
              <div class="card__trigger-value">{item_offer.featured}</div>
            )}

            <a
              href={`${this.props.part_link}/${this.props.slug}`}
              class="btn button button--block button--large button--dark card__cta card__cta-used-model"
              data-model={model}
              data-brand={brand}
              data-year={year}
              data-slug={slug}
            >
              Estou interessado
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Item.defaultProps = {
  item_image: {},
  item_offer: {},
  seal_list: [],
  isOnFirstPage: false,
};

Item.propTypes = {
  part_link: PropTypes.string,
  item_image: PropTypes.array,
  seal_list: PropTypes.array,
  isOnFirstPage: PropTypes.bool,
};

export default Item;

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class SubmitButton extends Component {
  render() {
    const { customClasses, label, isSubmitting } = this.props;

    return (
      <button
        className={classNames(
          'form__submit-button btn button button--large button--primary w-100',
          customClasses,
        )}
        type="submit"
      >
        {isSubmitting ? 'Enviando...' : label}
      </button>
    );
  }
}

SubmitButton.propTypes = {
  customClasses: PropTypes.string,
  label: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

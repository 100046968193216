/**
 * Iguala altura dos elementos com atributo data-match-height
 * @param {Element} container 
 */
export default function equalHeights(container = '') {
  let maxHeight = {}

  document.querySelectorAll(getSelector(container)).forEach(element => {
    const key = element.dataset.matchHeight

    if (maxHeight[key] === undefined) {
      maxHeight[key] = 0
    }

    document.querySelectorAll(getSelector(container, key)).forEach(elementKey => {
      var height = getHeight(elementKey)

      if (height > maxHeight[key]) {
        maxHeight[key] = height
      }

      elementKey.style.height = `${maxHeight[key]}px`
    })
  })
}

/**
 * Volta os elementos a sua altura original
 * @param {Element} container 
 */
export function removeEqualHeights(container = '') {
  document.querySelectorAll(getSelector(container)).forEach(element => {
    element.style.height = null
  })
}

/** 
 * 
 * @param {Element} container 
 * @param {String} key Valor do atributo data-match-height
 * @return {String} Seletores dos elementos
 */
const getSelector = (container = '', key = null) => {
  if (key) {
    return `${container} [data-match-height="${key}"]`.trim()
  } else {
    return `${container} [data-match-height]`.trim()
  }
}

/**
 * 
 * @param {Element} element
 */
const getHeight = (element) => {
  const styles = getComputedStyle(element) 
  let height = element.clientHeight

  if (styles['box-sizing'] !== 'border-box') {
    const paddingTop = parseFloat(styles.paddingTop)
    const paddingBottom = parseFloat(styles.paddingBottom)
    height -= paddingTop + paddingBottom
  }

  return height
}

import { checkPattern } from './checkPattern';

export function checkEmailPattern() {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailValidator = checkPattern(emailPattern, 'Digite um e-mail válido.');

  return value => {
    const validationResult = emailValidator(value);
    return validationResult;
  };
}

import { h, Component } from 'preact'
import DetailTabs from '../DetailTabs';

export default class DetailTabsConsortium extends Component {
  constructor(props) {
    super(props)

    this.state = {
      brand: '',
      title: '',
      image: ''
    }

    this.watch()
  }

  watch() {
    window.store.Consortium.watch(state => {
      const { brand, title, item_image: { middle_image: image } } = state.plans
      
      this.setState({
        brand,
        title,
        image
      })
    })
  }

  render() {
    const { brand, title, image } = this.state
    const Detash = <DetailTabs.Detash brand={brand} title={title} image={image} />

    return (
      <DetailTabs
        detash={Detash}
      />
    )
  }
}

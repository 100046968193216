export const removeErrorStatusFromField = field => {
  if (!field) return;
  const errorTargetId = `#invalid-${field.name}`;

  field.removeAttribute('data-bouncer-target', errorTargetId);
  field.removeAttribute('aria-describedby', `bouncer-error_${field.name}`);
  field.removeAttribute('aria-invalid', true);

  field.classList.remove('is-invalid');

  const fieldWrapperElement = field.parentElement;
  if (!fieldWrapperElement) return;

  const errorDisplayElement = fieldWrapperElement.querySelector(errorTargetId);
  if (errorDisplayElement) errorDisplayElement.innerText = '';
};

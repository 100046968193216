/* eslint-disable camelcase */
import { Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import ItemPlaceholder from './ItemPlaceholder';
import ButtonLoadMore from './ButtonLoadMore';

class List extends Component {
  componentDidMount() {
    window.matchHeight.match('.list-consortia__list');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-consortia__list');
  }

  cardsPerLineDictionary(perLine) {
    const perLineDictionary = {
      3: 'col-sm-6 col-lg-4',
      4: 'col-sm-6 col-md-4 col-lg-3',
    };
    return perLineDictionary[perLine];
  }

  render() {
    const {
      items,
      part_link,
      loading,
      loadingMore,
      showLoadMore,
      onClickButtonLoadMore,
      showBrand,
      cardsPerLine,
      cardTitleOnHeader,
    } = this.props;

    return (
      <div className="list-consortia__list">
        <div className="row">
          {items.map(item => (
            <div
              className={`${this.cardsPerLineDictionary(
                cardsPerLine,
              )} list-consortia__item-wrap`}
            >
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  slug={item.slug}
                  image={
                    item && item.item_image && item.item_image.middle_image
                  }
                  title={item.title}
                  subtitle={showBrand ? item.brand.name : item.subtitle}
                  item_offer={item.item_offer}
                  part_link={part_link}
                  ctaInterestButtonText={this.props.ctaInterestButtonText}
                  titleOnHeader={cardTitleOnHeader}
                />
              )}
            </div>
          ))}
        </div>
        {showLoadMore && (
          <div>
            <hr />
            <ButtonLoadMore
              onClick={onClickButtonLoadMore}
              loading={loadingMore}
            />
          </div>
        )}
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false,
  loadingMore: false,
  onClickButtonLoadMore() {},
  showBrand: false,
  cardsPerLine: 3,
  part_link: '',
  ctaInterestButtonText: 'Estou Interessado',
  cardTitleOnHeader: false,
};

List.propTypes = {
  items: PropTypes.arrayOf,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  cardTitleOnHeader: PropTypes.bool,
  ctaInterestButtonText: PropTypes.string,
  part_link: PropTypes.string,
  onClickButtonLoadMore: PropTypes.func,
  showBrand: PropTypes.bool,
  cardsPerLine: PropTypes.number,
};

export default List;

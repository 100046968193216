import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { resetList, setTotalPages, setMethodApi, setShowConversionForm, setTotal, setSort } from '../../stores/SearchFilter'
import { setShowNewVehicle, setShowOnlyNewVehicle } from '../../stores/ListUsedModels'

import List from './List'

class ListApp extends Component {
  constructor(props) {
    super(props)

    const { list, total_pages, total, show_new_vehicle, show_only_new_vehicle, channelUsedSort } = this.props

    this.state = {
      loadingMore: false
    }

    setMethodApi('getUsedModels')
    setShowNewVehicle(show_new_vehicle)
    setShowOnlyNewVehicle(show_only_new_vehicle)

    resetList(list)
    setTotalPages(total_pages)
    setTotal(total)
    if (channelUsedSort) setSort(channelUsedSort)

    if (list.length === 0) {
      setShowConversionForm(true)
    }

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
        showList: state.showList
      })
    })
  }

  showLoadMore() {
    const { total_pages, page, loadingMore } = this.state
    return (total_pages > page) || loadingMore
  }

  render() {
    const { list, loading, loadingMore, showList } = this.state

    if (showList) {
      return (
        <div class="container">
          <List
            items={list}
            loading={loading}
            loadingMore={loadingMore}
            showLoadMore={this.showLoadMore()}
            part_link={this.props.part_link}
          />
        </div>
      )
    }
  }
}

ListApp.defaultProps = {
  list: [],
  show_new_vehicle: null,
  show_only_new_vehicle: false,
}

ListApp.propTypes = {
  list: PropTypes.array,
  part_link: PropTypes.string,
  show_new_vehicle: PropTypes.bool,
  show_only_new_vehicle: PropTypes.bool,
}

export default ListApp

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Esse componente gera um input com borda
 * arredondadas com
 */
class SearchTerm extends Component {
  constructor(props) {
    super(props);

    this.handleClickSearch = this.handleClickSearch.bind(this)
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this)
  }

  handleClickSearch(e) {
    e.preventDefault()
    this.props.onClickSearch(e)
  }

  handleChangeKeyword(e) {
    e.preventDefault()
    this.props.onChangeKeyword(e)
  }

  render() {
    return (
      <div className="search-term">
        <button type="submit" class="search-term__btn" onClick={this.handleClickSearch} aria-label="Pesquisar">
          <i class="icon icon-search-b"></i>
        </button>

        <div class="search-term__wrapper">
          <input
            onInput={this.handleChangeKeyword}
            value={this.props.value}
            class="search-term__input"
            type="text"
            placeholder={this.props.placeholder}
            autofocus
          />
        </div>

      </div>
    )
  }
}

SearchTerm.defaultProps = {
  value: '',
  placeholder: 'O que está buscando?',
  onClickSearch() {}
}

SearchTerm.propTypes = {
  onChangeKeyword: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onClickSearch: PropTypes.func
}

export default SearchTerm

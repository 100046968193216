import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Pagination from 'rc-pagination';
import equalHeights from '../../utils/equalHeights';
import Result from './Result';
import List from './List';

/**
 * Ajusta a altura dos cards
 */
export const equalHeightsCards = () => {
  equalHeights('.list-pagination');
};

/**
 * Move scrollbar para o topo da listagem
 */
export const scrollToTop = () => {
  const elToTop =
    document.querySelector('.page__container') ||
    document.querySelector('.list-pagination');
  if (elToTop.scrollIntoView) {
    elToTop.scrollIntoView({ behavior: 'smooth' });
  }
};

/**
 * Executado após a chamada de setList
 */
export const configureList = () => {
  setImmediate(() => {
    equalHeightsCards();
    scrollToTop();
  });
};

export default class ListUsedModelsGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedCards: [],
    };

    this.handleComparaCheck = this.handleComparaCheck.bind(this);
    this.handleCompareClick = this.handleCompareClick.bind(this);
  }

  handleCompareClick() {
    const url = new URL(this.props.linkCompareVehicles, window.location.origin);
    const searchParams = new URLSearchParams();
    searchParams.set('slugs', this.state.checkedCards.join(','));
    url.search = searchParams.toString();
    window.location = url;
  }

  handleComparaCheck(e) {
    const { value, checked } = e.target;
    const { checkedCards } = this.state;

    if (checked) {
      if (!checkedCards.includes(value)) {
        this.setState(state => ({
          checkedCards: [...state.checkedCards, value],
        }));
      }
    } else if (checkedCards.includes(value)) {
      this.setState(state => ({
        checkedCards: state.checkedCards.filter(slug => slug !== value),
      }));
    }
  }

  get disableCompareVehicle() {
    return this.state.checkedCards.length < 2;
  }

  sholdShowItems() {
    return !this.props.isFetching && this.props.total > 0;
  }

  sholdShowConversionForm() {
    return !this.props.isFetching && this.props.total === 0;
  }

  render() {
    return (
      <div className="list-pagination">
        {this.sholdShowItems() && (
          <header className="list-pagination__header">
            <Result
              total={this.props.total}
              totalLabel={this.props.totalLabel}
            />
            <div className="list-pagination__compare">
              <button
                type="button"
                className="btn button button--dark button--large list-pagination__button-compare"
                disabled={this.disableCompareVehicle}
                onClick={this.handleCompareClick}
              >
                COMPARAR VEÍCULOS
              </button>
            </div>
          </header>
        )}
        {this.sholdShowItems() && (
          <List
            items={this.props.items}
            partLink={this.props.partLink}
            showUnitTag={this.props.showUnitTag}
            checkedCards={this.state.checkedCards}
            channelPremiumPrice={this.props.channelPremiumPrice}
            onCompareCheck={this.handleComparaCheck}
          />
        )}
        {this.sholdShowItems() && (
          <Pagination
            onChange={this.props.onPaginationChange}
            current={this.props.page}
            total={this.props.total}
            defaultPageSize={this.props.per_page}
            showTitle={false}
          />
        )}

        {this.sholdShowConversionForm() && <div>{this.props.formNoResult}</div>}

        <div
          className={classNames('loading-rippe', {
            'd-none': !this.props.isFetching,
          })}
        />
      </div>
    );
  }
}

ListUsedModelsGroups.defaultProps = {
  items: [],
  isFetching: true,
  totalLabel: '',
  onPaginationChange() {},
  formNoResult: null,
  channelPremiumPrice: null,
};

ListUsedModelsGroups.propTypes = {
  items: PropTypes.arrayOf,
  isFetching: PropTypes.bool,
  showUnitTag: PropTypes.bool,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func,
  totalLabel: PropTypes.string,
  formNoResult: PropTypes.node,
  partLink: PropTypes.string.isRequired,
  channelPremiumPrice: PropTypes.number,
  linkCompareVehicles: PropTypes.string.isRequired,
};

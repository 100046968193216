/*
  Galeria para vídeos em um slide
*/
import { h, Component } from 'preact'
import VideoPlayer from './Player'
import PropTypes from 'prop-types'

export default class GalleryVideo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 0
    }

    this.players = []

    this.handleInstancePlayer = this.handleInstancePlayer.bind(this)
  }

  componentDidMount() {
    this.initCarousel()
  }

  setEventsPlayer(instance) {
    const { fullscreenOnPlay } = this.props

    instance.on('playing', () => {
      if (fullscreenOnPlay) {
        instance.fullscreen.enter()
      }
    })
  }

  handleInstancePlayer(instance) {
    this.players.push(instance)
    this.setEventsPlayer(instance)
  }

  /**
   * Inicia ou para o vídeo automáticamente ao navegar pelo slide
   * 
   * @param {Integer} active index no array do vídeo que seja iniciado
   * @param {Object} state 
   * @return {Object} retorna um objeto com o index no array do vídeo que será iniciado
   */
  slideTransitionStart(active, state) {
    let player = this.players[active],
      playerPrev = this.players[state.active]

    player.play()
    playerPrev.pause()

    return { active }
  }

  slidePrevTransitionStart() {
    this.setState((state) => this.slideTransitionStart(state.active - 1, state))
  }

  slideNextTransitionStart() {
    this.setState((state) => this.slideTransitionStart(state.active + 1, state))
  }

  initCarousel() {
    const { slidesPerViewMobile} = this.props;
    
    new Carousel(this.ref, {
      speed: 500,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      on: {
        slidePrevTransitionStart: this.slidePrevTransitionStart.bind(this),
        slideNextTransitionStart: this.slideNextTransitionStart.bind(this)
      },
      scrollbar: {
        el: this.refScrollbar,
        draggable: true
      },
      slidesPerView: slidesPerViewMobile,
      breakpoints: {
        575: {
          slidesPerView: 1
        },
      }
    })
  }

  getOptions(options) {
    const { controls } = this.props
    let optionsCustom = options

    if (controls.length > 0) {
      optionsCustom = { ...optionsCustom, controls }
    }

    return optionsCustom
  }

  render() {
    const { items, iconPrevClass, iconNextClass } = this.props

    return (
      <div>
        <div ref={node => this.ref = node} className="swiper-container slide">
          <div className="swiper-wrapper">
            { items.map(item => (
              <div className="swiper-slide">
                <VideoPlayer 
                  type={item.type}
                  embedId={item.embedId}
                  options={this.getOptions(item.options)}
                  onInstance={this.handleInstancePlayer}
                />
              </div>
            )) }
          </div>
          <div className="swiper-button-prev slide__control slide__control--prev-margin-small d-none d-lg-block">
            <i className={`icon icon-${iconPrevClass}`}></i>
          </div>
          <div className="swiper-button-next slide__control slide__control--next-margin-small d-none d-lg-block">
            <i className={`icon icon-${iconNextClass}`}></i>
          </div>

          <div ref={ node => this.refScrollbar = node } className="video-gallery__scrollbar carousel__scrollbar"></div>
        </div>
      </div>
    )
  }
}

GalleryVideo.defaultProps = {
  /*
    Exemplo de uso da prop items:
    items: [
      {
        type: 'vimeo',
        embedId: '182592195', // ou https://vimeo.com/182592195,
        options: {}
      },
      {
        type: 'youtube',
        embedId: 'bTqVqk7FSmY', // ou 'https://www.youtube.com/watch?v=bTqVqk7FSmY',
        options: {}
      },
      {
        type: 'html5',
        options: {
          source: {
            type: 'video',
            poster: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg',
            sources: [
              {
                src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
                type: 'video/mp4'
              }
            ],
          }
        }
      },
    ]
  */
  items: [],
  controls: [],
  fullscreenOnPlay: false,
  iconPrevClass: 'left-chevron',
  iconNextClass: 'right-chevron',
  slidesPerViewMobile: 1.12,
}

GalleryVideo.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['html5', 'youtube', 'vimeo']),
      embedId: PropTypes.string, // url do vídeo do youtube ou vimeo
      options: PropTypes.object // https://github.com/sampotts/plyr#options
    })
  ),
  controls: PropTypes.array, // https://github.com/sampotts/plyr#options,
  fullscreenOnplay: PropTypes.bool,
  iconPrevClass: PropTypes.string,
  iconNextClass: PropTypes.string,
  slidesPerViewMobile: PropTypes.number
}

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/NewVehicles';
import PlaceholderCard from '../../Cards/Placeholder';
import { autoServiceApiV2 } from '../../../services/autoServiceApiV2';

export default class NewVehiclesLazyV2Carousel extends Component {
  constructor(props) {
    super(props);

    this.allNewVehicles = [];
    this.allCategoriesLabel = 'Todos';
    this.carouselInstance = null;

    this.state = {
      newVehiclesList: [],
      activeCategory: this.allCategoriesLabel,
      isFetching: true,
    };

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchNewVehicles = this.fetchNewVehicles.bind(this);
    this.isActiveCategory = this.isActiveCategory.bind(this);
  }

  componentDidMount() {
    this.carouselInstance = this.initCarousel();
    this.fetchNewVehicles();
  }

  componentDidUpdate() {
    this.carouselInstance = this.initCarousel();
  }

  handleCategoryClick(category) {
    this.setState(oldState => ({
      ...oldState,
      activeCategory: category,
    }));
  }

  get categories() {
    const allAvailableCategories = this.allNewVehicles.map(
      vehicle => vehicle.category,
    );
    const uniqueAvailableCategories = [
      this.allCategoriesLabel,
      ...new Set(allAvailableCategories),
    ];

    return uniqueAvailableCategories;
  }

  async fetchNewVehicles() {
    const requestParams = [{ active: true}, {sort: 'ordination' }];
    const result = await autoServiceApiV2.getClones(requestParams);
    let newVehiclesList = result ? result.entries : this.state.newVehiclesList;

    if (this.props.currentPageVehicleSlug) {
      newVehiclesList = newVehiclesList.filter(
        vehicle => vehicle.slug !== this.props.currentPageVehicleSlug,
      );
    }

    this.allNewVehicles = newVehiclesList;

    this.setState(() => ({
      newVehiclesList,
      isFetching: false,
    }));
  }

  initCarousel() {
    if (this.carouselInstance) this.carouselInstance.destroy();

    const carousel = new window.Carousel(this.carouselRef, {
      slidesPerView: 1.7,
      spaceBetween: 24,
      speed: 500,
      centerInsufficientSlides: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      preloadImages: false,
      observer: true,
      observeParents: true,
      lazy: {
        loadPrevNext: true,
      },
      navigation: {
        nextEl: '.vehicles-new__carousel-next',
        prevEl: '.vehicles-new__carousel-prev',
      },
      breakpoints: {
        460: {
          slidesPerView: 2.7,
        },
        560: {
          slidesPerView: 3.7,
        },
        680: {
          slidesPerView: 4.7,
        },
        1270: {
          slidesPerView: 5.7,
          spaceBetween: 32,
        },
      },
      scrollbar: {
        el: '.vehicles-new__carousel-scrollbar',
        draggable: true,
      },
      on: {
        observerUpdate: () => {
          window.matchHeight.match('.new-vehicles-lazy__carousel');
        },
      },
    });

    return carousel;
  }

  formatPrice(priceValue) {
    if (!priceValue) return null;

    const formattedPrice = priceValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    });

    return formattedPrice;
  }

  isActiveCategory(vehicleCategory) {
    const { activeCategory } = this.state;
    return (
      activeCategory === this.allCategoriesLabel ||
      activeCategory === vehicleCategory
    );
  }

  render() {
    const { newVehiclesList, isFetching, activeCategory } = this.state;
    const { cardLink, showCategoriesFilter, channelShowPrice } = this.props;

    return (
      <div className="new-vehicles-lazy__carousel-wrapper">
        <div className="new-vehicles-lazy__header container">
          <h2 className="new-vehicles-lazy__title">Veículos novos</h2>

          {showCategoriesFilter && (
            <div className="vehicles-new__category-filter">
              <div className="filter-pills">
                <span className="filter-pills__title" />
                <ul className="filter-pills__options">
                  {this.categories.map(category => (
                    <li key={category} className="filter-pills__options-item">
                      <button
                        type="button"
                        className="filter-pills__options-text"
                        data-active={
                          category.toLowerCase() ===
                          activeCategory.toLowerCase()
                        }
                        onClick={() => this.handleCategoryClick(category)}
                      >
                        {category}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className="new-vehicles-lazy__carousel swiper-container"
          data-is-fetching={isFetching}
          ref={node => {
            this.carouselRef = node;
          }}
        >
          <ul className="swiper-wrapper new-vehicles-lazy__carousel-items-wrapper">
            {isFetching &&
              newVehiclesList.length === 0 &&
              [1, 2, 3, 4, 5].map(item => (
                <li
                  className="new-vehicles-lazy__carousel-placeholder-item carousel__item swiper-slide"
                  key={item}
                >
                  <PlaceholderCard
                    customClasses="new-vehicles-lazy__placeholder-card"
                    showBody={false}
                  />
                </li>
              ))}
            {!isFetching &&
              newVehiclesList.length > 0 &&
              newVehiclesList.map(vehicle => (
                <li
                  className="new-vehicles-lazy__carousel-item swiper-slide"
                  key={vehicle.slug}
                  data-is-active-category={this.isActiveCategory(
                    vehicle.category,
                  )}
                >
                  <Card
                    title={vehicle.title}
                    image={vehicle.profile_image}
                    link={`${cardLink}/${vehicle.slug}`}
                    price={this.formatPrice(vehicle.price)}
                    buttonText="Saiba mais"
                    lazy
                    buttonIconLeft={
                      <i className="icon icon--left icon-arrow-r" />
                    }
                    showPrice={channelShowPrice && vehicle.show_price}
                  />
                </li>
              ))}
          </ul>
          <div className="carousel__prev vehicles-new__carousel-prev swiper-button-prev">
            <i className="icon icon-arrow-r" />
          </div>
          <div className="carousel__next vehicles-new__carousel-next swiper-button-next">
            <i className="icon icon-arrow-r" />
          </div>
          <div className="vehicles-new__carousel-scrollbar swiper-scrollbar" />
        </div>
      </div>
    );
  }
}

NewVehiclesLazyV2Carousel.defaultProps = {
  cardLink: '/novos',
  showCategoriesFilter: false,
  currentPageVehicleSlug: null,
};

NewVehiclesLazyV2Carousel.propTypes = {
  cardLink: PropTypes.string,
  showCategoriesFilter: PropTypes.bool,
  currentPageVehicleSlug: PropTypes.string,
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import { registerField } from '%/utils/customForm';
import {
  FieldGroup,
  Input,
  Checkbox,
  Select,
  RadioInput,
  TextArea,
} from '../FormComponents';

export default class CustomFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedCustomFormFields: [],
    };

    this.sortCustomFormFieldsByOrdination =
      this.sortCustomFormFieldsByOrdination.bind(this);
  }

  componentDidMount() {
    this.sortCustomFormFieldsByOrdination();
  }

  sortCustomFormFieldsByOrdination() {
    const sortedFields = this.props.customFormFields
      .slice()
      .sort((a, b) => a.ordination - b.ordination);

    this.setState({
      sortedCustomFormFields: sortedFields,
    });
  }

  getValidationAndMask(type) {
    const validationAndMaskMap = {
      email: {
        validation: { required: this.props.is_required, validEmail: true },
        mask: null,
      },
      tel: {
        validation: { required: this.props.is_required, validPhone: true },
        mask: 'phone',
      },
    };

    return validationAndMaskMap[type] || {};
  }

  renderCustomField(field) {
    const {
      field_type,
      title,
      placeholder_text,
      attribute_name,
      is_required,
      field_options,
      mask_type,
    } = field;

    const fieldOptions = field_options && field_options.split(',');
    const { validation, mask } = this.getValidationAndMask(mask_type);

    switch (field_type) {
      case 'short-text':
        return (
          <Input
            showLabels={!!title}
            title={title}
            type={mask_type}
            placeholder={
              placeholder_text !== '' ? placeholder_text : 'Digite aqui'
            }
            maxLength={128}
            required={is_required}
            {...registerField(attribute_name, { validation, mask })}
          />
        );
      case 'long-text':
        return (
          <TextArea
            showLabels
            title={title}
            name={attribute_name}
            required={is_required}
            placeholder={
              placeholder_text !== '' ? placeholder_text : 'Digite aqui'
            }
            maxLength={512}
            rows={3}
            {...registerField(attribute_name)}
          />
        );
      case 'select':
        return (
          <FieldGroup customClasses="custom-select-group">
            <Select
              name={attribute_name}
              options={fieldOptions.map(option => ({
                label: option.trim(),
                value: option.trim(),
              }))}
              label={title}
              placeholder={
                placeholder_text !== ''
                  ? placeholder_text
                  : 'Selecione uma opção'
              }
              {...registerField(attribute_name)}
            />
          </FieldGroup>
        );
      case 'radio':
        return (
          <FieldGroup customClasses="custom-radio-group">
            <legend className="custom-radio-group__label">{title}</legend>
            <RadioInput
              name={attribute_name}
              {...registerField(attribute_name)}
              options={fieldOptions.map(option => ({
                label: option.trim(),
                value: option.trim(),
              }))}
            />
          </FieldGroup>
        );
      case 'checkbox':
        return (
          <FieldGroup customClasses="custom-checkbox-group">
            <legend className="custom-checkbox-group__label">{title}</legend>
            <div className="custom-checkbox__wrapper">
              {fieldOptions.map(option => (
                <Checkbox
                  label={option.trim()}
                  name={option.trim()}
                  value={option.trim()}
                  isChecked={false}
                  {...registerField(attribute_name)}
                />
              ))}
            </div>
          </FieldGroup>
        );
      default:
        return null;
    }
  }

  render() {
    const { sortedCustomFormFields } = this.state;

    return (
      <FieldGroup>
        {sortedCustomFormFields.map(field => this.renderCustomField(field))}
      </FieldGroup>
    );
  }
}

CustomFields.propTypes = {
  customFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      field_type: PropTypes.string,
      title: PropTypes.string,
      placeholder_text: PropTypes.string,
      mask_type: PropTypes.string,
      attribute_name: PropTypes.string,
      field_options: PropTypes.string,
      is_required: PropTypes.bool,
      ordination: PropTypes.number,
    }),
  ),
};

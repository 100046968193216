import {getChannelCountry} from './internacionalization';

export const getPrefix = () => {
  const currencyType = getChannelCountry();

  if(currencyType === 'PT'){
    return '';
  }

  return 'R$ ';
};

export const getSuffix = () => {
  const currencyType = getChannelCountry();

  if(currencyType === 'PT'){
    return ' €';
  }
  return '';
};

export const displayPrice = (value) => {
  const currencyType = getChannelCountry();

  if(currencyType === 'PT'){
    return `${value} €`;
  }

  return `R$ ${value}`;
};

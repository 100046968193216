export function phoneMask(phone) {
  const cleanedPhone = phone.replace(/\D/g, '');

  let formattedPhone = cleanedPhone;

  if (cleanedPhone.length >= 1) {
    formattedPhone = `(${cleanedPhone.slice(0, 2)}`;
  }

  if (cleanedPhone.length > 2) {
    formattedPhone += `) ${cleanedPhone.slice(2, 7)}`;
  }

  if (cleanedPhone.length > 7) {
    formattedPhone += `-${cleanedPhone.slice(7, 11)}`;
  }

  return formattedPhone;
}

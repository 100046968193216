// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';

export default class NewVehiclesCard extends Component {
  formatted_price() {
    return `R$ ${this.props.price}`;
  }

  render() {
    const {
      title,
      image,
      link,
      price,
      showPrice,
      buttonIconLeft,
      buttonText,
      lazy,
    } = this.props;

    return (
      <div className="new-vehicle-card">
        {lazy ? (
          <a className="new-vehicle-card__image-link" href={link}>
            <img
              className="new-vehicle-card__image swiper-lazy"
              src={image}
              loading="lazy"
              alt={title}
              width="197"
              height="138"
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white loading-component vehicles-new__carousel__carousel-loading">
              <div>
                <div />
              </div>
            </div>
          </a>
        ) : (
          <a className="new-vehicle-card__image-link" href={link}>
            <img
              className="new-vehicle-card__image"
              src={image}
              alt={title}
              width="197"
              height="138"
            />
          </a>
        )}
        <h3 className="new-vehicle-card__title" data-match-height="card__title">
          {title}
        </h3>
        {showPrice && price && (
          <h3
            className="new-vehicle-card__price"
            data-match-height="card__price"
          >
            A partir de {this.formatted_price()}
          </h3>
        )}
        <a className="new-vehicle-card__link" href={link}>
          {buttonIconLeft && buttonIconLeft}
          {buttonText}
          {!buttonIconLeft && <span className="icon-more">+</span>}
        </a>
      </div>
    );
  }
}

NewVehiclesCard.defaultProps = {
  title: '',
  image: '',
  link: '',
  price: '',
  buttonText: 'Ver mais',
  showPrice: true,
  buttonIconLeft: null,
  lazy: false,
};

NewVehiclesCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  price: PropTypes.string,
  buttonText: PropTypes.string,
  showPrice: PropTypes.bool,
  buttonIconLeft: PropTypes.element,
  lazy: PropTypes.bool,
};

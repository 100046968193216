import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Esse componente gera um input com bordas
 * arredondadas com
 */
class SearchTerm extends Component {
  constructor(props) {
    super(props);

    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleClickSuggestion = this.handleClickSuggestion.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.goSuggest);
  }

  handleClickSearch(e) {
    e.preventDefault();
    this.goSuggest && clearTimeout(this.goSuggest);
    this.props.onClickSearch(this.props.value);
  }

  /**
   * chamada a funcao changeKeyword sempre que
   * o valor do termo/keyword for alterado
   */
  handleChangeKeyword(e) {
    e.preventDefault();
    this.props.changeKeywordImediate && this.props.changeKeywordImediate(e);
    if (this.goSuggest) {
      clearTimeout(this.goSuggest);
      this.goSuggest = null;
    }
    this.goSuggest = setTimeout(() => {
      this.props.changeKeyword(e);
    }, 500);
  }

  handleClickSuggestion(suggestion) {
    this.props.clickSuggestion(suggestion);
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleClickSearch(e);
    }
  }

  render() {
    return (
      <div
        class={classNames('search-term', {
          'search-term--open':
            this.props.showSuggestions && this.props.suggestions.length > 0,
        })}
      >
        <button
          type="submit"
          class="search-term__btn"
          alt="Botão de busca"
          aria-label="Botão de busca"
          onClick={this.handleClickSearch}
        >
          <i class="icon icon-search-b"></i>
        </button>

        <div class="search-term__wrapper">
          <input
            onInput={this.handleChangeKeyword}
            onKeyPress={this.handleKeyPress} // Adiciona o evento onKeyPress
            value={this.props.value}
            class="search-term__input"
            type="text"
            placeholder={this.props.placeholder}
          />
        </div>

        {this.props.showSuggestions && this.props.suggestions.length > 0 && (
          <div class="search-filter-simple__suggest search-term__suggest">
            {this.props.suggestions.map((suggestion, index) => {
              return (
                <div
                  key={index}
                  onClick={() => this.handleClickSuggestion(suggestion)}
                  class="search-term__suggest-item"
                >
                  {suggestion}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

SearchTerm.defaultProps = {
  value: '',
  placeholder: 'O que está buscando?',
  suggestions: [],
  clickSuggestion() {},
  showSuggestions: false,
  onClickSearch() {},
};

SearchTerm.propTypes = {
  changeKeyword: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  suggestions: PropTypes.array,
  clickSuggestion: PropTypes.func,
  showSuggestions: PropTypes.bool,
  onClickSearch: PropTypes.func,
};

export default SearchTerm;

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Scrollbars from '../Scrollbars'

class DropdownFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: []
    }

    window.store.SearchFilterFca.watch(state => {
      const categoriesListIsEmpty = this.state.categories.length === 0
      if (categoriesListIsEmpty) {
        this.setState({
          categories: state.categories || [],
        })
      } 
    })

    this.closeFilterOnClickOut = this.closeFilterOnClickOut.bind(this)
  }

  closeFilterOnClickOut(event) {
    const doesntIsTheRef = event.target !== this.ref
    const doesntIsInsideRef = !this.ref.contains(event.target)
    
    if (this.props.showFilter && this.ref && doesntIsTheRef && doesntIsInsideRef) {
      this.props.onClose(event)
    }
  }

  addEventListeners() {
      window.addEventListener('click', this.closeFilterOnClickOut)
  }

  componentDidMount() {
    this.addEventListeners()
  }

  render() {
    const { children, className, showFilter, selectedCategories, onChangeCategories } = this.props

    return (
      <div ref={node => this.ref = node} className={classNames('dropdown-filter', className, {
        'search-filter-simple__drop': true,
        'search-filter-simple__item': true,
        'search-filter-simple__drop--active': showFilter
      })}>
        
        { children }

        <div class="card-collapse-deep search-filter-simple__drop-item">
          <div class="card-collapse-deep__header">
            Filtrar por:
          </div>

          <ul class="list list--border-bottom">
            <li>
              <strong className="dropdown-filter__list-title">Categoria</strong>
              <Scrollbars autoHeight={true}>
                <ul class="list list--no-style">
                  {this.state.categories.map(category => (
                    <li>
                      <div class="custom-check custom-check--checkbox justify-content-start">
                        <input
                          type="radio"
                          value={category.value}
                          checked={selectedCategories.includes(category.value)}
                          onClick={onChangeCategories}
                        />
                        <div class="custom-check__check"></div>
                        <label>{ category.value }</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

DropdownFilter.defaultProps = {
  showFilter: false,
  onClose() {},
  selectedCategories: [],
  onChangeCategories() {},
}

DropdownFilter.propTypes = {
  showFilter: PropTypes.bool,
  onClose: PropTypes.func,
  selectedCategories: PropTypes.array,
  onChangeCategories: PropTypes.func,
}

export default DropdownFilter

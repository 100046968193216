import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FieldGroup extends Component {
  render() {
    const { customClasses, children } = this.props;

    return (
      <fieldset
        className={classNames('form__field-group', customClasses)}
      >
        {children}
      </fieldset>
    )
  }
}

FieldGroup.propTypes = {
  customClasses: PropTypes.string,
}

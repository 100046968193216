import { Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import CustomCheck from '../CustomCheck';
import FormMessageOverlay from '../FormMessageOverlay';
import SubmitButton from '../SubmitButton';
import validationFactory from '%/utils/validation';
import IMask from 'imask';
import { phoneMasks } from '%/utils/masks';
import handleInputChange from '%/utils/handleInputChange';
import InputCpf from '../InputCpf';
import LocationFields from '../Inputs/Location';
import fipeServiceApi from '%/utils/fipeServiceApi';
import getUTM from '%/utils/utm';

class GWMConversionForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.handleInputChange = handleInputChange.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.maskFactory = this.maskFactory.bind(this);
    this.setFieldMasks = this.setFieldMasks.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.checksAreValid = this.checksAreValid.bind(this);
    this.getFirstConversionParams = this.getFirstConversionParams.bind(this);
    this.getSecondConversionParams = this.getSecondConversionParams.bind(this);
  }

  componentDidMount() {
    this.validator = validationFactory(`#multi-step-conversion-form`);

    this.setFieldMasks();

    fipeServiceApi.getBrands().then(brands => {
      const fipeBrands = [];

      brands.forEach(({ label }) => {
        fipeBrands.push({
          label: label,
          value: label,
        });
      });

      this.setState({
        brands: fipeBrands,
      });
    });
  }

  resetState() {
    this.setState({
      model: '',
      name: '',
      lastname: '',
      email: '',
      cpf: '',
      phone: '',
      uf: null,
      city: null,
      cep: '',
      phoning: false,
      mailing: false,
      whatsapping: false,
      contactOptions: '',
      dataPermissions: false,
      userCurrentModelBrand: '',
      userCurrentModel: '',
      yearCurrentModel: '',
      brands: [],
      step: 1,
      tryToConvert: false,
    });
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  maskFactory({ field, state, masks }) {
    if (!field) {
      return;
    }

    const fieldMask = IMask(field, {
      mask: [...masks],
    });

    fieldMask.on('accept', () => this.setState({ [state]: fieldMask.value }));

    return fieldMask;
  }

  setFieldMasks() {
    this.maskFactory({
      field: this.phoneInput,
      state: 'phone',
      masks: phoneMasks,
    });

    this.maskFactory({
      field: this.cepInput,
      state: 'cep',
      masks: [{ mask: '00000-000' }],
    });
  }

  getFirstConversionParams() {
    const vehicleColor = window.store.GWMProject.getState().vehicleColor;

    const {
      model,
      name,
      lastname,
      email,
      cpf,
      phone,
      uf,
      city,
      cep,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      dataPermissions,
    } = this.state;

    const { bait, category, product } = this.props;

    return {
      bait: bait,
      brand: 'GWM',
      utmz: getUTM(),
      category: category,
      product: model == '' ? product : model,
      model: model == '' ? product : model,
      name,
      lastname,
      email,
      cpf,
      phone,
      uf,
      city,
      cep,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      dataPermissions,
      vehicleColor,
    };
  }

  getSecondConversionParams() {
    const vehicleColor = window.store.GWMProject.getState().vehicleColor;

    const {
      model,
      name,
      lastname,
      email,
      cpf,
      phone,
      uf,
      city,
      cep,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      dataPermissions,
      userCurrentModelBrand,
      userCurrentModel,
      yearCurrentModel,
    } = this.state;

    const { bait, category, product } = this.props;

    return {
      bait: bait,
      brand: 'GWM',
      utmz: getUTM(),
      category: category,
      product: model == '' ? product : model,
      model: model == '' ? product : model,
      name,
      lastname,
      email,
      cpf,
      phone,
      uf,
      city,
      cep,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      dataPermissions,
      userCurrentModelBrand,
      userCurrentModel,
      yearCurrentModel,
      vehicleColor,
    };
  }

  handleValidation() {
    const { step } = this.state;
    const { sendFirstConversion, sendSecondConversion } = this.props;

    this.setState({
      tryToConvert: true,
    });

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (!formIsValid) {
      return formIsValid;
    }

    if (!this.checksAreValid()) {
      return false;
    }

    if (step == 1) {
      const params = this.getFirstConversionParams();
      sendFirstConversion(params);
    } else if (step == 2) {
      const params = this.getSecondConversionParams();
      sendSecondConversion(params);
    }

    return true;
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf, subsidiary: '', subsidiaries: [] });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({ city, subsidiary: '' });

    if (this.props.filterUnitsByLocation && this.state.uf && this.state.city) {
      this.setState({ subsidiaryDisabled: true });

      let apiQuery = [
        { 'q[state_cont]': this.state.uf },
        { 'q[city_cont]': this.state.city },
      ];

      const categoryUpperCase = upperCaseAndRemoveAccent(
        this.props.modelCategory,
      );

      if (categoryUpperCase == 'ONIBUS') {
        apiQuery.push({ 'q[bus_eq]': true });
      } else {
        apiQuery.push({ 'q[truck_eq]': true });
      }

      window.autoServiceApi.getServedRegion(apiQuery).then(res => {
        let subsidiaries = res.entries.map(region => ({
          label: region.display_name,
          value: region.display_name,
          city: region.city,
        }));

        let subsidiariesUniq = this.uniqByKeepFirst(
          subsidiaries,
          sub => sub.label,
        );

        // TODO atualizar filtro do apicasso para remover nomes parciais, ex: SP cidade Arujá que retorna Guarujá.
        subsidiariesUniq = subsidiariesUniq.filter(
          s => s.city.length == this.state.city.length,
        );

        this.setState({
          subsidiaries: subsidiariesUniq,
          subsidiary: '',
          subsidiaryDisabled: false,
        });
      });
    }
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  handleStepChange(isStepIncrease = true) {
    const lastStep = 2;
    const { step } = this.state;

    if (isStepIncrease) {
      const dataIsValid = this.handleValidation();

      if (dataIsValid && step <= lastStep) {
        this.setState({
          step: step + 1,
          dataPermissions: false,
        });
      }
    } else {
      this.setState({
        step: step - 1,
      });
    }
  }

  checksAreValid() {
    const { dataPermissions } = this.state;

    return dataPermissions;
  }

  render() {
    const {
      model,
      name,
      lastname,
      email,
      cpf,
      phone,
      uf,
      city,
      cep,
      phoning,
      mailing,
      whatsapping,
      contactOptions,
      dataPermissions,
      userCurrentModelBrand,
      userCurrentModel,
      yearCurrentModel,
      brands,
      step,
      tryToConvert,
    } = this.state;

    const {
      titleForm,
      mainPhrase,
      linkPrivacyPolicy,
      handleCloseOverlay,
      isSubmittingForm,
      showOverlay,
      errorSendingForm,
      formType,
      avaliableModels,
    } = this.props;

    return (
      <form
        className="conversion-form form-conversion--static"
        id="multi-step-conversion-form"
        onSubmit={e => e.preventDefault()}
        ref={form => (this.form = form)}
        novalidate
      >
        <FormMessageOverlay
          handleClose={handleCloseOverlay}
          isVisible={showOverlay}
          type={errorSendingForm ? 'error' : 'success'}
          successMessage={'Solicitação enviada com sucesso!'}
          content={{
            success: {
              iconClass: 'icon icon-check-mark',
              topMessage: 'Solicitação enviada com sucesso!',
              bottomMessage:
                'Em breve um de nossos consultores entrará em contato com você',
            },
          }}
        />

        <header>
          <div className="conversion-form__header-phrase">
            <h2>{titleForm}</h2>
            <p>{mainPhrase}</p>
          </div>
        </header>

        <div className="form-conversion__body">
          <div
            className={`form-conversion-first-step ${
              step == 1 ? 'd-block' : 'd-none'
            }`}
          >
            {formType == 'index' && (
              <div className="form-group">
                <label>Carro de Interesse</label>
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="model"
                  options={avaliableModels}
                  value={model}
                  placeholderValue="Escolha o modelo desejado"
                  searchEnabled={false}
                  required={true}
                  errorMessageDisplay="#invalid-model"
                />

                <div
                  id="invalid-model"
                  className="invalid-feedback is-invalid-model"
                ></div>
              </div>
            )}

            <div className="form-group">
              <label for="name">Nome</label>
              <input
                onChange={this.handleInputChange}
                value={name}
                type="text"
                className="form-control"
                name="name"
                required
                placeholder={'Nome'}
                maxLength={80}
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <label for="lastname">Último nome</label>
              <input
                onChange={this.handleInputChange}
                value={lastname}
                type="text"
                className="form-control"
                name="lastname"
                required
                placeholder={'Seu último Nome'}
                maxLength={80}
                data-bouncer-target="#invalid-lastname"
              />
              <div id="invalid-lastname" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <label for="email">E-mail</label>
              <input
                onChange={this.handleInputChange}
                value={email}
                type="email"
                className="form-control"
                name="email"
                placeholder="E-mail"
                maxLength={80}
                required
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback" />
            </div>

            <InputCpf
              handleChange={this.handleInputChange}
              label={'CPF'}
              value={cpf}
              shouldValidate={true}
            />

            <div className="form-group">
              <label for="phone">Telefone</label>
              <input
                onChange={this.handleInputChange}
                value={phone}
                ref={phoneInput => (this.phoneInput = phoneInput)}
                className="form-control"
                name="phone"
                type="phone"
                data-bouncer-target="#invalid-phone"
                placeholder="Seu telefone"
                required
              />
              <div id="invalid-phone" className="invalid-feedback" />
            </div>

            <label>UF / Cidade</label>
            <LocationFields
              uf={uf}
              handleStateChange={this.handleStateChange}
              city={city}
              handleCityChange={this.handleCityChange}
            />

            <div className="form-group">
              <label for="cep">CEP</label>
              <input
                onChange={this.handleInputChange}
                value={cep}
                className="form-control"
                name="cep"
                data-bouncer-target="#invalid-cep"
                placeholder="CEP"
                ref={cepInput => (this.cepInput = cepInput)}
                required
              />
              <div id="invalid-cep" className="invalid-feedback" />
            </div>

            <fieldset className="mt-2">
              <legend className="conversion-form__control-label">
                Quero receber contato por:
              </legend>

              <div className="form-check form-check-inline">
                <CustomCheck
                  name="mailing"
                  value="true"
                  type="checkbox"
                  isChecked={mailing}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  E-mail
                </CustomCheck>
              </div>

              <div className="form-check form-check-inline">
                <CustomCheck
                  name="whatsapping"
                  value="true"
                  isChecked={whatsapping}
                  onChangeCheckable={this.handleContactOptionsChange}
                  type="checkbox"
                >
                  Whatsapp
                </CustomCheck>
              </div>

              <div className="form-check form-check-inline">
                <CustomCheck
                  name="phoning"
                  value="true"
                  isChecked={phoning}
                  onChangeCheckable={this.handleContactOptionsChange}
                  type="checkbox"
                >
                  Telefone
                </CustomCheck>
              </div>

              <div className="form-group contact-options">
                <input
                  type="text"
                  className="form-control d-none"
                  name="contact-options"
                  required
                  placeholder="Opções de contato"
                  data-bouncer-target="#invalid-contact-options"
                  value={contactOptions}
                  checked={contactOptions}
                />
                <div
                  id="invalid-contact-options"
                  className="invalid-feedback"
                />
              </div>
            </fieldset>

            <hr className="my-1" />

            <div className="form-group">
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                >
                  Li e concordo com a política de privacidade, e aceito
                  compartilhar meus dados com a GWM Brasil.
                </CustomCheck>
              </div>

              {tryToConvert && !dataPermissions && (
                <div
                  id="invalid-data-permissions"
                  className="invalid-feedback is-invalid-data-permissions d-block"
                >
                  <div
                    className="error-message"
                    id="bouncer-error_data-permissions"
                  >
                    Por favor, selecione esse campo
                  </div>
                </div>
              )}
            </div>

            <hr className="my-1" />

            <button
              type="button"
              className="btn button button--large button--primary w-100 mt-2 mb-2"
              onClick={this.handleStepChange}
            >
              ENVIAR
            </button>

            <hr className="my-1" />

            <footer className="form-conversion__footer pt-1">
              Conheça nossa{' '}
              <a href={linkPrivacyPolicy}>Política de privacidade</a>.
            </footer>
          </div>

          <div
            className={`form-conversion-second-step ${
              step == 2 ? 'd-block' : 'd-none'
            }`}
          >
            <div className="form-group">
              <label for="userCurrentModelBrand">Marca carro atual</label>
              <CustomSelect
                handleSelectChange={this.handleInputChange}
                name="userCurrentModelBrand"
                options={brands}
                value={userCurrentModelBrand}
                placeholderValue="Informe a marca do seu carro atual"
                searchEnabled={true}
                errorMessageDisplay="#invalid-userCurrentModelBrand"
              />

              <div
                id="invalid-userCurrentModelBrand"
                className="invalid-feedback is-invalid-userCurrentModelBrand d-block"
              ></div>
            </div>

            <div className="form-group">
              <label for="userCurrentModel">Modelo carro atual</label>
              <input
                onChange={this.handleInputChange}
                value={userCurrentModel}
                className="form-control"
                name="userCurrentModel"
                type="text"
                data-bouncer-target="#invalid-userCurrentModel"
                placeholder="Digite o modelo do seu carro atual"
              />
              <div id="invalid-userCurrentModel" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <label for="yearCurrentModel">Ano carro atual</label>
              <input
                onChange={this.handleInputChange}
                value={yearCurrentModel}
                className="form-control"
                name="yearCurrentModel"
                data-bouncer-target="#invalid-yearCurrentModel"
                placeholder="Digite o ano do seu carro atual"
                maxLength={4}
              />
              <div id="invalid-yearCurrentModel" className="invalid-feedback" />
            </div>

            <hr className="my-1" />

            <div className="form-group">
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                >
                  Li e concordo com a política de privacidade, e aceito
                  compartilhar meus dados com a GWM Brasil.
                </CustomCheck>
              </div>

              {tryToConvert && !dataPermissions && (
                <div
                  id="invalid-data-permissions"
                  className="invalid-feedback is-invalid-data-permissions d-block"
                >
                  <div
                    className="error-message"
                    id="bouncer-error_data-permissions"
                  >
                    Por favor, selecione esse campo
                  </div>
                </div>
              )}
            </div>

            <SubmitButton
              className="btn button button--large button--primary w-100 mt-2 mb-2"
              label={'ENVIAR'}
              isSubmitting={isSubmittingForm}
              handleClick={this.handleValidation}
            />

            <button
              type="button"
              className="btn button button--large button--primary-outline w-100 mt-2"
              onClick={() => {
                this.handleStepChange(false);
              }}
            >
              VOLTAR
            </button>

            <hr className="my-1" />

            <footer className="form-conversion__footer pt-1">
              Conheça nossa{' '}
              <a href={linkPrivacyPolicy}>Política de privacidade</a>.
            </footer>
          </div>
        </div>
      </form>
    );
  }
}

// default props
GWMConversionForm.defaultProps = {
  titleForm: '',
  mainPhrase: 'Preencha o formulário e acompanhe os lançamentos da GWM',
  bait: 'INTERNA - TENHO INTERESSE',
  category: 'Clone',
  product: 'HAVAL H6',
  linkPrivacyPolicy: '',
  handleCloseOverlay: function () {},
  isSubmittingForm: false,
  showOverlay: false,
  errorSendingForm: false,
  sendFirstConversion: function () {},
  sendSecondConversion: function () {},
  formType: 'internal',
  avaliableModels: [],
};

GWMConversionForm.propTypes = {
  titleForm: PropTypes.string,
  mainPhrase: PropTypes.string,
  bait: PropTypes.string,
  category: PropTypes.string,
  product: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  handleCloseOverlay: PropTypes.func,
  isSubmittingForm: PropTypes.bool,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  sendFirstConversion: PropTypes.func,
  sendSecondConversion: PropTypes.func,
  formType: PropTypes.string,
  avaliableModels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default GWMConversionForm;

import fetchival from 'fetchival';
import fetchCached from './utils/fetchCached';
import injectShareThis from './utils/injectShareThis';
import proxyEvents from './utils/proxyEvents';
import openModal from './utils/openModal';
import isMobile from './utils/isMobile';
import smoothscroll from 'smoothscroll-polyfill';
import { debounce } from 'lodash';
import CoverVideoConfigurator from './utils/CoverVideoConfigurator';
import equalHeights from './utils/equalHeights';
import Micromodal from 'micromodal';
import hookConversion from './utils/hookConversion';
import maskField, { MASK_TYPES } from './utils/maskField';
import Bouncer from 'formbouncerjs/dist/bouncer.polyfills';
import validationFactory from './utils/validation';
import { adjustsHeight } from './utils/VideoBG';
import normalizeDataTawkTo from './utils/normalize-data-tawk-to';
import { addClassFirstLastElement } from './utils/domUtils';
import { applyMasksText } from './utils/maskText';
import serialize from 'form-to-obj';
import { createCookieUTM } from './utils/utm';
import 'url-search-params-polyfill';
import privacyPolicyPopup from './privacyPolicyPopup';
import {getChannelCountry} from './utils/internacionalization';

import autoServiceApi from './utils/autoServiceApi';
import helpers from './helpers';

require('bootstrap.native/dist/bootstrap-native-v4');

window.matchHeight = {
  match: equalHeights
};
window.helpers = helpers;
window.CoverVideoConfigurator = CoverVideoConfigurator;
window.Micromodal = Micromodal;
window.videoBG = {
  adjustsHeight
};
window.MASK_TYPES = MASK_TYPES;
window.maskField = maskField;
window.Bouncer = Bouncer;
window.validationFactory = validationFactory;
window.isMobile = isMobile;
window.normalizeDataTawkTo = normalizeDataTawkTo;
window.applyMasksText = applyMasksText;
window.getChannelCountry = getChannelCountry;
window.domUtils = {
  addClassFirstLastElement
};
window.serialize = serialize;

window.injectShareThis = injectShareThis;
window.debounce = debounce;

smoothscroll.polyfill();

/**
 * @var {promise} window.fetchCached Função para ser usada nas requests de API
 */
window.fetchCached = fetchCached;
window.fetchival = fetchival;

window.autoServiceApi = autoServiceApi;

/**
 * Função executada sempre que uma conversão é enviada com sucesso
 */
window.hookConversion = hookConversion;

/**
 * @var {number} inputsPerSteps define o numero de inputs que serão exibidos por cada passo do formulario
 */
window.inputsPerSteps = 4;

window.whatsappDidUpdate = function(){};
window.scheduleServiceFormUpdate = function(){};
window.scheduleServiceFormClear = function(){};
window.scheduleServiceConversionFormUpdate = function(){};
window.scheduleServiceBannerFormUpdate = function(){};

window.privacyPolicyPopup = privacyPolicyPopup;

$(document).ready(function() {
  /**
   * @var {string} window.autoApiChannel Slug do channel definido nas meta tags do layout
   */
  window.autoApiChannel = document.querySelector(
    'meta[name=\'auto_api_channel\']'
  ).content;

  // Configura botões que servem para fazer scroll
  $(document).on('click', function(e) {
    const target = e.target,
      scrollTargetSelector = target.dataset && target.dataset.scrollTo;

    if (!scrollTargetSelector) {
      return;
    }

    e.preventDefault();

    document
      .querySelector(scrollTargetSelector)
      .scrollIntoView({ behavior: 'smooth' });
  });

  $(document).on('click', proxyEvents);

  // Abertura de modais
  $(document).on('click', openModal);

  applyMasksText();
  createCookieUTM();

  $('.channel-pop-up__action-close').on('click', function(e) {
    let expirationData = new Date();
    const pathPopup = `/${document.location.pathname.split('/')[1]}`;
    expirationData.setHours(expirationData.getHours() + 3); // 3 dias de validade
    document.cookie = `channel_pop_popup_close=true; expires=${expirationData.toUTCString()}; path=${pathPopup}`;

    const currentDate = new Date();
    document.cookie = `channel_pop_up_closed_at=${currentDate.toISOString()}; expires=${expirationData.toUTCString()}; path=${pathPopup}`;

    $('.channel-pop-up').remove();
  });
});

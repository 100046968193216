import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import VariantCollection, {
  variantObjectsToCustomSelectOptions
} from '%/utils/variantCollection';
import handleInputChange from '%/utils/handleInputChange';
import ProfileImageTypes from '%/utils/variantProfileImageTypes';


/**
 * @property {VariantCollection} variantsCollection
 */
export default class NewModelDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVariant: this.props.defaultVariant,
      variants: [],
    };

    this.getCurrentVariantItem = this.getCurrentVariantItem.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this);

    this.watchStore()
  }

  watchStore() {
    window.store.vehicleVersionDetails.watch(({ activeVersion }) => {
      if (activeVersion && this.getCurrentVariantItem()) {
        activeVersion && this.setState({ selectedVariant: activeVersion.slug })
      }
    })
  }

  handleVariantChange(e) {
    this.handleInputChange(e)

    const currentVariantItem = this.getCurrentVariantItem()

    if (currentVariantItem) {
      if (this.props.shouldShowVariantSelect) {
        this.historyPushState()

        // Atualiza o state
        if (this.variantOptionsToCustomSelect().length > 0) {
          const index = this.getIndexBySlugVersion()

          window.store.vehicleVersionDetails.setState({ activeVersion: this.state.variants[index] })
        }
      }
      this.props.handleVariantChange(currentVariantItem);
    }
  }

  historyPushState() {
    if (window.history.pushState)
      window.history.pushState({}, null, this.getUrlVersion())
  }

  /**
   * Retorna a url já com a versão selecionada para ser setada no history
   */
  getUrlVersion() {
    const href = window.location.pathname,
      slugVersion = this.getSlugVersion(),
      currentVersion = this.getCurrentVariantItem(),
      newSlugVersion = currentVersion.getVariantProperty('slug')

    const newPathname = href
      .replace(/\/$/, '')
      .replace(slugVersion, '')
      .replace(/\/$/, '')

    return `${window.location.origin}${newPathname}/${newSlugVersion}${window.location.search}`
  }

  /**
   * Retorna o slug da versão a parti da url
   */
  getSlugVersion() {
    const matches = window.location.pathname.match(/novo.+$/)
    if (matches && matches.length > 0) {
      const pathItems = matches[0].split('/')
      if (pathItems.length > 2) {
        return pathItems[2].replace(/\//g, '')
      }
    }
    return ''
  }

  /**
   * Retorna a posicação no array das variants(versões) a parti do slug da versão
   */
  getIndexBySlugVersion() {
    const slugVersion = this.getSlugVersion()

    const index = this.state.variants.reduce((acc, variant, index) => {
      if (variant.slug === slugVersion) {
        acc = index
      }
      return acc
    }, 0)

    return index
  }

  componentWillMount() {
    this.props.cloneId &&
      service.getNewModelDetails(this.props.cloneId).then(res => {
        this.setState({
          variants: res.entries
        });
      });
  }

  componentDidMount() {}

  /**
   * Retorna o valor configurado pela prop `brand`.
   * Caso nenhum valor esteja informado para essa prop, retorna a marca da
   * variante atualmente selecionada.
   * Se nenhuma das condições acima forem cumpridas, retorna a marca do primeiro
   * item da coleção de variantes.
   *
   * @return {string}
   */
  getCurrentItemBrand() {
    let result = this.props.brand;

    if (result) {
      return result;
    }

    let currentVariantItem = this.getCurrentVariantItem();
    if (this.getCurrentVariantItem()) {
      return currentVariantItem.getVariantProperty('brand');
    }

    return (
      (this.variantsCollection &&
        this.variantsCollection._variants &&
        this.variantsCollection._variants.length > 0 &&
        this.variantsCollection._variants[0]) ||
      ''
    );
  }

  componentWillUpdate() {
    this.variantsCollection = new VariantCollection(this.state.variants);
  }

  /**
   * Se houver apenas uma variante, não precisamos exibir o select, apenas
   * manter a primeira opção selecionada. Essa função trata isso.
   * @return {boolean}
   */
  shouldDisplayCustomSelect() {
    return this.props.shouldShowVariantSelect && this.state.variants.length > 1;
  }

  /**
   * Converte a prop de variantes para o formato utilizado no custom select:
   * { value: "foo", name: "bar" }
   */
  variantOptionsToCustomSelect() {
    return variantObjectsToCustomSelectOptions(
      this.state.variants,
      'slug',
      'name'
    );
  }

  /**
   * Obtém a foto da variante atualmente selecionada.
   * @return {string}
   */
  getCurrentVariantPhotoUrl() {
    return (
      this.getCurrentVariantItem() &&
      this.getCurrentVariantItem().profileImages.url //getProfileImageOfType( ProfileImageTypes.MODEL_MAIN )
    );
  }

  /**
   * Obtém o item da variante atualmente escolhido
   * @return {VariantItem|null}
   */
  getCurrentVariantItem() {
    const selectedVariant = this.props.defaultVariant || this.state.selectedVariant;
    return (
      selectedVariant &&
      this.variantsCollection &&
      this.variantsCollection.findVariantBy('slug', selectedVariant)
    );
  }

  getCurrentVariantItemPrice() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem.price;
  }

  getCurrentVariantTitle() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem.getVariantProperty('title')
  }

  getCurrentVariantSubtitle() {
    const selectedVariantItem = this.getCurrentVariantItem();
    return selectedVariantItem && selectedVariantItem.getVariantProperty('subtitle')
  }

  /**
   * Indica se o preço deve ser exibido
   * @return {bool}
   */
  shouldShowPrice() {
    return this.props.shouldShowPrice && this.getCurrentVariantItemPrice();
  }

  /**
   * Indica se a foto deve ser exibida
   * @return {bool}
   */
  shouldShowImage() {
    return this.props.shouldShowImage && !!this.getCurrentVariantPhotoUrl();
  }

  getSelectedVariant() {
    if (this.state.selectedVariant) {
      return this.state.selectedVariant
    } else {
      if (this.variantOptionsToCustomSelect().length > 0) {
        const index = this.getIndexBySlugVersion()

        window.store.vehicleVersionDetails.setState({ activeVersion: this.state.variants[index] })
        return this.variantOptionsToCustomSelect()[index].value
      } else {
        return ''
      }
    }
  }

  render() {
    const { showSocialSharer, shouldShowPrice, showLabelSelect } = this.props

    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <h1 className="showcase__title text-primary">
              { this.getCurrentVariantTitle() }
            </h1>
            {!this.shouldDisplayCustomSelect() && (
              <h2 className="showcase__subtitle">{this.getCurrentVariantSubtitle()}</h2>
            )}
            {!this.shouldDisplayCustomSelect() &&
              <div className="showcase__select-variants d-none">
                <CustomSelect
                      id="select-variants"
                      name="selectedVariant"
                      handleSelectChange={ this.handleVariantChange }
                      options={ this.variantOptionsToCustomSelect() }
                      value={ this.getSelectedVariant() }
                      placeholder={ false }
                      removeItemButton={ false }
                      searchEnabled={ this.props.searchEnabled }
                      disable={true}
                    />
              </div>
            }
            {this.shouldDisplayCustomSelect() &&
              <div className="showcase__select-variants">
                {showLabelSelect && (
                  <label className="showcase__label-variants">Escolha a versão:</label>
                )}
                <CustomSelect
                      id="select-variants"
                      name="selectedVariant"
                      handleSelectChange={ this.handleVariantChange }
                      options={ this.variantOptionsToCustomSelect() }
                      value={ this.getSelectedVariant() }
                      placeholder={ false }
                      removeItemButton={ false }
                      searchEnabled={ this.props.searchEnabled }
                    />
              </div>
            }
            {this.props.showPriceStartAt && (
              <p class="price-start-at">A partir de</p>
            )}
            {this.props.shouldShowPrice && (
              <div>
                <hr className="showcase__title-separator"></hr>
                <span class="showcase__starting-price">{this.props.showPriceCoin && "R$ " }{this.getCurrentVariantItemPrice()}</span>
              </div>
            )}
          </div>
          {showSocialSharer && (
            <div class="col-lg-6">
              <div class="social-sharer">
                <small class="share-label">
                  Compartilhe essa oferta:
                </small>
                <div class="sharethis-inline-share-buttons"></div>
              </div>
            </div>
          )}
        </div>
        {this.shouldShowImage() && (
            <div class="showcase-new-simple__image text-center">
              <img src={this.getCurrentVariantPhotoUrl()} alt={ this.getCurrentVariantTitle() } />
            </div>
          )}
      </div>
    );
  }
}

// default props
NewModelDetails.defaultProps = {
  shouldShowVariantSelect: false,
  showSocialSharer: true,
  handleVariantChange: function () { },
  shouldShowImage: true,
  shouldShowPrice: true,
  showPriceStartAt: false,
  showPriceCoin: false,
  shouldShowVariantSelect: true,
  // defaultVariant representa o valor de variante selecionado
  // Esse valor terá precedência sobre o valor selecionado pelo CustomSelect,
  // portanto, cuidado.
  defaultVariant: null,
  searchEnabled: true,
  showLabelSelect: false
};

// props config
NewModelDetails.propTypes = {
  shouldShowVariantSelect: PropTypes.bool,
  showSocialSharer: PropTypes.bool,
  handleVariantChange: PropTypes.func,
  variants: PropTypes.array.isRequired,
  shouldShowImage: PropTypes.bool.isRequired,
  shouldShowPrice: PropTypes.bool.isRequired,
  showPriceStartAt: PropTypes.bool,
  showPriceCoin: PropTypes.bool,
  shouldShowVariantSelect: PropTypes.bool.isRequired,
  defaultVariant: PropTypes.string,
  searchEnabled: PropTypes.bool,
  showLabelSelect: PropTypes.bool
};

import { maskJs } from 'mask-js'
import IMask from 'imask';
import { isPlainObject } from 'lodash';
import {MASKS} from "./maskField";
import {getChannelCountry} from "./internacionalization";

/**
 * Retorna a máscara usada por números de telefone para o padrão brasileiro
 * @param len
 * @return {string}
 */
const getMaskBr = (len) => {

  const masks = {
    13: '+99 (99) 99999-9999',
    12: '+99 (99) 9999-9999',
    11: '(99) 99999-9999',
    10: '(99) 9999-9999',
    9: '99999-9999',
    8: '9999-9999',
  }

  return masks[len] || '9999-9999';
}

/**
 * Aplica máscaras a elementos que não seja input
 */
export const phoneMask = (element) => {

  const country = getChannelCountry();

  const number = element.innerHTML.trim().replace(/\D/g, ''),
    length = number.length;
  let mask = '';

  if(country === 'PT'){
    mask = '+999 999 999 999'
  } else {
    mask = getMaskBr(length);
  }

  element.innerHTML = maskJs(mask, number)
}

export const applyMasksText = () => {
  document.querySelectorAll('.text-mask-phone').forEach(phoneMask)
}

/**
 * Mascara um texto.
 * @param {string} value O texto a ser mascarado
 * @param {string} type O tipo da máscara a ser aplicada no campo
 * @param {object} options Opções extra para o plugin
 *
 * @return {string} Texto mascarado
 */
export default (value, type, options) => {
  if (!MASKS[type]) {
    throw 'Invalid mask exception';
  }

  let iMaskConfig = {
    ...options
  };

  if (MASKS[type] instanceof Array) {
    iMaskConfig['mask'] = MASKS[type];
  } else if (isPlainObject(MASKS[type])) {
    iMaskConfig = { ...iMaskConfig, ...MASKS[type] };
  }

  return IMask.createMask(iMaskConfig).resolve(value);
};

// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
// import PropTypes from 'prop-types';
import { registerField } from '%/utils/customForm';
import FieldGroup from '../../FieldGroup';

import { statesIdsList, statesList } from './statesList';
import Select from '../Select';

export default class LocationGroupField extends Component {
  constructor(props) {
    super(props);

    this.statesOptions = statesList.map(state => ({
      label: state,
      value: state,
    }));

    this.state = {
      cities: [],
    };

    this.getUfId = this.getUfId.bind(this);
    this.handleStateFieldChange = this.handleStateFieldChange.bind(this);
    this.clearCityStateField = this.clearCityStateField.bind(this);
  }

  handleStateFieldChange(e) {
    const uf = e.target.value;
    const ufID = this.getUfId(uf);

    this.clearCityStateField();

    if (!ufID) return;
    this.populateCitySelect(ufID);
  }

  getUfId(uf) {
    if (!uf) return null;
    const state = statesIdsList.find(currentState => currentState.sigla === uf);
    return state && state.id;
  }

  clearCityStateField() {
    this.setState({ cities: [] });
  }

  populateCitySelect(uf) {
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`,
    )
      .then(res => res.json())
      .then(cities => {
        const formattedCities = cities.map(city => ({
          value: city.nome,
          label: city.nome,
        }));

        this.setState(() => ({ cities: formattedCities }));
      });
  }

  render() {
    return (
      <FieldGroup customClasses="location-field-group">
        <label className="form__field-label">Estado/cidade:</label>
        <div className="location-field-group__wrapper">
          <Select
            options={this.statesOptions}
            {...registerField('uf', { callback: this.handleStateFieldChange })}
          />
          <Select
            options={this.state.cities}
            placeholder="Selecione uma cidade"
            {...registerField('city')}
          />
        </div>
      </FieldGroup>
    );
  }
}

LocationGroupField.propTypes = {};

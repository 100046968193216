import { h } from 'preact';
import { Component } from 'react';
import { render } from 'react-dom';
import Choices from 'choices.js';
import PropTypes from 'prop-types';
import { isEqual } from '../utils/aux';

const KEY_VALUE = 'value'
const KEY_LABEL = 'label'

export default class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value, options, placeholderValue, disable } = this.props

    let valueSelected = value === undefined ? '' : value;
    let prevValueSelected = prevProps.value === undefined ? '' : prevProps.value

    let optionsChoices = []

    if (this.props.placeholder && this.props.placeholderValue) {
      optionsChoices.push({ [KEY_VALUE]: "", [KEY_LABEL]: placeholderValue, placeholder: true })
    }

    optionsChoices = [
      ...optionsChoices,
      ...options
    ]

    if (!isEqual(prevProps.options, options)) {
      this.choicesInstance.setChoices(optionsChoices, KEY_VALUE, KEY_LABEL, true)
      if (options && options.length > 0)
        this.choicesInstance.setChoiceByValue(valueSelected)
    }

    if (prevValueSelected !== valueSelected) {
      this.choicesInstance.setChoiceByValue(valueSelected)
    }

    if (disable) {
      this.choicesInstance.disable()
    } else {
      this.choicesInstance.enable()
    }
  }

  componentDidMount() {
    // Isso é necessário porque a mudança de markup feita pelo Choices.js
    // causa uma renderização errada por parte do [P]React. Nesse caso temos que
    // criar um componente não controlado.
    // Referências:
    // - https://github.com/ryanflorence/react-training/blob/gh-pages/lessons/05-wrapping-dom-libs.md
    // - https://reactjs.org/docs/integrating-with-other-libraries.html
    let node = this.getDOMNode(),
      optionsItems = [];

    if (this.props.placeholder && this.props.placeholderValue) {
      optionsItems.push(<option placeholder value="">{this.props.placeholderValue}</option>)
    }

    this.props.options.forEach(option => {
      let props = {}
      if (option.selected || (this.props.value == option[KEY_VALUE])) {
        props.selected = 'selected'
      }
      optionsItems.push(<option value={option[KEY_VALUE]} {...props}>{option[KEY_LABEL]}</option>);
    });
    
    const attributes = {
      ref: selectElement => (this.selectElement = selectElement),
      name: this.props.name,
      className: "form-control"
    }

    if(this.props.required){
      attributes.required = true
      attributes['data-bouncer-target'] = this.props.errorMessageDisplay
    }
    render(
      <select {...attributes }>
        {optionsItems}
      </select>,
      node
    );

    this.selectElement.addEventListener('addItem', this.handleChange.bind(this))

    this.choicesInstance = new Choices(this.selectElement, {
      classNames: {
        // É necessário porque o Micromodal utiliza a mesma class ('.is-open')
        // para indicar que o modal está aberto, contudo o Micromodal não
        // permite a mudança dessa classe.
        openState: 'select-is-open',
        ...this.props.classNames,
      },
      placeholder: this.props.placeholder,
      placeholderValue: this.props.placeholderValue,
      noResultsText: this.props.noResultsText,
      loadingText: this.props.loading,
      noChoicesText: this.props.noChoicesText,
      itemSelectText: this.props.itemSelectText,
      shouldSort: this.props.shouldSort,
      searchEnabled: this.props.searchEnabled
    });
  }

  componentWillUnmount() {
    this.choicesInstance.destroy();
  }

  handleChange(e) {
    this.props.handleSelectChange && this.props.handleSelectChange(e);
  }

  render() {
    return <div />;
  }
}

// props config
CustomSelect.defaultProps = {
  placeholder: true,
  placeholderValue: 'Selecione algo',
  noResultsText: 'Nenhum resultado encontrado',
  loadingText: 'Carregando...',
  noChoicesText: 'Nenhuma escolha disponível',
  itemSelectText: '',
  shouldSort: false,
  disable: false,
  required: false,
  searchEnabled: true,
  /**
   * ver valores possíveis para classNames em
   * https://github.com/jshjohnson/Choices#setup
   */
  classNames: {}
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func,
  placeholder: PropTypes.bool,
  placeholderValue: PropTypes.string,
  noResultsText: PropTypes.string,
  loadingText: PropTypes.string,
  noChoicesText: PropTypes.string,
  itemSelectText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  shouldSort: PropTypes.bool,
  disable: PropTypes.bool,
  required: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  classNames: PropTypes.object,
};

import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomCheck from '../CustomCheck';
import { makeItemOfferDisplay } from '../../utils/makeItemOfferDisplay';

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.handleCompareCheck = this.handleCompareCheck.bind(this);
  }

  handleCompareCheck(e) {
    this.props.onCompareCheck(e);
  }

  isPremiumVehicle(price) {
    const { channelPremiumPrice } = this.props;
    let premiumPrice = 150000;

    if (channelPremiumPrice > 0) {
      premiumPrice = channelPremiumPrice;
    }

    return price >= premiumPrice;
  }

  render() {
    const {
      partLink,
      slug,
      item_image,
      kind,
      name,
      item_unit,
      showUnitTag,
      fabrication_year,
      model_year,
      shielded,
      price,
      subscription_price,
    } = this.props;

    // Recalcula o itemOfferDisplay sempre que o componente renderizar
    const itemOfferDisplay = makeItemOfferDisplay(this.props.old_price, this.props.price);

    return (
      <div className="card">
        <div className="card__header">
          {this.isPremiumVehicle(price) && (
            <div className="card__badges">
              <div className="card__badge-item badge badge--premium">
                <i className="icon icon-diamond" />
                Premium
              </div>
            </div>
          )}
          <a href={`${partLink}/${slug}`}>
            <div className="card__image card__image--extra-large">
              {item_image.middle_image ? (
                <div
                  className="card__image-value"
                  style={`background-image: url('${item_image.middle_image}')`}
                />
              ) : (
                <div
                  className={classNames('card__image-value', {
                    'default-bg__motorcycle_middle-default': kind === 'Moto',
                    'default-bg__model-middle-default': kind !== 'Moto',
                  })}
                />
              )}
            </div>
          </a>
        </div>

        <div className="card__content">
          {showUnitTag && (
            <div
              className="card__unit text-left"
              data-match-height="carousel-used-models-features2-card-unit"
            >
              <i className="icon icon-map" />
              <span> {item_unit.name} </span>
            </div>
          )}
          <div className="card__title text-left" data-match-height="title">
            {name}
          </div>
          <div>
            <span className="badge badge-primary mr-2">{`${fabrication_year} / ${model_year}`}</span>
            {shielded && <span className="badge badge--dark">Blindado</span>}
          </div>
        </div>
        <div className="card__footer">
          <div
            className="card__triggers"
            data-vehicle-subscription-price={subscription_price}
          >
            {itemOfferDisplay.secondary_call && (
              <div className="card__trigger">
                <s>{itemOfferDisplay.secondary_call}</s>
              </div>
            )}
            {itemOfferDisplay.main_call && (
              <div className="card__title">
                {itemOfferDisplay.main_call}
              </div>
            )}
            {itemOfferDisplay.featured && (
              <div className="card__trigger-value">
                {itemOfferDisplay.featured}
              </div>
            )}
            <div className="card__compare">
              <CustomCheck
                className="custom-check--card"
                type="checkbox"
                name="compare"
                checkStyle={this.props.disableCheckCompare ? 'disabled' : ''}
                value={slug}
                isChecked={this.props.isCheckedCompare}
                disabled={this.props.disableCheckCompare}
                onChangeCheckable={this.handleCompareCheck}
              >
                Adicionar para comparação
              </CustomCheck>
            </div>
            <a
              href={`${partLink}/${slug}`}
              className="btn button button--block button--large card__cta button--primary"
            >
              Estou interessado
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  item_image: {},
  isCheckedCompare: false,
  disableCheckCompare: false,
  onCompareCheck() {},
  slug: null,
  kind: null,
  name: null,
  subscription_price: null,
  price: null,
  old_price: null,
  item_unit: null,
  showUnitTag: null,
  fabrication_year: null,
  model_year: null,
  shielded: null,
  channelPremiumPrice: null,
};

Card.propTypes = {
  partLink: PropTypes.string.isRequired,
  slug: PropTypes.string,
  item_image: PropTypes.objectOf,
  kind: PropTypes.string,
  name: PropTypes.string,
  item_unit: PropTypes.string,
  showUnitTag: PropTypes.bool,
  fabrication_year: PropTypes.string,
  model_year: PropTypes.string,
  subscription_price: PropTypes.string,
  price: PropTypes.string,
  old_price: PropTypes.string,
  shielded: PropTypes.bool,
  channelPremiumPrice: PropTypes.number,
  isCheckedCompare: PropTypes.bool,
  disableCheckCompare: PropTypes.bool,
  onCompareCheck: PropTypes.func,
};
export class FilterDataCacheHandler {
  constructor(objectStoreName) {
    this._databaseInstance = null;
    this.objectStoreName = objectStoreName;
  }

  async getCachedAPIData(url, skipCache = false) {
    if (skipCache) return null
    if (this._browserDoesNotSupportIndexedDb()) return null;

    try {
      if (!url) throw new Error('Erro ao recuperar cache: URL não fornecida.');

      const result = await this._getCachedData(url);

      return result;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  }

  async saveDataFromAPIToCache(url, apiData, expirationTime) {
    if (this._browserDoesNotSupportIndexedDb()) return false;

    try {
      if (!url || !apiData)
        throw new Error('Erro ao salvar os dados: parâmetros não fornecidos.');

      const wasSaved = await this._saveDataToCache(
        url,
        apiData,
        expirationTime,
      );

      return wasSaved;
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  _browserDoesNotSupportIndexedDb() {
    if (!('indexedDB' in window)) {
      console.warn(
        'Falha ao salvar o cache: esse browser não oferece suporte ao IndexedDB',
      );
      return true;
    }

    return false;
  }

  async _getCachedData(url) {
    if (!url) return null;

    const databaseInstance = await this._getDatabaseInstance();

    return new Promise((resolve, reject) => {
      const objectStore = databaseInstance
        .transaction([this.objectStoreName])
        .objectStore(this.objectStoreName);

      const request = objectStore.get(url);

      request.onerror = event => {
        reject(event.target);
      };

      request.onsuccess = event => {
        const result = event.target.result;

        if (result && result.timestamp >= Date.now()) {
          resolve(result.data);
        } else if (result && result.timestamp < Date.now()) {
          this._deleteDataFromCache(url);
          resolve(null);
        } else {
          resolve(null);
        }
      };
    });
  }

  async _getDatabaseInstance() {
    if (!this._databaseInstance) {
      this._databaseInstance = this._initDB();
    }

    return this._databaseInstance;
  }

  _initDB() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open('autoforceApiCacheDB', 1);

      request.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('requestResult')) {
          db.createObjectStore('requestResult', {
            keyPath: 'url',
          });
        }
      };

      request.onsuccess = event => {
        const db = event.target.result;
        resolve(db);
      };

      request.onerror = event => {
        reject(event.target.error);
      };
    });
  }

  async _deleteDataFromCache(url) {
    if (!url) return null;

    const databaseInstance = await this._getDatabaseInstance();

    return new Promise(resolve => {
      const request = databaseInstance
        .transaction([this.objectStoreName], 'readwrite')
        .objectStore(this.objectStoreName)
        .delete(url);

      request.onsuccess = () => {
        resolve(null);
      };
    });
  }

  async _saveDataToCache(
    url,
    dataToStore,
    expirationTime = 5 * 60 * 1000 + Date.now(),
  ) {
    if (!url || !dataToStore) return false;

    try {
      const databaseInstance = await this._getDatabaseInstance();
      const transaction = databaseInstance.transaction(
        [this.objectStoreName],
        'readwrite',
      );
      const store = transaction.objectStore(this.objectStoreName);

      store.put({
        url,
        data: dataToStore,
        timestamp: expirationTime,
      });

      await transaction.complete;

      return true;
    } catch (error) {
      console.error('Erro ao armazenar dados em cache:', error);
      return false;
    }
  }
}

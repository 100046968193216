import { createStore, createEvent } from 'effector';

export const setFormData = createEvent('set form data');
export const addField = createEvent('add field to data');
export const updateFieldValue = createEvent('update field to data');
export const setFieldError = createEvent('set field error');

const CustomFormFields = (() => {
  let instance;

  return (
    defaultValue = {
      formData: {},
      events: {
        updateFieldValue,
        setFieldError,
      },
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setFormData, (state, formData) => ({
        ...state,
        formData,
      }));

      instance.on(addField, (state, field) => {
        if (!field.key) return state;

        const fieldExists = Object.prototype.hasOwnProperty.call(
          state.formData,
          field.key,
        );

        if (fieldExists) return state;

        const fieldData = {
          value: field.value || null,
          validators: field.validators || [],
          error: field.error || null,
        };

        return {
          ...state,
          formData: { ...state.formData, [field.key]: fieldData },
        };
      });

      instance.on(updateFieldValue, (state, field) => {
        const fieldExists = Object.prototype.hasOwnProperty.call(
          state.formData,
          field.key,
        );
        if (!fieldExists) return null;

        const oldFormData = state.formData;
        const oldFieldData = oldFormData[field.key];
        const updatedFormData = {
          ...oldFormData,
          [field.key]: { ...oldFieldData, value: field.value },
        };

        return {
          ...state,
          formData: { ...updatedFormData },
        };
      });

      instance.on(setFieldError, (state, field) => {
        const fieldExists = Object.prototype.hasOwnProperty.call(
          state.formData,
          field.key,
        );
        if (!fieldExists) return null;

        const oldFormData = state.formData;
        const oldFieldData = oldFormData[field.key];
        const updatedFormData = {
          ...oldFormData,
          [field.key]: { ...oldFieldData, error: field.error },
        };

        return {
          ...state,
          formData: { ...updatedFormData },
        };
      });
    }

    return instance;
  };
})();

export default CustomFormFields;

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp'
import { setMethodApi } from '../../stores/SearchFilter'

class SimpleFilter extends Component {
  constructor(props) {
    super(props)
    
    setMethodApi('getConsortia')
  }

  render () {
    const { filterCategories, sort, showButtonFilter, showActiveStatus, showDropdownIcon } = this.props
    return (
      <SimpleFilterApp 
        fieldKeywordFilter="q[name_cont]"
        fieldCategoryFilter="q[categories_name_in][]"
        fieldSuggestion="title"
        filterCategories={filterCategories}
        classNameButtonFilter="button--line"
        classNameButtonOrder="button--line"
        sort={sort}
        showButtonFilter={showButtonFilter}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
      />
    )
  }
}

SimpleFilter.defaultProps = {
  showDropdownIcon: false,
  showActiveStatus: false,
}

SimpleFilter.propTypes = {
  filterCategories: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    })
  ),
  showButtonFilter: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
}

export default SimpleFilter

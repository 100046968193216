import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp'
import { sortItems } from '%/utils/sort'

class SimpleFilter extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const { filterCategories, sort, classNameButtonFilter, classNameButtonOrder, showDropdownIcon, showActiveStatus } = this.props

    return (
      <SimpleFilterApp
        fieldKeywordFilter="q[name_cont]"
        fieldCategoryFilter="q[categories_name_in][]"
        fieldSuggestion="title"
        filterCategories={filterCategories}
        classNameButtonFilter={classNameButtonFilter}
        classNameButtonOrder={classNameButtonOrder}
        showButtonFilter={false}
        sort={sort}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
        shouldSetURLSearchParams={true}
      />
    )
  }
}

SimpleFilter.defaultProps = {
  sort: sortItems,
  classNameButtonFilter: 'button--line',
  classNameButtonOrder: 'button--line',
  showDropdownIcon: false,
  showActiveStatus: false,
}

SimpleFilter.propTypes = {
  filterCategories: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    })
  ),
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
}

export default SimpleFilter

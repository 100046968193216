/**
 * Ajusta a altura do vídeo
 * @params {String} wrapper - Seletor do wrapper do vídeo
 */
export function adjustsHeight(wrapper) {
  let videoBackgroundElement = $(
    `${wrapper} .swiper-slide--video-slide`
  )
  
  videoBackgroundElement
    .css(
      'height',
      document
        .querySelectorAll(`${wrapper} .swiper-wrapper`)[0]
        .getBoundingClientRect().height + 'px'
    )
}

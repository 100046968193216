const parseCurrency = (currency) => (
    currency ? parseInt(currency
        .substring(0, currency.indexOf(','))    // Remove tudo depois da vírgula
        .replace(/\D/g, '')                     // Remove todos os caractéres, exceto por números
    ) : ''
);

/*
*  Ajusta os parâmetros para que fiquem no formato aceito pelo zFlow
*/
const parseParams = (params) => {
    const [areaCode, number] = params.phone.split(' ').map(part => part.replace(/\D/g, ''));
    let phone = {
        areaCode,
        number,
    };

    const product = params.product
                        .replace(/\B(?=[A-Z])|\s*\d{4}\b/g, '').trim();
    const version = params.version
                        .replace(params.product, '')
                        .replace(/\B(?=[A-Z])|\s*\d{4}\b/g, '').trim();
    const model = `${product} ${version}`;

    return {
        ...params,
        phone,
        model,
        modelYear: parseInt(params.modelYear),
        months: parseInt(params.months),
        price: parseCurrency(params.price),
        upfront: parseCurrency(params.upfront),
        cpf: params.cpf ? params.cpf.replace(/[^\w\s]/gi, '') : '',
    }
}

/* 
* Retorna modelYear e price, que são passados via data pela view.
*/
const getDataParams = () => {
    return document.getElementById('zflow-container').dataset;
}

/*
* Se a unidade selecionada tiver um CNPJ, ele é retornado. Caso
* contrário, retorna o CNPJ cadastrado na integração. 
*/
const getCNPJ = (unit) => {
    const unitsCNPJ = JSON.parse(document.querySelector('meta[name="zflow_units_cnpj"]').content);
    const sellerCNPJ = document.querySelector('meta[name="zflow_seller"]').content;

    let selectedCNPJ = unitsCNPJ[unit] || sellerCNPJ;
    selectedCNPJ = selectedCNPJ.replace(/[^\w\s]/gi, '');
    return selectedCNPJ; 
}

/*
* Retorna os parãmetros de configuração do zFlow, que são inseridos no Autódromo e injetados
* na view por meio de meta tags.
*/
const getConfigParams = (unit) => {
    return {
        client: document.querySelector('meta[name="zflow_client"]').content,
        from: document.querySelector('meta[name="zflow_from"]').content,
        vehicleSellerDocument: getCNPJ(unit),
    }
}

/*
*  Carrega o script do Assistente Digital e monta o iframe
*/
const injectZFlow = () => {
    (function (d, t) {
        var zf = d.createElement(t), s = d.getElementsByTagName(t)[0];
        zf.async = true;
        zf.src = "https://channels-assistants-vehicle.itau.com.br/static/zflow.js";
        s.parentNode.insertBefore(zf, s);
    })(document, 'script');

    setTimeout(() => {
        document.getElementById(window.zflowData.elem).scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }, 3500);
};

export const zFlow = (params) => {
    const allParams = {
        ...params,
        ...getDataParams()
    };

    const {
        name, email, phone, cpf,
        model, brand, modelYear, price,
        unit, upfront, months
    } = parseParams(allParams);

    const { client, from, vehicleSellerDocument } = getConfigParams(unit);

    window.zflowData = {
        disableScroll: true,
        elem: 'zflow-container',
        client,
        from,
        transaction: {
            client: {
                name,
                cpf,
                email,
                personalPhones: [{
                    countryCode: '55',
                    areaCode: phone.areaCode,
                    number: phone.number,
                    // MOBILE = Celular, LANDLINE = Fixo
                    phoneType: (parseInt(phone.number[0]) >= 7 ? 'MOBILE' : 'LANDLINE')
                }],
            },
            operationRequest: {
                vehicleSellerType: 'LEGAL_PERSON',
                vehicleSellerDocument,
                vehicleMake: brand,
                vehicleModel: model,
                vehicleModelYear: modelYear,
                vehicleManufactureYear: modelYear,
                vehicleValue: price,
                installments: months,
                // Flag para veículos novos. A integração só existe para novos, então deve ser sempre true
                newVehicle: true,
            },
        }
    }
    
    injectZFlow();
};

import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import ItemPlaceholder from './ItemPlaceholder';
import ButtonLoadMore from './ButtonLoadMore';

class List extends Component {
  componentDidUpdate() {
    window.matchHeight.match('.list-direct-sales-vehicles');
  }

  componentDidMount() {
    window.matchHeight.match('.list-direct-sales-vehicles');
  }

  render() {
    const {
      items,
      part_link,
      loading,
      loadingMore,
      showLoadMore,
      onClickButtonLoadMore,
      ctaButtonText,
    } = this.props;

    const channelPackElement = document.querySelector("meta[name='channel_pack']")
    const channel_pack = channelPackElement && channelPackElement.content;
    const columnMd = "col-md-6";
    const columnLg = channel_pack == 'showroom_fiat' ? "col-lg-3" : "col-lg-4";

    return (
      <div class="list-direct-sales-vehicles__list">
        <div class="row">
          {items.map(item => (
            <div class={`${columnMd} ${columnLg} list-direct-sales-vehicles__col-card`}>
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  slug={item.slug}
                  image={item.item_image && item.item_image.middle_image}
                  title={item.title}
                  subtitle={item.subtitle}
                  seal_list={item.seal_list}
                  secondary_call={
                    item.item_offer && item.item_offer.secondary_call
                  }
                  main_call={item.item_offer && item.item_offer.main_call}
                  featured={item.item_offer && item.item_offer.featured}
                  description={item.item_offer && item.item_offer.description}
                  extra={item.item_offer && item.item_offer.extra}
                  badge={item.item_offer && item.item_offer.badge}
                  part_link={part_link}
                  brand={item.brand.name}
                  ctaButtonText={ctaButtonText}
                />
              )}
            </div>
          ))}
        </div>
        {showLoadMore && (
          <div>
            <hr />
            <ButtonLoadMore
              onClick={onClickButtonLoadMore}
              loading={loadingMore}
            />
          </div>
        )}
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false,
  loadingMore: false,
  onClickButtonLoadMore() { }
};

List.propTypes = {
  items: PropTypes.array,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  part_link: PropTypes.string,
  onClickButtonLoadMore: PropTypes.func,
  ctaButtonText: PropTypes.string,
};

export default List;

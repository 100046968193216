import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { incrementList, nextPage } from '../../stores/SearchFilter'

class ButtonLoadMore extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { onClick } = this.props

    if (onClick) {
      this.props.onClick()
    }

    nextPage()
    incrementList()
  }

  render() {
    const { loading } = this.props

    let contentButton

    if (loading) {
      contentButton = (
        <div>
          <div className="ml-2 spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
          &nbsp; Carregando...
        </div>
      )
    } else {
      contentButton = (
        <span className="parts-button-load-more__text">Carregar mais Ofertas</span>
      )
    }

    return (
      <div className="parts-button-load-more container text-center">
        <button className="list-parts__btn-load-more btn button button--dark button--large button--block d-md-none" onClick={this.handleClick}>
          { contentButton }
        </button>
        <button className="list-parts__btn-load-more btn button button--large button--primary-line d-none d-md-inline" onClick={this.handleClick}>
          { contentButton }
        </button>
      </div>
    )
  }
}

ButtonLoadMore.defaultProps = {
  loading: false
}

ButtonLoadMore.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.boll
}

export default ButtonLoadMore

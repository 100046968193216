import fetchival from 'fetchival';

/**
 * @param {string} fullUrl Url da request concatenada com os parâmetros
 * @param {string} token Token para autorizar as request no Autódromo
 * @return {promise}
 */
export function fetchWrapper(fullUrl, token) {
  return fetchival
    .fetch(fullUrl, {
      method: 'get',
      headers: {
        Authorization: `Token token=${token}`,
      },
    })
    .then(res => res.json());
}

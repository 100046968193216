import { createStore, createEffect, createEvent } from 'effector';
import { setURLSearchParams } from '%/utils/filters';
import autoServiceApi from '../utils/autoServiceApi';
import { autoServiceApiV2 } from '../services/autoServiceApiV2/index';

const DEFAULT_FILTERS = {
  getUsedModels: () => {
    const { showNewVehicle, showOnlyNewVehicle } =
      window.store.ListUsedModels.getState();
    const customParams = {};

    if (showNewVehicle === false) {
      customParams['q[new_vehicle_eq]'] = showNewVehicle;
    }

    if (showOnlyNewVehicle === true) {
      customParams['q[new_vehicle_eq]'] = showOnlyNewVehicle;
    }

    return customParams;
  },
};

const fetchList = () => {
  const {
    filter,
    filterArray,
    sort,
    page,
    per_page,
    active,
    methodApi,
    apiVersion,
  } = window.store.SearchFilter.getState();
  const activeFilter = {
    v1: { 'q[active_eq]': active },
    v2: { active },
  };

  let customParams = [
    { sort },
    { page },
    { per_page },
    activeFilter[apiVersion],
    ...filterArray,
  ];

  let allFilters = {};

  if (DEFAULT_FILTERS[methodApi]) {
    allFilters = { ...allFilters, ...DEFAULT_FILTERS[methodApi]() };
  }

  allFilters = { ...allFilters, ...filter };

  // eslint-disable-next-line no-restricted-syntax
  for (const key in allFilters) {
    if (allFilters[key] !== undefined && allFilters[key] !== '')
      customParams.push({ [key]: allFilters[key] });
  }

  // adiciona o parâmetro para não exibir veículos em trânsito por default
  const hasInTransitParam = customParams.find(
    param => 'q[in_transit_eq_or_nil]' in param,
  );
  if (!hasInTransitParam && methodApi === 'getUsedModels') {
    customParams = [...customParams, { 'q[in_transit_eq_or_nil]': false }];
  }

  const serviceApi = apiVersion === 'v2' ? autoServiceApiV2 : autoServiceApi;

  return serviceApi[methodApi](customParams);
};

export const setList = createEffect('set list').use(fetchList);
export const incrementList = createEffect('increment list').use(fetchList);
export const setSuggestions = createEffect('set suggestions').use(fetchList);

export const setKeyword = createEvent('set keyword');
export const setFilter = createEvent('set filter');
export const addFilter = createEvent('add filter');
export const removeFilter = createEvent('remove filter');
export const incrementFilter = createEvent('increment filter');
export const resetFilter = createEvent('reset filter');
export const resetList = createEvent('reset List');
export const setTotalPages = createEvent('set total_pages');
export const setTotal = createEvent('set total');
export const nextPage = createEvent('next page');
export const setPage = createEvent('set page');
export const setPerPage = createEvent('set per_page');
export const setSort = createEvent('set sort');
export const setMethodApi = createEvent('set method api');
export const setApiVersion = createEvent('set api version');
export const setShowResults = createEvent('set show results');
export const setShowFilter = createEvent('set show filter');
export const setShowConversionForm = createEvent('set show conversion form');
export const setShowList = createEvent('set show list');
export const clearFilterObject = createEvent('clearFilterObject');

export const clearFilters = () => {
  removeFilter('q[model_category_in][]');
  removeFilter('q[name_in][]');
  removeFilter('q[models_name_in][]');
  removeFilter('q[categories_name_in][]');
  setSort('ordination,-created_at');
  clearFilterObject();
  setKeyword('');

  setList().then(() => {
    setURLSearchParams({}, [], {});
  });
};

const SearchFilter = (() => {
  let instance;

  return (
    defaultValue = {
      methodApi: '', // Nome do método definido em autoServiceApi
      apiVersion: 'v1',
      keyword: '',
      lastKeyword: '',
      sort: 'ordination,-created_at',
      filter: {}, // filtro no formato de objeto: { keyFilter: value }
      filterArray: [], // filtro no formato de array: [ { keyFilter: value } ]
      list: [],
      suggestions: [],
      loading: false,
      loadingMore: false,
      loadInitial: false,
      page: 1,
      total_pages: null,
      per_page: 12,
      total: 0,
      active: true,
      showResults: false,
      showFilter: false,
      showOrder: false,
      showConversionForm: false,
      showList: true,
      showFiltersCleaner: false,
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setMethodApi, (state, methodApi) => ({
        ...state,
        methodApi,
      }));

      instance.on(setApiVersion, (state, apiVersion) => ({
        ...state,
        apiVersion,
      }));

      instance.on(setShowResults, (state, showResults) => ({
        ...state,
        showResults,
      }));

      instance.on(setKeyword, (state, keyword) => ({
        ...state,
        keyword,
      }));

      instance.on(setSort, (state, sort) => ({
        ...state,
        sort,
        filter: {
          ...state.filter,
          sort,
        },
        page: 1,
      }));

      instance.on(setFilter, (state, filter) => ({
        ...state,
        filter,
      }));

      instance.on(addFilter, (state, filter) => ({
        ...state,
        filter: {
          ...state.filter,
          ...filter,
        },
      }));

      instance.on(removeFilter, (state, key) => {
        const filterArray = state.filterArray.filter(element => {
          let hasDifferentKey = false;

          // eslint-disable-next-line no-restricted-syntax
          for (const keyFilter in element) {
            if (keyFilter !== key) {
              hasDifferentKey = true;
            }
          }

          return hasDifferentKey;
        });

        return {
          ...state,
          filterArray,
        };
      });

      instance.on(clearFilterObject, state => ({
        ...state,
        filter: {},
      }));

      instance.on(incrementFilter, (state, filter) => ({
        ...state,
        filterArray: [...state.filterArray, filter],
      }));

      instance.on(resetFilter, state => ({
        ...state,
        filterArray: [],
        filter: {},
        keyword: '',
        lastKeyword: '',
      }));

      instance.on(nextPage, state => ({
        ...state,
        page: state.page + 1,
      }));

      instance.on(setPage, (state, page) => ({
        ...state,
        page,
      }));

      instance.on(setPerPage, (state, per_page) => ({
        ...state,
        per_page,
      }));

      instance.on(setTotalPages, (state, total_pages) => ({
        ...state,
        total_pages,
      }));

      instance.on(setTotal, (state, total) => ({
        ...state,
        total,
      }));

      instance.on(resetList, (state, list = []) => ({
        ...state,
        page: 1,
        list,
      }));

      instance.on(setSuggestions.done, (state, res) => ({
        ...state,
        suggestions: res.result.entries,
      }));

      instance.on(setShowConversionForm, (state, showConversionForm) => ({
        ...state,
        showConversionForm,
      }));

      instance.on(setShowFilter, (state, showFilter) => ({
        ...state,
        showFilter,
      }));

      instance.on(setShowList, (state, showList) => ({
        ...state,
        showList,
      }));

      // setList
      instance.on(setList.pending, (state, loading) => ({
        ...state,
        page: 1,
        loading,
      }));

      instance.on(setList.fail, state => ({
        ...state,
        loading: false,
      }));

      instance.on(setList.done, (state, res) => ({
        ...state,
        list: res.result.entries,
        total: res.result.total,
        showList: res.result.total > 0,
        showConversionForm: res.result.total === 0,
        total_pages: res.result.total_pages,
        loading: false,
        loadInitial: true,
        lastKeyword: state.keyword,
      }));

      // incrementList
      instance.on(incrementList.pending, (state, loading) => ({
        ...state,
        loading,
        loadingMore: loading,
      }));

      instance.on(incrementList.done, (state, res) => ({
        ...state,
        list: [...state.list, ...res.result.entries],
        total: res.result.total,
        showList: res.result.total > 0,
        showConversionForm: res.result.total === 0,
        total_pages: res.result.total_pages,
        loading: false,
        loadingMore: false,
        loadInitial: true,
      }));

      instance.on(incrementList.fail, state => ({
        ...state,
        loading: false,
        loadingMore: false,
        page: state.page - 1,
      }));
    }

    return instance;
  };
})();

export default SearchFilter;

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import wNumb from 'wnumb'
import { addClassOpen as addClassOpenFilter } from '../SearchFilterSidebar'

const totalFormat = wNumb({
  thousand: '.'
})

export default class Result extends Component {
  constructor(props) {
    super(props)

    this.handleFilterClick = this.handleFilterClick.bind(this)
  }

  handleFilterClick(e) {
    e.preventDefault()

    addClassOpenFilter()
  }

  render() {
    return (
      <div className="list-pagination__result">
        <h2 className="list-pagination__result-title">
          Encontramos <strong className="list-pagination__result-info"><span className="list-pagination__result-number">{totalFormat.to(this.props.total)}</span> {this.props.totalLabel}</strong> a partir dos filtros que você selecionou.
        </h2>
        <h3 className="list-pagination__result-subtitle d-none d-lg-block">
          Selecione entre dois e três veículos para comparar.
        </h3>
        <button
          className="btn button button--primary button--large list-pagination__button-filter d-lg-none w-100"
          onClick={this.handleFilterClick}
        >
          Filtro Avançado
        </button>
      </div>
    )
  }
}

Result.defaultProps = {
  total: 0,
  totalLabel: 'seminovos',
}

Result.propTypes = {
  total: PropTypes.number,
  totalLabel: PropTypes.string,
}

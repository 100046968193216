import { Component } from 'preact';
import PropTypes from 'prop-types';
import CardUsedModel from './CardUsedModel';

export default class List extends Component {
  constructor(props) {
    super(props);

    this.handleComparaCheck = this.handleComparaCheck.bind(this);
  }

  isCheckedCompare({ slug }) {
    return this.props.checkedCards.includes(slug);
  }

  disableCheck() {
    return this.props.checkedCards.length >= 3;
  }

  disableCheckCard({ slug }) {
    if (this.disableCheck()) {
      if (!this.props.checkedCards.includes(slug)) {
        return true;
      }
    }

    return false;
  }

  handleComparaCheck(e) {
    this.props.onCompareCheck(e);
  }

  render() {
    const { items, partLink, channelPremiumPrice, showUnitTag } = this.props;
    return (
      <div className="list-pagination__list pr-0 pl-0">
        <div
          className="row align-items-stretch mr-0 ml-0"
          style={{ gap: '32px' }}
        >
          {items.map(item => (
            <div className="list-pagination__card">
              <CardUsedModel
                {...item}
                partLink={partLink}
                showUnitTag={showUnitTag}
                channelPremiumPrice={channelPremiumPrice}
                disableCheckCompare={this.disableCheckCard(item)}
                isCheckedCompare={this.isCheckedCompare(item)}
                onCompareCheck={this.handleComparaCheck}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  onCompareCheck() {},
  checkedCards: [],
};

List.propTypes = {
  items: PropTypes.array,
  partLink: PropTypes.string.isRequired,
  showUnitTag: PropTypes.bool,
  channelPremiumPrice: PropTypes.number,
  /**
   * Cards com checkbox marcados
   */
  checkedCards: PropTypes.array,
  onCompareCheck: PropTypes.func,
};

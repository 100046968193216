// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp';
import {
  setPage,
  setPerPage,
  setApiVersion,
  setMethodApi,
} from '../../stores/SearchFilter';

class SimpleFilter extends Component {
  constructor(props) {
    super(props);

    setPage(1);
    setPerPage(-1);
    setApiVersion(this.props.apiVersion);
    setMethodApi('getClones');
  }

  render() {
    const {
      filterCategories,
      fieldCategoryFilter,
      classNameButtonFilter,
      classNameButtonOrder,
      showDropdownIcon,
      showActiveStatus,
    } = this.props;
    return (
      <SimpleFilterApp
        filterCategories={filterCategories}
        fieldCategoryFilter={fieldCategoryFilter}
        classNameButtonFilter={classNameButtonFilter}
        classNameButtonOrder={classNameButtonOrder}
        showButtonFilter={!!filterCategories}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
        shouldSetURLSearchParams
      />
    );
  }
}

SimpleFilter.defaultProps = {
  fieldCategoryFilter: 'q[model_category_in][]',
  classNameButtonFilter: 'button--line',
  classNameButtonOrder: 'button--line',
  apiVersion: 'v1',
  showDropdownIcon: false,
  showActiveStatus: false,
};

SimpleFilter.propTypes = {
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fieldCategoryFilter: PropTypes.string,
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  apiVersion: PropTypes.string,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
};

export default SimpleFilter;

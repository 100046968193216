import { isPlainObject } from "lodash";
import ProfileImageTypes from "./variantProfileImageTypes";

/**
 * Representa um item variante genérico.
 */
export default class VariantItem {
  constructor(variantObject) {
    if (!isPlainObject(variantObject)) {
      throw `variantObject precisa ser um objeto plano.`;
    }

    this._variant = variantObject;
  }

  /**
   *
   * @param {string} field A propriedade a ser obtida da variante
   * @param {*} fallback
   * @return {*|undefined}
   */
  getVariantProperty(field, fallback = undefined) {
    return this._variant[field] || fallback;
  }

  /**
   * Obtém as imagens da variante
   * @return {object|null}
   */
  get profileImages() {
    let result = null;
    try {
      result = this._variant.profile_image;

      if (Object.keys(result).includes('profile_image')) {
        result = result.profile_image;
      }
    } catch (e) {}

    return result;
  }

  /**
   * Obtém o preço da variante como vem do webservice.
   * @return {string|null}
   */
  get price() {
    return this._variant.price;
  }

  /**
   * Obtém o preço da variante no formato de número.
   * @return {number}
   */
  get priceAsNumber() {
    return parseFloat(this.price.replace(/\./g, "").replace(/,/, "."));
  }

  /**
   * Obtém a URL de uma imagem de veriante baseado no tipo.
   * @param {ProfileImageTypes} type O tipo de imagem a ser obtida
   * @return {string|null}
   */
  getProfileImageOfType(type) {
    const images = this.profileImages;
    return images && images.hasOwnProperty(type) && images[type].url
      ? images[type].url
      : null;
  }

  /**
   * Obtém um objeto literal para ser armazenado na store
   */
  activeVersionObject() {
    return {
      slug: this.getVariantProperty('slug', ''),
      fabrication_year: this.getVariantProperty('fabrication_year', 0),
      model_year: this.getVariantProperty('model_year', 0),
      price_value: this.getVariantProperty('price_value', 0),
      fipe_id: this.getVariantProperty('fipe_id', undefined),
      molicar_id: this.getVariantProperty('molicar_id', undefined),
      detail_list: this.getVariantProperty('detail_list', []),
      comfort_list: this.getVariantProperty('comfort_list', []),
      item_list: this.getVariantProperty('item_list', []),
      connectivity_list: this.getVariantProperty('connectivity_list', []),
      clone_id: this.getVariantProperty('clone_id', undefined),
      name: this.getVariantProperty('name', ''),
      item_image: this.getVariantProperty('item_image', ''),
      price: this.getVariantProperty('price', ''),
    };
  }
}

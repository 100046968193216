export function pushDataToDataLayer({ elementWithDataSelector, dataTitle, attributes }) {
  const el = document.querySelector(elementWithDataSelector);
  if (!el) return;

  const product = {};

  attributes.forEach(attribute => {
    const attributeData = el.dataset[attribute];
    if (attributeData) Object.assign(product, { [attribute]: attributeData })
  })

  dataLayer && dataLayer.push({
    [dataTitle]: product
  })
}

import { Component } from 'preact';
import { querys } from '%/utils/filters';
import classNames from 'classnames';
import IMask from 'imask';
import PropTypes from 'prop-types';
import { priceMask } from '%/utils/masks';
import { uniqByKeepFirst } from '%/utils/uniqByKeepFirst';
import CustomSelect from '../CustomSelect';
import CustomCheck from '../CustomCheck';

class AdvanceFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      disableSlectModels: true,
    };

    window.store.SearchFilter.watch(state => {
      const filter = {
        ...state.filter,
      };

      // adiciona a máscara
      if (this.maskInputMinPrice && filter[querys.min_price]) {
        this.maskInputMinPrice.value = filter[querys.min_price];
        filter[querys.min_price] = this.maskInputMinPrice.masked.value;
      }

      if (this.maskInputMaxPrice && filter[querys.max_price]) {
        this.maskInputMaxPrice.value = filter[querys.max_price];
        filter[querys.max_price] = this.maskInputMaxPrice.masked.value;
      }

      this.setState({
        filter,
      });
    });

    window.store.ListUsedModels.watch(state => {
      this.setState({
        showFilter: state.showFilterAdvanceMobile,
      });
    });

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectChangeBrands = this.handleSelectChangeBrands.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.maskInputMinPrice = IMask(this.inputMinPrice, priceMask);
    this.maskInputMaxPrice = IMask(this.inputMaxPrice, priceMask);
  }

  handleCheckboxChange(e) {
    const { name } = e.target;
    const value = e.target.checked ? e.target.checked : false;
    this.props.in_transit = value;
    this.setState(previousState => ({
      filter: {
        ...previousState.filter,
        [name]: value,
      },
    }));
  }

  handleSelectChange(e) {
    const { value, name } = e.target;

    this.setState(previousState => ({
      filter: {
        ...previousState.filter,
        [name]: value,
      },
    }));
  }

  handleSelectChangeBrands(e) {
    const { value, name } = e.target;

    if (value) {
      this.setState(previousState => ({
        disableSlectModels: false,
        filter: {
          ...previousState.filter,
          [querys.model]: '',
        },
      }));
    } else {
      this.setState(previousState => ({
        disableSlectModels: true,
        filter: {
          ...previousState.filter,
          [querys.model]: '',
        },
      }));
    }

    this.setState(previousState => ({
      filter: {
        ...previousState.filter,
        [name]: value,
      },
    }));
  }

  handleClose() {
    this.props.onClose();
  }

  handleSearch() {
    this.props.onSearchClick({
      ...this.state.filter,
      [querys.min_price]: this.maskInputMinPrice.unmaskedValue,
      [querys.max_price]: this.maskInputMaxPrice.unmaskedValue,
      [querys.in_transit]: this.state.filter[querys.in_transit] || false,
    });
  }

  handleReset(e) {
    e.preventDefault();
    this.props.onReset();
  }

  /**
   * Opções para o select de modelos
   */
  get optionsModels() {
    const brandValue = this.state.filter[querys.brand];
    return this.props.filterModels[brandValue] || [];
  }

  get brands() {
    const uniqBrands = uniqByKeepFirst(this.props.brands, brand => brand.label);
    return uniqBrands;
  }

  render() {
    const { showFilter } = this.state;

    return (
      <div
        className={classNames('d-none', 'advance-filter-modal', {
          'd-flex': showFilter,
        })}
      >
        <div className="advance-filter-modal__container">
          <header className="advance-filter-modal__header">
            <span className="advance-filter-modal__title">
              FILTRAR PESQUISA
            </span>
            <i
              className="advance-filter-modal__close modal__close icon icon-close"
              onClick={this.handleClose}
              onKeyDown={this.handleClose}
              role="button"
              aria-label="Fechar"
              tabIndex={0}
            />
          </header>
          <div className="advance-filter-modal__body">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleSelectChangeBrands}
                      name={querys.brand}
                      placeholderValue="Marca"
                      options={this.brands}
                      value={this.state.filter[querys.brand]}
                      shouldSort={false}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleSelectChange}
                      name={querys.model}
                      placeholderValue="Modelo"
                      options={this.optionsModels}
                      value={this.state.filter[querys.model]}
                      disable={this.state.disableSlectModels}
                      shouldSort={false}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name={querys.keywordUsedModel}
                      className="form-control"
                      onChange={this.handleSelectChange}
                      placeholder="Palavra-chave. Ex: 1.4 8V, Flex, Auto ..."
                      value={this.state.filter[querys.keywordUsedModel]}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      ref={node => {
                        this.inputMinPrice = node;
                      }}
                      type="text"
                      name={querys.min_price}
                      className="form-control"
                      onChange={this.handleSelectChange}
                      placeholder="Preço Mínimo"
                      value={this.state.filter[querys.min_price]}
                      maxLength="14"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      ref={node => {
                        this.inputMaxPrice = node;
                      }}
                      type="text"
                      name={querys.max_price}
                      className="form-control"
                      onChange={this.handleSelectChange}
                      placeholder="Preço Máximo"
                      value={this.state.filter[querys.max_price]}
                      maxLength="14"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleSelectChange}
                      name={querys.year_begin}
                      placeholderValue="Ano Mínimo"
                      options={this.props.yearBegin}
                      value={this.state.filter[querys.year_begin]}
                      shouldSort={false}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleSelectChange}
                      name={querys.year_end}
                      placeholderValue="Ano Máximo"
                      options={this.props.yearEnd}
                      value={this.state.filter[querys.year_end]}
                      shouldSort={false}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleSelectChange}
                      name={querys.min_km}
                      placeholderValue="Km Mínima"
                      options={this.props.km}
                      value={this.state.filter[querys.min_km]}
                      shouldSort={false}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={this.handleSelectChange}
                      name={querys.max_km}
                      placeholderValue="Km Máxima"
                      options={this.props.km}
                      value={this.state.filter[querys.max_km]}
                      shouldSort={false}
                    />
                  </div>
                </div>

                {this.props.units.length > 1 && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <CustomSelect
                        handleSelectChange={this.handleSelectChange}
                        name={querys.unit}
                        options={this.props.units}
                        value={this.state.filter[querys.unit]}
                        placeholderValue="Selecione uma unidade"
                        shouldSort
                        searchEnabled
                      />
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <CustomCheck
                      className="custom-check--align-left"
                      checkStyle="check"
                      type="checkbox"
                      name={querys.in_transit}
                      isChecked={this.state.filter[querys.in_transit]}
                      onChangeCheckable={this.handleCheckboxChange}
                    >
                      Somente em trânsito (sem foto)
                      <span className="info-element">
                        <i className="icon icon-info" />
                        <span className="info-text">
                          O veículo está em processo de transporte para o
                          estoque da concessionária.
                        </span>
                      </span>
                    </CustomCheck>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="advance-filter-modal__footer">
            <button
              type="button"
              className="advance-filter-modal__link advance-filter-modal__clear clear-fields"
              onClick={this.handleReset}
            >
              Limpar filtros
            </button>
            <button
              type="submit"
              className="advance-filter-modal__search btn button button--large button--primary button--icon-right"
              onClick={this.handleSearch}
            >
              BUSCAR <i className="icon icon-search-b" />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

AdvanceFilterModal.defaultProps = {
  km: [
    { label: '0 Km', value: 0 },
    { label: '5.000 Km', value: 5000 },
    { label: '10.000 Km', value: 10000 },
    { label: '20.000 Km', value: 20000 },
    { label: '30.000 Km', value: 30000 },
    { label: '40.000 Km', value: 40000 },
    { label: '60.000 Km', value: 60000 },
    { label: '80.000 Km', value: 80000 },
    { label: '100.000 Km', value: 100000 },
    { label: '120.000 Km', value: 120000 },
    { label: '140.000 Km', value: 140000 },
    { label: '160.000 Km', value: 160000 },
    { label: '180.000 Km', value: 180000 },
    { label: '200.000 Km', value: 200000 },
    { label: '250.000 Km', value: 250000 },
    { label: '300.000 Km', value: 300000 },
    { label: '400.000 Km', value: 400000 },
    { label: '500.000 Km', value: 500000 },
  ],
  brands: [],
  filterModels: {},
  yearBegin: [],
  yearEnd: [],
  units: [],
  in_transit: false,
  onReset() {},
  onClose() {},
  onSearchClick() {},
};

AdvanceFilterModal.propTypes = {
  onSearchClick: PropTypes.func,
  brands: PropTypes.arrayOf,
  filterModels: PropTypes.objectOf,
  yearBegin: PropTypes.arrayOf,
  yearEnd: PropTypes.arrayOf,
  km: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  onReset: PropTypes.func,
  in_transit: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AdvanceFilterModal;

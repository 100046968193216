// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import ItemV2 from './ItemV2';
import ItemPlaceholder from '../NewVehicle/ItemPlaceholder';
import ButtonLoadMore from '../NewVehicle/ButtonLoadMore';

class ListV2 extends Component {
  componentDidMount() {
    window.matchHeight.match('.list-vehicles-new__list');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-vehicles-new__list');
  }

  render() {
    const {
      items,
      cardLink,
      loading,
      loadingMore,
      showLoadMore,
      onClickButtonLoadMore,
      channelShowPrice
    } = this.props;

    return (
      <div className="list-vehicles-new__list">
        <div className="row">
          {items.map((item, index) => (
            <div className="col-6 col-md-4 col-lg-3 mb-4" key={item.slug}>
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <ItemV2
                  model={item.model}
                  brand={item.brand}
                  year={item.year}
                  release={item.release}
                  slug={item.slug}
                  image={item.profile_image}
                  name={item.name}
                  subtitle={item.subtitle}
                  show_price={channelShowPrice && item.show_price}
                  price={item.price}
                  cardLink={cardLink}
                  isOnFirstLine={index <= 3}
                />
              )}
            </div>
          ))}
        </div>
        {showLoadMore && (
          <div>
            <hr />
            <ButtonLoadMore
              onClick={onClickButtonLoadMore}
              loading={loadingMore}
            />
          </div>
        )}
      </div>
    );
  }
}

ListV2.defaultProps = {
  items: [],
  showLoadMore: false,
  loading: false,
  loadingMore: false,
  onClickButtonLoadMore() {},
};

ListV2.propTypes = {
  items: PropTypes.arrayOf,
  loadingMore: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  cardLink: PropTypes.string,
  onClickButtonLoadMore: PropTypes.func,
};

export default ListV2;

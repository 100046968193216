import MainUsedVehiclesCarousel from '../auto/components/Carousels/UsedVehicles/MainUsedVehiclesCarousel';
import NewVehiclesLazyV2Carousel from '../auto/components/Carousels/NewVehicles/NewVehiclesLazyV2';
import SimpleFilterNewVehicleV2 from '../auto/components/NewVehicleV2/SimpleFilterV2';

(() => {
  const components = {
    MainUsedVehiclesCarousel,
    NewVehiclesLazyV2Carousel,
    SimpleFilterNewVehicleV2,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();

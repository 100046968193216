import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import DirectSalesFormApp from '#/DirectSalesFormApp';
import MicroModal from 'micromodal';

export default class DirectSalesFormModal extends Component {
  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);
  }

  componentDidMount() {
    if (this.props.open) {
      this.modal = MicroModal.show(this.props.modalId);
    }
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.modal = MicroModal.show(this.props.modalId);
    } else {
      this.modal = MicroModal.close(this.props.modalId);
    }
  }

  renderForm() {
    const { product } = this.props;

    return (
      <DirectSalesFormApp
        formId={this.props.modalId}
        product={product}
        category={this.props.category}
        brand={this.props.brand}
        link={this.props.link}
        units={this.props.units}
        showUnits={this.props.showUnits}
        showCpf={this.props.showCpf}
        shouldShowDataPermissions={this.props.shouldShowDataPermissions}
        dataPermissionsCustomText={this.props.dataPermissionsCustomText}
        linkPrivacyPolicy={this.props.linkPrivacyPolicy}
        versions={this.props.versions}
      />
    );
  }

  render() {
    return (
      <div
        class="modal micromodal-slide"
        id={this.props.modalId}
        aria-hidden="true"
      >
        <div class="modal__overlay" tabindex="-1" data-micromodal-close>
          <div className="position-relative">
            <i
              className="modal__close modal__overlay-close--form icon icon-close"
              data-micromodal-close
            />
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

DirectSalesFormModal.defaultProps = {
  modalId: 'modal-direct-sales',
  open: false,
  brand: '-',
  product: 'Direct Sales',
  units: [],
  showUnits: true,
  showCpf: false,
  shouldShowDataPermissions: false,
};

DirectSalesFormModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  product: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  link: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string
};

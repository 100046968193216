import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import Item from './Item';
import ButtonLoadMore from './ButtonLoadMore';
import ItemPlaceholder from './ItemPlaceholder';

class List extends Component {
  componentDidMount() {
    window.matchHeight.match('.list-used-models');
  }

  componentDidUpdate() {
    window.matchHeight.match('.list-used-models');
  }

  render() {
    const { items, onClickButtonLoadMore, loading, loadingMore, part_link } =
      this.props;

    return (
      <div class="list-used-models__list">
        <div class="row">
          {items.map((item, index) => (
            <div class="col-md-6 col-lg-3 mb-4">
              {loading && !loadingMore ? (
                <ItemPlaceholder />
              ) : (
                <Item
                  item_image={item.item_image}
                  model={item.model}
                  year={item.fabrication_year}
                  slug={item.slug}
                  kind={item.kind}
                  badge={item.badge}
                  title={item.title}
                  subtitle={item.subtitle}
                  brand={item.brand}
                  item_offer={item.item_offer}
                  part_link={part_link}
                  seal_list={item.seal_list}
                  km={item.km}
                  fuel_text={item.fuel_text}
                  exchange={item.exchange}
                  isOnFirstPage={index < 4}
                />
              )}
            </div>
          ))}
        </div>
        <hr />
        {this.props.showLoadMore && (
          <ButtonLoadMore
            onClick={onClickButtonLoadMore}
            loading={loadingMore}
          />
        )}
      </div>
    );
  }
}

List.defaultProps = {
  items: [],
  loadingMore: false,
};

List.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  part_link: PropTypes.string,
};

export default List;

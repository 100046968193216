import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from './CustomSelect';
import CustomCheck from './CustomCheck';
import FormMessageOverlay from './FormMessageOverlay';
import SubmitButton from './SubmitButton';
import getUTM from '%/utils/utm';
import handleInputChange from '%/utils/handleInputChange';
import validationFactory from '%/utils/validation';
import IMask from 'imask';
import {
  phoneMasks,
  plateMasks,
  kmMask,
  dateMasks
} from '%/utils/masks';
import classNames from 'classnames';
import InputCpf from './InputCpf';
import LocationFields from './Inputs/Location';

export default class ScheduleServiceConversionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      email: null,
      km: '',
      model_car: '',
      name: null,
      phone: null,
      plate: '',
      type_service: null,
      utmz: getUTM(),
      mailing: false,
      phoning: false,
      whatsapping: false,
      contactOptions: '',
      year: '',
      cpf: '',
      versionValidStatus: true,
      dataPermissions: false,
      uf: null,
      city: null,
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContactOptionsChange = this.handleContactOptionsChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }

  validateLocationFields() {
    if (!this.state.city) {
      document.getElementsByName("city")[0].parentElement.classList.add("is-invalid")
      if (!this.state.uf) {
        document.getElementsByName("uf")[0].parentElement.classList.add("is-invalid")
      } else {
        document.getElementsByName("uf")[0].parentElement.classList.remove("is-invalid")
      }
    }
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf })
  }

  versionValid() {
    return this.state.model_car;
  }

  handleSubmit(e) {
    e.preventDefault();
    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;
    this.setState({ versionValidStatus: true });

    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return
      }
    }
    if(this.props.showLocationFields)
      this.validateLocationFields()

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }


    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData());
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e)
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : ''
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(this.state.phoning || this.state.mailing || this.state.whatsapping)
  }

  componentDidMount() {
    const phoneMask = IMask(this.phoneInput, {
      mask: [...phoneMasks]
    });
    phoneMask.on("accept", () => this.setState({ phone: phoneMask.value }));

    this.plateField && IMask(this.plateField, {
      mask: [...plateMasks]
    });

    this.kmField && IMask(this.kmField, {
      mask: [...kmMask]
    });

    this.dataField && IMask(this.dataField, {
      mask: [...dateMasks]
    });

    this.validator = validationFactory(`#${this.props.formId}`);
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      data: this.state.data,
      email: this.state.email,
      km: this.state.km,
      mailing: this.state.mailing,
      model_car: this.state.model_car,
      name: this.state.name,
      phone: this.state.phone,
      phoning: this.state.phoning,
      plate: this.state.plate,
      unit: this.state.unit,
      utmz: this.state.utmz,
      whatsapping: this.state.whatsapping,
      year: this.state.year,
      type_service: this.state.type_service
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf
    }

    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions
    }

    if (this.props.showLocationFields) {
      state.uf = this.state.uf
      state.city = this.state.city
    }

    let props = {
      bait: this.props.bait,
      brand: this.props.brand,
      channel: this.props.channel,
      category: this.props.category,
      product: this.props.product
    };

    if (this.props.unit) {
      props['unit'] = this.props.unit
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value
    } else {
      props['unit'] = this.state.unit
    }

    if (state['type_service']) {
      props['product'] = state['type_service']
    }

    const scriptedData = window.serviceConversionScriptedData && window.serviceConversionScriptedData() || {}

    return { ...state, ...props, ...scriptedData };
  }

  // internas de serviços
  componentDidUpdate() {
    if (window.scheduleServiceConversionFormUpdate !== undefined)
      window.scheduleServiceConversionFormUpdate();
  }

  render() {
    const { className, titleForm } = this.props;

    return (
      <form
        className={classNames(
          'conversion-form conversion-form--service',
          className
        )}
        ref={form => (this.form = form)}
        id={this.props.formId}
        onSubmit={this.handleSubmit}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />
        <header>
          <div className="conversion-form__header-phrase">
            <h2 class="form-conversion__title">{titleForm}</h2>
            <p>{this.props.mainPhrase}</p>
          </div>
        </header>
        <div className="form-conversion__body">
          <fieldset>
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={this.state.name}
                type="text"
                maxLength={80}
                className="form-control"
                name="name"
                required
                placeholder="Nome"
                data-bouncer-target="#invalid-name"
              />
              <div id="invalid-name" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={this.state.email}
                type="email"
                maxLength={80}
                required={this.props.contactsWithDynamicRequired ?
                  this.state.mailing
                  : true}
                className="form-control"
                name="email"
                placeholder="E-mail"
                data-bouncer-target="#invalid-email"
              />
              <div id="invalid-email" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <input
                onChange={this.handleInputChange}
                value={this.state.phone}
                ref={phoneInput => (this.phoneInput = phoneInput)}
                className="form-control"
                name="phone"
                required={this.props.contactsWithDynamicRequired ?
                  this.state.phoning || this.state.whatsapping
                  : true}
                type="phone"
                data-bouncer-target="#invalid-phone"
                placeholder="Telefone/Whatsapp"
                data-should-validate={this.props.contactsWithDynamicRequired ? String(this.state.phoning || this.state.whatsapping) : 'true'}
              />
              <div id="invalid-phone" className="invalid-feedback" />
            </div>

            {
              this.props.showCpf &&
              <InputCpf
                handleChange={this.handleInputChange}
                value={this.state.cpf}
              />
            }

            {this.props.showVehicleDetails && this.props.versions && this.props.versions.length <= 0 && (
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={this.state.model_car}
                  className="form-control"
                  name="model_car"
                  required
                  type="text"
                  data-bouncer-target="#invalid-model_car"
                  placeholder="Modelo"
                />
                <div id="invalid-model_car" className="invalid-feedback" />
              </div>
            )}
            {this.props.showVehicleDetails && this.props.versions && this.props.versions.length > 0 && (
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="model_car"
                  options={this.props.versions}
                  value={this.state.model_car}
                  shouldSort={false}
                  placeholderValue="Escolha um modelo"
                  searchEnabled={true}
                />

                {!this.state.versionValidStatus && (
                  <div id="invalid-version" class="invalid-feedback is-invalid-version">
                    <div class="error-message" id="bouncer-error_version">Por favor, selecione esse campo</div>
                  </div>
                )}
              </div>
            )}


            {
              this.props.showVehicleDetails && (
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    ref={plateField => (this.plateField = plateField)}
                    value={this.state.plate}
                    className="form-control"
                    name="plate"
                    required
                    data-bouncer-target="#invalid-plate"
                    placeholder="Placa"
                  />
                  <div id="invalid-plate" className="invalid-feedback" />
                </div>
              )
            }

            {
              this.props.showVehicleDetails && (
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    ref={yearField => (this.yearField = yearField)}
                    value={this.state.year}
                    className="form-control"
                    name="year"
                    required
                    data-bouncer-target="#invalid-year"
                    placeholder="Ano do modelo"
                  />
                  <div id="invalid-year" className="invalid-feedback" />
                </div>
              )
            }

            {
              this.props.showVehicleDetails && (
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    ref={kmField => (this.kmField = kmField)}
                    value={this.state.km}
                    className="form-control"
                    name="km"
                    required
                    data-bouncer-target="#invalid-km"
                    placeholder="KM"
                  />
                  <div id="invalid-km" className="invalid-feedback" />
                </div>
              )
            }

            {
              this.props.showVehicleDetails && (
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    value={this.state.data}
                    ref={dataField => (this.dataField = dataField)}
                    className="form-control"
                    name="data"
                    required
                    data-bouncer-target="#invalid-data"
                    placeholder="Data desejada"
                  />
                  <div id="invalid-data" className="invalid-feedback" />
                </div>
              )
            }

            {
              this.props.showLocationFields &&
                <LocationFields
                  handleStateChange={this.handleStateChange}
                  uf={this.state.uf}
                  city={this.state.city}
                />
            }

            {this.props.services.length > 0 &&
              <div className="form-group">
                <label class="conversion-form__control-label">Escolha um serviço:</label>
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="type_service"
                  options={this.props.services}
                  value={this.state.type_service}
                  placeholderValue="Selecione um serviço"
                />
                {/* Esse input hidden é um artifício para exibir possíveis
                    mensagens de validação para quando um serviço não for
                    selecionado */}
                <input
                  type="hidden"
                  value={this.state.type_service}
                  name="type_service"
                  className="form-control"
                  required
                  data-bouncer-target="#invalid-type_service"
                />
                <div id="invalid-type_service" className="invalid-feedback" />
              </div>
            }

            {!this.props.unit &&
              this.props.units &&
              this.props.units.length > 1 &&
              this.props.showUnits &&
              <div className="form-group">
                <label className="conversion-form__control-label">Escolha a unidade:</label>
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="unit"
                  options={this.props.units}
                  value={this.state.unit}
                  shouldSort={false}
                  placeholderValue="Selecione uma unidade"
                  searchEnabled={false}
                />
              </div>
            }
          </fieldset>

          <fieldset>
            <legend className="conversion-form__control-label">
              Quero receber contato por:
            </legend>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="mailing"
                value="true"
                onChangeCheckable={this.handleContactOptionsChange}
                isChecked={this.state.mailing}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                E-mail
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="whatsapping"
                value="true"
                isChecked={this.state.whatsapping}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Whatsapp
              </CustomCheck>
            </div>
            <div className="form-check form-check-inline">
              <CustomCheck
                name="phoning"
                value="true"
                isChecked={this.state.phoning}
                onChangeCheckable={this.handleContactOptionsChange}
                type="checkbox"
                checkStyle={this.props.checkStyle}
              >
                Telefone
              </CustomCheck>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control d-none"
                name="contact-options"
                required
                placeholder="Opções de contato"
                data-bouncer-target="#invalid-contact-options"
                value={this.state.contactOptions}
                checked={this.state.contactOptions}
              />
              <div id="invalid-contact-options" className="invalid-feedback" />
            </div>
          </fieldset>
          {this.props.shouldShowDataPermissions && (
            <fieldset className="mt-0 mb-2">
              <div className="form-check form-check-inline conversion-form__data-permissions-field">
                <CustomCheck
                  name="dataPermissions"
                  value="true"
                  isChecked={this.state.dataPermissions}
                  onChangeCheckable={this.handleInputChange}
                  type="checkbox"
                  checkStyle={this.props.checkStyle}
                >
                  { this.props.dataPermissionsCustomText }
                </CustomCheck>
              </div>
            </fieldset>
          )}
          <footer className="form-conversion__footer pt-1">
            Ao informar meus dados, eu concordo com a{' '}
            <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>.
          </footer>
          <hr className="my-3"></hr>
          <SubmitButton
            classes="btn button button--large button--primary w-100 mb-0"
            label="Estou interessado"
            isSubmitting={this.props.isSubmittingForm}
            handleClick={this.handleSubmit}
          />
        </div>
      </form>
    );
  }
}

// default props
ScheduleServiceConversionForm.defaultProps = {
  handleCloseOverlay: function () { },
  handleSubmit: function () { },
  bait: '-2',
  brand: '',
  category: 'service',
  channel: '',
  errorSendingForm: false,
  isSubmittingForm: false,
  origin: '',
  services: [],
  titleForm: 'Agendar Serviço',
  mainPhrase:
    'Preencha o formulário abaixo para receber o contato de um de nossos especialistas:',
  unit: '',
  utmz: '',
  units: [],
  showUnits: true,
  showCpf: false,
  versions: [],
  contactsWithDynamicRequired: false,
  shouldShowDataPermissions: false,
  showLocationFields: false,
};

// props config
ScheduleServiceConversionForm.propTypes = {
  checkStyle: PropTypes.oneOf(['dark', 'light']),
  errorSendingForm: PropTypes.bool,
  formId: PropTypes.string.isRequired, // Necessário para o Bouncer validar o form.
  handleCloseOverlay: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmittingForm: PropTypes.bool,
  linkPrivacyPolicy: PropTypes.string,
  mainPhrase: PropTypes.string,
  services: PropTypes.array,
  showOverlay: PropTypes.bool,
  titleForm: PropTypes.string,
  bait: PropTypes.string,
  brand: PropTypes.string,
  category: PropTypes.string,
  channel: PropTypes.string,
  origin: PropTypes.string,
  unit: PropTypes.string,
  dataPermissionsCustomText: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  product: PropTypes.string,
  contactsWithDynamicRequired: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  showLocationFields: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setURLSearchParams,
  applyInitialFilters,
  querys,
  mapFilters,
  mapQueryString,
  findFilterArray,
} from '%/utils/filters';
import SimpleFilter from './SimpleFilter';
import {
  setKeyword,
  setList,
  setSort,
  addFilter,
  incrementFilter,
  removeFilter,
  setSuggestions,
  setShowResults,
  setFilter,
} from '../../stores/SearchFilter';

class SimpleFilterApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      showFilter: false,
      showOrder: false,
      alreadyApplyInitialFilters: false,
    };

    this.watchStore();

    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleClickSuggestion = this.handleClickSuggestion.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleChangeKeywordImediate =
      this.handleChangeKeywordImediate.bind(this);
    this.handleChangeCategories = this.handleChangeCategories.bind(this);
    this.handleChangeModels = this.handleChangeModels.bind(this);
    this.handleFilterDropClick = this.handleFilterDropClick.bind(this);
    this.handleClickOrder = this.handleClickOrder.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleSetSortParams = this.handleSetSortParams.bind(this);
  }

  componentDidMount() {
    this.applyInitialFilters();
    if (this.props.shouldSetListOnInit) setList();
  }

  handleClickSearch(keyword) {
    const { fieldKeywordFilter } = this.props;
    setKeyword(keyword);
    addFilter({
      [fieldKeywordFilter]: keyword,
    });
    return setList().then(res => {
      this.props.onClickSearch();
      setShowResults(true);
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams();
      }
      return res;
    });
  }

  handleClickSuggestion(suggestion) {
    const { fieldKeywordFilter } = this.props;

    setKeyword(suggestion);
    addFilter({
      [fieldKeywordFilter]: suggestion,
    });

    return setList().then(res => {
      this.props.onClickSuggestion(suggestion);
      setShowResults(true);
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams();
      }
      return res;
    });
  }

  handleChangeKeyword(keyword) {
    const { fieldKeywordFilter } = this.props;

    setKeyword(keyword);
    addFilter({
      [fieldKeywordFilter]: keyword,
    });

    return setSuggestions();
  }

  handleSetSortParams(params) {
    setSort(params);
    setList().then(() => {
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams();
      }
    });
    this.setState({ showOrder: false });
  }

  handleChangeKeywordImediate(keyword) {
    this.setState({ keyword });
    this.props.onChangeKeywordImediate(keyword);
  }

  handleChangeCategories(categories) {
    const { fieldCategoryFilter } = this.props;
    if (fieldCategoryFilter) {
      removeFilter(fieldCategoryFilter);
      categories.forEach(category => {
        incrementFilter({ [fieldCategoryFilter]: category });
      });
      setList().then(() => {
        if (categories && categories.length > 0) {
          setShowResults(true);
        }
        if (this.props.shouldSetURLSearchParams) {
          this.applyURLSearchParams();
        }
      });
    } else {
      console.warn('Defina a prop fieldCategoryFilter');
    }
  }

  handleChangeModels(models) {
    const { fieldModelFilter } = this.props;
    if (fieldModelFilter) {
      removeFilter(fieldModelFilter);
      models.forEach(model => {
        incrementFilter({ [fieldModelFilter]: model });
      });
      setList().then(() => {
        if (models && models.length > 0) {
          setShowResults(true);
        }
        if (this.props.shouldSetURLSearchParams) {
          this.applyURLSearchParams();
        }
      });
    } else {
      console.warn('Defina a prop fieldModelFilter');
    }
  }

  handleFilterDropClick() {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  handleClickOrder() {
    const { showOrder } = this.state;
    this.setState({ showOrder: !showOrder });
  }

  handleFilterClick() {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
    this.props.onFilterClick();
  }

  watchStore() {
    window.store.SearchFilter.watch(state => {
      this.setState({
        keyword: state.keyword,
        categoriesSelected: findFilterArray(
          state.filterArray,
          this.props.fieldCategoryFilter,
        ),
        modelsSelected: findFilterArray(
          state.filterArray,
          this.props.fieldModelFilter,
        ),
        valueSort: state.sort,
      });
    });
  }

  /**
   * Faz o mapeamento da chaves utilizadas nas buscas para o que será exibido na url
   */
  mapFilters() {
    return {
      ...mapFilters,
      [this.props.fieldKeywordFilter]: 'q',
      [this.props.fieldCategoryFilter]: 'category[]',
      [this.props.fieldModelFilter]: 'model[]',
      ...this.props.mapFilters,
    };
  }

  applyURLSearchParams() {
    const { filter, filterArray, sort } = window.store.SearchFilter.getState();

    const filterSimple = {
      ...filter,
      sort,
    };

    setURLSearchParams(filterSimple, filterArray, this.mapFilters());
  }

  /**
   * Verifica se tem algum filtro da url a ser aplicado.
   * @param {Object} filtersSimple
   * @param {Array} filtersArray
   * @param {String} q
   * @returns {Boolean}
   */
  hasInitialFilters(filtersSimple = {}, filtersArray = [], q = '') {
    if (Object.keys(filtersSimple).filter(key => key === 'sort').length > 0) {
      return true;
    }

    if (filtersArray.length > 0) {
      return true;
    }

    if (q) {
      return true;
    }

    return false;
  }

  /**
   * Aplica filtro inicial com valores da url.
   */
  applyInitialFilters() {
    if (this.state.alreadyApplyInitialFilters) return;

    const customMapQueryString = mapQueryString(this.mapFilters());
    const { filtersSimple, filtersArray, q, sort } =
      applyInitialFilters(customMapQueryString);

    setFilter(filtersSimple);
    filtersArray.forEach(item => incrementFilter(item));

    if (q) {
      setKeyword(q);
    }

    if (sort) {
      setSort(sort);
    }

    if (this.hasInitialFilters(filtersSimple, filtersArray, q)) {
      setShowResults(true);
      this.props.onApplyInitialFilters({
        filtersSimple,
        filtersArray,
        q,
        sort,
      });
    }

    this.setState({ alreadyApplyInitialFilters: true });
  }

  render() {
    const { showFilter, showOrder } = this.state;

    return (
      <SimpleFilter
        keyword={this.state.keyword}
        fieldSuggestion={this.props.fieldSuggestion}
        onClickSearch={this.handleClickSearch}
        onClickSuggestion={this.handleClickSuggestion}
        onFilterClick={this.handleFilterClick}
        onChangeKeyword={this.handleChangeKeyword}
        onChangeKeywordImediate={this.handleChangeKeywordImediate}
        onSetSortParams={this.handleSetSortParams}
        onChangeCategories={this.handleChangeCategories}
        onChangeModels={this.handleChangeModels}
        isButtonFilterDropdown={this.props.isButtonFilterDropdown}
        categoriesSelected={this.state.categoriesSelected}
        modelsSelected={this.state.modelsSelected}
        filterCategories={this.props.filterCategories}
        filterModels={this.props.filterModels}
        classNameButtonFilter={this.props.classNameButtonFilter}
        classNameButtonOrder={this.props.classNameButtonOrder}
        valueSort={this.state.valueSort}
        sort={this.props.sort}
        showButtonFilter={this.props.showButtonFilter}
        showButtonOrder={this.props.showButtonOrder}
        orderDropRight={this.props.orderDropRight}
        onFilterDropClick={this.handleFilterDropClick}
        onClickOrder={this.handleClickOrder}
        showFilter={showFilter}
        showOrder={showOrder}
        showDropdownIcon={this.props.showDropdownIcon}
        showActiveStatus={this.props.showActiveStatus}
      />
    );
  }
}

SimpleFilterApp.defaultProps = {
  onClickSearch() {},
  onFilterClick() {},
  onClickSuggestion() {},
  onChangeKeywordImediate() {},
  onApplyInitialFilters() {},
  fieldKeywordFilter: querys.keyword,
  showButtonFilter: true,
  showButtonOrder: true,
  orderDropRight: false,
  showDropdownIcon: false,
  shouldSetURLSearchParams: false,
  fieldCategoryFilter: 'q[name_in][]',
  fieldModelFilter: 'q[models_name_in][]',
  mapFilters: {},
  shouldSetListOnInit: false,
};

SimpleFilterApp.propTypes = {
  onClickSearch: PropTypes.func,
  onClickSuggestion: PropTypes.func,
  onFilterClick: PropTypes.func,
  onChangeKeywordImediate: PropTypes.func,
  onApplyInitialFilters: PropTypes.func,
  fieldKeywordFilter: PropTypes.string, // representa a chave que será usada para filtrar as sugestões
  fieldCategoryFilter: PropTypes.string, // representa a chave que será usada para filtrar por categorias
  fieldModelFilter: PropTypes.string, // representa a chave que será usada para filtrar por modelos
  isButtonFilterDropdown: PropTypes.bool,
  fieldSuggestion: PropTypes.string,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterModels: PropTypes.arrayOf(),
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
  showButtonFilter: PropTypes.bool,
  showButtonOrder: PropTypes.bool,
  shouldSetURLSearchParams: PropTypes.bool,
  orderDropRight: PropTypes.bool,
  mapFilters: PropTypes.objectOf(),
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  shouldSetListOnInit: PropTypes.bool,
};

export default SimpleFilterApp;

import { h, Component } from 'preact';

import TimeMissingCard from '../TimeMissingCard';
import railsDateToChronometer from '../../utils/railsDateToChronometer';

class ItemOffer extends Component {
  get brand() {
    const { channelBrand } = document.querySelector('body').dataset;

    return channelBrand.includes('jeep') ? 'jeep' : channelBrand;
  }

  get image() {
    const { model_code, version_code, series_code, model_year } = this.props;

    return `https://www.${this.brand}.com.br/content/dam/${this.brand}/products/${model_code}/${version_code}/${series_code}/${model_year}/page/profile.png`;
  }

  render() {
    const {
      title,
      subtitle,
      seal_list,
      expired_at,
      part_link,
      slug,
      secondary_call,
      main_call,
      featured,
      description,
      extra,
      badge,
      category,
    } = this.props;

    return (
      <div className="card">
        <div className="list-offers__container-time">
          <TimeMissingCard date={railsDateToChronometer(expired_at)} />
        </div>

        <div className="card__header">
          <span className="list-offers-vehicles-new__tag">{category}</span>
          <a className="list-offers__card-link" href={`${part_link}/${slug}`}>
            <div className="card__image">
              <div
                className="card__image-value"
                style={`background-image: url(${this.image})`}
              ></div>
            </div>
          </a>
        </div>

        <div className="card__content">
          <div data-match-height="offer-vehicle-card-content">
            <div className="card__title">{title}</div>
            <div className="card__subtitle">{subtitle}</div>
          </div>
          <hr className="card__separator" />
          <div data-match-height="offer-vehicle-card-list">
            {seal_list && seal_list.length > 0 && (
              <div>
                <ul className="card__list list">
                  {seal_list.map(text => (
                    <li>{text}</li>
                  ))}
                </ul>
                <hr className="card__separator" />
              </div>
            )}
          </div>
        </div>

        <div data-match-height="card-footer" className="card__footer">
          <div
            data-match-height="offer-vehicle-footer"
            className="card__box-offer"
          >
            {secondary_call && (
              <div className="card__trigger">{secondary_call}</div>
            )}
            {main_call && <div className="card__title">{main_call}</div>}
            {featured && <div className="card__trigger-value">{featured}</div>}
            {description && (
              <div className="card__trigger-description">{description}</div>
            )}
            {extra && <div className="card__trigger-description">{extra}</div>}
            {badge && (
              <div className="badge badge-primary badge--pill">{badge}</div>
            )}
          </div>
          <a
            href={`${part_link}/${slug}`}
            className="list-offers__card-link btn button button--primary button--block button--large card__cta"
          >
            ESTOU INTERESSADO
          </a>
        </div>
      </div>
    );
  }
}

ItemOffer.defaultProps = {
  list: [],
  seal_list: [],
};

export default ItemOffer;

import { createStore } from 'effector';

/**
 * Cria ou retorna a instância do store para os
 * resultados de pesquisa dos veículos
 */
const vehicleSearchResults = (function(){
  let instance;

  return function(defaultValue = []){
    if(!instance) {
      instance = createStore(defaultValue);
    }

    return instance
  };
})();

export default vehicleSearchResults;

// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ButtonFilter from './ButtonFilter';
import DropdownFilter from './DropdownFilter';
import ButtonOrder from './ButtonOrder';
import DropdownOrder from './DropdownOrder';

class SimpleFilterButtons extends Component {
  constructor(props) {
    super(props);

    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleClickOrder = this.handleClickOrder.bind(this);
    this.handleFilterDropClick = this.handleFilterDropClick.bind(this);
    this.handleSetSortParams = this.handleSetSortParams.bind(this);
    this.handleChangeCategories = this.handleChangeCategories.bind(this);
    this.handleChangeModels = this.handleChangeModels.bind(this);
  }

  handleFilterClick(e) {
    e.preventDefault();
    this.props.onFilterClick(e);
  }

  handleFilterDropClick(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.props.onFilterDropClick(e);
  }

  handleClickOrder(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.props.onClickOrder(e);
  }

  handleSetSortParams(params) {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      sortTitle: params.title,
    });
    this.props.onSetSortParams(params.paramValue);
  }

  handleChangeCategories(categories) {
    this.props.onChangeCategories(categories);
  }

  handleChangeModels(models) {
    this.props.onChangeModels(models);
  }

  showDropdownFilter() {
    const { filterCategories, filterModels } = this.props;

    if (typeof filterCategories === 'string' && filterCategories) return true;

    if (Array.isArray(filterCategories) && filterCategories.length > 0)
      return true;

    if (Array.isArray(filterModels) && filterModels.length > 0) return true;

    return false;
  }

  render() {
    const {
      showFilter,
      showOrder,
      showButtonFilter,
      filterCategories,
      filterModels,
      classNameButtonFilter,
      classNameButtonOrder,
      sort,
      orderDropRight,
      categoriesSelected,
      modelsSelected,
      showDropdownIcon,
      showActiveStatus,
      showFiltersCleaner,
    } = this.props;

    return (
      <div className="simple-filter-buttons">
        <div className="row">
          {showButtonFilter && (
            <div className="col-6">
              {!this.showDropdownFilter() && (
                <ButtonFilter
                  className={classNames(
                    'simple-filter-btn-filter simple-filter__item button--line',
                    classNameButtonFilter,
                  )}
                  onClick={this.handleFilterClick}
                  showDropdownIcon={showDropdownIcon}
                  showActiveStatus={showActiveStatus}
                />
              )}

              {this.showDropdownFilter() && (
                <DropdownFilter
                  showFilter={showFilter}
                  categoriesSelected={categoriesSelected}
                  modelsSelected={modelsSelected}
                  filterCategories={filterCategories}
                  filterModels={filterModels}
                  onChangeCategories={this.handleChangeCategories}
                  onChangeModels={this.handleChangeModels}
                  onClose={this.handleFilterDropClick}
                  showFiltersCleaner={showFiltersCleaner}
                >
                  <ButtonFilter
                    className={classNames(
                      'simple-filter-btn-filter simple-filter__item button--line',
                      classNameButtonFilter,
                    )}
                    onClick={this.handleFilterDropClick}
                    isOpen={showFilter}
                    showDropdownIcon={showDropdownIcon}
                    showActiveStatus={showActiveStatus}
                  />
                </DropdownFilter>
              )}
            </div>
          )}

          <div
            className={classNames({
              'col-6': showButtonFilter,
              'col-12': !showButtonFilter,
            })}
          >
            <DropdownOrder
              showOrder={showOrder}
              onSetSortParams={this.handleSetSortParams}
              value={this.props.valueSort}
              sort={sort}
              dropRight={orderDropRight}
              onClose={this.handleClickOrder}
            >
              <ButtonOrder
                className={classNames('button--line', classNameButtonOrder)}
                onClick={this.handleClickOrder}
                isOpen={showOrder}
                showDropdownIcon={showDropdownIcon}
                showActiveStatus={showActiveStatus}
                sort={sort}
              />
            </DropdownOrder>
          </div>
        </div>
      </div>
    );
  }
}

SimpleFilterButtons.defaultProps = {
  onFilterClick() {},
  onClickOrder() {},
  onSetSortParams() {},
  onChangeCategories() {},
  onChangeModels() {},
  onFilterDropClick() {},
  showButtonFilter: true,
  orderDropRight: false,
  showFilter: false,
  showOrder: false,
  categoriesSelected: [],
  modelsSelected: [],
  showDropdownIcon: false,
  showActiveStatus: false,
  showFiltersCleaner: false,
};

SimpleFilterButtons.propTypes = {
  onFilterClick: PropTypes.func,
  onSetSortParams: PropTypes.func,
  onClickOrder: PropTypes.func,
  onChangeCategories: PropTypes.func,
  onChangeModels: PropTypes.func,
  onFilterDropClick: PropTypes.func,
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filterModels: PropTypes.arrayOf,
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  valueSort: PropTypes.string,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    }),
  ),
  showButtonFilter: PropTypes.bool,
  orderDropRight: PropTypes.bool,
  showFilter: PropTypes.bool,
  showOrder: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  showFiltersCleaner: PropTypes.bool,
  categoriesSelected: PropTypes.arrayOf,
  modelsSelected: PropTypes.arrayOf,
};

export default SimpleFilterButtons;

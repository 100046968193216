/**
 * @typedef {string} ProfileImageType
 */

/**
 * @enum {ProfileImageType}
 */
const ProfileImageTypes = {
  AVATAR: "avatar",
  LOGO: "logo",
  LOGO_BRAND: "logo_brand",
  LOGO_BRAND_THUMB: "logo_brand_thumb",
  LOGO_BRAND_HORIZONTAL: "logo_brand_horizontal",
  MODEL_MAIN: "model_main",
  USED_MODEL: "used_model",
  MODEL_MIDDLE: "model_middle",
  MODEL_THUMB: "model_thumb",
  MODEL_SMALL: "model_small",
  CUSTOMER_OFFER: "customer_offer"
};

export default ProfileImageTypes;

import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Representa uma caixa de mensagem que ficará em forma de overlay no
 * formulário. Normalmente utilizado para mostrar mensagens relacionadas ao
 * envio do form.
 */
export default class FormMessageOverlay extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    e.preventDefault();
    this.props.handleClose();
  }

  /**
   * Obtém o conteúdo baseado no tipo de overlay
   */
  get content() {
    const feedbackType = this.props.type
    const currentContent = this.props.content[feedbackType]
    if (feedbackType == 'success') {
      currentContent.topMessage = this.props.successMessage
    }
    return currentContent;
  }

  get mainClassNames() {
    return (
      'form-message-overlay ' +
      (this.props.isVisible ? 'form-message-overlay--active' : '')
    );
  }

  /**
   * Retorna o tipo de conteúdo do overlay.
   */
  get classNameContentType() {
    return (
      'form-message-overlay__content ' +
      'form-message-overlay__content--' +
      this.props.type
    );
  }

  render() {
    return (
      <div className={this.mainClassNames}>
        <div className={this.classNameContentType}>
          <div className="form-message-overlay__icon">
            <i className={this.content.iconClass} />
          </div>
          <div className="form-message-overlay__top-message">
            {this.content.topMessage}
          </div>
          <div className="form-message-overlay__bottom-message">
            {this.content.bottomMessage}
          </div>
          <a
            href="#"
            onClick={this.handleClose}
            className="form-message-overlay__close-button"
          >
            Fechar
          </a>
        </div>
      </div>
    );
  }
}

FormMessageOverlay.defaultProps = {
  type: 'success',
  successMessage: 'Solicitação realizada com sucesso!',
  content: {
    success: {
      iconClass: 'icon icon-check-mark',
      topMessage: 'Solicitação realizada com sucesso!',
      bottomMessage:
        'Em alguns instantes um de nossos consultores entrará em contato com você.'
    },
    error: {
      iconClass: 'icon icon-error-mark',
      topMessage: 'Erro no envio do formulário',
      bottomMessage:
        'Houve um erro ao tentar enviar o formulário. Por favor, tente novamente.'
    }
  },
  isVisible: false,
  handleClose: function () { }
};

FormMessageOverlay.propTypes = {
  successMessage: PropTypes.string,
};

function CoverVideoConfigurator(container, videoIframe) {
  let containerHeight, containerWidth;

  if (!videoIframe) {
    console.log('iframe do vídeo não encontrado.');
    return;
  }

  const configVideo = () => {
    let containerClientRects = container.getClientRects()[0];
    containerHeight = containerClientRects.height;
    containerWidth = containerClientRects.width;

    videoIframe.style.height = containerHeight + 175 + 'px';
    videoIframe.style.width = containerWidth + 'px';
  };

  if (typeof $ !== 'undefined') {
    $(window)
      .on('resize', function () {
        configVideo();
      })
      .trigger('resize');
  } else {
    window.addEventListener('resize', configVideo);
    configVideo();
  }
}

export default CoverVideoConfigurator;

import {
  checkCnpjPattern,
  checkCpfPattern,
  checkEmailPattern,
  checkMax,
  checkMaxLength,
  checkMin,
  checkMinLength,
  checkPattern,
  checkPhonePattern,
  requiredField,
} from './validators';

/**
 * Retorna as funções de validação (validators) baseado na configuração passada por parâmetro.
 *
 * @param {{
 *  required?: boolean,
 *  min?: number,
 *  max?: number,
 *  minLength?: number,
 *  maxLength?: number,
 *  pattern?: RegExp,
 *  validEmail?: boolean,
 *  validPhone?: boolean,
 *  validCpf?: boolean
 *  validCnpj?: boolean
 * }} [validationConfig={}] - Configuração de validação para o campo.
 *
 * @example
 * // Exemplo de uso:
 * const fieldProps = getValidators({ required: true, minLength: 3, maxLength: 10 });
 * // Resultado esperado: [Function, Function, Function]
 */

export const getValidators = validationConfig => {
  if (!validationConfig) return [];

  const validatorFactoriesMap = {
    required: requiredField,
    min: checkMin,
    max: checkMax,
    minLength: checkMinLength,
    maxLength: checkMaxLength,
    pattern: checkPattern,
    validEmail: checkEmailPattern,
    validPhone: checkPhonePattern,
    validCpf: checkCpfPattern,
    validCnpj: checkCnpjPattern,
  };

  const validationKeys = Object.keys(validationConfig);

  const validators = validationKeys.map(key => {
    const makeValidator = validatorFactoriesMap[key];
    const config = validationConfig[key];

    if (!makeValidator) {
      const errorMessage = `O validador com a chave '${key}' não é conhecido.`;

      console.warn(errorMessage);
      throw new Error(errorMessage);
    }

    return makeValidator(config);
  });

  return validators;
};

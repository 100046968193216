import { h, Component } from 'preact'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class ResultSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      total: 0,
      showOrder: false
    }

    window.store.SearchFilter.watch(state => {
      this.setState({
        keyword: state.keyword,
        total: state.total
      })
    })

    this.handleClickOrder = this.handleClickOrder.bind(this)
    this.handleCloseKeyword = this.handleCloseKeyword.bind(this)
    this.handleFilterClick = this.handleFilterClick.bind(this)
    this.handleFilterMobileClick = this.handleFilterMobileClick.bind(this)
    this.setSortParams = this.setSortParams.bind(this)

    /**
     * Lista de tipos de ordenação dos resultados
     *
     * @var {array} sort
     */
    this.sort = [
      { title: 'Mais Recente', paramValue: '-created_at' },
      { title: 'Mais Antigo', paramValue: 'created_at' },
      { title: 'Mais Relevante', paramValue: 'ordination' },
      { title: 'Por Preço Maior/Menor', paramValue: '-price_value' },
      { title: 'Por Preço Menor/Maior', paramValue: 'price_value' },
      { title: 'Por Nome A/Z', paramValue: 'name' },
      { title: 'Por Nome Z/A', paramValue: '-name' }
    ];
  }

  handleClickOrder(e) {
    e.preventDefault()

    this.setState(state => ({
      showOrder: !state.showOrder
    }))
  }

  handleCloseKeyword(e) {
    e.preventDefault()
    this.props.onCloseKeyword()
  }

  handleFilterClick(e) {
    e.preventDefault()
    this.props.onFilterClick()
  }

  handleFilterMobileClick(e) {
    e.preventDefault()
    this.props.onFilterMobileClick()
  }

  setSortParams(params) {
    this.setState(state => ({
      sortParams: params,
      showOrder: false
    }))
    this.props.onSetSortParams(params)
  }

  getParamTitleByValue(value) {
    const param = this.sort.find(param => param.paramValue === value)
    return param ? param.title : 'Ordenar'
  }

  render() {
    const { total, keyword } = this.state
    const { showDropdownIcon } = this.props

    return (
      <div class="result-search">
        { total > 0 || total === null ?
          <div class="row">
            <div class="col-12 col-md-6 col-lg-8">
              { keyword &&
                <p className="result-search__p">
                  Buscando por <strong>{ keyword }</strong><br />
                </p>
              }
              <p class="mb-4">
                <strong>{ total }</strong> { total > 1 ? 'resultados encontrados' : 'resultado encontrado' }
              </p>
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <button
                onClick={this.handleFilterClick}
                className="d-none d-lg-block simple-filter__item btn button button--large button--line-white button--no-upper w-100">
                <i className="icon icon-filter"></i>
                Filtrar
                  { showDropdownIcon && <i className="icon icon-chevron"></i>}
              </button>

              <button
                onClick={this.handleFilterMobileClick}
                className="d-lg-none simple-filter__item btn button button--large button--line-white button--no-upper w-100">
                <i className="icon icon-filter"></i>
                Filtrar
                { showDropdownIcon && <i className="icon icon-chevron"></i>}
              </button>
            </div>
            <div className="col-6 col-md-3 col-lg-2">

              <div className={classNames({
                'search-filter-simple__drop': true,
                'search-filter-simple__item': true,
                'search-filter-simple__drop--active': this.state.showOrder
              })}>
                <a
                  class="btn button button--large button--no-upper button--line-white w-100"
                  href="#"
                  onClick={this.handleClickOrder}
                >
                  { this.state.showOrder && 'Fechar' }
                  { !this.state.showOrder &&
                    <span>
                      <i className="icon icon-order"></i>
                      <span class="filter__order-label" >
                        { this.state.sortParams ?
                            this.getParamTitleByValue(this.state.sortParams)
                            : 'Ordenar'
                        }
                      </span>
                      { showDropdownIcon && <i className="icon icon-chevron"></i>}
                    </span>
                  }
                </a>

                <div class="card-collapse-deep search-filter-simple__drop-item search-filter-simple__drop-item--right">
                  <div class="card-collapse-deep__header">
                    Ordenar por:
                  </div>

                  <ul class="list list--border-bottom">
                    {this.sort.map(sortItem => (
                      <li
                        className={classNames({
                          'card-collapse-deep__title': true,
                          'card-collapse-deep__title--link': true,
                          'card-collapse-deep__title--active': (sortItem.paramValue === this.state.sortParams)
                        })}
                        onClick={() => this.setSortParams(sortItem.paramValue)}
                      >
                        <strong>{sortItem.title}</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>
          </div>
          :
          <div class="row">
            <div className="col-12 col-lg-8 result-search__col-no-result">
              <p className="result-search__p">
                Nenhum veículo encontrado
              </p>
              <button
                onClick={this.handleCloseKeyword}
                class="result-search__button-keyword btn button button--large button--line-white button--no-upper">
                Resultado da busca&nbsp;
                { keyword &&
                  <span>
                    para&nbsp;
                    <strong class="text-uppercase">"{ keyword }"</strong>
                  </span>
                }
                <span className="result-search__icon-close rounded-circle">
                  <i class="icon icon-close-b"></i>
                </span>
              </button>
            </div>
            <div className="col-lg-4 d-none d-lg-flex result-search__col-no-result-buttons">

              <div class="row">
                <div class="col-sm-6">
                  <button
                    onClick={this.handleFilterClick}
                    className="simple-filter__item btn button button--large button--line-white button--no-upper">
                    <i className="icon icon-filter"></i>
                    Filtrar
                  </button>
                </div>
                <div class="col-sm-6">

                  <div className={classNames({
                    'search-filter-simple__drop': true,
                    'search-filter-simple__item': true,
                    'search-filter-simple__drop--active': this.state.showOrder
                  })}>
                    <a
                      class="btn button button--large button--no-upper button--line-white"
                      href="#"
                      onClick={this.handleClickOrder}
                    >
                      { this.state.showOrder && 'Fechar' }
                      { !this.state.showOrder && <span><i className="icon icon-order"></i> Ordenar</span>}
                    </a>

                    <div class="card-collapse-deep search-filter-simple__drop-item search-filter-simple__drop-item--right">
                      <div class="card-collapse-deep__header">
                        Ordenar por:
                      </div>

                      <ul class="list list--border-bottom">
                        {this.sort.map(sortItem => (
                          <li
                            className={classNames({
                              'card-collapse-deep__title': true,
                              'card-collapse-deep__title--link': true,
                              'card-collapse-deep__title--active': (sortItem.paramValue === this.state.sortParams)
                            })}
                            onClick={() => this.setSortParams(sortItem.paramValue)}
                          >
                            <strong>{sortItem.title}</strong>
                          </li>
                        ))}
                      </ul>
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        }
      </div>
    )
  }
}

ResultSearch.defaultProps = {
  onCloseKeyword() {},
  onFilterClick() {},
  onFilterMobileClick() {},
  showDropdownIcon: false
}

ResultSearch.propTypes = {
  onCloseKeyword: PropTypes.func,
  onFilterClick: PropTypes.func,
  onFilterMobileClick: PropTypes.func,
  showDropdownIcon: PropTypes.bool
}

export default ResultSearch

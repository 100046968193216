// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  resetList,
  setTotalPages,
  setShowConversionForm,
  setTotal,
} from '../../stores/SearchFilter';
import ListV2 from './ListV2';

class ListAppV2 extends Component {
  constructor(props) {
    super(props);

    const { list, total_pages, total } = this.props;

    this.state = {
      list: [],
    };

    setTotalPages(total_pages);
    if (total) setTotal(total);
    resetList(list);

    if (list.length === 0 && this.state.loading === false) {
      setShowConversionForm(true);
    }

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
        total: state.total,
      });
    });
  }

  showLoadMore() {
    const { total, list, loadingMore } = this.state;
    return total > list.length || loadingMore;
  }

  render() {
    const { list, loading, loadingMore } = this.state;
    const { cardLink, showPrice } = this.props;

    if (list.length > 0) {
      return (
        <ListV2
          items={list}
          loading={loading}
          loadingMore={loadingMore}
          showLoadMore={this.showLoadMore()}
          cardLink={cardLink}
          channelShowPrice={showPrice}
        />
      );
    }

    if (list.length === 0 && !loading) return null;

    return (
      <div>
        <ul className="row new-vehicles-listing__list">
          {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
            <li key={item} className="new-vehicles-listing__item">
              <div className="ph-item" style={{ width: '100%' }}>
                <div className="ph-col-12">
                  <div className="ph-picture" />
                  <div className="ph-row">
                    <div className="ph-col-2 empty" />
                    <div className="ph-col-8 big" />
                    <div className="ph-col-2 empty" />
                  </div>
                  <div className="ph-row">
                    <div className="ph-col-8" />
                    <div className="ph-col-8" />
                    <div className="ph-col-8" />
                  </div>
                  <div className="ph-row">
                    <div className="ph-col-2 empty" />
                    <div className="ph-col-8 big" />
                    <div className="ph-col-2 empty" />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

ListAppV2.defaultProps = {
  list: [],
};

ListAppV2.propTypes = {
  list: PropTypes.arrayOf,
  total_pages: PropTypes.number,
  total: PropTypes.number,
  cardLink: PropTypes.string,
  per_page: PropTypes.number,
};

export default ListAppV2;

export function cnpjMask(value) {
  let formattedValue = value;

  formattedValue = formattedValue.replace(/\D/g, '');
  formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
  formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
  formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');

  return formattedValue;
}

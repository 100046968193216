import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp'
import { setMethodApi } from '../../stores/SearchFilter'

class SimpleFilter extends Component {
  constructor(props) {
    super(props)

    setMethodApi('getCategoryDirectSales')
  }

  render() {
    const { filterCategories, fieldCategoryFilter, sort, showActiveStatus, showDropdownIcon } = this.props
    return (
      <SimpleFilterApp
        filterCategories={filterCategories}
        fieldCategoryFilter={fieldCategoryFilter}
        fieldKeywordFilter="q[name_cont]"
        fieldSuggestion="name"
        classNameButtonFilter="button--line"
        classNameButtonOrder="button--line"
        sort={sort}
        showButtonFilter={false}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
      />
    )
  }
}

SimpleFilter.defaultProps = {
  sort: [
    { title: 'Por Nome A/Z', paramValue: 'name' },
    { title: 'Por Nome Z/A', paramValue: '-name' }
  ],
  showDropdownIcon: false,
  showActiveStatus: false,
}

SimpleFilter.propTypes = {
  fieldCategoryFilter: PropTypes.string, // representa a chave que será usada para filtrar as categorias
  filterCategories: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string,
    })
  ),
}

export default SimpleFilter

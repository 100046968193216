import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp';

class SimpleFilter extends Component {
  render() {
    const { filterCategories, fieldCategoryFilter, sort, showDropdownIcon, showActiveStatus, mapFilters } = this.props;
    return (
      <SimpleFilterApp
        filterCategories={filterCategories}
        fieldCategoryFilter={fieldCategoryFilter}
        fieldKeywordFilter="q[name_cont]"
        fieldSuggestion="name"
        classNameButtonFilter="button--line"
        classNameButtonOrder="button--line"
        mapFilters={mapFilters}
        sort={sort}
        showButtonFilter={false}
        showDropdownFilter={false}
        shouldSetURLSearchParams={true}
        showDropdownIcon={showDropdownIcon}
        showActiveStatus={showActiveStatus}
      />
    );
  }
}

SimpleFilter.defaultProps = {
  sort: [
    { title: 'Por Nome A/Z', paramValue: 'name' },
    { title: 'Por Nome Z/A', paramValue: '-name' }
  ],
  mapFilters: {
    'q[category_direct_sale_id_eq]': 'category_direct_sale'
  },
  showDropdownIcon: false,
  showActiveStatus: false,
};

SimpleFilter.propTypes = {
  fieldCategoryFilter: PropTypes.string, // representa a chave que será usada para filtrar as categorias
  filterCategories: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      paramValue: PropTypes.string
    })
  ),
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
};

export default SimpleFilter;

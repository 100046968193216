export const addErrorStatusToField = (field, error) => {
  if (!field || !error) return;
  const errorTargetId = `#invalid-${field.name}`;

  field.setAttribute('data-bouncer-target', errorTargetId);
  field.setAttribute('aria-describedby', `bouncer-error_${field.name}`);
  field.setAttribute('aria-invalid', true);

  field.classList.add('is-invalid');

  const fieldWrapperElement = field.parentElement;
  if (!fieldWrapperElement) return;

  const errorDisplayElement = fieldWrapperElement.querySelector(errorTargetId);
  if (errorDisplayElement) errorDisplayElement.innerText = error.message;
};

import { Client } from './Client';

export const makeApiV2Client = () => {
  const apiV2URLElement = document.querySelector(
    `meta[name='auto_api_url_v2']`,
  );
  const apiV2URL = apiV2URLElement && apiV2URLElement.content;

  const apiV2Client = new Client(apiV2URL);

  return apiV2Client;
};

import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Accordion from './Accordion'

class AccondionVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eventKey: null,
      content: [],
      detail_list: [],
      comfort_list: [],
      item_list: [],
      connectivity_list: []
    }

    this.listToggle = [
      {
        category: 'detail_list',
        label: 'Especificações',
      },
      {
        category: 'comfort_list',
        label: 'Conforto'
      },
      {
        category: 'item_list',
        label: 'Segurança'
      },
      {
        category: 'connectivity_list',
        label: 'Conectividade'
      }
    ]

    this.handleClick = this.handleClick.bind(this)

    this.watchStore()
  }

  watchStore() {
    window.store &&
      window.store.vehicleVersionDetails.watch(({ activeVersion }) => {
        activeVersion &&
          this.setState({
            detail_list: activeVersion.detail_list,
            comfort_list: activeVersion.comfort_list,
            item_list: activeVersion.item_list,
            connectivity_list: activeVersion.connectivity_list
          })
      })
  }

  handleClick(eventKey) {
    this.setState({ eventKey })
  }

  /**
   * Converte o conteúdo de um item em texto. Útil para renderizar de forma
   * diferente baseada na estrutura de `item`.
   * @returns {string}
   */
  itemToText(item) {
    if (item.includes(':')) {
      item = item.split(':');
      return (
        <span>
          <strong>{item[0]}</strong>: {item[1]}
        </span>
      );
    }

    if (typeof item === 'string') {
      return item;
    }
  }

  /**
   * Indica se a listagem é de detalhes. Util para verificar quando devemos
   * inserir bullets na listagem.
   * @param {string[]} categoryItems
   * @return {bool}
   */
  isDetailsList(categoryItems) {
    return categoryItems.reduce((acc, cur) => acc && cur.includes(':'), true);
  }

  render() {
    const { eventKey } = this.state
    const { id } = this.props

    return (
      <Accordion id={id} defaultActiveKey={eventKey}>
        { this.listToggle && this.listToggle.map((toggle, index) =>
          this.state[toggle.category] && this.state[toggle.category].length > 0 &&
          <div>
            <Accordion.Toggle eventKey={index} onClick={this.handleClick}>{ toggle.label }</Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
              <ul className={classNames('list list--specs-items list--border-bottom', {
                'list--no-style': this.isDetailsList(this.state[toggle.category]),
                'list--primary': !this.isDetailsList(this.state[toggle.category])
              })}>
                { this.state[toggle.category].map(item =>
                  <li>{this.itemToText(item)}</li>
                ) }
              </ul>
            </Accordion.Collapse>
          </div>
        )}
      </Accordion>
    )
  }
}

AccondionVehicle.defaultProps = {
  id: 'accordion-vehicle'
}

AccondionVehicle.propTypes = {
  id: PropTypes.string.isRequired
}

export default AccondionVehicle

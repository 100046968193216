/**
 * Funções utilitária para o upload de arquivo contendo lógica de alteração do estado do componente jsx
 */

export function resetInputFile() {
  if (this.inputFile) {
    this.inputFile.value = '';
  }
}

/**
 * Espera que o componente receba a prop onUpload que deve retornar uma promise
 */
export function uploadFile() {
  const { files } = this.state;
  if (files && files.length > 0) {
    const [file] = files;

    this.setState({ sendingFile: true, uploadError: false });

    const handleUpload = this.handleUpload || this.props.onUpload;

    this.uploadPromise = handleUpload({ file })
      .then(res => {
        this.setState({ uploadError: false });
        return res;
      })
      .catch(res => {
        this.setState({ uploadError: true });
        resetInputFile.call(this);
        return res;
      })
      .finally(() => {
        this.setState({ sendingFile: false });
      });
  }
}

export function handleInputFileChange(e) {
  const { files } = e.target;

  this.inputFile = e.target;

  this.setState({
    files,
  });

  uploadFile.call(this);
}

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class TextArea extends Component {
  render() {
    const {
      customClasses,
      showLabels,
      title,
      name,
      placeholder,
      onChange,
      required,
      maxLength,
      rows,
    } = this.props;

    return (
      <div className={classNames('form__text-field', customClasses)}>
        {showLabels && (
          <label className="form__text-field-label" htmlFor={name}>
            {title}
          </label>
        )}
        <textarea
          className="form__text-field-textarea"
          id={name}
          name={name}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength}
          rows={rows}
        ></textarea>
      </div>
    );
  }
}

TextArea.propTypes = {
  customClasses: PropTypes.string,
  showLabels: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  setState: PropTypes.func,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
};

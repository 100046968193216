import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { 
  setList, 
  setKeyword,
  changeCategoryFilter
} from '../../stores/SearchFilterFca';
import DropdownFilter from './DropdownFilter'
import ButtonFilter from './ButtonFilter'
import SearchTerm from './SearchTerm'

class SimpleFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showFilter: false,
      keyword: '',
      selectedCategories: []
    }

    this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this)
    this.handleClickSearch = this.handleClickSearch.bind(this)
    this.handleFilterClick = this.handleFilterClick.bind(this)
    this.handleFilterDropClick = this.handleFilterDropClick.bind(this)
    this.handleChangeCategories = this.handleChangeCategories.bind(this)
  }

  handleSubmitSearch(e) {
    e.preventDefault()

    setKeyword(this.state.keyword)
  }

  handleChangeKeyword(e) {
    let { value } = e.target
    this.setState({ keyword: value })
    setKeyword(this.state.keyword)
    setList()
  }

  handleFilterClick(e) {
    e.preventDefault()
    const { showFilter } = this.state
    this.setState({ showFilter: !showFilter })
  }

  handleFilterDropClick(e) {
    if (e && e.preventDefault) e.preventDefault()
    this.setState({ showFilter: false })
  }

  handleClickSearch(e) {
    setKeyword(this.state.keyword)
    setList()
  }

  handleChangeCategories(event) {
    const { selectedCategories } = this.state;
    const category = event.target.value;

    const categoryAlreadySelected = selectedCategories.includes(category);

    if (categoryAlreadySelected) {
      const index = selectedCategories.indexOf(category);
      selectedCategories.splice(index, 1);
    } else {
      selectedCategories.push(category);
    }

    this.setState({ selectedCategories });

    changeCategoryFilter(selectedCategories)
    setList().then(() => {
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams()
      }
    })
  }

  render () {
    const { showFilter } = this.state

    return (
      <form class="simple-filter" onSubmit={this.handleSubmitSearch}>

        <div class="row">
          <div class="col-md-3 col-lg-2">
            <DropdownFilter
              className="d-none d-md-block"
              showFilter={showFilter}
              onClose={this.handleFilterDropClick}
              onChangeCategories={this.handleChangeCategories}
              selectedCategories={this.state.selectedCategories}
            >
              <ButtonFilter
                className="simple-filter-btn-filter simple-filter__item button--line"
                onClick={this.handleFilterClick}
                isOpen={showFilter}
              />
            </DropdownFilter>
          </div>

          <div className="col-md-9 col-lg-10">

            <SearchTerm
              className="simple-filter__item"
              value={this.state.keyword}
              onChangeKeyword={this.handleChangeKeyword}
              onClickSearch={this.handleClickSearch}
              placeholder="O que está buscando?"
            />

          </div>
        </div>

      </form>
    )
  }
}

SimpleFilter.defaultProps = {
  shouldSetURLSearchParams: false,
}

SimpleFilter.propTypes = {
  shouldSetURLSearchParams: PropTypes.bool,
}

export default SimpleFilter

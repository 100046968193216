/**
 * Chaves usadas para buscas a API
 */
export const querys = {
  keyword: 'q[name_cont]',
  keywordUsedModel: 'q[brand_or_name_or_version_or_model_or_plate_cont]',
  kind: 'q[kind_in][]',
  brand: 'q[brand_eq]',
  model: 'q[model_in][]',
  exchange: 'q[exchange_in][]',
  min_price: 'q[price_value_gteq_all]',
  max_price: 'q[price_value_lteq_all]',
  year_begin: 'q[model_year_gteq_all]',
  year_end: 'q[model_year_lteq_all]',
  min_km: 'q[km_value_gteq_all]',
  max_km: 'q[km_value_lteq_all]',
  unit: 'q[unit_id_eq]',
  in_transit: 'q[in_transit_eq_or_nil]',
  state: 'q[state_in][]',
};

/**
 * Usado para query string
 */
export const mapFilters = {
  'q[name_cont]': 'q',
  'q[model_cont]': 'model_cont',
  'q[category_in][]': 'category[]',
  'q[kind_in][]': 'kind[]',
  'q[brand_eq]': 'brand[]',
  'q[model_in][]': 'model[]',
  'q[exchange_in][]': 'exchange[]',
  'q[price_value_gteq_all]': 'price_min',
  'q[price_value_lteq_all]': 'price_max',
  'q[model_year_gteq_all]': 'year_min',
  'q[model_year_lteq_all]': 'year_max',
  'q[km_value_gteq_all]': 'km_min',
  'q[km_value_lteq_all]': 'km_max',
  'q[unit_id_eq]': 'unit',
  'q[in_transit_eq_or_nil]': 'in_transit',
  sort: 'sort',
  'q[state_in]': 'state[]',
};

/**
 * Verifica se a chave do filtro é do tipo array
 * @param {String} key
 */
export const isFilterArray = key => /\[\]/.test(key);

/**
 * Chave e valor de mapFilters invertidas
 */
export const mapQueryString = (customMapFilters = {}) =>
  Object.entries(customMapFilters).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

/**
 *
 * @param {Array} filters array de objetos
 * @param {String} key
 */
export const findFilterArray = (filters, key) => {
  const values = [];

  filters.forEach(item => {
    if (item[key] || typeof item[key] === 'boolean') {
      values.push(item[key]);
    }
  });

  return values;
};

/**
 * Retorna um array "entries" com o valores a serem aplicados na query string
 * @param {Object} filter
 * @param {Array} filterArray
 * @param {Object} mapFiltersList
 */
export function getURLSearchParams(
  filter = {},
  filterArray = [],
  mapFiltersList = {},
) {
  const params = [];

  const setParams = (items = {}) => {
    Object.entries(items).forEach(([key, value]) => {
      if (value || typeof value === 'boolean') {
        const query = mapFiltersList[key];
        params.push([query, value]);
      }
    });
  };

  setParams(filter);
  filterArray.forEach(item => setParams(item));

  return params;
}

/**
 * Aplica filtro na url
 * @param {Object} filter
 * @param {Array} filterArray
 * @param {Object} mapFiltersList
 */
export function setURLSearchParams(
  filter = {},
  filterArray = [],
  mapFiltersList = {},
) {
  const params = getURLSearchParams(filter, filterArray, mapFiltersList);
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(params);

  url.search = decodeURI(searchParams.toString());

  window.history.pushState(null, null, url);
}

export function clearURLSearchParams() {
  const url = new URL(window.location);
  url.search = '';
  window.history.pushState(null, null, url);
}

/**
 * Filtro inicial a parti da query string
 */
export function applyInitialFilters(mapQuery = {}) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const filtersSimple = {};
  const filtersArray = [];
  let q = '';
  let sort = '';

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of urlSearchParams.entries()) {
    const keyFilter = mapQuery[key];

    if (!isFilterArray(key)) {
      filtersSimple[keyFilter] = value;
    } else {
      filtersArray.push({ [keyFilter]: value });
    }

    if (key === 'q') {
      q = value;
    }
    if (key === 'sort') {
      sort = value;
    }
  }

  return {
    filtersSimple,
    filtersArray,
    q,
    sort,
  };
}

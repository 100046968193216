import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

export default class CustomScrollbars extends Component {
  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#999',
      width: '3px'
    }

    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    )
  }

  renderTrack({ style, ...props }) {
    const trackStyle = {
      backgroundColor: '#ccc',
      width: '3px',
      right: '2px',
      bottom: '2px',
      top: '2px',
      'border-radius': '3px'
    }

    return (
      <div
        style={{ ...style, ...trackStyle }}
        {...props}
      />
    )
  }

  render() {
    const { hideTracksWhenNotNeeded } = this.props

    return (
      <Scrollbars
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        renderTrackHorizontal={this.renderTrack}
        renderTrackVertical={this.renderTrack}
        hideTracksWhenNotNeeded={hideTracksWhenNotNeeded}
        {...this.props}
      />
    )
  }
}

CustomScrollbars.defaultProps = {
  hideTracksWhenNotNeeded: true,
}

CustomScrollbars.propTypes = {
  CustomScrollbars: PropTypes.bool,
}

/**
 * Controla a exibição do popup de política de privacidade
 * com base na ação do visitante e no registro de cookie anterior
 */
export default class privacyPolicyPopup {
  /**
   * @params {object} options - opções de customização para o popup
   * @params {string} options.selectorTemplate - seletor do elemento no DOM com o template do popup
   * @params {string} options.selectorButtonAllow - seletor do elemento do botão referente ao botão de aceitar a política
   * @params {string} options.cookieName - nome do cookie usado 
   * @params {integer} options.expirationDays - tempo em dias até a expiração do cookie 
   */
  constructor(options = {}) {
    // sobrescreve as opções predefinidas que foram enviadas para o objeto
    this.options = {
      selectorTemplate: '#privacy-policy-popup',
      selectorPopup: '.privacy-policy-popup',
      selectorButtonAllow: '#privacy-policy-popup-accept',
      cookieName: 'autodromo_privacy_policy',
      expirationDays: 365,
      ...options
    }

    /**
     * em um primeiro momento apenas o status ALLOW será usado
     */
    this.status = {
      deny: 'DENY',
      allow: 'ALLOW',
      dismiss: 'DISMISS'
    }
  }

  /**
   * inicializa o popup na página conforme opções configuradas
   */
  init() {
    this.appendMarkup();
  }

  /**
   * exibe o popup para o usuário caso não tenha registro de cookie como 'ALLOW'
   */
  appendMarkup() {
    if(this.cookieValue != this.status.allow) {
      // recupera apenas o conteúdo presente dentro do template do popup
      let popupNode = document.importNode(this.elementTemplate.content, true);
      document.body.appendChild(popupNode);
      this.elementButtonAllow.addEventListener('click', event => this.handleButtonAllowClick( event ))
    }
  }

  /**
   * registra o cookie caso o usuário click no botão de "aceito"
   * e remove o elemento do popup do DOM
   */
  handleButtonAllowClick(event) {
    let expirationData = new Date();
    expirationData.setHours(expirationData.getHours() + (this.options.expirationDays * 24));
    document.cookie = `${this.options.cookieName}=${this.status.allow}; expires=${expirationData.toUTCString()}; path=/`;

    document.body.removeChild(this.elementPopup);
  }

  /**
   * @returns {Element} elemento de template do popup no DOM
   */
  get elementTemplate() {
    return document.querySelector(this.options.selectorTemplate);
  }

  /**
   * @returns {Element} elemento do popup no DOM
   */
  get elementPopup() {
    return document.querySelector(this.options.selectorPopup);
  }

  /**
   * @returns {Element} elemento de template do popup no DOM
   */
  get elementButtonAllow() {
    return document.querySelector(this.options.selectorButtonAllow);
  }

  /**
   * @returns {string} retorna uma das opções de this.status(ex.: DENY, ALLOW, DISMISS)
   */
  get cookieValue() {
    const value = ' ' + document.cookie
    const parts = value.split(' ' + this.options.cookieName + '=')
    return parts.length < 2
      ? undefined
      : parts
          .pop()
          .split(';')
          .shift()
  }
}

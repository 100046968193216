import { h, Component } from 'preact'
import PropTypes from 'prop-types'

export default class Item extends Component {
  getLink() {
    const { part_link, slug } = this.props
    return `${part_link}/${slug}`
  }

  render () {
    const { image, title, subtitle, item_offer } = this.props

    return (
      <div class="card card-consortia">
        { image && (
          <div class="card__img">
            <div class="card__title">{ title }</div>
            <a href={this.getLink()}>
              <img class="card__img-value" src={image} alt={title} />
            </a>
          </div>
        )}

        <div class="card__content">
          <div>
            <div class='card__trigger-value-description'>Parcelas a partir de</div>
            { item_offer && item_offer.month_value && item_offer.month_value.featured && 
              <div class="card__trigger-value consortia__price">{ item_offer.month_value.featured } / mês</div>
            }
            { item_offer && item_offer.month_value && item_offer.month_value.badge &&
              <div class="badge badge--pill card-consortia__badge">{ item_offer.month_value.badge }</div>
            }
          </div>
          <div>
            { item_offer && item_offer.total_value && item_offer.total_value.featured && 
              <div>
                <div class="card__trigger-value consortia__full-price"><span class='consortia__full-price-description'>Valor da carta: </span>{ item_offer.total_value.featured }</div>
              </div>
            }
          </div>
          <a href={this.getLink()} class="card-consortia__button btn button button--block button--small card__cta">Estou Interessado</a>
        </div>
      </div>
    )
  }
}

Item.propTypes = {
  slug: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  part_link: PropTypes.string,
  item_offer: PropTypes.shape({
    total_value: PropTypes.shape({
      secondary_call: PropTypes.string,
      featured: PropTypes.string,
    }),
    month_value: PropTypes.shape({
      secondary_call: PropTypes.string,
      featured: PropTypes.string,
      badge: PropTypes.string,
    })
  })
}

import { Component } from 'preact';
import PropTypes from 'prop-types';
import handleInputChange from '../../utils/handleInputChange';
import CustomSelect from '../CustomSelect';

export default class SearchFilterMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listing: this.props.initialListing || 'seminovos',
      kind: null,
      brand: null,
      model: null,
      minYear: null,
      maxYear: null,
      q: '',
      disableSelectModels: true,
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.handleListingsChange = this.handleListingsChange.bind(this);
    this.handleBrandsChange = this.handleBrandsChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  handleListingsChange(e) {
    const { value } = e.target;
    this.setState({
      listing: value,
    });

    this.props.onListingsChange(e);
  }

  handleBrandsChange(e) {
    const { value } = e.target;
    this.setState({
      brand: value,
      model: '', // dica: aqui deve ser um string vazia, se passar null o select não limpa
      disableSelectModels: !value,
    });
  }

  handleFilterClick(e) {
    e.preventDefault();
    this.props.onFilterClick();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { listing, kind, brand, model, minYear, maxYear, q } = this.state;

    const params = {};

    if (brand) params.brand = brand;
    if (kind) params.kind = kind;
    if (model) params.model = model;
    if (minYear) params.minYear = minYear;
    if (maxYear) params.maxYear = maxYear;
    if (q) params.q = q;
    if (listing) params.listing = listing;

    this.props.onSubmit(params);
  }

  /**
   * Tipos de veículos usados no select
   */
  get kinds() {
    const listAllOption = {
      label: 'Todos',
      value: '',
    };
    const kindOptions = this.props.kinds.map(kind => ({
      label: kind.title,
      value: kind.title,
    }));

    const kinds = [listAllOption, ...kindOptions];

    return kinds;
  }

  /**
   * Marcas usadas no select
   */

  get brands() {
    const listAllOption = {
      label: 'Todas',
      value: '',
    };

    let listBrands = this.props.brands;

    if (this.props.showVehicleTypeFilter && this.state.kind) {
      const filteredBrands = this.props.brands.filter(
        brand => brand.kind === this.state.kind,
      );
      listBrands = filteredBrands;
    }

    const uniqueBrands = [...new Set(listBrands.map(brand => brand.title))];

    const brandOptions = uniqueBrands.map(brand => ({
      label: brand,
      value: brand,
    }));

    return [listAllOption, ...brandOptions];
  }

  /**
   * Modelos usados no select
   */
  get models() {
    const listModels = this.props.models;
    const { brand } = this.state;

    if (!listModels[brand]) return [];

    return listModels[brand]
      .map(model => ({
        ...model,
        label: model.title,
        value: model.title,
      }))
      .filter(({ kind }) => {
        if (this.props.showVehicleTypeFilter && this.state.kind)
          return kind === this.state.kind;
        return true;
      });
  }

  /**
   * Anos usados no select
   */
  get years() {
    return this.props.years.map(year => ({
      label: year,
      value: year,
    }));
  }

  /**
   * Listagens usadas no select
   */
  get listings() {
    return [
      {
        label: 'Seminovos',
        value: 'seminovos',
      },
      {
        label: 'Novos',
        value: 'ofertas-especiais',
      },
    ];
  }

  render() {
    return (
      <div className="search-filter-main">
        <form className="search-filter-main__form" onSubmit={this.handleSubmit}>
          <h3 className="search-filter-main__title d-lg-none">Busca rápida</h3>
          {this.props.ShowListingTypeChoice && (
            <div className="search-filter-main__form-group search-filter-main__form-group--brands d-lg-block mx-0">
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--primary',
                }}
                name="listing"
                handleSelectChange={this.handleListingsChange}
                options={this.listings}
                value={this.state.listing}
                searchEnabled={false}
              />
            </div>
          )}

          {this.props.showVehicleTypeFilter && (
            <div className="search-filter-main__form-group search-filter-main__form-group--kinds d-lg-block">
              <CustomSelect
                classNames={{
                  containerOuter: 'choices choices--primary',
                }}
                name="kind"
                handleSelectChange={this.handleInputChange}
                options={this.kinds}
                selected=""
                value={this.state.kind}
                placeholderValue="Tipo de veículo"
                searchEnabled={false}
              />
            </div>
          )}

          <div className="search-filter-main__form-group search-filter-main__form-group--brands d-none d-lg-block">
            <CustomSelect
              classNames={{
                containerOuter: 'choices choices--primary',
              }}
              name="brand"
              handleSelectChange={this.handleBrandsChange}
              options={this.brands}
              value={this.state.brand}
              placeholderValue="Marca"
              searchEnabled={false}
            />
          </div>

          <div className="search-filter-main__form-group search-filter-main__form-group--models d-none d-lg-block">
            <CustomSelect
              classNames={{
                containerOuter: 'choices choices--primary',
              }}
              name="model"
              handleSelectChange={this.handleInputChange}
              disable={this.state.disableSelectModels}
              options={this.models}
              value={this.state.model}
              placeholderValue="Modelo"
              searchEnabled={false}
            />
          </div>

          <div className="search-filter-main__form-group search-filter-main__form-group--year-min d-none d-lg-block">
            <CustomSelect
              classNames={{
                containerOuter: 'choices choices--primary',
              }}
              name="minYear"
              handleSelectChange={this.handleInputChange}
              options={this.years}
              value={this.state.minYear}
              placeholderValue="Ano de"
              searchEnabled={false}
            />
          </div>

          <div className="search-filter-main__form-group search-filter-main__form-group--year-max d-none d-lg-block">
            <CustomSelect
              classNames={{
                containerOuter: 'choices choices--primary',
              }}
              name="maxYear"
              handleSelectChange={this.handleInputChange}
              options={this.years}
              value={this.state.maxYear}
              placeholderValue="Ano até"
              searchEnabled={false}
            />
          </div>

          <div className="search-filter-main__form-group search-filter-main__form-group--keyword">
            <input
              type="text"
              name="q"
              value={this.state.q}
              className="form-control"
              placeholder="Ex: 1.4 8V, Flex, Automático..."
              onInput={this.handleInputChange}
            />
          </div>

          <div className="search-filter-main__form-group search-filter-main__form-group--actions">
            <button
              type="button"
              className="btn button button--large w-100 search-filter-main__button-advance d-lg-none"
              onClick={this.handleFilterClick}
            >
              Busca Avançada
            </button>
            <button
              type="submit"
              onClick={this.handleSubmit}
              className="btn button button--primary button--large w-100 search-filter-main__button-submit"
            >
              <span className="d-lg-none">Buscar</span>
              {!this.props.isProcessing && (
                <i className="icon icon-search-b d-none d-lg-block" />
              )}
              {this.props.isProcessing && (
                <div className="spinner-border ml-2 d-lg-none" />
              )}
              {this.props.isProcessing && (
                <div className="spinner-border ml-2 d-none d-lg-block" />
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

SearchFilterMain.defaultProps = {
  initialListing: '',
  brands: [],
  kinds: [],
  models: {},
  years: [],
  isProcessing: false,
  showVehicleTypeFilter: false,
  ShowListingTypeChoice: true,
  /**
   * onSubmit deve retornar uma Promise
   * @returns {Promise}
   */
  onSubmit() {},
  onFilterClick() {},
  onListingsChange() {},
};

SearchFilterMain.propTypes = {
  initialListing: PropTypes.string,
  brands: PropTypes.arrayOf,
  models: PropTypes.objectOf,
  years: PropTypes.arrayOf,
  kinds: PropTypes.arrayOf,
  isProcessing: PropTypes.bool,
  showVehicleTypeFilter: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFilterClick: PropTypes.func,
  onListingsChange: PropTypes.func,
  ShowListingTypeChoice: PropTypes.bool,
};

/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';

class Item extends Component {
  getLink() {
    const { part_link, slug } = this.props;
    return `${part_link}/${slug}`;
  }

  render() {
    const {
      model,
      brand,
      year,
      release,
      slug,
      image,
      title,
      subtitle,
      show_price,
      price,
    } = this.props;

    return (
      <div className="card card--full-width-mobile">
        <div
          className="card__header"
          data-model={model}
          data-brand={brand}
          data-year={year}
          data-slug={slug}
        >
          {release && (
            <span className="card__badge badge badge-primary badge--pill">
              Novo
            </span>
          )}
          <a href={this.getLink()}>
            <div className="card__img">
              <img className="card__img-value" src={image} alt={title} />
            </div>
          </a>
        </div>

        <div className="card__content">
          <div
            data-match-height="card-vehicle-new-title"
            className="card__title"
          >
            {title}
          </div>
          {show_price && price && (
            <div className="card__price">A partir de R$ {price}</div>
          )}
        </div>

        <div className="card__footer">
          <div className="card__trigger-description">{subtitle}</div>
          <a
            href={this.getLink()}
            className="btn button button--link card__cta"
            data-model={model}
            data-brand={brand}
            data-year={year}
            data-slug={slug}
          >
            <i className="icon icon-arrow-r" />
            Saiba Mais
          </a>
        </div>
      </div>
    );
  }
}

export default Item;

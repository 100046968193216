import { Component } from 'preact';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import autoServiceGroupApi from '../../utils/autoServiceGroupApi';
import storeListPagination, {
  setPage,
  setPerPage,
  resetPerPage,
  setSort,
  resetSort,
  setFilters,
  setList,
} from '../../stores/ListPagination';
import { configureList } from '../ListPagination';
import SearchFilterSidebar from '../SearchFilterSidebar';

/**
 * Retorna true se deve adicionar parâmetro na url
 * @param {String} key
 * @param {Any} value
 * @param {Object} mapFields mapa com as chaves do filtro
 */
const shouldSetSearchParam = (key, value, mapFields = {}) =>
  value !== undefined &&
  value !== null &&
  value !== '' &&
  Object.keys(mapFields).includes(key);

/**
 * Retorna um arry entries com chave e valor do que deve ser setado na query string
 * @param {Object} filters
 * @param {Object} mapFields mapa com as chaves do filtro
 */
const getFiltersToSetSearchParam = (filters = {}, mapFields = {}) =>
  Object.entries(filters).filter(([key, value]) =>
    shouldSetSearchParam(key, value, mapFields),
  );

/**
 * Retorna o objeto URLSearchParams com os dados do filtro
 * @param {Object} filters
 * @param {Object} mapFields mapa com as chaves do filtro
 * @returns {URLSearchParams or undefined}
 */
export const getSearchParamsOfTheFilter = (filters = {}, mapFields = {}) => {
  const searchParams = new URLSearchParams();

  const filtersToSetSearchParam = getFiltersToSetSearchParam(
    filters,
    mapFields,
  );

  const shouldSetSearchParams = filtersToSetSearchParam.length > 0;

  if (shouldSetSearchParams) {
    filtersToSetSearchParam.forEach(([key, value]) => {
      if (value || value === 0) searchParams.set(key, value);
    });

    return searchParams;
  }

  return undefined;
};

export default class SearchFilterSidebarApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kinds: [],
      brands: [],
      categories: [],
      models: {},
      exchanges: [],
      colors: [],
    };

    this.setSelectFilterOptions();

    this.storeListPagination();

    this.setFiltersState = this.setFiltersState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(filters) {
    this.setUrlWithQueryString(filters);
    this.applyFiltersInStore(filters);
    setList().then(() => {
      configureList();
    });
  }

  /**
   * Altera url com dados do filtro, usado ao submeter o formulário
   */
  setUrlWithQueryString(filters) {
    const searchParams = getSearchParamsOfTheFilter(
      filters,
      this.props.mapFields,
    );
    if (searchParams) {
      const url = new URL(window.location);
      url.search = searchParams.toString();
      window.history.pushState(null, '', url);
    }
  }

  getSearchParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }

  setFiltersState(filters) {
    const years = Object.keys(filters.years).map(year => parseInt(year, 10));
    const minYear = Math.min.apply(null, years);
    let maxYear = Math.max.apply(null, years);

    const minPrice = filters.prices.min;
    let maxPrice = filters.prices.max;

    const minKm = filters.kms.min;
    let maxKm = filters.kms.max;

    if (minPrice === maxPrice) maxPrice += 10000;
    if (minKm === maxKm) maxKm += 1000;
    if (minYear === maxYear) maxYear += 1;

    this.setState({
      kinds: filters.kinds,
      brands: filters.brands,
      models: filters.models,
      exchanges: filters.shifts,
      categories: filters.categories,
      colors: filters.colors,
      minPrice,
      maxPrice,
      minKm,
      maxKm,
      minYear,
      maxYear,
    });
  }

  getFilters() {
    const params = [{ new_vehicle: this.props.usedModelsFilterNewVehicle }];

    if (this.props.filterFullStock) {
      return autoServiceGroupApi.getSelectFilterStockOptions(params);
    }

    return autoServiceGroupApi.getSelectFilterOptions(params);
  }

  setSelectFilterOptions() {
    this.getFilters().then(filters => this.setFiltersState(filters));
  }

  /**
   * Aplica os dados do filtro na store
   * @param {Object} params
   */
  applyFiltersInStore(params) {
    const { mapFields, defaultOrder } = this.props;
    setPage(1);

    if (params.selectOrderBy) {
      setSort(params.selectOrderBy);
    } else if (defaultOrder) {
      setSort(defaultOrder);
    } else {
      resetSort();
    }

    if (params.selectPerPage) {
      setPerPage(params.selectPerPage);
    } else {
      resetPerPage();
    }

    const filters = [];

    Object.entries(params).forEach(filter => {
      const [key, value] = filter;

      if (
        mapFields[key] &&
        value !== '' &&
        (!isNil(value) || mapFields[key].toLowerCase().includes('km'))
      )
        if (mapFields[key].indexOf('in') !== -1 && value.indexOf(',') !== -1) {
          // poderia ser usado o método includes do ES6,
          // mas ainda sem suporte para o IE
          const values = value.split(',');
          values.forEach(inValue => {
            filters.push({ [`${mapFields[key]}[]`]: inValue });
          });
        } else {
          filters.push({ [mapFields[key]]: value });
        }
    });

    setFilters(filters);
  }

  storeListPagination() {
    storeListPagination().watch(state => {
      this.setState({
        isFetching: state.isFetching,
      });
    });
  }

  render() {
    return (
      <SearchFilterSidebar
        customClass={this.props.customClass}
        kinds={this.state.kinds}
        brands={this.state.brands}
        models={this.state.models}
        colors={this.state.colors}
        exchanges={this.state.exchanges}
        categories={this.state.categories}
        minPrice={this.state.minPrice}
        maxPrice={this.state.maxPrice}
        minKm={this.state.minKm}
        maxKm={this.state.maxKm}
        minYear={this.state.minYear}
        maxYear={this.state.maxYear}
        states={this.props.states}
        cities={this.props.cities}
        units={this.props.units}
        isProcessing={this.state.isFetching}
        initialFilters={this.getSearchParams()}
        onSubmit={this.props.onSubmit || this.handleSubmit}
        onReset={this.props.onReset || this.handleSubmit}
        hasTypeVehicles={this.props.hasTypeVehicles}
        showVehicleTypeFilter={this.props.showVehicleTypeFilter}
        showCategoryFilter={this.props.showCategoryFilter}
        isHomePage={this.props.isHomePage}
      />
    );
  }
}

SearchFilterSidebarApp.defaultProps = {
  customClass: '',
  mapFields: {},
  usedModelsFilterNewVehicle: false,
  hasTypeVehicles: false,
  showVehicleTypeFilter: false,
  showCategoryFilter: false,
  filterFullStock: false,
  units: [],
  cities: [],
  states: [],
  onReset: null,
  onSubmit: null,
  defaultOrder: null,
};

SearchFilterSidebarApp.propTypes = {
  mapFields: PropTypes.objectOf,
  customClass: PropTypes.string,
  hasTypeVehicles: PropTypes.bool,
  filterFullStock: PropTypes.bool,
  showCategoryFilter: PropTypes.bool,
  /**
   * Argumento usado para alterar valores do filtro getSelectFilterOptions
   */
  usedModelsFilterNewVehicle: PropTypes.bool,
  showVehicleTypeFilter: PropTypes.bool,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  cities: PropTypes.arrayOf,
  states: PropTypes.arrayOf,
  defaultOrder: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

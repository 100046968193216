import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  querys,
  mapFilters,
  setURLSearchParams,
  clearURLSearchParams,
} from '%/utils/filters';
import { usedModelSortItems } from '%/utils/sort';
import SimpleFilterApp from '../SearchFilter/SimpleFilterApp';
import ResultSearch from '../SearchFilter/ResultSearch';
import AdvancedFilter from './AdvancedFilter';
import AdvanceFilterModal from './AdvanceFilterModal';

import {
  setFilter,
  setList,
  setSort,
  setKeyword,
  resetList,
  setShowResults,
} from '../../stores/SearchFilter';
import {
  setShowFilterAdvanceMobile,
  setShowComponent,
} from '../../stores/ListUsedModels';

import autoServiceApi from '../../utils/autoServiceApi';

export const COMPONENTS = {
  SIMPLE_FILTER: 'SimpleFilter',
  RESULT_SEARCH: 'ResultSearch',
  ADVANCED_FILTER: 'AdvancedFilter',
};

class SearchFilterUsedmodel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBrands: [],
      filterModels: {},
    };
    setSort(this.props.orderVehicles || '-featured,ordination,-created_at');

    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleFilterMobileClick = this.handleFilterMobileClick.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSetSortParams = this.handleSetSortParams.bind(this);
    this.handleCloseKeyword = this.handleCloseKeyword.bind(this);
    this.handleClickBackFilter = this.handleClickBackFilter.bind(this);
    this.handleClickClearFilter = this.handleClickClearFilter.bind(this);
    this.handleClickClearFilterModal =
      this.handleClickClearFilterModal.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleApplyInitialFilters = this.handleApplyInitialFilters.bind(this);

    window.store.ListUsedModels.watch(state => {
      this.setState({
        showComponent: state.showComponent,
      });
    });
  }

  componentDidMount() {
    const { filterByNewVehicle } = this.props;
    autoServiceApi
      .getSelectFilterOptions([{ new_vehicle: filterByNewVehicle }])
      .then(res => {
        const filterModels = {};

        const filterBrands = res.brands.map(({ title: brandTitle }) => {
          const title = brandTitle;

          filterModels[title] = res.models[title].map(model => ({
            label: model.title,
            value: model.title,
          }));

          return { label: title, value: title };
        });

        // Cria lista de anos do maior até o menor
        const years = Object.keys(res.years);
        const yearBegin = Math.min(...years);
        const yearEnd = Math.max(...years);
        const filterYears = [];
        for (let i = yearEnd; i >= yearBegin; i -= 1) {
          filterYears.push({
            name: i,
            value: i,
          });
        }

        this.setState({
          filterBrands,
          filterModels,
          filterYears,
        });
      });
  }

  handleFilterClick() {
    setShowComponent('AdvancedFilter');
  }

  handleFilterMobileClick() {
    setShowFilterAdvanceMobile(true);
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    setList().then(() => {
      setShowComponent('ResultSearch');
      setShowResults(true);
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams();
      }
    });
  }

  handleClickSearch() {
    setShowComponent('ResultSearch');
  }

  handleApplyInitialFilters() {
    setShowComponent('ResultSearch');
  }

  handleSearchClick(filter) {
    setFilter(filter);
    setKeyword(filter[querys.keyword]);
    setShowFilterAdvanceMobile(false);
  }

  handleCloseModal() {
    setShowFilterAdvanceMobile(false);
  }

  handleClickClearFilter() {
    this.clearFilter();
    setShowComponent('AdvancedFilter');
  }

  handleClickClearFilterModal() {
    this.clearFilter();
  }

  handleCloseKeyword() {
    clearURLSearchParams();
    setShowComponent('SimpleFilter');
    resetList();
  }

  handleClickBackFilter() {
    setShowComponent('SimpleFilter');
  }

  handleSetSortParams(params) {
    setSort(params);
    setList().then(() => {
      if (this.props.shouldSetURLSearchParams) {
        this.applyURLSearchParams();
      }
    });
  }

  get mapFilters() {
    return {
      ...mapFilters,
      [querys.keywordUsedModel]: 'q',
      'q[brand_eq]': 'brand',
      'q[model_in][]': 'model',
      ...this.props.mapFilters,
    };
  }

  applyURLSearchParams() {
    const { filter, filterArray, sort } = window.store.SearchFilter.getState();

    const filterSimple = {
      ...filter,
      sort,
    };

    setURLSearchParams(filterSimple, filterArray, this.mapFilters);
  }

  clearFilter() {
    setFilter({});
    setKeyword('');
    setList().then(() => {
      clearURLSearchParams();
    });
  }

  renderComponent() {
    const { showComponent } = this.state;
    const {
      classNameButtonFilter,
      classNameButtonOrder,
      showDropdownIcon,
      showActiveStatus,
    } = this.props;

    switch (showComponent) {
      case COMPONENTS.SIMPLE_FILTER:
        return (
          <SimpleFilterApp
            onFilterClick={this.handleFilterClick}
            onClickSearch={this.handleClickSearch}
            onApplyInitialFilters={this.handleApplyInitialFilters}
            isButtonFilterDropdown={false}
            classNameButtonFilter={classNameButtonFilter}
            classNameButtonOrder={classNameButtonOrder}
            showDropdownIcon={showDropdownIcon}
            showActiveStatus={showActiveStatus}
            fieldKeywordFilter={querys.keywordUsedModel}
            fieldSuggestion="model"
            shouldSetURLSearchParams={this.props.shouldSetURLSearchParams}
            mapFilters={this.mapFilters}
            sort={usedModelSortItems}
          />
        );

      case COMPONENTS.RESULT_SEARCH:
        return (
          <ResultSearch
            onCloseKeyword={this.handleCloseKeyword}
            onFilterClick={this.handleFilterClick}
            onFilterMobileClick={this.handleFilterMobileClick}
            onSetSortParams={this.handleSetSortParams}
            showDropdownIcon={showDropdownIcon}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const { showComponent, filterBrands, filterModels, filterYears } =
      this.state;
    const { units } = this.props;

    return (
      <div className="search-filter-simple">
        {this.renderComponent()}

        <form onSubmit={this.handleSearchSubmit}>
          <AdvancedFilter
            customClass={classNames('d-none', {
              'd-md-block': showComponent === COMPONENTS.ADVANCED_FILTER,
            })}
            onSearchClick={this.handleSearchClick}
            onClickBackFilter={this.handleClickBackFilter}
            onClickClearFilter={this.handleClickClearFilter}
            brands={filterBrands}
            filterModels={filterModels}
            models={[]}
            yearBegin={filterYears}
            yearEnd={filterYears}
            units={units}
          />
        </form>

        <form onSubmit={this.handleSearchSubmit}>
          <AdvanceFilterModal
            onSearchClick={this.handleSearchClick}
            onClose={this.handleCloseModal}
            onReset={this.handleClickClearFilterModal}
            brands={filterBrands}
            filterModels={filterModels}
            models={[]}
            yearBegin={filterYears}
            yearEnd={filterYears}
            units={units}
          />
        </form>
      </div>
    );
  }
}

SearchFilterUsedmodel.defaultProps = {
  units: [],
  filterByNewVehicle: false,
  shouldSetURLSearchParams: false,
  mapFilters: {},
  classNameButtonFilter: 'button--line-white',
  classNameButtonOrder: 'button--line-white',
  showDropdownIcon: false,
  showActiveStatus: false,
  orderVehicles: '',
};

SearchFilterUsedmodel.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  filterByNewVehicle: PropTypes.string, // usado para filtrar as marcas e modelos
  shouldSetURLSearchParams: PropTypes.bool,
  mapFilters: PropTypes.objectOf(PropTypes.string),
  orderVehicles: PropTypes.string,
  classNameButtonFilter: PropTypes.string,
  classNameButtonOrder: PropTypes.string,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
};

export default SearchFilterUsedmodel;

import { h, Component } from 'preact';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class ButtonFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultLabel:
        this.props.children.length > 0 ? this.props.children : 'Filtrar',
    };

    window.store.SearchFilter.watch(state => {
      this.setState({
        activeStatus: this.setActiveCategory(state.filterArray),
      });
    });
  }

  setActiveCategory(filters) {
    const categoryKey = 'q[model_category_in][]';
    const categoryFilter = filters.find(filter => filter[categoryKey]);
    if (!categoryFilter) return;

    return categoryFilter[categoryKey];
  }

  setLabel() {
    const { activeStatus, defaultLabel } = this.state;
    if (!this.props.showActiveStatus || !activeStatus) return defaultLabel;

    return activeStatus;
  }

  renderContent() {
    const { isOpen, showDropdownIcon } = this.props;

    return (
      <span>
        <span
          className={classNames({
            'd-none': !isOpen,
          })}
          data-testid="close"
        >
          Fechar
          {showDropdownIcon && <i className="icon icon-chevron"></i>}
        </span>

        <span
          className={classNames({
            'd-none': isOpen,
          })}
          data-testid="open"
        >
          <i class="icon icon-filter"></i>
          {this.setLabel()}
          {showDropdownIcon && <i className="icon icon-chevron"></i>}
        </span>
      </span>
    );
  }

  render() {
    const { className, onClick } = this.props;
    return (
      <a
        class={classNames(
          'btn button button--large button--no-upper',
          className,
        )}
        href="#"
        onClick={e => onClick(e)}
      >
        {this.renderContent()}
      </a>
    );
  }
}

ButtonFilter.defaultProps = {
  onClick: () => {},
  isOpen: false,
  showDropdownIcon: false,
  showActiveStatus: false,
};

ButtonFilter.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  showActiveStatus: PropTypes.bool,
};

export default ButtonFilter;

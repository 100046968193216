import { h } from 'preact';
import { Component } from 'react';
import classNames from 'classnames';
import {
  setPlans,
  setAvailableMonths,
  setSelectedMonths
} from '../../stores/consortium';

export default class PlanMonthsRadiobox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // selectedMonths representa a quantidade de meses que o usuário escolheu,
      // que varia de acordo com o plano.
      selectedMonths: null,
      availableMonths: []
    };

    this.handleSelectMonths = this.handleSelectMonths.bind(this);

    window.store.Consortium.watch(setAvailableMonths, ({ availableMonths }) => {
      this.setState({
        availableMonths
      });
    });

    window.store.Consortium.watch(setSelectedMonths, ({ selectedMonths }) => {
      this.setState({
        selectedMonths
      });
    });

    window.store.Consortium.watch(setPlans, ({ plans }) => {
      this.setState({
        availableMonths: plans.plans
      });
    });
  }

  componentDidMount() {
    if (this.state.selectedMonths == null) {
      setSelectedMonths({
        selectedMonths: this.getFirstAvailableMonths()
      });
    }
  }

  getFirstAvailableMonths() {
    return (
      this.state.availableMonths.length > 0 &&
      this.state.availableMonths[0].months
    );
  }
  
  handleSelectMonths(months) {
    let radiobtn = document.getElementById("months_" + months);
    radiobtn.checked = true;
    document.querySelectorAll('.showcase__plan-chooser-months .selected').forEach(box => {
      box.classList.remove('selected');
    });
    radiobtn.parentNode.classList.add("selected");
    setSelectedMonths({ selectedMonths: months })
  }

  renderPills() {
    return (
      this.state.availableMonths.length > 0 &&
      this.state.availableMonths.map(({ months }) => {
        return (
          <span className={`showcase__plan-chooser-month${this.state.selectedMonths === months ? ' selected' : ''}`} onClick={() => this.handleSelectMonths(months)}>
            <input type="radio" id={"months_" + months} name={"months"} value={months} checked={this.state.selectedMonths === months}/>
            <label for="html">{months}&times;</label>
          </span>
        );
      })
    );
  }

  render() {
    return (
      <div class="showcase__plan-chooser-months">{this.renderPills()}</div>
    );
  }
}

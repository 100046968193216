import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Detash from './Detash';
import { get } from "lodash";
import classNames from "classnames";

export default class DetailTabs extends Component {
  constructor(props) {
    super(props);

    // A chave `labels` só serve para guardar valores já convertidos para "id",
    // melhorando a velocidade da renderização.
    this.memo = {
      labels: {},
    };

    this.state = {
      selectedTab: null,
      content: [],
      expandedTabs: {
        'Especificações': false,
        'Conforto': false,
        'Segurança': false,
        'Conectividade': false,
      },
    };

    this.listTabs = [
      'Especificações',
      'Conforto',
      'Segurança',
      'Conectividade',
    ];

    this.refList = [];
    this.refPanel = [];
    this.refAnchors = [];

    this.labelToId = this.labelToId.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleSeeMore = this.handleSeeMore.bind(this);
    this.watchStore();
  }

  watchStore() {
    window.store &&
      window.store.vehicleVersionDetails.watch(({ activeVersion }) => {
        activeVersion &&
          this.setState(
            {
              selectedTab: activeVersion.selectedTab || this.listTabs[0],
              content: [
                activeVersion.detail_list,
                activeVersion.comfort_list,
                activeVersion.item_list,
                activeVersion.connectivity_list,
              ],
              selectedImage: get(activeVersion, 'profile_image.profile_image.url', '') || get(activeVersion, 'profile_image.url', '') || this.state.selectedImage,
              selectedTitle: activeVersion.title && (this.props.titleMultiline ? activeVersion.title : `${activeVersion.title} ${activeVersion.subtitle}`) || this.state.selectedTitle,
              selectedSubTitle: `${activeVersion.subtitle || this.state.selectedSubTitle}`,
              selectedSlug: activeVersion.slug || this.state.selectedSlug
            },
            () => {
              if (typeof $ === 'undefined') return;
              $('.tabs__link').first().trigger('click');
            }
          );
      });
  }

  /**
   * Converte uma label para um ID, removendo acentuação e convertendo a fonte
   * para lowecase.
   * @param {string} label
   */
  labelToId(label) {
    if (!label) return false;

    if (this.memo.labels[label]) {
      return this.memo.labels[label];
    }

    this.memo.labels[label] = label
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-');
    return this.labelToId(label);
  }

  /**
   * Renderiza a lista com as tabs
   */
  renderTabs() {
    const { content } = this.state;
    let items = this.listTabs.map((item, index) => {
      if (content[index] && content[index].length > 0) {
        return (
          <li key={this.labelToId(item)} className="tabs__item">
            <a
              onClick={(e) => this.handleSelectTab(e, item)}
              className={
                'tabs__link ' + (this.state.selectedTab === item && 'active')
              }
              href={'#' + this.labelToId(item)}
              id={'technical_sheet_tab_' + this.labelToId(item)}
            >
              {item}
            </a>
          </li>
        );
      }
    });
    return <ul className="nav tabs__nav">{items}</ul>;
  }

  /**
   * Converte o conteúdo de um item em texto. Útil para renderizar de forma
   * diferente baseada na estrutura de `item`.
   * @returns {string}
   */
  itemToText(item) {
    if (item.includes(':')) {
      item = item.split(':');
      return (
        <span>
          <strong>{item[0]}</strong>: {item[1]}
        </span>
      );
    }

    if (typeof item === 'string') {
      return item;
    }
  }

  /**
   * Indica se a listagem é de detalhes. Util para verificar quando devemos
   * inserir bullets na listagem.
   * @param {string[]} categoryItems
   * @return {bool}
   */
  isDetailsList(categoryItems) {
    return categoryItems.reduce((acc, cur) => acc && cur.includes(':'), true);
  }

  /**
   * Gerencia a expansão das tabs de detalhes do veículo
   * @param event
   */
  handleSeeMore(event) {
    event.preventDefault();

    const { selectedTab, expandedTabs } = this.state;

    this.setState({
      expandedTabs: { ...expandedTabs, [selectedTab]: !this.state.expandedTabs[selectedTab] },
    });
  }

  /**
   * Renderiza os paineis de conteúdo das tabs
   */
  renderContentPanels() {
    const { seeMore: { enabled: seeMoreEnabled, heightLimit, seeMoreText } } = this.props;

    const panels = this.state.content.map((category, index) => (
      <div
        key={'panel-' + this.labelToId(this.listTabs[index])}
        id={this.labelToId(this.listTabs[index])}
        ref={node => this.refPanel[this.listTabs[index]] = node}
        className={classNames('tab-pane tabs__content', {
          'active': this.state.selectedTab === this.listTabs[index],
          'tabs__content--expanded': this.state.expandedTabs[this.listTabs[index]],
        })}
      >
        <div className={"tabs__content-list-wrapper"}>
          <ul
            className={
              'list list--specs-items ' +
              (category && this.isDetailsList(category) ? 'list--no-style' : 'list--primary')
            }
            ref={node => this.refList[this.listTabs[index]] = node}
          >
            {category && category.length > 0 &&
              category.map((item) => <li>{this.itemToText(item)}</li>)}
          </ul>
        </div>

        {seeMoreEnabled &&
          <a className={"tabs__content-see-more"}
            href={"#"}
            ref={node => this.refAnchors[this.listTabs[index]] = node}
            onClick={this.handleSeeMore}>
            {this.state.expandedTabs[this.listTabs[index]] ?
              'Fechar' : seeMoreText ? <span className={"seeMoreText"} dangerouslySetInnerHTML={{ __html: seeMoreText }} /> : 'Ver mais +'}
          </a>
        }
      </div>
    ));

    if (seeMoreEnabled) {

      const { selectedTab } = this.state;

      (() => {
        setTimeout(() => {

          if (!this.refList[selectedTab]) return;

          const listHeight = this.refList[selectedTab].getBoundingClientRect().height;

          if (listHeight > heightLimit) {
            this.refPanel[selectedTab].classList.add('tabs__content--height-limited');
          }

        }, 50);
      })()
    }

    return <div>{panels}</div>;
  }

  /**
   * Gerencia a exibição de conteúdo da tab e seu estado.
   * @param {Event} e
   * @param {string} selectedTab O label da tab selecionada
   */
  handleSelectTab(e, selectedTab) {
    e.preventDefault();
    this.setState({
      selectedTab,
    });
  }

  render() {
    const { detash, showImage, titleMultiline, showLink } = this.props;
    const { selectedImage, selectedTitle, selectedSubTitle, selectedSlug } = this.state;
    return (
      <div className="detail-tabs">
        <div className="card-panel card-panel--into-space">
          {detash}
          {showImage && (
            <div className="detail-tabs__image">
              {!titleMultiline && <strong className="detail-tabs__title">{selectedTitle}</strong>}
              {titleMultiline && <h2 className="detail-tabs__title">{selectedTitle}</h2>}
              {titleMultiline && <h3 className="detail-tabs__subtitle">{selectedSubTitle}</h3>}
              {selectedImage && <div className="detail-tabs__image-wrap"><img src={selectedImage} alt={`Foto do ${selectedTitle}`} /></div>}
              {showLink && <a className="btn button button--primary w-100" href="#conversion-from-new-model">{this.props.showLinkButtonName}</a>}
            </div>
          )}
          <div className="tabs">
            {this.renderTabs()}
            {this.renderContentPanels()}
          </div>
        </div>
      </div>
    );
  }
}

DetailTabs.Detash = Detash;

DetailTabs.defaultProps = {
  showImage: false,
  showLink: false,
  titleMultiline: false,
  showLinkButtonName: 'Tenho interesse',
  seeMore: {
    enabled: false,
    heightLimit: 500,
  },
};

DetailTabs.propTypes = {
  detash: PropTypes.node,
  showImage: PropTypes.bool,
  showLink: PropTypes.bool,
  titleMultiline: PropTypes.bool,
  showLinkButtonName: PropTypes.string,
  seeMore: PropTypes.shape({
    enabled: PropTypes.bool,
    heightLimit: PropTypes.number
  }),
};

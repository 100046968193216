import { Component } from 'preact';
import PropTypes from 'prop-types';
import autoServiceGroupApi from '../../utils/autoServiceGroupApi';
import SearchFilterMain from '../SearchFilterMain';
import { addClassOpen as addClassOpenFilter } from '../SearchFilterSidebar';
import { getSearchParamsOfTheFilter } from '../SearchFilterSidebarApp';
import SearchFilterSidebarUsedModelsApp, {
  mapFields as mapFieldsUsedModels,
} from '../SearchFilterSidebarApp/UsedModels';
import SearchFilterSidebarUsedModelsSpecial, {
  mapFields as mapFieldsUsedModelsSpecial,
} from '../SearchFilterSidebarApp/UsedModelsSpecial';
import SearchFilterSidebarFullStockApp, {
  mapFields as mapFieldsFullStock,
} from '../SearchFilterSidebarApp/UsedModelsFullStock';

const LIST_TYPES = {
  USED_MODELS: 'seminovos',
  USED_MODELS_SPECIAL: 'ofertas-especiais',
  FULL_STOCK: 'estoque',
};

const mapFields = {
  [LIST_TYPES.USED_MODELS]: mapFieldsUsedModels,
  [LIST_TYPES.USED_MODELS_SPECIAL]: mapFieldsUsedModelsSpecial,
  [LIST_TYPES.FULL_STOCK]: mapFieldsFullStock,
};

export default class SearchFilterMainApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
      kinds: [],
      models: {},
      years: [],
      isProcessing: false,
      listing: this.props.showFullStock
        ? LIST_TYPES.FULL_STOCK
        : LIST_TYPES.USED_MODELS,
    };

    this.setSelectFilterOptions();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleListingsChange = this.handleListingsChange.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.setFiltersState = this.setFiltersState.bind(this);
    this.getFilters = this.getFilters.bind(this);
  }

  handleFilterClick() {
    addClassOpenFilter();
  }

  handleSubmit(params) {
    this.setState({ isProcessing: true });

    const searchParams = getSearchParamsOfTheFilter(
      params,
      mapFields[this.state.listing],
    );
    const paths = {
      [LIST_TYPES.USED_MODELS]: this.props.usedvehiclesRedirectUrl,
      [LIST_TYPES.USED_MODELS_SPECIAL]: this.props.newvehiclesRedirectUrl,
      [LIST_TYPES.FULL_STOCK]: this.props.fullStockRedirectUrl,
    };

    const { origin } = window.location;
    const redirectUrl = paths[this.state.listing];
    const url = new window.URL(`${origin}${redirectUrl}`);

    if (searchParams) {
      url.search = searchParams.toString();
    }

    window.location = url.href;
  }

  handleListingsChange(e) {
    const { value } = e.target;

    this.setState({ listing: value });

    this.getFilters().then(filters => this.setFiltersState(filters));
  }

  getFilters() {
    if (this.props.showFullStock) {
      return autoServiceGroupApi.getSelectFilterStockOptions();
    }

    return autoServiceGroupApi.getSelectFilterOptions();
  }

  setFiltersState(filters) {
    this.setState({
      kinds: filters.kinds,
      brands: filters.brands,
      models: filters.models,
      years: this.filterYearWithZeroVehicles(filters.years),
    });
  }

  setSelectFilterOptions() {
    this.getFilters().then(filters => this.setFiltersState(filters));
  }

  get selectYears() {
    return this.state.years.sort();
  }

  filterYearWithZeroVehicles(yearVehicles) {
    // Data format yearVehicles = { <year>: <quantity> ...}, example = { 2010: 2, 2020: 1, 1999: 3}
    return Object.entries(yearVehicles)
      .filter(yearVehicle => yearVehicle[1] > 0)
      .map(year => parseInt(year[0], 10));
  }

  render() {
    return (
      <div>
        <SearchFilterMain
          initialListing={this.state.listing}
          ShowListingTypeChoice={!this.props.showFullStock}
          kinds={this.state.kinds}
          brands={this.state.brands}
          models={this.state.models}
          years={this.selectYears}
          isProcessing={this.state.isProcessing}
          showVehicleTypeFilter={this.props.showVehicleTypeFilter}
          onSubmit={this.handleSubmit}
          onListingsChange={this.handleListingsChange}
          onFilterClick={this.handleFilterClick}
        />
        {this.state.listing === LIST_TYPES.USED_MODELS_SPECIAL && (
          <SearchFilterSidebarUsedModelsSpecial
            customClass="search-filter-sidebar--mobile-only"
            units={this.props.units}
            states={this.props.states}
            cities={this.props.cities}
            onSubmit={this.handleSubmit}
            onReset={() => {}}
            showVehicleTypeFilter={this.props.showVehicleTypeFilter}
            isHomePage={this.props.isHomePage}
          />
        )}
        {this.state.listing === LIST_TYPES.USED_MODELS && (
          <SearchFilterSidebarUsedModelsApp
            customClass="search-filter-sidebar--mobile-only"
            units={this.props.units}
            states={this.props.states}
            cities={this.props.cities}
            onSubmit={this.handleSubmit}
            onReset={() => {}}
            showVehicleTypeFilter={this.props.showVehicleTypeFilter}
            isHomePage={this.props.isHomePage}
          />
        )}
        {this.state.listing === LIST_TYPES.FULL_STOCK && (
          <SearchFilterSidebarFullStockApp
            customClass="search-filter-sidebar--mobile-only"
            units={this.props.units}
            states={this.props.states}
            cities={this.props.cities}
            onSubmit={this.handleSubmit}
            onReset={() => {}}
            showVehicleTypeFilter={this.props.showVehicleTypeFilter}
            isHomePage={this.props.isHomePage}
          />
        )}
      </div>
    );
  }
}

SearchFilterMainApp.defaultProps = {
  units: [],
  cities: [],
  states: [],
  showVehicleTypeFilter: false,
  showFullStock: false,
  fullStockRedirectUrl: 'estoque',
};

SearchFilterMainApp.propTypes = {
  usedvehiclesRedirectUrl: PropTypes.string.isRequired,
  newvehiclesRedirectUrl: PropTypes.string.isRequired,
  fullStockRedirectUrl: PropTypes.string,
  states: PropTypes.arrayOf,
  cities: PropTypes.arrayOf,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  showFullStock: PropTypes.bool,
  showVehicleTypeFilter: PropTypes.bool,
};

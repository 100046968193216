import fetchival from 'fetchival';
import fetchCached from './utils/fetchCached';
import autoHookConversion from './utils/autoHookConversion';

/**
 * @typedef ServiceOptions
 * @property {string} baseUrl URL base das requisições
 * @property {string} token Token de requisição
 * @property {string} [account] A conta a ser utilizada
 * @property {string} [channel] O canal da conta
 * @property {string} [channelToken] O token de identificação do canal
 */

/**
 * Valida se o objeto de opções do fetcher é válido e se as chaves requeridas
 * possuem valores não nulos.
 * @param {ServiceOptions} options Opções para o fetcher
 */
export function optionsAreValid(options) {
  const mandatoryOptions = ['baseUrl', 'token'];
  return mandatoryOptions.reduce(
    (acc, cur) => acc && options[cur] != null,
    true
  );
}

/**
 * Service de requisições para a API da AutoForce.
 * @property {ServiceOptions} options
 * @property {*} _serviceFetcher Objeto que faz requisições
 */
export default class AutoForceService {
  /**
   * @param {ServiceOptions} options
   */
  constructor(options) {
    if (!optionsAreValid(options)) {
      throw 'URL base da API e/ou token não informados.';
    }

    this.options = options;

    // Configuramos o fetcher com uma aplicação parcial da função `fetch`.
    this._serviceFetcher = (url, fetcherOptions) =>
      fetchival(`${options.baseUrl}/${url}`, {
        ...{
          headers: {
            Authorization: `Token token=${options.token}`
          }
        },
        ...fetcherOptions
      });
  }

  /**
   * @return {fetchival}
   */
  get serviceFetcher() {
    return this._serviceFetcher;
  }

  /**
   * Obtém um modelo novo por um dado atributo.
   * O parâmetro de query é convertido para
   * "q[<attribute>_eq]=<attributeValue>".
   * O sufixo de comparação nesse caso é sempre "_eq", logo sempre esperamos que
   * o valor do atributo seja igual ao que passamos na função.
   *
   * Supondo que `attribute` seja "*slug*" e `attributeValue` seja
   * "*rs-7-sportback*", a query de filtragem na API será montada da seguinte
   * forma: `q[slug_eq]=rs-7-sportback`.
   *
   * @param {string} attribute O atributo a ser buscado na API
   * @param {*} attributeValue O valor do atributo
   * @param {object[]} queryOptions Opções de query da API
   * @returns {fetchival}
   */
  getNewModelByAttribute(attribute, attributeValue, queryOptions = []) {
    return fetchCached(
      `/channel/${this.options.account}/clones`,
      [{ [`q[${attribute}_eq]`]: attributeValue }].concat(queryOptions)
    );
  }

  /**
   * Obtém os detalhes de um modelo novo baseado no ID do clone.
   * @param {number|string} cloneId O ID do clone
   * @param {object[]} queryOptions
   * @returns {Promise}
   */
  getNewModelDetails(cloneId, queryOptions = []) {
    return fetchCached(
      `/clones/${cloneId}/children`,
      [
        { include: 'profile_image,name,show_price?' },
        {
          "q": JSON.stringify({
            active_eq: true,
            with_active_version: true
          })
        },
        { sort: 'ordination' }
      ].concat(queryOptions),
      5
    );
  }

  /**
   * Efetua uma conversão no AutoRacing.
   * @param {*} data
   * @returns {Promise}
   */
  convert(data) {
    let conversion = {
      ...data,
      origin: this.options.channelToken
    };

    return this.serviceFetcher('autoracing')
      .post(conversion)
      .then(() => {
        /**
         * função padrao que deve ser chamado sempre que uma
         * conversão for enviada
         */
        autoHookConversion(conversion);

        /**
         * sempre que uma conversão for enviada com sucesso,
         * esse método vai ser chamado enviando os dados por parametro
         */
        hookConversion(conversion);
      });
  }

  /**
   * Endpoint no contexto do canal
   *
   * @param {string} suffixEndpoint Sufixo do endpoint da request
   * @returns {string} Retorna o endpoint completo
   */
  getChannelEndpoint(suffixEndpoint) {
    return `/channel/${this.options.channel + suffixEndpoint}`;
  }

  /**
   * Lista os clones com base nos parâmetros
   *
   * @param {object[]} customParams Parâmetros adicionais para a request
   * @returns {Promisse}
   */
  getClones(customParams) {
    return fetchCached(
      this.getChannelEndpoint('/clones'),
      [{ include: 'profile_image' }].concat(customParams)
    );
  }

  /**
   * Obtem o ID da proposta na simulação de financiamento
   * @param {*} data
   * @returns {Promise}
   */
  createFinancingSimulation(data) {
    return this.serviceFetcher('financing-simulator-stellantis').post(data);
  }

  /**
   * Obtem as opções de financiamento
   * @param {*} data
   * @returns {Promise}
   */
  checkFinancingSimulation(data) {
    return this.serviceFetcher('check-simulation').post(data);
  }

  /**
   * Finaliza a simulação de financiamento
   * @param {*} data
   * @returns {Promise}
   */
  finishFinancingSimulation(data) {
    return this.serviceFetcher('finish-simulation-stellantis').post(data);
  }
}

/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';

class ItemV2 extends Component {
  getLink() {
    const { cardLink, slug } = this.props;
    return `${cardLink}/${slug}`;
  }

  formattedPrice(price) {
    const formattedPriceValue = price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedPriceValue;
  }

  render() {
    const {
      model,
      brand,
      name,
      year,
      release,
      slug,
      image,
      subtitle,
      show_price,
      price,
      isOnFirstLine,
    } = this.props;

    return (
      <div className="card card--full-width-mobile">
        <div
          className="card__header"
          data-model={model}
          data-brand={brand}
          data-year={year}
          data-slug={slug}
        >
          {release && (
            <span className="card__badge badge badge-primary badge--pill">
              Novo
            </span>
          )}
          <a href={this.getLink()}>
            <div className="card__img">
              <img
                className="card__img-value"
                loading={isOnFirstLine ? 'auto' : 'lazy'}
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority={isOnFirstLine ? 'high' : 'low'}
                src={image}
                alt={name}
                width={185}
                height={130}
              />
            </div>
          </a>
        </div>

        <div className="card__content">
          <div
            data-match-height="card-vehicle-new-title"
            className="card__title"
          >
            {name}
          </div>
          {show_price && !!price && (
            <div className="card__price">
              A partir de {this.formattedPrice(price)}
            </div>
          )}
        </div>

        <div className="card__footer">
          <div className="card__trigger-description">{subtitle}</div>
          <a
            href={this.getLink()}
            className="btn button button--link card__cta"
            data-model={model}
            data-brand={brand}
            data-year={year}
            data-slug={slug}
          >
            <i className="icon icon-arrow-r" />
            Saiba Mais
          </a>
        </div>
      </div>
    );
  }
}

export default ItemV2;

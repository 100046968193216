import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import noUiSlider from 'nouislider'
import { debounce } from 'lodash'

export default class InputRange extends Component {

  createRange() {
    const { min, max, step, format } = this.props
    const [ minStart, maxStart ] = this.props.value
    
    let options = {
      step,
      start: [minStart, maxStart],
      connect: true,
      range: {
        min,
        max,
      }
    }

    if (format) {
      options.format = format
    }

    noUiSlider.create(this.ref, options)

    const onChange = debounce(this.props.onChange, 30)

    this.ref.noUiSlider.on('update', (values) => {
      onChange(values)
    })

    this.props.onSetRef(this.ref)
  }

  componentDidMount() {
    this.createRange()
  }

  componentWillUnmount() {
    this.ref.noUiSlider.off()
    this.ref.noUiSlider = null
  }

  componentDidUpdate(prevProps) {
    const { disabled, min, max } = this.props

    if (disabled)
      this.ref.setAttribute('disabled', true)
    else
      this.ref.removeAttribute('disabled')

    if (prevProps.min !== min || prevProps.max !== max) {
      this.ref.noUiSlider.updateOptions({
        range: { min, max }
      })
      this.props.onChange([min, max])
    }
  }

  render() {
    return <div ref={node => this.ref = node} />
  }
}

InputRange.defaultProps = {
  onChange() {},
  onSetRef() {},
  disabled: false,
  step: 1,
}

InputRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSetRef: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  /**
   * Ver https://refreshless.com/nouislider/number-formatting/
   */
  format: PropTypes.object,
}

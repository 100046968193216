import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import FormMessageOverlay from './FormMessageOverlay';
import SubmitButton from './SubmitButton';
import classNames from 'classnames';

/**
 * @property {VariantCollection} variantsCollection
 */
export default class ConsortiumSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalValue: this.props.minTotalValue,
      valueType: "total"
    };
    this.handleUpdateTotalValue = this.handleUpdateTotalValue.bind(this);
    this.updateValueType = this.updateValueType.bind(this);
    this.applyFilters();
  }

  getSearchParams() {
    let searchParams = new URLSearchParams(window.location.search)
    let params = {}
    for (let [key, value] of searchParams.entries()) {
      params[key] = value
    }
    return params
  }

  applyFilters() {

    const params = this.getSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      switch(key) {
        case "consortium_plans_total_value_lteq_all":
          this.setState({valueType: "total", totalValue: value});
          break;
        case "consortium_plans_value_per_month_lteq_all":
          this.setState({valueType: "month", totalValue: value});
          break;
      }

    });
  }

  handleUpdateTotalValue(event) {
    event.preventDefault()
    this.setState({totalValue: event.target.value})
  }

  updateValueType(event) {
    event.preventDefault()
    this.setState({
      valueType: event.target.value,
      totalValue: event.target.value == "total" ? this.props.maxTotalValue : this.props.maxValuePerMonth
    })
  }

  formatNumber(value) {
    if (value instanceof Number) return value
    if (value.includes('.')) return value

    return Intl.NumberFormat('pt-BR').format(value)
  }

  render() {
    return (
      <form
        className="search-form"
        ref={form => (this.form = form)}
        id={this.props.formId}
        onSubmit={this.handleSubmit}
        action={this.props.formAction}
        novalidate
      >
        <FormMessageOverlay
          handleClose={this.props.handleCloseOverlay}
          isVisible={this.props.showOverlay}
          type={this.props.errorSendingForm ? 'error' : 'success'}
        />
        <div className="form-conversion__body">
          <header>
            <h2 className="form-conversion__title">Simule seu plano!</h2>
          </header>
          <fieldset>
          <h4>Fazer simulação utilizando:</h4>
          <div className='slider-type-buttons'>
            <button className={`slider-type-select-button ${this.state.valueType == "total" && "selected" || ""}`} onClick={this.updateValueType} value="total">Valor da Carta</button>
            <button className={`slider-type-select-button ${this.state.valueType == "month" && "selected" || ""}`} onClick={this.updateValueType} value="month">Valor da Parcela</button>
          </div>
          <hr/>
          <h4>Escolha o valor:</h4>
          <div className='range-output'>
            <span className='range-output-min'>R$ {this.formatNumber(this.state.valueType == "total" ? this.props.minTotalValue : this.props.minValuePerMonth)},00</span>
            <span className='range-output-max'>R$ {this.formatNumber(this.state.valueType == "total" ? this.props.maxTotalValue : this.props.maxValuePerMonth)},00</span>
          </div>
          {
            this.state.valueType == "total" && (
              <input
                name="consortium_plans_total_value_lteq_all"
                type="range"
                min={this.props.minTotalValue}
                max={this.props.maxTotalValue}
                step="1"
                value={this.state.totalValue}
                data-orientation="vertical"
                onChange={this.handleUpdateTotalValue}
              />
            )
          }
          {
            this.state.valueType == "month" && (
              <input
                name="consortium_plans_value_per_month_lteq_all"
                type="range"
                min={this.props.minValuePerMonth}
                max={this.props.maxValuePerMonth}
                step="1"
                value={this.state.totalValue}
                data-orientation="vertical"
                onChange={this.handleUpdateTotalValue}
              />
            )
          }
          <div className='slider-value-display'>
            <span className='currency'>R$</span>
            <span className='slider-value'>{this.formatNumber(this.state.totalValue)},00</span>
          </div>
          <hr/>
          </fieldset>
          <SubmitButton
            classes={classNames('btn button button--large button--primary w-100')}
            label="FAZER SIMULAÇÃO"
            isSubmitting={this.props.isSubmittingForm}
            handleClick={this.handleSubmit}
          />
        </div>
      </form>
    );
  }
}

ConsortiumSearchForm.defaultProps = {
  maxTotalValue: "0",
  maxValuePerMonth: "0",
};

ConsortiumSearchForm.propTypes = {
  maxTotalValue: PropTypes.string,
  maxValuePerMonth: PropTypes.string,
  formAction: PropTypes.string,
};

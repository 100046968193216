export const phoneMasks = [
  {
    mask: '(00) 0000-0000'
  },
  {
    mask: '(00) 00000-0000'
  }
];

export const phoneMasksPt = [
  {
    mask: '+000 000 000 000'
  }
];

export const cpfMasks = [
  {
    mask: '000.000.000-00'
  }
];

export const cnpjMasks = [
  {
    mask: '00.000.000/0000-00',
  },
];

export const dateMasks = [
  {
    mask: '00/00/0000'
  }
];

export const priceMask = {
  mask: 'R$ num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: '.',
      scale: 2,
      padFractionalZeros: false,
      radix: ',',
      mapToRadix: [','],
    }
  }
};

export const plateMasks = [{ mask: 'aaa-0a00' }, { mask: 'aaa-0000' }];

export const kmMask = [{
  mask: 'num km ',
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: '.'
    }
  }
}];

export const priceWithoutThousandsSeparatorMask = {
  mask: 'R$ num',
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ''
    }
  }
};

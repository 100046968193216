import { h } from 'preact';

const ItemPlaceholder = () => (
  <div class="ph-item" style="width: 100%;">
    <div class="ph-col-12">
      <div class="ph-picture" />
      <div class="ph-row">
        <div class="ph-col-2 empty" />
        <div class="ph-col-8 big" />
        <div class="ph-col-2 empty" />
      </div>
      <div class="ph-row">
        <div class="ph-col-8" />
        <div class="ph-col-8" />
        <div class="ph-col-8" />
      </div>
      <div class="ph-row">
        <div class="ph-col-2 empty" />
        <div class="ph-col-8 big" />
        <div class="ph-col-2 empty" />
      </div>
    </div>
  </div>
);

export default ItemPlaceholder;

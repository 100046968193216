export const conversionEventWithUserData = (eventName, conversion) => {
  const { name, phone, email, bait_type } = conversion;

  const namesList = name.split(' ');
  const firstName = namesList.shift();
  const lastName = namesList.join(' ');
  const isWhatsappConversion = bait_type === 'whatsapp';

  return {
    event: eventName,
    nome: firstName,
    sobrenome: lastName,
    telefone: phone,
    ...(!isWhatsappConversion && { email }),
  };
};

import { createStore } from 'effector';

/**
 * Cria ou retorna a instância do store para os
 * resultados de pesquisa dos veículos
 */
const vehicleVersionDetails = (function(){
  let instance;

  return function(activeVersion = {}){
    if(!instance) {
      instance = createStore(activeVersion);
    }

    return instance
  };
})();

export default vehicleVersionDetails;

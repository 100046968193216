import { addField } from '../../stores/CustomFormFields';
import { handleOnBlur } from './handleOnBlur';
import { makeHandleFieldChange } from './makeHandleFieldChange';
import { makeHandleFieldInput } from './makeHandleFieldInput';
import { getValidators } from './validation';

/**
 * Registra um campo e retorna as propriedades do campo para serem usadas em um componente.
 *
 * @param {string} fieldName - Nome do campo.
 * @param {{
 *   validation?: {
 *     required?: boolean,
 *     min?: number,
 *     max?: number,
 *     minLength?: number,
 *     maxLength?: number,
 *     pattern?: RegExp,
 *     validEmail?: boolean,
 *     validPhone?: boolean,
 *     validCpf?: boolean
 *     validCnpj?: boolean
 *   },
 *   mask?: 'phone' | 'cpf' | 'cnpj',
 *   callback?: Function
 * }} [config={}] - Configuração opcional para personalizar o comportamento do campo.
 *
 * @returns {{ onChange: Function, name: string, value?: * }} - Propriedades do campo para serem utilizadas em um componente.
 *
 * @example
 * // Exemplo de uso:
 * const fieldProps = registerField('username', { validation: { required: true, minLength: 3, maxLength: 10 }, mask: 'phone', callback: () => {} });
 * // Resultado esperado: { name: 'username', onChange: Function, onInput: Function, onKeyUp?: Function }
 */

export const registerField = (fieldName, config = {}) => {
  const validators = getValidators(config.validation);

  const handleFieldInput = makeHandleFieldInput(validators, config.mask);
  const handleFieldChange = makeHandleFieldChange(validators, config.callback);

  const fieldProps = {
    name: fieldName,
    onInput: handleFieldInput,
    onChange: handleFieldChange,
    onBlur: handleOnBlur,
  };

  addField({
    key: fieldName,
    validators,
  });

  return fieldProps;
};

import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '#/CustomSelect';
import CustomCheck from '#/CustomCheck';
import SubmitButton from '#/SubmitButton';
import FormMessageOverlay from './FormMessageOverlay';
import handleInputChange from '%/utils/handleInputChange';
import handleUpdateState from '%/utils/handleUpdateState';
import validationFactory from '%/utils/validation';
import maskField, { MASK_TYPES } from '%/utils/maskField';
import getUTM from '%/utils/utm';
import InputCpf from './InputCpf';

class DirectSalesForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.clearForm = this.clearForm.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleUpdateState = handleUpdateState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.handleContactOptionsChange = this.handleContactOptionsChange.bind(this);
  }

  resetState() {
    this.setState({
      email: '',
      name: '',
      phone: '',
      mailing: false,
      phoning: false,
      whatsapping: false,
      contactOptions: '',
      cpf: '',
      dataPermissions: false,
      versionValidStatus: true,
    });
  }

  /**
   * Validação extra para o cpf
   */
  cpfValid() {
    return !this.props.showCpf || (this.props.showCpf && this.state.cpf)
  }

  versionValid() {
    return this.state.model_car;
  }

  handleSubmit(e) {
    e.preventDefault();

    const validationResult = this.validator.validateAll(this.form),
      formIsValid = validationResult.length === 0;

    if (!formIsValid) {
      return;
    }

    if (!this.cpfValid()) {
      alert('O campo de CPF é obrigatório')
      return
    }

    this.setState({ versionValidStatus: true });
    if (this.props.versions && this.props.versions.length > 0) {
      if (!this.versionValid()) {
        this.setState({ versionValidStatus: false });
        return
      }
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, this.getFormData()).then(() => {
      this.resetState();
    });
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e)
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : ''
  }

  /*
   * Verifica se não há opções de contato selecionadas
   */
  contactOptionsValue() {
    return !!(this.state.phoning || this.state.mailing || this.state.whatsapping)
  }

  /**
   * Retorna os dados pertinentes para a conversão.
   * @return {*}
   */
  getFormData() {
    let state = {
      email: this.state.email,
      mailing: this.state.mailing,
      name: this.state.name,
      phone: this.state.phone,
      phoning: this.state.phoning,
      whatsapping: this.state.whatsapping,
      model_car: this.state.model_car,
    };

    if (this.props.showCpf) {
      state.cpf = this.state.cpf
    }

    if (this.props.shouldShowDataPermissions) {
      state.data_permissions = this.state.dataPermissions
    }

    let props = {
      bait: this.props.bait,
      channel: this.props.channel,
      product: this.props.product,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM()
    };

    if (this.props.unit) {
      props['unit'] = this.props.unit;
    } else if (this.props.units.length === 1) {
      props['unit'] = this.props.units[0].value;
    } else {
      props['unit'] = this.state.unit;
    }

    return { ...state, ...props };
  }

  componentDidMount() {
    const updatePhoneValue = (maskValue) => this.setState({ phone: maskValue })
    maskField(MASK_TYPES.PHONE, this.phoneInput, {}, updatePhoneValue);
    this.validator = validationFactory(`#${this.props.formId}`);
  }

  clearForm(e) {
      e.preventDefault();
      this.resetState();
  }

  render() {
    return (
      <div>
        <form
          id={this.props.formId}
          className="form-modal"
          onSubmit={this.handleSubmit}
          ref={form => (this.form = form)}
        >
          <FormMessageOverlay
            handleClose={this.props.handleCloseOverlay}
            isVisible={this.props.showOverlay}
            type={this.props.errorSendingForm ? 'error' : 'success'}
          />
          <header class="form-modal__header">{this.props.titleForm}</header>
          <div className="form-modal__body">
            <header>
              Preencha o formulário abaixo e seja notificado quando houver uma oferta:
            </header>
            <fieldset>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Nome:"
                  name="name"
                  className="form-control"
                  maxLength={80}
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  required
                  data-bouncer-target="#invalid-name"
                />
                <div id="invalid-name" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="E-mail:"
                  name="email"
                  className="form-control"
                  maxLength={80}
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  required
                  data-bouncer-target="#invalid-email"
                />
                <div id="invalid-email" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <input
                  type="phone"
                  placeholder="Telefone/WhatsApp:"
                  name="phone"
                  className="form-control"
                  ref={phoneInput => (this.phoneInput = phoneInput)}
                  value={this.state.phone}
                  required
                  onChange={this.handleInputChange}
                  data-bouncer-target="#invalid-phone"
                />
                <div id="invalid-phone" className="invalid-feedback" />
              </div>

              {
                this.props.showCpf &&
                <InputCpf
                  handleChange={this.handleInputChange}
                  value={this.state.cpf}
                  updateState={value => this.handleUpdateState('cpf', value)}
                />
              }

            {this.props.versions && this.props.versions.length > 0 && (
              <div className="form-group">
                <CustomSelect
                  handleSelectChange={this.handleInputChange}
                  name="model_car"
                  options={this.props.versions}
                  value={this.state.model_car}
                  shouldSort={false}
                  placeholderValue="Escolha um modelo"
                  searchEnabled={true}
                />

                {!this.state.versionValidStatus && (
                  <div id="invalid-version" class="invalid-feedback is-invalid-version">
                    <div class="error-message" id="bouncer-error_version">Por favor, selecione esse campo</div>
                  </div>
                )}
              </div>
            )}

              {!this.props.unit && this.props.units.length > 1 && this.props.showUnits && (
                <div className="form-group">
                  <div className="form-group">
                    <label className="conversion-form__control-label">Escolha a unidade:</label>
                    <CustomSelect
                      handleSelectChange={this.handleInputChange}
                      name="unit"
                      options={this.props.units}
                      value={this.state.unit}
                      shouldSort={false}
                      placeholderValue="Escolha a unidade"
                      searchEnabled={false}
                    />
                  </div>
                </div>
              )}

            </fieldset>
            <fieldset>
              <legend>Quero receber contato por:</legend>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="dark"
                  name="mailing"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.mailing}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  E-mail
                </CustomCheck>
              </div>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="dark"
                  name="whatsapping"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.whatsapping}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Whatsapp
                </CustomCheck>
              </div>
              <div className="form-check form-check-inline">
                <CustomCheck
                  checkStyle="dark"
                  name="phoning"
                  value="true"
                  type="checkbox"
                  isChecked={this.state.phoning}
                  onChangeCheckable={this.handleContactOptionsChange}
                >
                  Telefone
                </CustomCheck>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control d-none"
                  name="contact-options"
                  required
                  placeholder="Opções de contato"
                  data-bouncer-target="#invalid-contact-options"
                  value={this.state.contactOptions}
                  checked={this.state.contactOptions}
                />
                <div id="invalid-contact-options" className="invalid-feedback" />
              </div>
            </fieldset>

            { this.props.shouldShowDataPermissions && (
                <fieldset>
                <div className="form-check form-check-inline conversion-form__data-permissions-field dark">
                    <CustomCheck
                    name="dataPermissions"
                    value="true"
                    isChecked={this.state.dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                    checkStyle="dark"
                    >
                    { this.props.dataPermissionsCustomText}
                    </CustomCheck>
                </div>
                </fieldset>
            )}
            <fieldset className="consent-text mt-2">
              Ao informar meus dados, eu concordo com a{' '}
              <a href={this.props.linkPrivacyPolicy}>Política de privacidade</a>.
            </fieldset>
          </div>
          <footer>
            <a className="clear-fields" href="#" onClick={this.clearForm}>
              Limpar campos
            </a>
            <SubmitButton
              classes="btn form-modal__submit submit-button button button--primary mb-0"
              handleClick={this.handleSubmit}
              label="Solicitar Contato"
              isSubmitting={this.props.isSubmitting}
            />
          </footer>
        </form>
      </div>
    );
  }
}

DirectSalesForm.defaultProps = {
  formId: 'showcase-direct-sales-no-offer-form-modal',
  titleForm: 'Solicitar Contato',
  handleSubmit() { },
  handleCloseOverlay() { },
  isSubmitting: false,
  product: 'Vendas Diretas',
  channel: '',
  category: 'DirectSale',
  bait: '-5',
  units: [],
  showUnits: true,
  showCpf: false,
  shouldShowDataPermissions: false,
  versions: [],
};

DirectSalesForm.propTypes = {
  formId: PropTypes.string.isRequired,
  titleForm: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCloseOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  bait: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  showUnits: PropTypes.bool,
  showCpf: PropTypes.bool,
  shouldShowDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default DirectSalesForm;

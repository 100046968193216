import {
  cnpjMask,
  cpfMask,
  phoneMask,
  priceMask,
  plateMask,
  kmMask,
} from './masks';

export function getMask(maskKey) {
  const masksMap = {
    phone: phoneMask,
    cpf: cpfMask,
    cnpj: cnpjMask,
    price: priceMask,
    plate: plateMask,
    km: kmMask,
  };

  const mask = masksMap[maskKey];
  if (!mask) {
    const errorMessage = `A máscara com a chave '${maskKey}' não é conhecida.`;

    console.warn(errorMessage);
    throw new Error(errorMessage);
  }

  return mask;
}
